import userStatisticsActions from "./action";

const INITIAL_DATA = {
  statistics: null,
  loading: false,
  error: null,
};
export default function userStatisticsReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case userStatisticsActions.GET_USER_STATISTICS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case userStatisticsActions.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload,
        loading: false,
        error: null,
      };
    case userStatisticsActions.GET_STATISTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
