import { call, all, takeEvery, put } from "redux-saga/effects";
import actions from "./actions";
import CloudHawkApi2 from "@iso/config/Services/CloudHawk2";
import qs from "qs";
import Notification from "@iso/components/Notification";

//  ********* 查询产品类型列表和对应的系列 *******   //
function* selectModelMenuRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getModelMenu, payload);
    if (response.code === 100) {
      yield put(actions.selectModelMenuSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectModelMenuFailure(response));
    }
  } catch (error) {
    yield put(actions.selectModelMenuFailure(error));
  }
}
//  ********* 属性列表（该产品已发布的属性）web *******   //
function* selectProductTslRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getProductTsl, payload);
    if (response.code === 100) {
      yield put(actions.selectProductTslSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectProductTslFailure(response));
    }
  } catch (error) {
    yield put(actions.selectProductTslFailure(error));
  }
}

//
//  ********* 设备列表 new 导出 *******   //
function* exportEqNewTslRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.exportEqNew, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.exportEqNewTslSuccess(response.data));
  } catch (error) {
    yield put(actions.exportEqNewTslFailure(error));
  }
}
//  ********* 关联设备列表 new 导出 *******   //
function* exportEqNewAssociatedRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.exportEqNewA, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.exportEqNewAssociatedSuccess(response.data));
  } catch (error) {
    yield put(actions.exportEqNewAssociatedFailure(error));
  }
}
//  ********* 上下线记录 new 导出 *******   //
function* exportEqNewOnlineRecordRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.exportEqNewEQ, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.exportEqNewOnlineRecordSuccess(response.data));
  } catch (error) {
    yield put(actions.exportEqNewOnlineRecordFailure(error));
  }
}
//  ********* 设备上报日志 new 导出 *******   //
function* exportEqNewDeviceReportRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.exportEqNewDR, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.exportEqNewDeviceReportSuccess(response.data));
  } catch (error) {
    yield put(actions.exportEqNewDeviceReportFailure(error));
  }
}
//  ********* 设备操作日志 new 导出 *******   //
function* exportEqNewDeviceOperationRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.exportEqNewDO, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.exportEqNewDeviceOperationSuccess(response.data));
  } catch (error) {
    yield put(actions.exportEqNewDeviceOperationFailure(error));
  }
}

//  ********* 设备列表 old 导出 *******   //
function* exportEqOldTslListRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.exportEqOldList, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.exportEqOldTslListSuccess(response.data));
  } catch (error) {
    yield put(actions.exportEqOldTslListFailure(error));
  }
}
//  ********* 设备列表 old 模板下载 *******   //
function* exportEqOldTslRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.exportEqOld, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.exportEqOldTslSuccess(response.data));
  } catch (error) {
    yield put(actions.exportEqOldTslFailure(error));
  }
}
//  ********* 设备列表 old 异常结果导出 *******   //
function* exportEqOldErrorRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.exportEqOldError, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.exportEqOldErrorSuccess(response.data));
  } catch (error) {
    yield put(actions.exportEqOldErrorFailure(error));
  }
}

function* unbindManageUsersRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.unbindManageUsers, payload);
    if (response.code === 100) {
      yield put(actions.unbindManageUsersSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.unbindManageUsersFailure(response));
    }
  } catch (error) {
    yield put(actions.unbindManageUsersFailure(error));
  }
}

//  ********* ManageUsers 导出异常信息 *******   //
function* exportManageUsersErrorRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.exportManageUsersError, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.exportManageUsersErrorSuccess(response.data));
  } catch (error) {
    yield put(actions.exportManageUsersErrorFailure(error));
  }
}

//
// 获取 设备管理/详情 list
//  *********  select 物模型属性 *******   //
function* selectEqNewTslRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getEqNewTslSelect, payload);
    if (response.code === 100) {
      yield put(actions.selectEqNewTslSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqNewTslFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqNewTslFailure(error));
  }
}
//  *********  select 关联设备列表 *******   //
function* selectEqNewAssociatedRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.getEqNewAssociatedSelect,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.selectEqNewAssociatedSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqNewAssociatedFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqNewAssociatedFailure(error));
  }
}
//  *********  select 上下线记录 *******   //
function* selectEqNewOnlineRecordRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.getEqNewOnlineRecordSelect,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.selectEqNewOnlineRecordSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqNewOnlineRecordFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqNewOnlineRecordFailure(error));
  }
}
//  *********  select 充电记录 *******   //
function* selectEqOldChargeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getEqOldChargeSelect, payload);
    if (response.code === 100) {
      yield put(actions.selectEqOldChargeSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqOldChargeFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqOldChargeFailure(error));
  }
}
//  *********  select 割草记录 *******   //
function* selectEqOldCutGrassRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getEqOldCutGrassSelect, payload);
    if (response.code === 100) {
      yield put(actions.selectEqOldCutGrassSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqOldCutGrassFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqOldCutGrassFailure(error));
  }
}
//  *********  select 故障记录 *******   //
function* selectEqOldFaultRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getEqOldFaultSelect, payload);
    if (response.code === 100) {
      yield put(actions.selectEqOldFaultSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqOldFaultFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqOldFaultFailure(error));
  }
}
//  *********  select 设备上报日志 *******   //
function* selectEqNewDeviceReportRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.getEqNewDeviceReportSelect,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.selectEqNewDeviceReportSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqNewDeviceReportFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqNewDeviceReportFailure(error));
  }
}
//  *********  select 设备操作日志 *******   //
function* selectEqNewDeviceOperationRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.getEqNewDeviceOperationSelect,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.selectEqNewDeviceOperationSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqNewDeviceOperationFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqNewDeviceOperationFailure(error));
  }
}
//  *********  select 组件更换记录 *******   //
function* selectEqNewSeriesChangeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.getEqNewSeriesChangeSelect,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.selectEqNewSeriesChangeSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqNewSeriesChangeFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqNewSeriesChangeFailure(error));
  }
}
// old
//  *********  select 上下线记录 old *******   //
function* selectEqNewOnlineRecordOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.getEqNewOnlineRecordSelectOld,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.selectEqNewOnlineRecordOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqNewOnlineRecordOldFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqNewOnlineRecordOldFailure(error));
  }
}
//  *********  select 设备上报日志 old *******   //
function* selectEqNewDeviceReportOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.getEqNewDeviceReportSelectOld,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.selectEqNewDeviceReportOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqNewDeviceReportOldFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqNewDeviceReportOldFailure(error));
  }
}

//
// 获取 select
//  *********  获取 new+old 产品型号 *******   //
function* selectEqAllProductTypeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectEqAllProductType, payload);
    if (response.code === 100) {
      yield put(actions.selectEqAllProductTypeSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqAllProductTypeFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqAllProductTypeFailure(error));
  }
}
//  *********  获取 new 产品型号 *******   //
function* selectEqNewProductTypeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectEqNewProductType, payload);
    if (response.code === 100) {
      yield put(actions.selectEqNewProductTypeSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqNewProductTypeFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqNewProductTypeFailure(error));
  }
}
//  *********  获取 old 产品型号 *******   //
function* selectEqOldProductTypeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectEqOldProductType, payload);
    if (response.code === 100) {
      yield put(actions.selectEqOldProductTypeSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqOldProductTypeFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqOldProductTypeFailure(error));
  }
}
//  *********  获取 old 产品品牌 *******   //
function* selectEqOldProductBrandRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectEqOldProductBrand, payload);
    if (response.code === 100) {
      yield put(actions.selectEqOldProductBrandSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqOldProductBrandFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqOldProductBrandFailure(error));
  }
}
//  *********  获取 old 产品类型 Category *******   //
function* selectEqOldProductCategoryRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.selectEqOldProductCategory,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.selectEqOldProductCategorySuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqOldProductCategoryFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqOldProductCategoryFailure(error));
  }
}
//  *********  获取 old 产品系列 Model *******   //
function* selectEqOldProductModelRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectEqOldProductModel, payload);
    if (response.code === 100) {
      yield put(actions.selectEqOldProductModelSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectEqOldProductModelFailure(response));
    }
  } catch (error) {
    yield put(actions.selectEqOldProductModelFailure(error));
  }
}
//  *********  获取 ia厂商管理 Supplier *******   //
function* selectSupplierRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectSupplier, payload);
    if (response.code === 100) {
      yield put(actions.selectSupplierSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectSupplierFailure(response));
    }
  } catch (error) {
    yield put(actions.selectSupplierFailure(error));
  }
}

//
// create
//  *********  create 新平台设备 *******   //
function* createEqNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createEqNew, payload);
    if (response.code === 100) {
      yield put(actions.createEqNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createEqNewFailure(response));
    }
  } catch (error) {
    yield put(actions.createEqNewFailure(error));
  }
}
//  *********  create 厂商管理 Supplier *******   //
function* createSupplierRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createSupplier, payload);
    if (response.code === 100) {
      yield put(actions.createSupplierSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createSupplierFailure(response));
    }
  } catch (error) {
    yield put(actions.createSupplierFailure(error));
  }
}

//
// edit
//  *********  edit  old 平台设备 *******   //
function* editEqOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editEqOld, payload);
    if (response.code === 100) {
      yield put(actions.editEqOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editEqOldFailure(response));
    }
  } catch (error) {
    yield put(actions.editEqOldFailure(error));
  }
}
//  *********  edit  厂商管理 Supplier *******   //
function* editSupplierRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editSupplier, payload);
    if (response.code === 100) {
      yield put(actions.editSupplierSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editSupplierFailure(response));
    }
  } catch (error) {
    yield put(actions.editSupplierFailure(error));
  }
}

//
// 获取 detail
//  *********  获取 新平台设备 detail *******   //
function* detailEqNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailEqNew, payload);
    if (response.code === 100) {
      yield put(actions.detailEqNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.detailEqNewFailure(response));
    }
  } catch (error) {
    yield put(actions.detailEqNewFailure(error));
  }
}
//  *********  获取 新平台设备 concat *******   //
function* concatEqNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.concatEqNew, payload);
    if (response.code === 100) {
      yield put(actions.concatEqNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.concatEqNewFailure(response));
    }
  } catch (error) {
    yield put(actions.concatEqNewFailure(error));
  }
}
//  *********  获取 老平台设备 detail *******   //
function* detailEqOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailEqOld, payload);
    if (response.code === 100) {
      yield put(actions.detailEqOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.detailEqOldFailure(response));
    }
  } catch (error) {
    yield put(actions.detailEqOldFailure(error));
  }
}
//  *********  获取 老平台设备 concat *******   //
function* concatEqOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.concatEqOld, payload);
    if (response.code === 100) {
      yield put(actions.concatEqOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.concatEqOldFailure(response));
    }
  } catch (error) {
    yield put(actions.concatEqOldFailure(error));
  }
}

//
// 获取 List
//  *********  获取 新平台设备 List *******   //
function* fetchEqNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchEqNew, payload);
    if (response.code === 100) {
      yield put(actions.fetchEqNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchEqNewFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchEqNewFailure(error));
  }
}
//  *********  获取 老平台设备 List *******   //
function* fetchEqOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchEqOld, payload);
    if (response.code === 100) {
      yield put(actions.fetchEqOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchEqOldFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchEqOldFailure(error));
  }
}
//  *********  获取 厂商管理 List *******   //
function* fetchSupplierRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchSupplier, payload);
    if (response.code === 100) {
      yield put(actions.fetchSupplierSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchSupplierFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchSupplierFailure(error));
  }
}

//
// delete
//  *********  delete 新平台设备 *******   //
function* deleteEqNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteEqNew, payload);
    if (response.code === 100) {
      yield put(actions.deleteEqNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteEqNewFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteEqNewFailure(error));
  }
}
//  *********  delete 老平台设备 *******   //
function* deleteEqOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteEqOld, payload);
    if (response.code === 100) {
      yield put(actions.deleteEqOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteEqOldFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteEqOldFailure(error));
  }
}
//  *********  delete 厂商管理 Supplier *******   //
function* deleteSupplierRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteSupplier, payload);
    if (response.code === 100) {
      yield put(actions.deleteSupplierSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteSupplierFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteSupplierFailure(error));
  }
}

//
//  *********  unbind 老 平台设备 *******   //
function* unbindEqOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.unbindEqOld, payload);
    if (response.code === 100) {
      yield put(actions.unbindEqOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.unbindEqOldFailure(response));
    }
  } catch (error) {
    yield put(actions.unbindEqOldFailure(error));
  }
}

export default function* equipmentSaga() {
  yield all([
    takeEvery(actions.SELECT_MODEL_MENU_REQUEST, selectModelMenuRequest),
    takeEvery(actions.SELECT_PRODUCT_TSL_REQUEST, selectProductTslRequest),

    takeEvery(actions.EXPORT_EQ_NEW_REQUEST, exportEqNewTslRequest),
    takeEvery(actions.EXPORT_EQ_NEW_A_REQUEST, exportEqNewAssociatedRequest),
    takeEvery(actions.EXPORT_EQ_NEW_OR_REQUEST, exportEqNewOnlineRecordRequest),
    takeEvery(actions.EXPORT_EQ_NEW_DR_REQUEST, exportEqNewDeviceReportRequest),
    takeEvery(
      actions.EXPORT_EQ_NEW_DO_REQUEST,
      exportEqNewDeviceOperationRequest,
    ),

    takeEvery(actions.EXPORT_EQ_OLD_LIST_REQUEST, exportEqOldTslListRequest),
    takeEvery(actions.EXPORT_EQ_OLD_REQUEST, exportEqOldTslRequest),
    takeEvery(actions.EXPORT_EQ_OLD_ERROE_REQUEST, exportEqOldErrorRequest),

    takeEvery(actions.UNBIND_USER_REQUEST, unbindManageUsersRequest),

    takeEvery(
      actions.EXPORT_MANAGE_USER_ERROE_REQUEST,
      exportManageUsersErrorRequest,
    ),

    takeEvery(actions.SELECT_EQ_NEW_T_REQUEST, selectEqNewTslRequest),
    takeEvery(actions.SELECT_EQ_NEW_A_REQUEST, selectEqNewAssociatedRequest),
    takeEvery(actions.SELECT_EQ_NEW_OR_REQUEST, selectEqNewOnlineRecordRequest),
    takeEvery(actions.SELECT_EQ_NEW_DR_REQUEST, selectEqNewDeviceReportRequest),
    takeEvery(
      actions.SELECT_EQ_NEW_DO_REQUEST,
      selectEqNewDeviceOperationRequest,
    ),
    takeEvery(actions.SELECT_EQ_NEW_SC_REQUEST, selectEqNewSeriesChangeRequest),
    //
    takeEvery(actions.SELECT_EQ_OLD_C_REQUEST, selectEqOldChargeRequest), // 充电记录 old
    takeEvery(actions.SELECT_EQ_OLD_CG_REQUEST, selectEqOldCutGrassRequest), // 割草记录 old
    takeEvery(actions.SELECT_EQ_OLD_F_REQUEST, selectEqOldFaultRequest), // 故障记录 old
    //
    takeEvery(
      actions.SELECT_EQ_NEW_OR_OLD_REQUEST,
      selectEqNewOnlineRecordOldRequest,
    ),
    takeEvery(
      actions.SELECT_EQ_NEW_DR_OLD_REQUEST,
      selectEqNewDeviceReportOldRequest,
    ),

    takeEvery(
      actions.SELECT_EQ_ALL_PRODUCT_TYPE_REQUEST,
      selectEqAllProductTypeRequest,
    ),
    takeEvery(
      actions.SELECT_EQ_NEW_PRODUCT_TYPE_REQUEST,
      selectEqNewProductTypeRequest,
    ),
    takeEvery(
      actions.SELECT_EQ_OLD_PRODUCT_TYPE_REQUEST,
      selectEqOldProductTypeRequest,
    ),
    takeEvery(
      actions.SELECT_EQ_OLD_PRODUCT_BRAND_REQUEST,
      selectEqOldProductBrandRequest,
    ),
    takeEvery(
      actions.SELECT_EQ_OLD_PRODUCT_CATEGORY_REQUEST,
      selectEqOldProductCategoryRequest,
    ),
    takeEvery(
      actions.SELECT_EQ_OLD_PRODUCT_MODEL_REQUEST,
      selectEqOldProductModelRequest,
    ),
    takeEvery(actions.SELECT_SUPPLIER_REQUEST, selectSupplierRequest),

    takeEvery(actions.CREATE_EQ_NEW_REQUEST, createEqNewRequest),
    takeEvery(actions.CREATE_SUPPLIER_REQUEST, createSupplierRequest),

    takeEvery(actions.EDIT_EQ_OLD_REQUEST, editEqOldRequest),
    takeEvery(actions.EDIT_SUPPLIER_REQUEST, editSupplierRequest),

    // 获取 detail / concat
    takeEvery(actions.DETAIL_EQ_NEW_REQUEST, detailEqNewRequest),
    takeEvery(actions.CONCAT_EQ_NEW_REQUEST, concatEqNewRequest),
    takeEvery(actions.DETAIL_EQ_OLD_REQUEST, detailEqOldRequest),
    takeEvery(actions.CONCAT_EQ_OLD_REQUEST, concatEqOldRequest),

    // 获取 List
    takeEvery(actions.FETCH_EQ_NEW_REQUEST, fetchEqNewRequest),
    takeEvery(actions.FETCH_EQ_OLD_REQUEST, fetchEqOldRequest),
    takeEvery(actions.FETCH_SUPPLIER_REQUEST, fetchSupplierRequest),

    // delete
    takeEvery(actions.DELETE_EQ_NEW_REQUEST, deleteEqNewRequest),
    takeEvery(actions.DELETE_EQ_OLD_REQUEST, deleteEqOldRequest),
    takeEvery(actions.DELETE_SUPPLIER_REQUEST, deleteSupplierRequest),

    // unbind
    takeEvery(actions.UNBIND_EQ_OLD_REQUEST, unbindEqOldRequest),
  ]);
}
