const productStatisticsActions = {
  //Fetch product Statistics Data
  GET_PRODUCT_STATISTICS_REQUEST: "GET_PRODUCT_STATISTICS_REQUEST",
  GET_PRODUCT_STATISTICS_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_PRODUCT_STATISTICS_FAILURE: "GET_PRODUCT_STATISTICS_FAILURE",
  getProductStatisticsRequest: (request) => {
    return {
      type: productStatisticsActions.GET_PRODUCT_STATISTICS_REQUEST,
      payload: request,
    };
  },
  getProductStatisticsSuccess: (user) => {
    return {
      type: productStatisticsActions.GET_PRODUCT_STATISTICS_SUCCESS,
      payload: user,
    };
  },
  getProductStatisticsFailure: (error) => ({
    type: productStatisticsActions.GET_PRODUCT_STATISTICS_FAILURE,
    payload: error,
  }),
};

export default productStatisticsActions;
