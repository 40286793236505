import adsActions from "./actions";

const INITIAL_DATA = {
  adsdataTotal: 0,
  adsdata: [],
  editAds: [],
  addAds: [],
  loading: true,
  error: null,
};
export default function adsReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case adsActions.FETCH_ADS_DATA_REQUEST:
      return {
        ...state,
        adsdata: [],
        loading: true,
        error: null,
      };
    case adsActions.FETCH_ADS_DATA_SUCCESS:
      let adsdata = Object.assign([], action.payload);
      adsdata.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        adsdata: action.payload.list,
        adsdataTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case adsActions.FETCH_ADS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case adsActions.ADD_ADS_REQUEST:
      return {
        ...state,
        addAds: [],
        loading: true,
        error: null,
      };
    case adsActions.ADD_ADS_SUCCESS:
      return {
        ...state,
        addAds: action.payload,
        loading: false,
        error: null,
      };
    case adsActions.ADD_ADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // EDIT ADS
    case adsActions.EDIT_ADS_REQUEST:
      return {
        ...state,
        editAds: [],
        loading: true,
        error: null,
      };
    case adsActions.EDIT_ADS_SUCCESS:
      return {
        ...state,
        editAds: action.payload,
        loading: false,
        error: null,
      };
    case adsActions.EDIT_ADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case adsActions.DELETE_ADS_REQUEST:
      return {
        ...state,
        adsdata: [],
        loading: true,
        error: null,
      };
    case adsActions.DELETE_ADS_SUCCESS:
      return {
        ...state,
        adsdata: action.payload,
        loading: false,
        error: null,
      };
    case adsActions.DELETE_ADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
