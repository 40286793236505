import { call, all, takeEvery, put } from "redux-saga/effects";
import productStatisticsActions from "./action";
import CloudHawkApi from "@iso/config/Services/CloudHawk";

function* getProductStatisticsRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getOrderStatistics, payload);
    yield put(
      productStatisticsActions.getProductStatisticsSuccess(response.data),
    );
  } catch (error) {
    yield put(productStatisticsActions.getProductStatisticsFailure(error));
  }
}

export default function* productStatisticsSaga() {
  yield all([
    takeEvery(
      productStatisticsActions.GET_PRODUCT_STATISTICS_REQUEST,
      getProductStatisticsRequest,
    ),
  ]);
}
