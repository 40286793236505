const IAAddressManageActions = {
  GET_IAAddressManage_List_REQUEST: "GET_IAAddressManage_List_REQUEST",
  GET_IAAddressManage_List_SUCCESS: "GET_IAAddressManage_List_SUCCESS",
  GET_IAAddressManage_List_ERROR: "GET_IAAddressManage_List_ERROR",

  ADD_IAAddressManage_List_REQUEST: "ADD_IAAddressManage_List_REQUEST",
  ADD_IAAddressManage_List_SUCCESS: "ADD_IAAddressManage_List_SUCCESS",
  ADD_IAAddressManage_List_ERROR: "ADD_IAAddressManage_List_ERROR",

  EDIT_IAAddressManage_List_REQUEST: "EDIT_IAAddressManage_List_REQUEST",
  EDIT_IAAddressManage_List_SUCCESS: "EDIT_IAAddressManage_List_SUCCESS",
  EDIT_IAAddressManage_List_ERROR: "EDIT_IAAddressManage_List_ERROR",

  DEL_IAAddressManage_List_REQUEST: "DEL_IAAddressManage_List_REQUEST",
  DEL_IAAddressManage_List_SUCCESS: "DEL_IAAddressManage_List_SUCCESS",
  DEL_IAAddressManage_List_ERROR: "DEL_IAAddressManage_List_ERROR",

  GET_IAAddressManage_Detail_REQUEST: "GET_IAAddressManage_Detail_REQUEST",
  GET_IAAddressManage_Detail_SUCCESS: "GET_IAAddressManage_Detail_SUCCESS",
  GET_IAAddressManage_Detail_ERROR: "GET_IAAddressManage_Detail_ERROR",

  GET_IAAddressManage_Select_REQUEST: "GET_IAAddressManage_Select_REQUEST",
  GET_IAAddressManage_Select_SUCCESS: "GET_IAAddressManage_Select_SUCCESS",
  GET_IAAddressManage_Select_ERROR: "GET_IAAddressManage_Select_ERROR",

  fetchGETIAAddressManageSelect: () => ({
    type: IAAddressManageActions.GET_IAAddressManage_Select_REQUEST,
  }),
  fetchGETIAAddressManageSelectSuccess: (data) => ({
    type: IAAddressManageActions.GET_IAAddressManage_Select_SUCCESS,
    payload: data,
  }),
  fetchGETIAAddressManageSelectError: (error) => ({
    type: IAAddressManageActions.GET_IAAddressManage_Select_ERROR,
    payload: error,
  }),

  fetchGETIAAddressManageDetail: (request) => ({
    type: IAAddressManageActions.GET_IAAddressManage_Detail_REQUEST,
    payload: request,
  }),
  fetchGETIAAddressManageDetailSuccess: (data) => ({
    type: IAAddressManageActions.GET_IAAddressManage_Detail_SUCCESS,
    payload: data,
  }),
  fetchGETIAAddressManageDetailError: (error) => ({
    type: IAAddressManageActions.GET_IAAddressManage_Detail_ERROR,
    payload: error,
  }),

  fetchGETIAAddressManageList: (request) => ({
    type: IAAddressManageActions.GET_IAAddressManage_List_REQUEST,
    payload: request,
  }),
  fetchGETIAAddressManageListSuccess: (data) => ({
    type: IAAddressManageActions.GET_IAAddressManage_List_SUCCESS,
    payload: data,
  }),
  fetchGETIAAddressManageListError: (error) => ({
    type: IAAddressManageActions.GET_IAAddressManage_List_ERROR,
    payload: error,
  }),

  fetchADDIAAddressManageList: (request) => ({
    type: IAAddressManageActions.ADD_IAAddressManage_List_REQUEST,
    payload: request,
  }),
  fetchADDIAAddressManageListSuccess: (data) => ({
    type: IAAddressManageActions.ADD_IAAddressManage_List_SUCCESS,
    payload: data,
  }),
  fetchADDIAAddressManageListError: (error) => ({
    type: IAAddressManageActions.ADD_IAAddressManage_List_ERROR,
    payload: error,
  }),

  fetchEDITIAAddressManageList: (request) => ({
    type: IAAddressManageActions.EDIT_IAAddressManage_List_REQUEST,
    payload: request,
  }),
  fetchEDITIAAddressManageListSuccess: (data) => ({
    type: IAAddressManageActions.EDIT_IAAddressManage_List_SUCCESS,
    payload: data,
  }),
  fetchEDITIAAddressManageListError: (error) => ({
    type: IAAddressManageActions.EDIT_IAAddressManage_List_ERROR,
    payload: error,
  }),

  fetchDELIAAddressManageList: (request) => ({
    type: IAAddressManageActions.DEL_IAAddressManage_List_REQUEST,
    payload: request,
  }),
};

export default IAAddressManageActions;
