const mallActions = {
  // GET MALL BRANDS
  GET_MALL_BRANDS_REQUEST: "GET_MALL_BRANDS_REQUEST",
  GET_MALL_BRANDS_SUCCESS: "GET_MALL_BRANDS_SUCCESS",
  GET_MALL_BRANDS_FAILURE: "GET_MALL_BRANDS_FAILURE",
  getMallBrandsDataRequest: (request) => ({
    type: mallActions.GET_MALL_BRANDS_REQUEST,
    payload: request,
  }),
  getMallBrandsDataSuccess: (brands) => ({
    type: mallActions.GET_MALL_BRANDS_SUCCESS,
    payload: brands,
  }),
  getMallBrandsDataFailure: (error) => ({
    type: mallActions.GET_MALL_BRANDS_FAILURE,
    payload: error,
  }),

  // GET MALL CATEGORIES
  GET_MALL_CATEGORIES_REQUEST: "GET_MALL_CATEGORIES_REQUEST",
  GET_MALL_CATEGORIES_SUCCESS: "GET_MALL_CATEGORIES_SUCCESS",
  GET_MALL_CATEGORIES_FAILURE: "GET_MALL_CATEGORIES_FAILURE",
  getMallCategoriesDataRequest: (request) => ({
    type: mallActions.GET_MALL_CATEGORIES_REQUEST,
    payload: request,
  }),
  getMallCategoriesDataSuccess: (categories) => ({
    type: mallActions.GET_MALL_CATEGORIES_SUCCESS,
    payload: categories,
  }),
  getMallCategoriesDataFailure: (error) => ({
    type: mallActions.GET_MALL_CATEGORIES_FAILURE,
    payload: error,
  }),

  // GET MALL Products
  GET_MALL_PRODUCTS_REQUEST: "GET_MALL_PRODUCTS_REQUEST",
  GET_MALL_PRODUCTS_SUCCESS: "GET_MALL_PRODUCTS_SUCCESS",
  GET_MALL_PRODUCTS_FAILURE: "GET_MALL_PRODUCTS_FAILURE",
  getMallProductRequest: (request) => ({
    type: mallActions.GET_MALL_PRODUCTS_REQUEST,
    payload: request,
  }),
  getMallProductSuccess: (categories) => ({
    type: mallActions.GET_MALL_PRODUCTS_SUCCESS,
    payload: categories,
  }),
  getMallProductFailure: (error) => ({
    type: mallActions.GET_MALL_PRODUCTS_FAILURE,
    payload: error,
  }),

  // CREATE MALL PRODUCT CATEGORIES
  CREATE_MALL_PRODUCT_REQUEST: "CREATE_MALL_PRODUCT_REQUEST",
  CREATE_MALL_PRODUCT_SUCCESS: "CREATE_MALL_PRODUCT_SUCCESS",
  CREATE_MALL_PRODUCT_FAILURE: "CREATE_MALL_PRODUCT_FAILURE",
  createMallProductDataRequest: (request) => ({
    type: mallActions.CREATE_MALL_PRODUCT_REQUEST,
    payload: request,
  }),
  createMallProductDataSuccess: (product) => ({
    type: mallActions.CREATE_MALL_PRODUCT_SUCCESS,
    payload: product,
  }),
  createMallProductDataFailure: (error) => ({
    type: mallActions.CREATE_MALL_PRODUCT_FAILURE,
    payload: error,
  }),

  // GET MALL Promotions
  GET_MALL_PROMOTIONS_REQUEST: "GET_MALL_PROMOTIONS_REQUEST",
  GET_MALL_PROMOTIONS_SUCCESS: "GET_MALL_PROMOTIONS_SUCCESS",
  GET_MALL_PROMOTIONS_FAILURE: "GET_MALL_PROMOTIONS_FAILURE",
  getMallPromotionRequest: (request) => ({
    type: mallActions.GET_MALL_PROMOTIONS_REQUEST,
    payload: request,
  }),
  getMallPromotionSuccess: (categories) => ({
    type: mallActions.GET_MALL_PROMOTIONS_SUCCESS,
    payload: categories,
  }),
  getMallPromotionFailure: (error) => ({
    type: mallActions.GET_MALL_PROMOTIONS_FAILURE,
    payload: error,
  }),

  // CREATE MALL PROMOTION CATEGORIES
  CREATE_MALL_PROMOTION_REQUEST: "CREATE_MALL_PROMOTION_REQUEST",
  CREATE_MALL_PROMOTION_SUCCESS: "CREATE_MALL_PROMOTION_SUCCESS",
  CREATE_MALL_PROMOTION_FAILURE: "CREATE_MALL_PROMOTION_FAILURE",
  createMallPromotionDataRequest: (request) => ({
    type: mallActions.CREATE_MALL_PROMOTION_REQUEST,
    payload: request,
  }),
  createMallPromotionDataSuccess: (product) => ({
    type: mallActions.CREATE_MALL_PROMOTION_SUCCESS,
    payload: product,
  }),
  createMallPromotionDataFailure: (error) => ({
    type: mallActions.CREATE_MALL_PROMOTION_FAILURE,
    payload: error,
  }),

  // EDIT MALL PRODUCT CATEGORIES
  EDIT_MALL_PRODUCT_REQUEST: "EDIT_MALL_PRODUCT_REQUEST",
  EDIT_MALL_PRODUCT_SUCCESS: "EDIT_MALL_PRODUCT_SUCCESS",
  EDIT_MALL_PRODUCT_FAILURE: "EDIT_MALL_PRODUCT_FAILURE",
  editMallProductDataRequest: (request) => ({
    type: mallActions.EDIT_MALL_PRODUCT_REQUEST,
    payload: request,
  }),
  editMallProductDataSuccess: (product) => ({
    type: mallActions.EDIT_MALL_PRODUCT_SUCCESS,
    payload: product,
  }),
  editMallProductDataFailure: (error) => ({
    type: mallActions.EDIT_MALL_PRODUCT_FAILURE,
    payload: error,
  }),

  // GET Mall Promotion
  GET_MALL_PROMOTION_REQUEST: "GET_MALL_PROMOTION_REQUEST",
  GET_MALL_PROMOTION_SUCCESS: "GET_MALL_PROMOTION_SUCCESS",
  GET_MALL_PROMOTION_FAILURE: "GET_MALL_PROMOTION_FAILURE",
  getPromotionDataRequest: (request) => ({
    type: mallActions.GET_MALL_PROMOTION_REQUEST,
    payload: request,
  }),
  getPromotionDataSuccess: (promotion) => ({
    type: mallActions.GET_MALL_PROMOTION_SUCCESS,
    payload: promotion,
  }),
  getPromotionDataFailure: (error) => ({
    type: mallActions.GET_MALL_PROMOTION_FAILURE,
    payload: error,
  }),

  // Edit Mall Promotion
  EDIT_MALL_PROMOTION_REQUEST: "EDIT_MALL_PROMOTION_REQUEST",
  EDIT_MALL_PROMOTION_SUCCESS: "EDIT_MALL_PROMOTION_SUCCESS",
  EDIT_MALL_PROMOTION_FAILURE: "EDIT_MALL_PROMOTION_FAILURE",
  editPromotionDataRequest: (request) => ({
    type: mallActions.EDIT_MALL_PROMOTION_REQUEST,
    payload: request,
  }),
  editPromotionDataSuccess: (product) => ({
    type: mallActions.EDIT_MALL_PROMOTION_SUCCESS,
    payload: product,
  }),
  editPromotionDataFailure: (error) => ({
    type: mallActions.EDIT_MALL_PROMOTION_FAILURE,
    payload: error,
  }),

  // Delete Mall Promotion
  DELETE_MALL_PROMOTION_REQUEST: "DELETE_MALL_PROMOTION_REQUEST",
  DELETE_MALL_PROMOTION_SUCCESS: "DELETE_MALL_PROMOTION_SUCCESS",
  DELETE_MALL_PROMOTION_FAILURE: "DELETE_MALL_PROMOTION_FAILURE",
  deletePromotionDataRequest: (request) => ({
    type: mallActions.DELETE_MALL_PROMOTION_REQUEST,
    payload: request,
  }),
  deletePromotionDataSuccess: (product) => ({
    type: mallActions.DELETE_MALL_PROMOTION_SUCCESS,
    payload: product,
  }),
  deletePromotionDataFailure: (error) => ({
    type: mallActions.DELETE_MALL_PROMOTION_FAILURE,
    payload: error,
  }),
};

export default mallActions;
