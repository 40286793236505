import { call, all, takeEvery, put } from "redux-saga/effects";
import mallActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";
import { createBrowserHistory } from "history";
import Notification from "@iso/components/Notification";

const history = createBrowserHistory();
const alert = (response) => {
  Notification("success", response.message);
};

const Error = (response) => {
  Notification("error", response.message);
};

//GET MALL BRANDS
function* getMallBrandsRequest() {
  try {
    const response = yield call(CloudHawkApi.getMallBrands);
    yield put(mallActions.getMallBrandsDataSuccess(response.data));
  } catch (error) {
    yield put(mallActions.getMallBrandsDataFailure(error));
  }
}

//GET MALL CATEGORIES
function* getMallCategoriesDataEffect() {
  try {
    const response = yield call(CloudHawkApi.getMallCategories);
    yield put(mallActions.getMallCategoriesDataSuccess(response.data));
  } catch (error) {
    yield put(mallActions.getMallCategoriesDataFailure(error));
  }
}

//GET MALL products
function* getMallProductRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getMallProducts, payload);
    yield put(mallActions.getMallProductSuccess(response.data));
  } catch (error) {
    yield put(mallActions.getMallProductFailure(error));
  }
}

//CREATE MALL PRODUCT
function* createMallProductDataEffect(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createProduct, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
      history.push("/dashboard/MallManagement");
      const params = {
        categoryId: "",
        brandId: "",
        countryIds: [],
      };
      getMallProductRequest(params);
    }
    yield put(mallActions.createMallProductDataSuccess(response.data));
  } catch (error) {
    yield put(mallActions.createMallProductDataFailure(error));
  }
}

//GET MALL Promotion
function* getMallPromotionRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getMallPromotions);
    yield put(mallActions.getMallPromotionSuccess(response.data));
  } catch (error) {
    yield put(mallActions.getMallPromotionFailure(error));
  }
}

//CREATE MALL PROMOTION
function* createMallPromotionDataEffect(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createMallPromotion, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
      history.push("/dashboard/MallPromotions");
      const params = {
        categoryId: "",
        brandId: "",
        countryIds: [],
      };
      getMallPromotionRequest(params);
    }
    yield put(mallActions.createMallPromotionDataSuccess(response.data));
  } catch (error) {
    yield put(mallActions.createMallPromotionDataFailure(error));
  }
}

//EDIT MALL BRANDS
function* editMallProductDataEffect(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editProduct, payload);
    yield put(mallActions.editMallProductDataSuccess(response.data));
  } catch (error) {
    yield put(mallActions.editMallProductDataFailure(error));
  }
}

function* getPromotionRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getMallPromotionDetails, payload);
    yield put(mallActions.getPromotionDataSuccess(response.data));
  } catch (error) {
    yield put(mallActions.getPromotionDataFailure(error));
  }
}

function* editPromotionRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editMallPromotion, payload);
    if (response && response.success) {
      alert(response);
    } else {
      Notification("error", "Error");
    }
    yield put(mallActions.editPromotionDataSuccess(response.data));
  } catch (error) {
    yield put(mallActions.editPromotionDataFailure(error));
  }
}

function* deletePromotionRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteMallPromotion, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
    yield put(mallActions.deletePromotionDataSuccess(response.data));
  } catch (error) {
    yield put(mallActions.deletePromotionDataFailure(error));
  }
}

export default function* mallManagementSaga() {
  yield all([
    takeEvery(mallActions.GET_MALL_BRANDS_REQUEST, getMallBrandsRequest),
    takeEvery(
      mallActions.GET_MALL_CATEGORIES_REQUEST,
      getMallCategoriesDataEffect,
    ),
    takeEvery(mallActions.GET_MALL_PROMOTION_REQUEST, getPromotionRequest),
    takeEvery(
      mallActions.CREATE_MALL_PRODUCT_REQUEST,
      createMallProductDataEffect,
    ),
    takeEvery(
      mallActions.CREATE_MALL_PROMOTION_REQUEST,
      createMallPromotionDataEffect,
    ),
    takeEvery(mallActions.EDIT_MALL_PRODUCT_REQUEST, editMallProductDataEffect),
    takeEvery(mallActions.EDIT_MALL_PROMOTION_REQUEST, editPromotionRequest),
    takeEvery(mallActions.GET_MALL_PRODUCTS_REQUEST, getMallProductRequest),
    takeEvery(mallActions.GET_MALL_PROMOTIONS_REQUEST, getMallPromotionRequest),
    takeEvery(
      mallActions.DELETE_MALL_PROMOTION_REQUEST,
      deletePromotionRequest,
    ),
  ]);
}
