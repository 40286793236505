import { call, all, takeEvery, put } from "redux-saga/effects";
import EditProductTemplateActions2 from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};
const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

//获取当前模板所有信息
function* getEditProductTemplateList2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getTemplatePowerInfoList, payload);
    if (!response.success) {
      Error(response);
    }
    yield put(
      EditProductTemplateActions2.fetchGETEditProductTemplateListSuccess2(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      EditProductTemplateActions2.fetchGETEditProductTemplateListError2(error),
    );
  }
}

// Modular 下拉
function* getModularList2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getModularList, payload);
    if (response.success) {
      yield put(
        EditProductTemplateActions2.fetchGETModularListSuccess2(response.data),
      );
    } else {
      Error(response);
      yield put(
        EditProductTemplateActions2.fetchGETModularListError2(response),
      );
    }
  } catch (error) {
    yield put(EditProductTemplateActions2.fetchGETModularListError2(error));
  }
}
// 获取次级页面下拉列表
function* getSecondary2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getSecondarySelect2, payload);
    if (response.success) {
      yield put(
        EditProductTemplateActions2.fetchGETSecondarySelectListSuccess2(
          response.data,
        ),
      );
    } else {
      Error(response);
      yield put(
        EditProductTemplateActions2.fetchGETSecondarySelectListError2(response),
      );
    }
  } catch (error) {
    yield put(
      EditProductTemplateActions2.fetchGETSecondarySelectListError2(error),
    );
  }
}

//属性下拉框
function* getParamsList2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getParamsList, payload);
    if (response.success) {
      yield put(
        EditProductTemplateActions2.fetchGETParamsListSuccess2(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(EditProductTemplateActions2.fetchGETServiceListError2(error));
  }
}
//服务下拉框--对应特定属性
function* getServiceList2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getServiceList, payload);
    if (response.success) {
      yield put(
        EditProductTemplateActions2.fetchGETServiceListSuccess2(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(EditProductTemplateActions2.fetchGETServiceListError2(error));
  }
}

// edit
// 设备页详情
function* editEditProductTemplateList2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.editTemplatePowerDetailList,
      payload,
    );
    if (response.success) {
      alert(response);
      yield put(
        EditProductTemplateActions2.fetchEDITEditProductTemplateListSuccess2(
          response.data,
        ),
      );
    } else {
      Error(response);
      yield put(
        EditProductTemplateActions2.fetchEDITEditProductTemplateListError2(
          response,
        ),
      );
    }
  } catch (error) {
    yield put(
      EditProductTemplateActions2.fetchEDITEditProductTemplateListError2(error),
    );
    // yield put(EditProductTemplateActions2.fetchGETEditProductTemplateListError(error));
  }
}
// 模块管理
function* saveModuleTemplate2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.saveTemplateModularPowerSetList,
      payload,
    );
    if (response.success) {
      alert(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTSaveModuleTemplateSUCCESS2(
          response.data,
        ),
      );
    } else {
      Error(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTSaveModuleTemplateERROR2(response),
      );
    }
  } catch (error) {
    yield put(
      EditProductTemplateActions2.fetchPOSTSaveModuleTemplateERROR2(error),
    );
    // yield put(EditProductTemplateActions2.fetchGETEditProductTemplateListError(error));
  }
}
// 次级页面
function* saveSecondTemplate2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.saveTemplateSecondPowerSetList,
      payload,
    );
    if (response.success) {
      alert(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTSaveSecondTemplateSUCCESS2(
          response.data,
        ),
      );
    } else {
      Error(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTSaveSecondTemplateERROR2(response),
      );
    }
  } catch (error) {
    yield put(
      EditProductTemplateActions2.fetchPOSTSaveSecondTemplateERROR2(error),
    );
    // yield put(EditProductTemplateActions2.fetchGETEditProductTemplateListError(error));
  }
}
// 设置页面
function* saveSettingsTemplate2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.saveTemplateSettingsPowerSetList,
      payload,
    );
    if (response.success) {
      alert(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTSaveSettingsTemplateSUCCESS2(
          response.data,
        ),
      );
    } else {
      Error(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTSaveSettingsTemplateERROR2(
          response,
        ),
      );
    }
  } catch (error) {
    yield put(
      EditProductTemplateActions2.fetchPOSTSaveSettingsTemplateERROR2(error),
    );
    // yield put(EditProductTemplateActions2.fetchGETEditProductTemplateListError(error));
  }
}

// delete
// 模块管理
function* deleteModuleTemplate2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.deleteTemplateModularPowerSetList,
      payload,
    );
    if (response.success) {
      alert(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTDeleteModuleTemplateSUCCESS2(
          response.data,
        ),
      );
    } else {
      Error(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTDeleteModuleTemplateERROR2(
          response,
        ),
      );
    }
  } catch (error) {
    yield put(
      EditProductTemplateActions2.fetchPOSTDeleteModuleTemplateERROR2(error),
    );
    // yield put(EditProductTemplateActions2.fetchGETEditProductTemplateListError(error));
  }
}
// 次级页面
function* deleteSecondTemplate2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.deleteTemplateSecondPowerSetList,
      payload,
    );
    if (response.success) {
      alert(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTDeleteSecondTemplateSUCCESS2(
          response.data,
        ),
      );
    } else {
      Error(response);
      yield put(
        EditProductTemplateActions2.fetchPOSTDeleteSecondTemplateERROR2(
          response,
        ),
      );
    }
  } catch (error) {
    yield put(
      EditProductTemplateActions2.fetchPOSTDeleteSecondTemplateERROR2(error),
    );
    // yield put(EditProductTemplateActions2.fetchGETEditProductTemplateListError(error));
  }
}

//模板-设置页面和设备详情页-保存
function* saveAllInfoTemplate2(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.saveAllInfoTemplatePower, payload);
    if (response.success) {
      alert(response);
      yield put(
        EditProductTemplateActions2.fetchSaveAllInfoTemplateSuccess2(
          response.data,
        ),
      );
    } else {
      Error(response);
      yield put(
        EditProductTemplateActions2.fetchSaveAllInfoTemplateError2(response),
      );
    }
  } catch (error) {
    yield put(
      EditProductTemplateActions2.fetchSaveAllInfoTemplateError2(error),
    );
    // yield put(EditProductTemplateActions2.fetchGETEditProductTemplateListError(error));
  }
}

export default function* userSaga() {
  yield all([
    //
    takeEvery(
      EditProductTemplateActions2.GET_Modular_List_REQUEST2,
      getModularList2,
    ),
    takeEvery(
      EditProductTemplateActions2.GET_SecondarySelect_List_REQUEST2,
      getSecondary2,
    ),
    //
    takeEvery(
      EditProductTemplateActions2.GET_Service_List_REQUEST2,
      getServiceList2,
    ),
    takeEvery(
      EditProductTemplateActions2.GET_Params_List_REQUEST2,
      getParamsList2,
    ),

    takeEvery(
      EditProductTemplateActions2.GET_EditProductTemplate_List_REQUEST2,
      getEditProductTemplateList2,
    ),

    // edit
    takeEvery(
      EditProductTemplateActions2.EDIT_EditProductTemplate_List_REQUEST2,
      editEditProductTemplateList2,
    ),
    takeEvery(
      EditProductTemplateActions2.POST_SaveModuleTemplate_REQUEST2,
      saveModuleTemplate2,
    ),
    takeEvery(
      EditProductTemplateActions2.POST_SaveSecondTemplate_REQUEST2,
      saveSecondTemplate2,
    ),
    takeEvery(
      EditProductTemplateActions2.POST_SaveSettingsTemplate_REQUEST2,
      saveSettingsTemplate2,
    ),

    // delete
    takeEvery(
      EditProductTemplateActions2.POST_DeleteModuleTemplate_REQUEST2,
      deleteModuleTemplate2,
    ),
    takeEvery(
      EditProductTemplateActions2.POST_DeleteSecondTemplate_REQUEST2,
      deleteSecondTemplate2,
    ),

    takeEvery(
      EditProductTemplateActions2.POST_SaveAllInfoTemplate_REQUEST2,
      saveAllInfoTemplate2,
    ),
  ]);
}
