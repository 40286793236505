const SysUserActions = {
  GET_User_List_REQUEST: "GET_User_List_REQUEST",
  GET_User_List_SUCCESS: "GET_User_List_SUCCESS",
  GET_User_List_ERROR: "GET_User_List_ERROR",

  ADD_User_List_REQUEST: "ADD_User_List_REQUEST",
  ADD_User_List_SUCCESS: "ADD_User_List_SUCCESS",
  ADD_User_List_ERROR: "ADD_User_List_ERROR",

  EDIT_User_List_REQUEST: "EDIT_User_List_REQUEST",
  EDIT_User_List_SUCCESS: "EDIT_User_List_SUCCESS",
  EDIT_User_List_ERROR: "EDIT_User_List_ERROR",

  DEL_User_List_REQUEST: "DEL_User_List_REQUEST",
  DEL_User_List_SUCCESS: "DEL_User_List_SUCCESS",
  DEL_User_List_ERROR: "DEL_User_List_ERROR",

  ResetPWD_User_List_REQUEST: "ResetPWD_User_List_REQUEST",
  ResetPWD_User_List_SUCCESS: "ResetPWD_User_List_SUCCESS",
  ResetPWD_User_List_ERROR: "ResetPWD_User_List_ERROR",

  Personal_ResetPWD_REQUEST: "Personal_ResetPWD_REQUEST",

  GET_BrandsALL_List_REQUEST: "GET_BrandsALL_List_REQUEST",
  GET_BrandsALL_List_SUCCESS: "GET_BrandsALL_List_SUCCESS",
  GET_BrandsALL_List_ERROR: "GET_BrandsALL_List_ERROR",

  GET_CategoriesALL_List_REQUEST: "GET_CategoriesALL_List_REQUEST",
  GET_CategoriesALL_List_SUCCESS: "GET_CategoriesALL_List_SUCCESS",
  GET_CategoriesALL_List_ERROR: "GET_CategoriesALL_List_ERROR",

  GET_ProductALL_List_REQUEST: "GET_ProductALL_List_REQUEST",
  GET_ProductALL_List_SUCCESS: "GET_ProductALL_List_SUCCESS",
  GET_ProductALL_List_ERROR: "GET_ProductALL_List_ERROR",

  GET_Department_SELECT_REQUEST: "GET_Department_SELECT_REQUEST",
  GET_Department_SELECT_SUCCESS: "GET_Department_SELECT_SUCCESS",
  GET_Department_SELECT_ERROR: "GET_Department_SELECT_ERROR",

  fetchGETDepartmentRequest: () => ({
    type: SysUserActions.GET_Department_SELECT_REQUEST,
  }),
  fetchGETDepartmentSuccess: (data) => ({
    type: SysUserActions.GET_Department_SELECT_SUCCESS,
    payload: data,
  }),
  fetchGETDepartmentError: (error) => ({
    type: SysUserActions.GET_Department_SELECT_ERROR,
    payload: error,
  }),

  fetchGETProductALLList: (request) => ({
    type: SysUserActions.GET_ProductALL_List_REQUEST,
    payload: request,
  }),
  fetchGETProductALLListSuccess: (data) => ({
    type: SysUserActions.GET_ProductALL_List_SUCCESS,
    payload: data,
  }),
  fetchGETProductALLListError: (error) => ({
    type: SysUserActions.GET_ProductALL_List_ERROR,
    payload: error,
  }),

  fetchGETCategoriesALLList: () => ({
    type: SysUserActions.GET_CategoriesALL_List_REQUEST,
  }),
  fetchGETCategoriesALLListSuccess: (data) => ({
    type: SysUserActions.GET_CategoriesALL_List_SUCCESS,
    payload: data,
  }),
  fetchGETCategoriesALLListError: (error) => ({
    type: SysUserActions.GET_CategoriesALL_List_ERROR,
    payload: error,
  }),

  fetchGETBrandsALLList: () => ({
    type: SysUserActions.GET_BrandsALL_List_REQUEST,
  }),
  fetchGETBrandsALLListSuccess: (data) => ({
    type: SysUserActions.GET_BrandsALL_List_SUCCESS,
    payload: data,
  }),
  fetchGETBrandsALLListError: (error) => ({
    type: SysUserActions.GET_BrandsALL_List_ERROR,
    payload: error,
  }),

  fetchGETUserList: (request) => ({
    type: SysUserActions.GET_User_List_REQUEST,
    payload: request,
  }),
  fetchGETUserListSuccess: (data) => ({
    type: SysUserActions.GET_User_List_SUCCESS,
    payload: data,
  }),
  fetchGETUserListError: (error) => ({
    type: SysUserActions.GET_User_List_ERROR,
    payload: error,
  }),

  fetchADDUserList: (request) => ({
    type: SysUserActions.ADD_User_List_REQUEST,
    payload: request,
  }),
  fetchADDUserListSuccess: (data) => ({
    type: SysUserActions.ADD_User_List_SUCCESS,
    payload: data,
  }),
  fetchADDUserListError: (error) => ({
    type: SysUserActions.ADD_User_List_ERROR,
    payload: error,
  }),

  fetchEDITUserList: (request) => ({
    type: SysUserActions.EDIT_User_List_REQUEST,
    payload: request,
  }),
  fetchEDITUserListSuccess: (data) => ({
    type: SysUserActions.EDIT_User_List_SUCCESS,
    payload: data,
  }),
  fetchEDITUserListError: (error) => ({
    type: SysUserActions.EDIT_User_List_ERROR,
    payload: error,
  }),
  //系统用户管理-重置密码
  fetchResetPWDUserList: (request) => ({
    type: SysUserActions.ResetPWD_User_List_REQUEST,
    payload: request,
  }),

  fetchResetPWDUserListSuccess: (data) => ({
    type: SysUserActions.ResetPWD_User_List_SUCCESS,
    payload: data,
  }),
  fetchResetPWDUserListError: (error) => ({
    type: SysUserActions.ResetPWD_User_List_ERROR,
    payload: error,
  }),

  //个人中心-重置密码
  fetchPersonalResetPWD: (request) => ({
    type: SysUserActions.Personal_ResetPWD_REQUEST,
    payload: request,
  }),

  fetchDELUserList: (request) => ({
    type: SysUserActions.DEL_User_List_REQUEST,
    payload: request,
  }),

  //个人中心-编辑(myProfile)
  Fetch_Edit_MyProfile_REQUEST: "Fetch_Edit_MyProfile_REQUEST",
  fetchEditMyProfileRequest: (request) => ({
    type: SysUserActions.Fetch_Edit_MyProfile_REQUEST,
    payload: request,
  }),
};

export default SysUserActions;
