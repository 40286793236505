const SysRoleActions = {
  GET_Role_List_REQUEST: "GET_Role_List_REQUEST",
  GET_Role_List_SUCCESS: "GET_Role_List_SUCCESS",
  GET_Role_List_ERROR: "GET_Role_List_ERROR",

  ADD_Role_List_REQUEST: "ADD_Role_List_REQUEST",
  ADD_Role_List_SUCCESS: "ADD_Role_List_SUCCESS",
  ADD_Role_List_ERROR: "ADD_Role_List_ERROR",

  EDIT_Role_List_REQUEST: "EDIT_Role_List_REQUEST",
  EDIT_Role_List_SUCCESS: "EDIT_Role_List_SUCCESS",
  EDIT_Role_List_ERROR: "EDIT_Role_List_ERROR",

  DEL_Role_List_REQUEST: "DEL_Role_List_REQUEST",
  DEL_Role_List_SUCCESS: "DEL_Role_List_SUCCESS",
  DEL_Role_List_ERROR: "DEL_Role_List_ERROR",

  GET_RoleALL_List_REQUEST: "GET_RoleALL_List_REQUEST",
  GET_RoleALL_List_SUCCESS: "GET_RoleALL_List_SUCCESS",
  GET_RoleALL_List_ERROR: "GET_RoleALL_List_ERROR",

  fetchGETRoleALLList: () => ({
    type: SysRoleActions.GET_RoleALL_List_REQUEST,
    // payload: request
  }),
  fetchGETRoleALLListSuccess: (data) => ({
    type: SysRoleActions.GET_RoleALL_List_SUCCESS,
    payload: data,
  }),
  fetchGETRoleALLListError: (error) => ({
    type: SysRoleActions.GET_RoleALL_List_ERROR,
    payload: error,
  }),
  fetchGETRoleList: (request) => ({
    type: SysRoleActions.GET_Role_List_REQUEST,
    payload: request,
  }),
  fetchGETRoleListSuccess: (data) => ({
    type: SysRoleActions.GET_Role_List_SUCCESS,
    payload: data,
  }),
  fetchGETRoleListError: (error) => ({
    type: SysRoleActions.GET_Role_List_ERROR,
    payload: error,
  }),

  fetchADDRoleList: (request) => ({
    type: SysRoleActions.ADD_Role_List_REQUEST,
    payload: request,
  }),
  fetchADDRoleListSuccess: (data) => ({
    type: SysRoleActions.ADD_Role_List_SUCCESS,
    payload: data,
  }),
  fetchADDRoleListError: (error) => ({
    type: SysRoleActions.ADD_Role_List_ERROR,
    payload: error,
  }),

  fetchEDITRoleList: (request) => ({
    type: SysRoleActions.EDIT_Role_List_REQUEST,
    payload: request,
  }),
  fetchEDITRoleListSuccess: (data) => ({
    type: SysRoleActions.EDIT_Role_List_SUCCESS,
    payload: data,
  }),
  fetchEDITRoleListError: (error) => ({
    type: SysRoleActions.EDIT_Role_List_ERROR,
    payload: error,
  }),

  fetchDELRoleList: (request) => ({
    type: SysRoleActions.DEL_Role_List_REQUEST,
    payload: request,
  }),
};

export default SysRoleActions;
