import { call, all, takeEvery, put } from "redux-saga/effects";
import IAAddressApplyActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import qs from "qs";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

function* getIAAddressApplyList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getIAPageList, payload);
    if (response.success) {
      yield put(
        IAAddressApplyActions.fetchGETIAAddressApplyListSuccess(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(IAAddressApplyActions.fetchGETIAAddressApplyListError(error));
  }
}

function* addIAAddressApplyList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addIAPageList, payload.params1);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(
      IAAddressApplyActions.fetchGETIAAddressApplyList(payload.params2),
    );
  }
}

function* recoveryIAAddressApplyList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.recoveryIAPageList,
      payload.params1,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(
      IAAddressApplyActions.fetchGETIAAddressApplyList(payload.params2),
    );
  }
}
//批量导出
function* exportIAAddressApplyList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.exportIAPageList, payload);
    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);
  } catch (error) {}
}
//导出
function* exportIAAddressApply(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.exportIAPage, payload);
    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);
  } catch (error) {}
}

//删除IA地址获取中, 已回收的地址
function* deleteIAAddressApplyList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.deleteIAAddressApply,
      payload.params1,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(
      IAAddressApplyActions.fetchGETIAAddressApplyList(payload.params2),
    );
  }
}
export default function* userSaga() {
  yield all([
    takeEvery(
      IAAddressApplyActions.GET_IAAddressApply_List_REQUEST,
      getIAAddressApplyList,
    ),
    takeEvery(
      IAAddressApplyActions.ADD_IAAddressApply_List_REQUEST,
      addIAAddressApplyList,
    ),
    takeEvery(
      IAAddressApplyActions.Recovery_IAAddressApply_List_REQUEST,
      recoveryIAAddressApplyList,
    ),
    takeEvery(
      IAAddressApplyActions.EXPORT_IAAddressApply_LIST_REQUEST,
      exportIAAddressApplyList,
    ),
    takeEvery(
      IAAddressApplyActions.EXPORT_IAAddressApply_REQUEST,
      exportIAAddressApply,
    ),
    takeEvery(
      IAAddressApplyActions.Del_IAAddressApply_List_REQUEST,
      deleteIAAddressApplyList,
    ),
  ]);
}
