import { call, all, takeEvery, put, select } from "redux-saga/effects";
import SysDepartmentActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk2";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
  });
};

function* getDepartmentList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getDepartmentList, payload);
    if (response.success) {
      yield put(
        SysDepartmentActions.fetchGETDepartmentListSuccess(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(SysDepartmentActions.fetchGETDepartmentListError(error));
  }
}

function* getDepartmentALLList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getDepartmentALLList);
    yield put(
      SysDepartmentActions.fetchGETDepartmentALLListSuccess(response.data),
    );
  } catch (error) {
    yield put(SysDepartmentActions.fetchGETDepartmentALLListError(error));
  }
}

function* addDepartmentList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addDepartmentList, payload);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(
      SysDepartmentActions.fetchGETDepartmentList({
        pageSize: payload.pageSize,
        pageNum: payload.pageNum,
      }),
    );
  }
}

function* editDepartmentList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editDepartmentList, payload);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    // yield put(SysDepartmentActions.fetchGETDepartmentListError(error));
  } finally {
    yield put(
      SysDepartmentActions.fetchGETDepartmentList({
        pageNum: payload.pageNum,
        pageSize: payload.pageSize,
        name: payload.searchname,
      }),
    );
  }
}

function* delDepartmentList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.delDepartmentList, payload);
    if (response.success) {
      const total = yield select((state) => state.SysDepartment.totalList);
      if (payload.pageNum > Math.ceil((total - 1) / payload.pageSize)) {
        //last page
        const params = {
          name: payload.name,
          pageNum: payload.pageNum - 1,
          pageSize: payload.pageSize,
        };
        yield put(SysDepartmentActions.fetchGETDepartmentList(params));
      } else {
        //this page
        const params = {
          name: payload.name,
          pageNum: payload.pageNum,
          pageSize: payload.pageSize,
        };
        yield put(SysDepartmentActions.fetchGETDepartmentList(params));
      }
      alert(response);
    } else {
      const params = {
        name: payload.name,
        pageNum: payload.pageNum,
        pageSize: payload.pageSize,
      };
      yield put(SysDepartmentActions.fetchGETDepartmentList(params));
      Error(response);
    }
  } catch (error) {}
}
export default function* userSaga() {
  yield all([
    takeEvery(
      SysDepartmentActions.GET_Department_List_REQUEST,
      getDepartmentList,
    ),
    takeEvery(
      SysDepartmentActions.GET_DepartmentALL_List_REQUEST,
      getDepartmentALLList,
    ),
    takeEvery(
      SysDepartmentActions.ADD_Department_List_REQUEST,
      addDepartmentList,
    ),
    takeEvery(
      SysDepartmentActions.EDIT_Department_List_REQUEST,
      editDepartmentList,
    ),
    takeEvery(
      SysDepartmentActions.DEL_Department_List_REQUEST,
      delDepartmentList,
    ),
  ]);
}
