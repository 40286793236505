import ruleManageActions from "./actions";

const INITIAL_DATA = {
  ruleManagedataTotal: 0,
  ruleManagedata: [],
  eventList: [],
  propertyList: [],
  messageTempList: [],
  SysAdminsList: [],
  SysUsersList: [],
  loading: true,
  error: null,
  deleteStatus: "0", //  删除
};
export default function ruleManageReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case ruleManageActions.DELETE_ruleManage_REQUEST:
      return {
        ...state,
        deleteStatus: "1", //  删除
        loading: true,
        error: null,
      };
    case ruleManageActions.DELETE_ruleManage_SUCCESS:
      return {
        ...state,
        deleteStatus: "2", //  删除
        loading: false,
        error: null,
      };
    case ruleManageActions.DELETE_ruleManage_FAILURE:
      return {
        ...state,
        deleteStatus: "3", //  删除
        loading: false,
        error: action.payload,
      };

    case ruleManageActions.FETCH_ruleManage_DATA_REQUEST:
      return {
        ...state,
        ruleManagedata: [],
        loading: true,
        error: null,
      };
    case ruleManageActions.FETCH_ruleManage_DATA_SUCCESS:
      return {
        ...state,
        ruleManagedata: action.payload.list,
        ruleManagedataTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case ruleManageActions.FETCH_ruleManage_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ruleManageActions.ADD_ruleManage_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ruleManageActions.ADD_ruleManage_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ruleManageActions.ADD_ruleManage_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // EDIT ruleManage
    case ruleManageActions.EDIT_ruleManage_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ruleManageActions.EDIT_ruleManage_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ruleManageActions.EDIT_ruleManage_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ruleManageActions.GET_Event_REQUEST:
      return {
        ...state,
        eventList: [],
        loading: true,
        error: null,
      };
    case ruleManageActions.GET_Event_SUCCESS:
      return {
        ...state,
        eventList: action.payload,
        loading: false,
        error: null,
      };
    case ruleManageActions.GET_Event_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ruleManageActions.GET_Property_REQUEST:
      return {
        ...state,
        propertyList: [],
        loading: true,
        error: null,
      };
    case ruleManageActions.GET_Property_SUCCESS:
      return {
        ...state,
        propertyList: action.payload,
        loading: false,
        error: null,
      };
    case ruleManageActions.GET_Property_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ruleManageActions.GET_MessageTemp_REQUEST:
      return {
        ...state,
        messageTempList: [],
        loading: true,
        error: null,
      };
    case ruleManageActions.GET_MessageTemp_SUCCESS:
      return {
        ...state,
        messageTempList: action.payload,
        loading: false,
        error: null,
      };
    case ruleManageActions.GET_MessageTemp_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ruleManageActions.GET_SysAdminsList_REQUEST:
      return {
        ...state,
        SysAdminsList: [],
        loading: true,
        error: null,
      };
    case ruleManageActions.GET_SysAdminsList_SUCCESS:
      return {
        ...state,
        SysAdminsList: action.payload,
        loading: false,
        error: null,
      };
    case ruleManageActions.GET_SysAdminsList_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // 通知用户下拉框
    case ruleManageActions.GET_SysUsersList_REQUEST:
      return {
        ...state,
        SysUsersList: [],
        loading: true,
        error: null,
      };
    case ruleManageActions.GET_SysUsersList_SUCCESS:
      return {
        ...state,
        SysUsersList: action.payload,
        loading: false,
        error: null,
      };
    case ruleManageActions.GET_SysUsersList_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
