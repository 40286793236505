const brandActions = {
  //Fetch brand Data
  GET_BRAND_ID_REQUEST: "GET_BRAND_ID_REQUEST",
  GET_BRAND_SUCCESS: "GET_BRAND_SUCCESS",
  GET_BRAND_FAILURE: "GET_BRAND_FAILURE",
  getBrandRequest: (request) => {
    return {
      type: brandActions.GET_BRAND_ID_REQUEST,
      payload: request,
    };
  },
  getBrandSuccess: (brand) => {
    return {
      type: brandActions.GET_BRAND_SUCCESS,
      payload: brand,
    };
  },
  getBrandFailure: (error) => ({
    type: brandActions.GET_BRAND_FAILURE,
    payload: error,
  }),
};

export default brandActions;
