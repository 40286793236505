const orderActions = {
  // Add Product
  DOWNLOAD_EXCEL_REQUEST: "DOWNLOAD_EXCEL_REQUEST",
  DOWNLOAD_EXCEL_SUCCESS: "DOWNLOAD_EXCEL_SUCCESS",
  UPLOAD_EXCEL_REQUEST: "UPLOAD_EXCEL_REQUEST",
  UPLOAD_EXCEL_SUCCESS: "UPLOAD_EXCEL_SUCCESS",
  GET_ORDERS_REQUEST: "GET_ORDERS_REQUEST",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  DELETE_ORDER_REQUEST: "DELETE_ORDER_REQUEST",
  UNBIND_ORDER_REQUEST: "UNBIND_ORDER_REQUEST",
  JSON_TO_EXCEL_REQUEST: "JSON_TO_EXCEL_REQUEST",
  JSON_TO_EXCEL_SUCCESS: "JSON_TO_EXCEL_SUCCESS",

  jsonToExcelRequest: (request) => ({
    type: orderActions.JSON_TO_EXCEL_REQUEST,
    payload: request,
  }),
  jsonToExcelSuccess: (jsonData) => ({
    type: orderActions.JSON_TO_EXCEL_SUCCESS,
    payload: jsonData,
  }),

  downloadExcelRequest: (request) => ({
    type: orderActions.DOWNLOAD_EXCEL_REQUEST,
    payload: request,
  }),
  downloadExcelSuccess: (product) => ({
    type: orderActions.DOWNLOAD_EXCEL_SUCCESS,
    payload: product,
  }),
  uploadExcelRequest: (request) => ({
    type: orderActions.UPLOAD_EXCEL_REQUEST,
    payload: request,
  }),
  uploadExcelSuccess: (product) => ({
    type: orderActions.UPLOAD_EXCEL_SUCCESS,
    payload: product,
  }),
  getOrdersRequest: (product) => ({
    type: orderActions.GET_ORDERS_REQUEST,
    payload: product,
  }),
  getOrdersSuccess: (product) => ({
    type: orderActions.GET_ORDERS_SUCCESS,
    payload: product,
  }),
  deleteOrderRequest: (product) => ({
    type: orderActions.DELETE_ORDER_REQUEST,
    payload: product,
  }),
  unbindOrderRequest: (product) => ({
    type: orderActions.UNBIND_ORDER_REQUEST,
    payload: product,
  }),

  // Edit ORDER
  EDIT_ORDER_REQUEST: "EDIT_ORDER_REQUEST",
  EDIT_ORDER_SUCCESS: "EDIT_ORDER_SUCCESS",
  EDIT_ORDER_FAILURE: "EDIT_ORDER_FAILURE",
  editOrderDataRequest: (request) => ({
    type: orderActions.EDIT_ORDER_REQUEST,
    payload: request,
  }),
  editOrderDataSuccess: (order) => ({
    type: orderActions.EDIT_ORDER_SUCCESS,
    payload: order,
  }),
  editOrderDataFailure: (error) => ({
    type: orderActions.EDIT_ORDER_FAILURE,
    payload: error,
  }),
};
export default orderActions;
