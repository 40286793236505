import SysUserActions from "./actions";

const INITIAL_DATA = {
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除

  BrandsALL: [],
  CategoriesALL: [],
  ProductALL: [],

  DepartmentSelect: [],
  data: [],
  totalList: 0,
  loading: true,
  error: null,
  reset: false,
};
export default function SysUserReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case SysUserActions.GET_Department_SELECT_REQUEST:
      return {
        ...state,
        DepartmentSelect: [],
        loading: false,
        error: null,
      };
    case SysUserActions.GET_Department_SELECT_SUCCESS:
      return {
        ...state,
        DepartmentSelect: action.payload,
        loading: false,
        error: null,
      };
    case SysUserActions.GET_Department_SELECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysUserActions.GET_ProductALL_List_REQUEST:
      return {
        ...state,
        ProductALL: [],
        loading: true,
        error: null,
      };
    case SysUserActions.GET_ProductALL_List_SUCCESS:
      return {
        ...state,
        ProductALL: action.payload,
        loading: false,
        error: null,
      };
    case SysUserActions.GET_ProductALL_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysUserActions.GET_BrandsALL_List_REQUEST:
      return {
        ...state,
        BrandsALL: [],
        loading: true,
        error: null,
      };
    case SysUserActions.GET_BrandsALL_List_SUCCESS:
      return {
        ...state,
        BrandsALL: action.payload,
        loading: false,
        error: null,
      };
    case SysUserActions.GET_BrandsALL_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysUserActions.GET_CategoriesALL_List_REQUEST:
      return {
        ...state,
        CategoriesALL: [],
        loading: true,
        error: null,
      };
    case SysUserActions.GET_CategoriesALL_List_SUCCESS:
      return {
        ...state,
        CategoriesALL: action.payload,
        loading: false,
        error: null,
      };
    case SysUserActions.GET_CategoriesALL_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysUserActions.GET_User_List_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case SysUserActions.GET_User_List_SUCCESS:
      let data = Object.assign([], action.payload);

      data.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        data: action.payload.list,
        totalList: action.payload.total,
        loading: false,
        error: null,
      };
    case SysUserActions.GET_User_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysUserActions.ADD_User_List_REQUEST:
      return {
        ...state,
        // data: [],
        createStatus: "1",
        // loading: true,
        error: null,
      };
    case SysUserActions.ADD_User_List_SUCCESS:
      return {
        ...state,
        createStatus: "2",
        // loading: false,
        error: action.payload,
      };
    case SysUserActions.ADD_User_List_ERROR:
      return {
        ...state,
        createStatus: "3",
        // loading: false,
        error: action.payload,
      };

    case SysUserActions.EDIT_User_List_REQUEST:
      return {
        ...state,
        // data: [],
        // totalList: 0,
        editStatus: "1",
        // loading: true,
        error: null,
      };
    case SysUserActions.EDIT_User_List_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        // loading: false,
        error: action.payload,
      };
    case SysUserActions.EDIT_User_List_ERROR:
      return {
        ...state,
        editStatus: "3",
        // loading: false,
        error: action.payload,
      };

    case SysUserActions.ResetPWD_User_List_REQUEST:
      return {
        ...state,
        data: [],
        totalList: 0,
        loading: true,
        error: null,
      };
    case SysUserActions.ResetPWD_User_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysUserActions.ResetPWD_User_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SysUserActions.DEL_User_List_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SysUserActions.DEL_User_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysUserActions.DEL_User_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
