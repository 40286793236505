import { call, all, takeEvery, put, select } from "redux-saga/effects";
import welComePageActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
  });
};

function* fetchwelComePageDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getWelPage, payload);
    yield put(welComePageActions.fetchwelComePageDataSuccess(response.data));
  } catch (error) {
    yield put(welComePageActions.fetchwelComePageDataFailure(error));
  }
}

function* addwelComePageDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createWelPage, payload.params1);

    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(welComePageActions.addwelComePageDataFailure(error));
  } finally {
    yield put(welComePageActions.fetchwelComePageDataRequest(payload.params2));
  }
}

function* editwelComePageDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editWelPage, payload.params1);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(welComePageActions.editwelComePageDataFailure(error));
  } finally {
    yield put(welComePageActions.fetchwelComePageDataRequest(payload.params2));
  }
}

function* deletewelComePageDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteWelPage, payload);
    if (response.success) {
      const total = yield select(
        (state) => state.welcomePage.welComePagedataTotal,
      );
      if (
        payload.params2.pageNum >
        Math.ceil((total - 1) / payload.params2.pageSize)
      ) {
        payload.params2.pageNum = payload.params2.pageNum - 1;
        yield put(
          welComePageActions.fetchwelComePageDataRequest(payload.params2),
        );
      } else {
        yield put(
          welComePageActions.fetchwelComePageDataRequest(payload.params2),
        );
      }
      alert(response);
    } else {
      yield put(
        welComePageActions.fetchwelComePageDataRequest(payload.params2),
      );
      Error(response);
    }
  } catch (error) {
    yield put(welComePageActions.deletewelComePageDataFailure(error));
  }
}

export default function* welComePageSaga() {
  yield all([
    takeEvery(
      welComePageActions.FETCH_welComePage_DATA_REQUEST,
      fetchwelComePageDataRequest,
    ),
    takeEvery(
      welComePageActions.ADD_welComePage_REQUEST,
      addwelComePageDataRequest,
    ),
    takeEvery(
      welComePageActions.EDIT_welComePage_REQUEST,
      editwelComePageDataRequest,
    ),
    takeEvery(
      welComePageActions.DELETE_welComePage_REQUEST,
      deletewelComePageDataRequest,
    ),
  ]);
}
