const FaultManageMentActions = {
  //获取已处理故障列表
  GET_faultHandled_List_REQUEST: "GET_faultHandled_List_REQUEST",
  GET_faultHandled_List_SUCCESS: "GET_faultHandled_List_SUCCESS",
  GET_faultHandled_List_ERROR: "GET_faultHandled_List_ERROR",
  fetchGETfaultHandledList: (request) => ({
    type: FaultManageMentActions.GET_faultHandled_List_REQUEST,
    payload: request,
  }),
  fetchGETfaultHandledListSuccess: (data) => ({
    type: FaultManageMentActions.GET_faultHandled_List_SUCCESS,
    payload: data,
  }),
  fetchGETfaultHandledListError: (error) => ({
    type: FaultManageMentActions.GET_faultHandled_List_ERROR,
    payload: error,
  }),

  //获取待处理故障列表
  GET_faultNoHandler_List_REQUEST: "GET_faultNoHandler_List_REQUEST",
  GET_faultNoHandler_List_SUCCESS: "GET_faultNoHandler_List_SUCCESS",
  GET_faultNoHandler_List_ERROR: "GET_faultNoHandler_List_ERROR",
  fetchGETfaultNoHandlerList: (request) => ({
    type: FaultManageMentActions.GET_faultNoHandler_List_REQUEST,
    payload: request,
  }),
  fetchGETfaultNoHandlerListSuccess: (data) => ({
    type: FaultManageMentActions.GET_faultNoHandler_List_SUCCESS,
    payload: data,
  }),
  fetchGETfaultNoHandlerListError: (error) => ({
    type: FaultManageMentActions.GET_faultNoHandler_List_ERROR,
    payload: error,
  }),

  //批量处理-待处理故障列表
  Edit_faultNoHandler_List_REQUEST: "Edit_faultNoHandler_List_REQUEST",
  fetchEditFaultNoHandlerList: (request) => ({
    type: FaultManageMentActions.Edit_faultNoHandler_List_REQUEST,
    payload: request,
  }),

  //导出-已处理故障列表
  EXPORT_faultHandled_List_REQUEST: "EXPORT_faultHandled_List_REQUEST",
  fetchExportFaultHandledList: (request) => ({
    type: FaultManageMentActions.EXPORT_faultHandled_List_REQUEST,
    payload: request,
  }),

  //导出-待处理故障列表
  EXPORT_faultNoHandler_List_REQUEST: "EXPORT_faultNoHandler_List_REQUEST",
  fetchExportFaultNoHandlerList: (request) => ({
    type: FaultManageMentActions.EXPORT_faultNoHandler_List_REQUEST,
    payload: request,
  }),

  //获取新老型号下拉框
  GET_productSelect_List_REQUEST: "GET_productSelect_List_REQUEST",
  GET_productSelect_List_SUCCESS: "GET_productSelect_List_SUCCESS",
  GET_productSelect_List_ERROR: "GET_faultHandled_List_ERROR",
  fetchGETproductSelectList: () => ({
    type: FaultManageMentActions.GET_productSelect_List_REQUEST,
  }),
  fetchGETproductSelectSuccess: (data) => ({
    type: FaultManageMentActions.GET_productSelect_List_SUCCESS,
    payload: data,
  }),
  fetchGETproductSelectListError: (error) => ({
    type: FaultManageMentActions.GET_productSelect_List_ERROR,
    payload: error,
  }),
};

export default FaultManageMentActions;
