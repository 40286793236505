const messagePushActions = {
  //Fetch messagePush Data
  FETCH_messagePush_DATA_REQUEST: "FETCH_messagePush_DATA_REQUEST",
  FETCH_messagePush_DATA_SUCCESS: "FETCH_messagePush_DATA_SUCCESS",
  FETCH_messagePush_DATA_FAILURE: "FETCH_messagePush_DATA_FAILURE",
  SET_messagePush_DATA: "SET_messagePush_DATA",
  fetchmessagePushDataRequest: (request) => ({
    type: messagePushActions.FETCH_messagePush_DATA_REQUEST,
    payload: request,
  }),
  fetchmessagePushDataSuccess: (messagePush) => ({
    type: messagePushActions.FETCH_messagePush_DATA_SUCCESS,
    payload: messagePush,
  }),
  fetchmessagePushDataFailure: (error) => ({
    type: messagePushActions.FETCH_messagePush_DATA_FAILURE,
    payload: error,
  }),

  // Add messagePush
  ADD_messagePush_REQUEST: "ADD_messagePush_REQUEST",
  ADD_messagePush_SUCCESS: "ADD_messagePush_SUCCESS",
  ADD_messagePush_FAILURE: "ADD_messagePush_FAILURE",
  addmessagePushDataRequest: (request) => ({
    type: messagePushActions.ADD_messagePush_REQUEST,
    payload: request,
  }),
  addmessagePushDataSuccess: (messagePush) => ({
    type: messagePushActions.ADD_messagePush_SUCCESS,
    payload: messagePush,
  }),
  addmessagePushDataFailure: (error) => ({
    type: messagePushActions.ADD_messagePush_FAILURE,
    payload: error,
  }),

  // Edit messagePush
  EDIT_messagePush_REQUEST: "EDIT_messagePush_REQUEST",
  EDIT_messagePush_SUCCESS: "EDIT_messagePush_SUCCESS",
  EDIT_messagePush_FAILURE: "EDIT_messagePush_FAILURE",
  editmessagePushDataRequest: (request) => ({
    type: messagePushActions.EDIT_messagePush_REQUEST,
    payload: request,
  }),
  editmessagePushDataSuccess: (messagePush) => ({
    type: messagePushActions.EDIT_messagePush_SUCCESS,
    payload: messagePush,
  }),
  editmessagePushDataFailure: (error) => ({
    type: messagePushActions.EDIT_messagePush_FAILURE,
    payload: error,
  }),

  // read messageList
  READ_messageList_REQUEST: "READ_messageList_REQUEST",
  READ_messageList_SUCCESS: "READ_messageList_SUCCESS",
  READ_messageList_FAILURE: "READ_messageList_FAILURE",
  readmessageListDataRequest: (request) => ({
    type: messagePushActions.READ_messageList_REQUEST,
    payload: request,
  }),
  readmessageListDataSuccess: (messageList) => ({
    type: messagePushActions.READ_messageList_SUCCESS,
    payload: messageList,
  }),
  readmessageListDataFailure: (error) => ({
    type: messagePushActions.READ_messageList_FAILURE,
    payload: error,
  }),

  // Delete messageList
  DELETE_messageList_REQUEST: "DELETE_messageList_REQUEST",
  DELETE_messageList_SUCCESS: "DELETE_messageList_SUCCESS",
  DELETE_messageList_FAILURE: "DELETE_messageList_FAILURE",
  deletemessageListDataRequest: (request) => ({
    type: messagePushActions.DELETE_messageList_REQUEST,
    payload: request,
  }),
  deletemessageListDataSuccess: (messageList) => ({
    type: messagePushActions.DELETE_messageList_SUCCESS,
    payload: messageList,
  }),
  deletemessageListDataFailure: (error) => ({
    type: messagePushActions.DELETE_messageList_FAILURE,
    payload: error,
  }),

  //Fetch messageList Data
  FETCH_messageList_DATA_REQUEST: "FETCH_messageList_DATA_REQUEST",
  FETCH_messageList_DATA_SUCCESS: "FETCH_messageList_DATA_SUCCESS",
  FETCH_messageList_DATA_FAILURE: "FETCH_messageList_DATA_FAILURE",
  fetchmessageListDataRequest: (request) => ({
    type: messagePushActions.FETCH_messageList_DATA_REQUEST,
    payload: request,
  }),
  fetchmessageListDataSuccess: (messageList) => ({
    type: messagePushActions.FETCH_messageList_DATA_SUCCESS,
    payload: messageList,
  }),
  fetchmessageListDataFailure: (error) => ({
    type: messagePushActions.FETCH_messageList_DATA_FAILURE,
    payload: error,
  }),

  //Fetch messageLog Data
  FETCH_messageLog_DATA_REQUEST: "FETCH_messageLog_DATA_REQUEST",
  FETCH_messageLog_DATA_SUCCESS: "FETCH_messageLog_DATA_SUCCESS",
  FETCH_messageLog_DATA_FAILURE: "FETCH_messageLog_DATA_FAILURE",
  fetchmessageLogDataRequest: (request) => ({
    type: messagePushActions.FETCH_messageLog_DATA_REQUEST,
    payload: request,
  }),
  fetchmessageLogDataSuccess: (messageLog) => ({
    type: messagePushActions.FETCH_messageLog_DATA_SUCCESS,
    payload: messageLog,
  }),
  fetchmessageLogDataFailure: (error) => ({
    type: messagePushActions.FETCH_messageLog_DATA_FAILURE,
    payload: error,
  }),

  //获取国别下拉框
  GET_CountrySelect_List_REQUEST: "GET_CountrySelect_List_REQUEST",
  GET_CountrySelect_List_SUCCESS: "GET_CountrySelect_List_SUCCESS",
  GET_CountrySelect_List_ERROR: "GET_CountrySelect_List_ERROR",
  fetchGETCountrySelectList: () => ({
    type: messagePushActions.GET_CountrySelect_List_REQUEST,
  }),
  fetchGETCountrySelectListSuccess: (data) => ({
    type: messagePushActions.GET_CountrySelect_List_SUCCESS,
    payload: data,
  }),
  fetchGETCountrySelectListError: (error) => ({
    type: messagePushActions.GET_CountrySelect_List_ERROR,
    payload: error,
  }),

  //用户下拉框
  GET_UserSelect_List_REQUEST: "GET_UserSelect_List_REQUEST",
  GET_UserSelect_List_SUCCESS: "GET_UserSelect_List_SUCCESS",
  GET_UserSelect_List_ERROR: "GET_UserSelect_List_ERROR",
  fetchGETUserSelectList: (params) => ({
    type: messagePushActions.GET_UserSelect_List_REQUEST,
    payload: params,
  }),
  fetchGETUserSelectListSuccess: (data) => ({
    type: messagePushActions.GET_UserSelect_List_SUCCESS,
    payload: data,
  }),
  fetchGETUserSelectListError: (error) => ({
    type: messagePushActions.GET_UserSelect_List_ERROR,
    payload: error,
  }),

  //修改消息列表- 消息状态-从未读到已读
  Edit_MessList_Status_REQUEST: "Edit_MessList_Status_REQUEST",
  fetEditMessListStatus: (data) => ({
    type: messagePushActions.Edit_MessList_Status_REQUEST,
    payload: data,
  }),
  //消息推动- push
  Push_MessPush_Data_REQUEST: "Push_MessPush_Data_REQUEST",
  PushMessagePushDataRequest: (data) => ({
    type: messagePushActions.Push_MessPush_Data_REQUEST,
    payload: data,
  }),
  // 删除已完成状态的消息推送记录
  Delete_MessagePush_Record_Request: "Delete_MessagePush_Record_Request",
  delMessagePushRecordRequest: (params) => ({
    type: messagePushActions.Delete_MessagePush_Record_Request,
    payload: params,
  }),
};

export default messagePushActions;
