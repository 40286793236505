const Actions = {
  // 不同终端类型用户
  // /api/v2/web/user/analysis/terminal/type
  load_terminal_type_REQUEST: "load_terminal_type_REQUEST",
  load_terminal_type_SUCCESS: "load_terminal_type_SUCCESS",
  load_terminal_type_FAILURE: "load_terminal_type_FAILURE",
  load_terminal_typeRequest: (request) => ({
    type: Actions.load_terminal_type_REQUEST,
    payload: request,
  }),
  load_terminal_typeSuccess: (welComePage) => ({
    type: Actions.load_terminal_type_SUCCESS,
    payload: welComePage,
  }),
  load_terminal_typeFailure: (error) => ({
    type: Actions.load_terminal_type_FAILURE,
    payload: error,
  }),

  // 用户新增
  // /api/v2/web/user/analysis/newly/added
  load_newly_added_REQUEST: "load_newly_added_REQUEST",
  load_newly_added_SUCCESS: "load_newly_added_SUCCESS",
  load_newly_added_FAILURE: "load_newly_added_FAILURE",
  load_newly_addedRequest: (request) => ({
    type: Actions.load_newly_added_REQUEST,
    payload: request,
  }),
  load_newly_addedSuccess: (welComePage) => ({
    type: Actions.load_newly_added_SUCCESS,
    payload: welComePage,
  }),
  load_newly_addedFailure: (error) => ({
    type: Actions.load_newly_added_FAILURE,
    payload: error,
  }),

  // 实时在线用户
  // /api/v2/web/user/analysis/realtime/online
  load_realtime_online_REQUEST: "load_realtime_online_REQUEST",
  load_realtime_online_SUCCESS: "load_realtime_online_SUCCESS",
  load_realtime_online_FAILURE: "load_realtime_online_FAILURE",
  load_realtime_onlineRequest: (request) => ({
    type: Actions.load_realtime_online_REQUEST,
    payload: request,
  }),
  load_realtime_onlineSuccess: (welComePage) => ({
    type: Actions.load_realtime_online_SUCCESS,
    payload: welComePage,
  }),
  load_realtime_onlineFailure: (error) => ({
    type: Actions.load_realtime_online_FAILURE,
    payload: error,
  }),

  // 版本分布
  // /api/v2/web/user/analysis/version/division
  load_version_division_REQUEST: "load_version_division_REQUEST",
  load_version_division_SUCCESS: "load_version_division_SUCCESS",
  load_version_division_FAILURE: "load_version_division_FAILURE",
  load_version_divisionRequest: (request) => ({
    type: Actions.load_version_division_REQUEST,
    payload: request,
  }),
  load_version_divisionSuccess: (welComePage) => ({
    type: Actions.load_version_division_SUCCESS,
    payload: welComePage,
  }),
  load_version_divisionFailure: (error) => ({
    type: Actions.load_version_division_FAILURE,
    payload: error,
  }),

  // 用户活跃度
  // /api/v2/web/user/analysis/activation
  load_activation_REQUEST: "load_activation_REQUEST",
  load_activation_SUCCESS: "load_activation_SUCCESS",
  load_activation_FAILURE: "load_activation_FAILURE",
  load_activationRequest: (request) => ({
    type: Actions.load_activation_REQUEST,
    payload: request,
  }),
  load_activationSuccess: (welComePage) => ({
    type: Actions.load_activation_SUCCESS,
    payload: welComePage,
  }),
  load_activationFailure: (error) => ({
    type: Actions.load_activation_FAILURE,
    payload: error,
  }),

  // 用户群体分布
  // /api/v2/web/user/analysis/group/distribution
  load_group_distribution_REQUEST: "load_group_distribution_REQUEST",
  load_group_distribution_SUCCESS: "load_group_distribution_SUCCESS",
  load_group_distribution_FAILURE: "load_group_distribution_FAILURE",
  load_group_distributionRequest: (request) => ({
    type: Actions.load_group_distribution_REQUEST,
    payload: request,
  }),
  load_group_distributionSuccess: (welComePage) => ({
    type: Actions.load_group_distribution_SUCCESS,
    payload: welComePage,
  }),
  load_group_distributionFailure: (error) => ({
    type: Actions.load_group_distribution_FAILURE,
    payload: error,
  }),
};

export default Actions;
