import mallActions from "./actions";
import { getDateFormatted } from "@iso/lib/helpers/dateFormat";

const INITIAL_DATA = {
  getMallProducts: [],
  getMallPromotions: [],
  getMallPromotion: [],
  getMallBrands: [],
  createMallProduct: [],
  createMallPromotion: [],
  editMallProduct: [],
  editMallPromotion: [],
  deleteMallPromotion: [],
  getMallCategories: [],
  loading: true,
  error: null,
};

export default function mallReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    //  *********  Create Mall Product *******   //
    case mallActions.CREATE_MALL_PRODUCT_REQUEST:
      return {
        ...state,
        createMallProduct: [],
        loading: true,
        error: null,
      };
    case mallActions.CREATE_MALL_PRODUCT_SUCCESS:
      return {
        ...state,
        createMallProduct: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Get Mall Products *******   //
    case mallActions.GET_MALL_PRODUCTS_REQUEST:
      return {
        ...state,
        getMallProducts: [],
        loading: true,
        error: null,
      };
    case mallActions.GET_MALL_PRODUCTS_SUCCESS:
      let data = Object.assign([], action.payload);
      data.forEach((element, index) => {
        element.key = index;
        const date = new Date(element.createdAt);
        element.createdAt = getDateFormatted(date);
      });
      return {
        ...state,
        getMallProducts: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Create Mall Promotion *******   //
    case mallActions.CREATE_MALL_PROMOTION_REQUEST:
      return {
        ...state,
        createMallPromotion: [],
        loading: true,
        error: null,
      };
    case mallActions.CREATE_MALL_PROMOTION_SUCCESS:
      return {
        ...state,
        createMallPromotion: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Get Mall Promotions *******   //
    case mallActions.GET_MALL_PROMOTIONS_REQUEST:
      return {
        ...state,
        getMallPromotions: [],
        loading: true,
        error: null,
      };
    case mallActions.GET_MALL_PROMOTIONS_SUCCESS:
      return {
        ...state,
        getMallPromotions: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Get Mall Brands *******   //
    case mallActions.GET_MALL_BRANDS_REQUEST:
      return {
        ...state,
        getMallBrands: [],
        loading: true,
        error: null,
      };
    case mallActions.GET_MALL_BRANDS_SUCCESS:
      return {
        ...state,
        getMallBrands: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Get Mall Categories *******   //
    case mallActions.GET_MALL_CATEGORIES_REQUEST:
      return {
        ...state,
        getMallCategories: [],
        loading: true,
        error: null,
      };
    case mallActions.GET_MALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        getMallCategories: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Edit Mall Product *******   //
    case mallActions.EDIT_MALL_PRODUCT_REQUEST:
      return {
        ...state,
        editMallProduct: [],
        loading: true,
        error: null,
      };
    case mallActions.EDIT_MALL_PRODUCT_SUCCESS:
      return {
        ...state,
        editMallProduct: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Get Mall Promotion *******   //
    case mallActions.GET_MALL_PROMOTION_REQUEST:
      return {
        ...state,
        getMallPromotion: [],
        loading: true,
        error: null,
      };
    case mallActions.GET_MALL_PROMOTION_SUCCESS:
      return {
        ...state,
        getMallPromotion: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Edit Mall Promotion *******   //
    case mallActions.EDIT_MALL_PROMOTION_REQUEST:
      return {
        ...state,
        editMallPromotion: [],
        loading: true,
        error: null,
      };
    case mallActions.EDIT_MALL_PROMOTION_SUCCESS:
      return {
        ...state,
        editMallPromotion: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Delete Mall Promotion *******   //
    case mallActions.DELETE_MALL_PROMOTION_REQUEST:
      return {
        ...state,
        deleteMallPromotion: [],
        loading: true,
        error: null,
      };
    case mallActions.DELETE_MALL_PROMOTION_SUCCESS:
      return {
        ...state,
        deleteMallPromotion: action.payload,
        loading: false,
        error: null,
      };

    //  ********* Mall Management Error*******   //
    case mallActions.GET_MALL_BRANDS_FAILURE:
    case mallActions.GET_MALL_CATEGORIES_FAILURE:
    case mallActions.CREATE_MALL_PRODUCT_FAILURE:
    case mallActions.CREATE_MALL_PROMOTION_FAILURE:
    case mallActions.EDIT_MALL_PRODUCT_FAILURE:
    case mallActions.GET_MALL_PRODUCTS_FAILURE:
    case mallActions.GET_MALL_PROMOTION_FAILURE:
    case mallActions.EDIT_MALL_PROMOTION_FAILURE:
    case mallActions.DELETE_MALL_PROMOTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
