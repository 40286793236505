import antdDe from 'antd/lib/locale-provider/de_DE';
import appLocaleData from 'react-intl/locale-data/de';
import deMessages from '../locales/de_DE.json';

const DeLang = {
  messages: {
    ...deMessages,
  },
  antd: antdDe,
  locale: 'de',
  data: appLocaleData,
};
export default DeLang;
