import React from "react";
import ImageCellView from "./ImageCell";
import DeleteCell from "./DeleteCell";
import EditableCell from "./EditableCell";
import FilterDropdown from "./FilterDropdown";
import ArrayCell from "./ArrayCell";
import IntlMessages from "@iso/components/utility/intlMessages";
import { R_IMAGE_URL } from "@iso/config/Services/requestUrl";

// const imageBaseUrl = R_IMAGE_URL+"/image/480/480/";
const imageBaseUrl = R_IMAGE_URL.replace(/\/$/g, "");
const imageBaseUrl2 = R_IMAGE_URL.replace(/\/image\/400\/400\//g, "").replace(
  /\/$/g,
  "",
);

const TextCellfirmwareType = (text) => {
  if (text === "BOARD") {
    return (
      <span>
        <IntlMessages id="update.BOARD" />
      </span>
    );
  } else if (text === "DTU") {
    return (
      <span>
        <IntlMessages id="update.DTU" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const DateCell = (time) => {
  if (!time) {
    return <span>{`-`}</span>;
  } else {
    time = new Date(time);
  }
  const year = time.getFullYear();
  let month = time.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = time.getDate();
  day = day < 10 ? "0" + day : day;
  let hour = time.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  let minute = time.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  let second = time.getSeconds();
  second = second < 10 ? "0" + second : second;

  let formatting = "YYYY-MM-DD hh:mm:ss";
  formatting = formatting.replace(/YYYY/g, year);
  formatting = formatting.replace(/MM/g, month);
  formatting = formatting.replace(/DD/g, day);
  formatting = formatting.replace(/hh/g, hour);
  formatting = formatting.replace(/mm/g, minute);
  formatting = formatting.replace(/ss/g, second);
  return <span>{formatting}</span>;
};
const DayCell = (time) => {
  if (!time) {
    return <span>{`-`}</span>;
  } else {
    time = new Date(time);
  }
  const year = time.getFullYear();
  let month = time.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = time.getDate();
  day = day < 10 ? "0" + day : day;
  let hour = time.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  let minute = time.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  let second = time.getSeconds();
  second = second < 10 ? "0" + second : second;

  let formatting = "YYYY-MM-DD";
  formatting = formatting.replace(/YYYY/g, year);
  formatting = formatting.replace(/MM/g, month);
  formatting = formatting.replace(/DD/g, day);
  formatting = formatting.replace(/hh/g, hour);
  formatting = formatting.replace(/mm/g, minute);
  formatting = formatting.replace(/ss/g, second);
  return <span>{formatting}</span>;
};
const ImageCell = (src) => {
  if (src) {
    let isGif = src.toUpperCase().indexOf(".GIF") > 0 ? true : false;
    let _img_url = isGif ? imageBaseUrl2 : imageBaseUrl;
    const _src = src.indexOf("http") >= 0 ? src : _img_url + "/" + src;
    return <ImageCellView src={_src} />;
  } else {
    return <span>{"-"}</span>;
  }
};
const ImageCell2 = (src) => {
  if (src) {
    let isGif = src.toUpperCase().indexOf(".GIF") > 0 ? true : false;
    let _img_url = isGif ? imageBaseUrl2 : imageBaseUrl;
    const _src = src.indexOf("http") >= 0 ? src : _img_url + "/" + src;
    return <ImageCellView src={_src} />;
  } else {
    return <span>{"-"}</span>;
  }
};
const LinkCell = (link, href) => (
  <a
    href={link ? link : "#"}
    target="__blank"
  >
    {link}
  </a>
);
const TextCell = (text) => (
  <span>{text !== undefined && text !== null && text !== "" ? text : "-"}</span>
);
const RemarkCell = (text) => (
  <div style={{ minWidth: "300px" }}>{text ? text : "-"}</div>
);
const TextCellresult = (text) => {
  // * SUCCESS :1.0 成功
  // * FAILURE :2.0 失败
  // * AWAIT_UPGRADE :3.0 待升级
  if (text === "SUCCESS" || text === true) {
    return (
      <span>
        <IntlMessages id="update.SUCCESS" />
      </span>
    );
  } else if (text === "FAILURE" || text === false) {
    return (
      <span>
        <IntlMessages id="update.FAILURE" />
      </span>
    );
  } else if (text === "AWAIT_UPGRADE" || text === false) {
    return (
      <span>
        <IntlMessages id="update.AWAIT_UPGRADE" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCellupdateType = (text) => {
  if (text === "NOT_FORCE" || text === "active" || text === true) {
    return (
      <span>
        <IntlMessages id="update.active" />
      </span>
    );
  } else if (text === "FORCE" || text === "silence" || text === false) {
    return (
      <span>
        <IntlMessages id="update.force" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCellmajor = (text) => {
  if (text === "1" || text === true) {
    return (
      <span>
        <IntlMessages id="equipment.detail.major1" />
      </span>
    );
  } else if (text === "0" || text === false) {
    return (
      <span>
        <IntlMessages id="equipment.detail.major0" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCellonline = (text) => {
  if (text === "1" || text === "Y" || text === true) {
    return (
      <span style={{ color: "green" }}>
        <IntlMessages id="equipment.detail.online" />
      </span>
    );
  } else if (text === "0" || text === "N" || text === false) {
    return (
      <span style={{ color: "red" }}>
        <IntlMessages id="equipment.detail.offline" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCellenable = (text) => {
  if (text === "enable" || text === "1" || text === 1 || text === true) {
    return (
      <span style={{ color: "green" }}>
        <IntlMessages id="enable" />
      </span>
    );
  } else if (
    text === "disable" ||
    text === "0" ||
    text === 0 ||
    text === false
  ) {
    return (
      <span style={{ color: "red" }}>
        <IntlMessages id="disabled" />
      </span>
    );
  } else if (text === "draft") {
    return (
      <span style={{ color: "gray" }}>
        <IntlMessages id="craft" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCellapplication = (text) => {
  if (text === "app") {
    // return (<span><IntlMessages id="enable" /></span>)
    return (
      <span>
        <IntlMessages id="app" />
      </span>
    );
  } else if (text === "device") {
    // return (<span><IntlMessages id="craft" /></span>)
    return (
      <span>
        <IntlMessages id="device" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCellstatus = (text) => {
  if (text === "normal" || text === "Y") {
    return (
      <span>
        <IntlMessages id="equipment.detail.normal" />
      </span>
    );
  } else if (text === "alarm") {
    return (
      <span>
        <IntlMessages id="equipment.detail.alarm" />
      </span>
    );
  } else if (text === "fault") {
    return (
      <span>
        <IntlMessages id="equipment.detail.fault" />
      </span>
    );
  } else if (text === null || text === undefined) {
    return (
      <span>
        <IntlMessages id="equipment.detail.fault" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCelldevelopStatus = (text) => {
  if (text === "develop") {
    return (
      <span>
        <IntlMessages id="product.status.develop" />
      </span>
    );
  } else if (text === "trial") {
    return (
      <span>
        <IntlMessages id="product.status.trial" />
      </span>
    );
  } else if (text === "listed") {
    return (
      <span>
        <IntlMessages id="product.status.listed" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCellcommunicationType = (text) => {
  if (text === "GSM_4G") {
    return <span>{"4G"}</span>;
  } else if (text === "WIFI") {
    return <span>{"WIFI"}</span>;
  } else if (text === "BLE") {
    return <span>{"Bluetooth"}</span>;
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCellaccessMode = (text) => {
  if (text === "r") {
    return (
      <span>
        <IntlMessages id="product.detail.rwType2" />
      </span>
    );
  } else if (text === "rw") {
    return (
      <span>
        <IntlMessages id="product.detail.rwType1" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCelltargetType = (text) => {
  if (text === "VERSION") {
    return (
      <span>
        <IntlMessages id="firmware.targetType.version" />
      </span>
    );
  } else if (text === "DEVICE") {
    return (
      <span>
        <IntlMessages id="firmware.targetType.device" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const TextCellstatus_firm = (text) => {
  if (text === "ENABLE") {
    return (
      <span style={{ color: "green" }}>
        <IntlMessages id="enable" />
      </span>
    );
  } else if (text === "DISABLE") {
    return (
      <span style={{ color: "red" }}>
        <IntlMessages id="disabled" />
      </span>
    );
  } else if (text === "EXAMINE_WAIT") {
    return (
      <span style={{ color: "blue" }}>
        <IntlMessages id="examine_wait" />
      </span>
    );
  } else if (text === "EXAMINE_REJECT") {
    return (
      <span style={{ color: "gray" }}>
        <IntlMessages id="examine_reject" />
      </span>
    );
  } else {
    return <span>{"-"}</span>;
  }
};
const BooleanCell = (text) => (
  <span> {!!Boolean(text) ? "true" : "false"}</span>
);
const TextCellDynamic = (object, Column) => {
  const value = object && object.chipId !== "" ? object.chipId : "-";
  if (object && object.communicationType === Column) {
    return <span>{value}</span>;
  } else if (object && object.communicationType !== Column) {
    return <span>{"-"}</span>;
  } else {
    return <span>{"-"}</span>;
  }
};
const NestedKeyCells = (object, NestedKey) => {
  const value = NestedKey.split("-");
  const key = object[value[0]];
  const nestedKey = key[value[1]];
  return <span>{nestedKey}</span>;
};
const AutoTextCell = (text) => {
  return <div style={{ minWidth: "180px" }}>{text ? text : "-"}</div>;
};
export {
  TextCellfirmwareType,
  DateCell,
  DayCell,
  ImageCell,
  ImageCell2,
  LinkCell,
  TextCell,
  TextCellresult,
  RemarkCell,
  TextCellupdateType,
  TextCellmajor,
  TextCellonline,
  TextCellenable,
  TextCellapplication,
  TextCellstatus,
  TextCelldevelopStatus,
  TextCellcommunicationType,
  TextCellaccessMode,
  TextCelltargetType,
  TextCellstatus_firm,
  BooleanCell,
  TextCellDynamic,
  NestedKeyCells,
  AutoTextCell,
  EditableCell,
  DeleteCell,
  FilterDropdown,
  ArrayCell,
};
