import DeviceTestActions from "./actions";

const INITIAL_DATA = {
  DeviceTestNewdataTotal: 0,
  DeviceTestNewdata: [],
  DeviceTestOlddataTotal: 0,
  DeviceTestOlddata: [],
  editDeviceTest: [],
  addDeviceTest: [],
  loading: true,
  error: null,
  newDetailData:
    sessionStorage.getItem("newDetailData") !== null
      ? JSON.parse(sessionStorage.getItem("newDetailData"))
      : {},
  oldDetailData:
    sessionStorage.getItem("oldDetailData") !== null
      ? JSON.parse(sessionStorage.getItem("oldDetailData"))
      : {},
};
export default function DeviceTestReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case DeviceTestActions.view_OldDeviceTest_Detail:
      sessionStorage.setItem("oldDetailData", JSON.stringify(action.payload));
      return {
        ...state,
        oldDetailData: action.payload,
        loading: false,
        error: null,
      };
    case DeviceTestActions.view_NewDeviceTest_Detail:
      sessionStorage.setItem("newDetailData", JSON.stringify(action.payload));
      return {
        ...state,
        newDetailData: action.payload,
        loading: false,
        error: null,
      };
    case DeviceTestActions.FETCH_DeviceTestNew_DATA_REQUEST:
      return {
        ...state,
        DeviceTestNewdata: [],
        loading: true,
        error: null,
      };
    case DeviceTestActions.FETCH_DeviceTestNew_DATA_SUCCESS:
      let DeviceTestNewdata = Object.assign([], action.payload);
      DeviceTestNewdata.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        DeviceTestNewdata: action.payload.list,
        DeviceTestNewdataTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case DeviceTestActions.FETCH_DeviceTestNew_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DeviceTestActions.FETCH_DeviceTestOld_DATA_REQUEST:
      return {
        ...state,
        DeviceTestOlddata: [],
        loading: true,
        error: null,
      };
    case DeviceTestActions.FETCH_DeviceTestOld_DATA_SUCCESS:
      let DeviceTestOlddata = Object.assign([], action.payload);
      DeviceTestOlddata.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        DeviceTestOlddata: action.payload.list,
        DeviceTestOlddataTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case DeviceTestActions.FETCH_DeviceTestOld_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DeviceTestActions.ADD_DeviceTestNew_REQUEST:
      return {
        ...state,
        addDeviceTest: [],
        loading: true,
        error: null,
      };
    case DeviceTestActions.ADD_DeviceTestNew_SUCCESS:
      return {
        ...state,
        addDeviceTest: action.payload,
        loading: false,
        error: null,
      };
    case DeviceTestActions.ADD_DeviceTestNew_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // EDIT DeviceTest
    case DeviceTestActions.EDIT_DeviceTestNew_REQUEST:
      return {
        ...state,
        editDeviceTest: [],
        loading: true,
        error: null,
      };
    case DeviceTestActions.EDIT_DeviceTestNew_SUCCESS:
      return {
        ...state,
        editDeviceTest: action.payload,
        loading: false,
        error: null,
      };
    case DeviceTestActions.EDIT_DeviceTestNew_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DeviceTestActions.DELETE_DeviceTest_REQUEST:
      return {
        ...state,
        DeviceTestdata: [],
        loading: true,
        error: null,
      };
    case DeviceTestActions.DELETE_DeviceTest_SUCCESS:
      return {
        ...state,
        DeviceTestdata: action.payload,
        loading: false,
        error: null,
      };
    case DeviceTestActions.DELETE_DeviceTest_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
