const userActions = {
  //Fetch USER LIST Data
  FETCH_USER_DATA_REQUEST: "FETCH_USER_DATA_REQUEST",
  FETCH_USER_DATA_SUCCESS: "FETCH_USER_DATA_SUCCESS",
  FETCH_USER_DATA_FAILURE: "FETCH_USER_DATA_FAILURE",
  FETCH_ALL_USER_REQUEST: "FETCH_ALL_USER_REQUEST",
  FETCH_ALL_USER_SUCCESS: "FETCH_ALL_USER_SUCCESS",
  FETCH_ALL_USER_FAILURE: "FETCH_ALL_USER_FAILURE",

  //Reset Password
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_REDIRECT: "RESET_REDIRECT",

  //Change Password
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  SET_USER_DATA: "SET_USER_DATA",
  fetchUserDataRequest: (request) => ({
    type: userActions.FETCH_USER_DATA_REQUEST,
    payload: request,
  }),
  fetchlanguageDataSuccess: (user) => ({
    type: userActions.FETCH_USER_DATA_SUCCESS,
    payload: user,
  }),
  fetchlanguageDataFailure: (error) => ({
    type: userActions.FETCH_USER_DATA_FAILURE,
    payload: error,
  }),

  fetchAllUserDataRequest: (request) => ({
    type: userActions.FETCH_ALL_USER_REQUEST,
    payload: request,
  }),
  fetchAllUserDataSuccess: (user) => ({
    type: userActions.FETCH_ALL_USER_SUCCESS,
    payload: user,
  }),
  fetchAllUserDataFailure: (error) => ({
    type: userActions.FETCH_ALL_USER_FAILURE,
    payload: error,
  }),

  //Reset Password
  UserResetPasswordDataRequest: (request) => ({
    type: userActions.RESET_PASSWORD_REQUEST,
    payload: request,
  }),
  UserResetPasswordDataSuccess: (user) => ({
    type: userActions.RESET_PASSWORD_SUCCESS,
    payload: user,
  }),
  UserResetPasswordDataFailure: (error) => ({
    type: userActions.RESET_PASSWORD_FAILURE,
    payload: error,
  }),
  resetRedirect: (error) => ({
    type: userActions.RESET_REDIRECT,
  }),
  //Change Password
  UserChangePasswordDataRequest: (request) => ({
    type: userActions.CHANGE_PASSWORD_REQUEST,
    payload: request,
  }),
  UserChangePasswordDataSuccess: (user) => ({
    type: userActions.CHANGE_PASSWORD_SUCCESS,
    payload: user,
  }),
  UserChangePasswordDataFailure: (error) => ({
    type: userActions.CHANGE_PASSWORD_FAILURE,
    payload: error,
  }),

  //get verification code
  Get_ForgetPass_Verification_Request: "Get_ForgetPass_Verification_Request",
  GetForgetPassVerificationRequest: (request) => ({
    type: userActions.Get_ForgetPass_Verification_Request,
    payload: request,
  }),

  //Send_resetPassword_Request
  Send_resetPassword_Request: "Send_resetPassword_Request",
  Send_resetPassword_Success: "Send_resetPassword_Success",
  Send_resetPassword_Failure: "Send_resetPassword_Failure",
  SendResetPasswordRequest: (request) => ({
    type: userActions.Send_resetPassword_Request,
    payload: request,
  }),
  SendResetPasswordSuccess: (request) => ({
    type: userActions.Send_resetPassword_Success,
    payload: request,
  }),

  SendResetPasswordFailure: (error) => ({
    type: userActions.Send_resetPassword_Failure,
    payload: error,
  }),
};

export default userActions;
