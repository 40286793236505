import Actions from "./actions";

const INITIAL_DATA = {
  realtime_online_data: [], // 实时在线用户
  terminal_type_data: null, // 不同终端类型用户
  newly_added_data: [], // 用户新增
  realtime_onlin_data: [], // 实时在线用户
  version_division_data: {
    androidData: [],
    iosData: [],
  }, // 版本分布
  activation_data: [], // 用户活跃度
  group_distribution_data: [], // 用户群体分布

  loading: true,
  error: null,
};
export default function PageReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    // 不同终端类型用户
    case Actions.load_terminal_type_REQUEST:
      return {
        ...state,
        terminal_type_data: null,
        loading: true,
        error: null,
      };
    case Actions.load_terminal_type_SUCCESS:
      const _terminal_type_data = action.payload;
      if (_terminal_type_data.total) {
        const total = _terminal_type_data.total;
        const iosTotal = _terminal_type_data.iosTotal;
        const androidTotal = _terminal_type_data.androidTotal;
        // const otherTotal = _terminal_type_data.otherTotal;
        let iosPercent = iosTotal ? (iosTotal / total) * 100 : 0;
        let androidPercent = androidTotal ? (androidTotal / total) * 100 : 0;
        let otherPercent = 100 - iosPercent - androidPercent;
        iosPercent = iosPercent.toFixed(1) + "%";
        androidPercent = androidPercent.toFixed(1) + "%";
        otherPercent = otherPercent.toFixed(1) + "%";

        _terminal_type_data.iosPercent = iosPercent;
        _terminal_type_data.androidPercent = androidPercent;
        _terminal_type_data.otherPercent = otherPercent;
      } else {
        _terminal_type_data.iosPercent = "0%";
        _terminal_type_data.androidPercent = "0%";
        _terminal_type_data.otherPercent = "0%";
      }
      return {
        ...state,
        terminal_type_data: _terminal_type_data,
        loading: false,
        error: null,
      };
    case Actions.load_terminal_type_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 用户新增
    case Actions.load_newly_added_REQUEST:
      return {
        ...state,
        newly_added_data: {
          addList: [],
          new: "+0",
          total: "0",
        },
        loading: true,
        error: null,
      };
    case Actions.load_newly_added_SUCCESS:
      const res = action.payload;
      let addList = res["addList"];
      let _addList = addList.filter((item) => item.ordinateData > 0);
      if (_addList.length === 0) {
        addList = [];
        res["addList"] = addList;
      }
      if (addList && addList.length > 1) {
        const len = addList.length;
        res["new"] = addList[len - 1].ordinateData;
      } else {
        res["new"] = 0;
      }
      return {
        ...state,
        newly_added_data: res,
        loading: false,
        error: null,
      };
    case Actions.load_newly_added_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 实时在线用户
    case Actions.load_realtime_online_REQUEST:
      return {
        ...state,
        realtime_online_data: [],
        loading: true,
        error: null,
      };
    case Actions.load_realtime_online_SUCCESS:
      return {
        ...state,
        realtime_online_data: action.payload,
        loading: false,
        error: null,
      };
    case Actions.load_realtime_online_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 版本分布
    case Actions.load_version_division_REQUEST:
      return {
        ...state,
        version_division_data: {
          androidData: [],
          iosData: [],
        },
        loading: true,
        error: null,
      };
    case Actions.load_version_division_SUCCESS:
      const androidData = action.payload.androidData || [];
      const iosData = action.payload.iosData || [];
      // const androidData = [];
      // const iosData = [];
      for (let i = 0; i < androidData.length; i++) {
        androidData[i].percentF = androidData[i].percent
          ? (androidData[i].percent * 100).toFixed(1) + "%"
          : "0%";
      }
      for (let i = 0; i < iosData.length; i++) {
        iosData[i].percentF = iosData[i].percent
          ? (iosData[i].percent * 100).toFixed(1) + "%"
          : "0%";
      }

      return {
        ...state,
        version_division_data: {
          androidData: androidData,
          iosData: iosData,
        },
        loading: false,
        error: null,
      };
    case Actions.load_version_division_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 用户活跃度
    case Actions.load_activation_REQUEST:
      return {
        ...state,
        activation_data: [],
        loading: true,
        error: null,
      };
    case Actions.load_activation_SUCCESS:
      return {
        ...state,
        activation_data: action.payload,
        loading: false,
        error: null,
      };
    case Actions.load_activation_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 用户群体分布
    case Actions.load_group_distribution_REQUEST:
      return {
        ...state,
        group_distribution_data: [],
        loading: true,
        error: null,
      };
    case Actions.load_group_distribution_SUCCESS:
      return {
        ...state,
        group_distribution_data: action.payload,
        loading: false,
        error: null,
      };
    case Actions.load_group_distribution_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
