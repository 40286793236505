const languageActions = {
  // select Language Data
  SELECT_LANGUAGE_REQUEST: "SELECT_LANGUAGE_REQUEST",
  SELECT_LANGUAGE_SUCCESS: "SELECT_LANGUAGE_SUCCESS",
  SELECT_LANGUAGE_FAILURE: "SELECT_LANGUAGE_FAILURE",
  selectlanguageRequest: (request) => ({
    type: languageActions.SELECT_LANGUAGE_REQUEST,
    payload: request,
  }),
  selectlanguageSuccess: (language) => ({
    type: languageActions.SELECT_LANGUAGE_SUCCESS,
    payload: language,
  }),
  selectlanguageFailure: (error) => ({
    type: languageActions.SELECT_LANGUAGE_FAILURE,
    payload: error,
  }),

  //Fetch Language Data
  FETCH_LANGUAGE_DATA_REQUEST: "FETCH_LANGUAGE_DATA_REQUEST",
  FETCH_LANGUAGE_DATA_SUCCESS: "FETCH_LANGUAGE_DATA_SUCCESS",
  FETCH_LANGUAGE_DATA_FAILURE: "FETCH_LANGUAGE_DATA_FAILURE",
  fetchlanguageDataRequest: (request) => ({
    type: languageActions.FETCH_LANGUAGE_DATA_REQUEST,
    payload: request,
  }),
  fetchlanguageDataSuccess: (language) => ({
    type: languageActions.FETCH_LANGUAGE_DATA_SUCCESS,
    payload: language,
  }),
  fetchlanguageDataFailure: (error) => ({
    type: languageActions.FETCH_LANGUAGE_DATA_FAILURE,
    payload: error,
  }),
  //Fetch country Country COUNTRY Data
  FETCH_COUNTRY_DATA_REQUEST: "FETCH_COUNTRY_DATA_REQUEST",
  FETCH_COUNTRY_DATA_SUCCESS: "FETCH_COUNTRY_DATA_SUCCESS",
  FETCH_COUNTRY_DATA_FAILURE: "FETCH_COUNTRY_DATA_FAILURE",
  fetchCountryDataRequest: (request) => ({
    type: languageActions.FETCH_COUNTRY_DATA_REQUEST,
    payload: request,
  }),
  fetchCountryDataSuccess: (language) => ({
    type: languageActions.FETCH_COUNTRY_DATA_SUCCESS,
    payload: language,
  }),
  fetchCountryDataFailure: (error) => ({
    type: languageActions.FETCH_COUNTRY_DATA_FAILURE,
    payload: error,
  }),

  // Add Langauge
  ADD_LANGUAGE_REQUEST: "ADD_LANGUAGE_REQUEST",
  ADD_LANGUAGE_SUCCESS: "ADD_LANGUAGE_SUCCESS",
  ADD_LANGUAGE_FAILURE: "ADD_LANGUAGE_FAILURE",
  addLanguageDataRequest: (request) => ({
    type: languageActions.ADD_LANGUAGE_REQUEST,
    payload: request,
  }),
  addLanguageDataSuccess: (language) => ({
    type: languageActions.ADD_LANGUAGE_SUCCESS,
    payload: language,
  }),
  addLanguageDataFailure: (error) => ({
    type: languageActions.ADD_LANGUAGE_FAILURE,
    payload: error,
  }),
  // Add country Country COUNTRY
  ADD_COUNTRY_REQUEST: "ADD_COUNTRY_REQUEST",
  ADD_COUNTRY_SUCCESS: "ADD_COUNTRY_SUCCESS",
  ADD_COUNTRY_FAILURE: "ADD_COUNTRY_FAILURE",
  addCountryDataRequest: (request) => ({
    type: languageActions.ADD_COUNTRY_REQUEST,
    payload: request,
  }),
  addCountryDataSuccess: (language) => ({
    type: languageActions.ADD_COUNTRY_SUCCESS,
    payload: language,
  }),
  addCountryDataFailure: (error) => ({
    type: languageActions.ADD_COUNTRY_FAILURE,
    payload: error,
  }),

  // Edit Langauge
  EDIT_LANGUAGE_REQUEST: "EDIT_LANGUAGE_REQUEST",
  EDIT_LANGUAGE_SUCCESS: "EDIT_LANGUAGE_SUCCESS",
  EDIT_LANGUAGE_FAILURE: "EDIT_LANGUAGE_FAILURE",
  editLanguageDataRequest: (request) => ({
    type: languageActions.EDIT_LANGUAGE_REQUEST,
    payload: request,
  }),
  editLanguageDataSuccess: (language) => ({
    type: languageActions.EDIT_LANGUAGE_SUCCESS,
    payload: language,
  }),
  editLanguageDataFailure: (error) => ({
    type: languageActions.EDIT_LANGUAGE_FAILURE,
    payload: error,
  }),
  // Edit country Country COUNTRY
  EDIT_COUNTRY_REQUEST: "EDIT_COUNTRY_REQUEST",
  EDIT_COUNTRY_SUCCESS: "EDIT_COUNTRY_SUCCESS",
  EDIT_COUNTRY_FAILURE: "EDIT_COUNTRY_FAILURE",
  editCountryDataRequest: (request) => ({
    type: languageActions.EDIT_COUNTRY_REQUEST,
    payload: request,
  }),
  editCountryDataSuccess: (language) => ({
    type: languageActions.EDIT_COUNTRY_SUCCESS,
    payload: language,
  }),
  editCountryDataFailure: (error) => ({
    type: languageActions.EDIT_COUNTRY_FAILURE,
    payload: error,
  }),

  // Delete Langauge
  DELETE_LANGUAGE_REQUEST: "DELETE_LANGUAGE_REQUEST",
  DELETE_LANGUAGE_SUCCESS: "DELETE_LANGUAGE_SUCCESS",
  DELETE_LANGUAGE_FAILURE: "DELETE_LANGUAGE_FAILURE",
  deleteLanguageDataRequest: (request) => ({
    type: languageActions.DELETE_LANGUAGE_REQUEST,
    payload: request,
  }),
  deleteLanguageDataSuccess: (language) => ({
    type: languageActions.DELETE_LANGUAGE_SUCCESS,
    payload: language,
  }),
  deleteLanguageDataFailure: (error) => ({
    type: languageActions.DELETE_LANGUAGE_FAILURE,
    payload: error,
  }),
  // Delete country Country COUNTRY
  DELETE_COUNTRY_REQUEST: "DELETE_COUNTRY_REQUEST",
  DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_FAILURE: "DELETE_COUNTRY_FAILURE",
  deleteCountryDataRequest: (request) => ({
    type: languageActions.DELETE_COUNTRY_REQUEST,
    payload: request,
  }),
  deleteCountryDataSuccess: (language) => ({
    type: languageActions.DELETE_COUNTRY_SUCCESS,
    payload: language,
  }),
  deleteCountryDataFailure: (error) => ({
    type: languageActions.DELETE_COUNTRY_FAILURE,
    payload: error,
  }),
};

export default languageActions;
