import modalActions from "./modalActions";

const INITIAL_DATA = {
  modal: [],
  loading: false,
  error: null,
};
export default function modalReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case modalActions.GET_MODAL_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case modalActions.GET_MODAL_SUCCESS:
      return {
        ...state,
        modal: action.payload,
        loading: false,
        error: null,
      };
    case modalActions.GET_MODAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
