import welComePageActions from "./actions";

const INITIAL_DATA = {
  welComePagedataTotal: 0,
  welComePagedata: [],
  editwelComePage: [],
  addwelComePage: [],
  loading: true,
  error: null,
};
export default function welComePageReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case welComePageActions.FETCH_welComePage_DATA_REQUEST:
      return {
        ...state,
        welComePagedata: [],
        loading: true,
        error: null,
      };
    case welComePageActions.FETCH_welComePage_DATA_SUCCESS:
      let welComePagedata = Object.assign([], action.payload);
      welComePagedata.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        welComePagedata: action.payload.list,
        welComePagedataTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case welComePageActions.FETCH_welComePage_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case welComePageActions.ADD_welComePage_REQUEST:
      return {
        ...state,
        addwelComePage: [],
        loading: true,
        error: null,
      };
    case welComePageActions.ADD_welComePage_SUCCESS:
      return {
        ...state,
        addwelComePage: action.payload,
        loading: false,
        error: null,
      };
    case welComePageActions.ADD_welComePage_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case welComePageActions.EDIT_welComePage_REQUEST:
      return {
        ...state,
        editwelComePage: [],
        loading: true,
        error: null,
      };
    case welComePageActions.EDIT_welComePage_SUCCESS:
      return {
        ...state,
        editwelComePage: action.payload,
        loading: false,
        error: null,
      };
    case welComePageActions.EDIT_welComePage_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case welComePageActions.DELETE_welComePage_REQUEST:
      return {
        ...state,
        welComePagedata: [],
        loading: true,
        error: null,
      };
    case welComePageActions.DELETE_welComePage_SUCCESS:
      return {
        ...state,
        welComePagedata: action.payload,
        loading: false,
        error: null,
      };
    case welComePageActions.DELETE_welComePage_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
