const modalActions = {
  //Fetch modal Data
  GET_MODAL_ID_REQUEST: " GET_MODAL_ID_REQUEST",
  GET_MODAL_SUCCESS: "GET_MODAL_SUCCESS",
  GET_MODAL_FAILURE: "GET_MODAL_FAILURE",
  getModalRequest: (request) => {
    return {
      type: modalActions.GET_MODAL_ID_REQUEST,
      payload: request,
    };
  },
  getModalSuccess: (modal) => {
    return {
      type: modalActions.GET_MODAL_SUCCESS,
      payload: modal,
    };
  },
  getModalFailure: (error) => ({
    type: modalActions.GET_MODAL_FAILURE,
    payload: error,
  }),
};

export default modalActions;
