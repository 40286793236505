const adsActions = {
  //Fetch Ads Data
  FETCH_ADS_DATA_REQUEST: "FETCH_ADS_DATA_REQUEST",
  FETCH_ADS_DATA_SUCCESS: "FETCH_ADS_DATA_SUCCESS",
  FETCH_ADS_DATA_FAILURE: "FETCH_ADS_DATA_FAILURE",
  SET_ADS_DATA: "SET_ADS_DATA",
  fetchAdsDataRequest: (request) => ({
    type: adsActions.FETCH_ADS_DATA_REQUEST,
    payload: request,
  }),
  fetchAdsDataSuccess: (ads) => ({
    type: adsActions.FETCH_ADS_DATA_SUCCESS,
    payload: ads,
  }),
  fetchAdsDataFailure: (error) => ({
    type: adsActions.FETCH_ADS_DATA_FAILURE,
    payload: error,
  }),

  // Add Ads
  ADD_ADS_REQUEST: "ADD_ADS_REQUEST",
  ADD_ADS_SUCCESS: "ADD_ADS_SUCCESS",
  ADD_ADS_FAILURE: "ADD_ADS_FAILURE",
  addAdsDataRequest: (request) => ({
    type: adsActions.ADD_ADS_REQUEST,
    payload: request,
  }),
  addAdsDataSuccess: (ads) => ({
    type: adsActions.ADD_ADS_SUCCESS,
    payload: ads,
  }),
  addAdsDataFailure: (error) => ({
    type: adsActions.ADD_ADS_FAILURE,
    payload: error,
  }),

  // Edit Ads
  EDIT_ADS_REQUEST: "EDIT_ADS_REQUEST",
  EDIT_ADS_SUCCESS: "EDIT_ADS_SUCCESS",
  EDIT_ADS_FAILURE: "EDIT_ADS_FAILURE",
  editAdsDataRequest: (request) => ({
    type: adsActions.EDIT_ADS_REQUEST,
    payload: request,
  }),
  editAdsDataSuccess: (ads) => ({
    type: adsActions.EDIT_ADS_SUCCESS,
    payload: ads,
  }),
  editAdsDataFailure: (error) => ({
    type: adsActions.EDIT_ADS_FAILURE,
    payload: error,
  }),

  // Delete Ads
  DELETE_ADS_REQUEST: "DELETE_ADS_REQUEST",
  DELETE_ADS_SUCCESS: "DELETE_ADS_SUCCESS",
  DELETE_ADS_FAILURE: "DELETE_ADS_FAILURE",
  deleteAdsDataRequest: (request) => ({
    type: adsActions.DELETE_ADS_REQUEST,
    payload: request,
  }),
  deleteAdsDataSuccess: (ads) => ({
    type: adsActions.DELETE_ADS_SUCCESS,
    payload: ads,
  }),
  deleteAdsDataFailure: (error) => ({
    type: adsActions.DELETE_ADS_FAILURE,
    payload: error,
  }),
};

export default adsActions;
