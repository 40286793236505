const DeviceTestActions = {
  //Fetch DeviceTestNew Data
  FETCH_DeviceTestNew_DATA_REQUEST: "FETCH_DeviceTestNew_DATA_REQUEST",
  FETCH_DeviceTestNew_DATA_SUCCESS: "FETCH_DeviceTestNew_DATA_SUCCESS",
  FETCH_DeviceTestNew_DATA_FAILURE: "FETCH_DeviceTestNew_DATA_FAILURE",
  SET_DeviceTestNew_DATA: "SET_DeviceTestNew_DATA",
  fetchDeviceTestNewDataRequest: (request) => ({
    type: DeviceTestActions.FETCH_DeviceTestNew_DATA_REQUEST,
    payload: request,
  }),
  fetchDeviceTestNewDataSuccess: (DeviceTest) => ({
    type: DeviceTestActions.FETCH_DeviceTestNew_DATA_SUCCESS,
    payload: DeviceTest,
  }),
  fetchDeviceTestNewDataFailure: (error) => ({
    type: DeviceTestActions.FETCH_DeviceTestNew_DATA_FAILURE,
    payload: error,
  }),

  //Fetch DeviceTestOld Data
  FETCH_DeviceTestOld_DATA_REQUEST: "FETCH_DeviceTestOld_DATA_REQUEST",
  FETCH_DeviceTestOld_DATA_SUCCESS: "FETCH_DeviceTestOld_DATA_SUCCESS",
  FETCH_DeviceTestOld_DATA_FAILURE: "FETCH_DeviceTestOld_DATA_FAILURE",
  SET_DeviceTestOld_DATA: "SET_DeviceTestOld_DATA",
  fetchDeviceTestOldDataRequest: (request) => ({
    type: DeviceTestActions.FETCH_DeviceTestOld_DATA_REQUEST,
    payload: request,
  }),
  fetchDeviceTestOldDataSuccess: (DeviceTest) => ({
    type: DeviceTestActions.FETCH_DeviceTestOld_DATA_SUCCESS,
    payload: DeviceTest,
  }),
  fetchDeviceTestOldDataFailure: (error) => ({
    type: DeviceTestActions.FETCH_DeviceTestOld_DATA_FAILURE,
    payload: error,
  }),

  // Add DeviceTestNew
  ADD_DeviceTestNew_REQUEST: "ADD_DeviceTestNew_REQUEST",
  ADD_DeviceTestNew_SUCCESS: "ADD_DeviceTestNew_SUCCESS",
  ADD_DeviceTestNew_FAILURE: "ADD_DeviceTestNew_FAILURE",
  addDeviceTestNewDataRequest: (request) => ({
    type: DeviceTestActions.ADD_DeviceTestNew_REQUEST,
    payload: request,
  }),
  addDeviceTestNewDataSuccess: (DeviceTest) => ({
    type: DeviceTestActions.ADD_DeviceTestNew_SUCCESS,
    payload: DeviceTest,
  }),
  addDeviceTestNewDataFailure: (error) => ({
    type: DeviceTestActions.ADD_DeviceTestNew_FAILURE,
    payload: error,
  }),

  // Add DeviceTestOld
  ADD_DeviceTestOld_REQUEST: "ADD_DeviceTestOld_REQUEST",
  ADD_DeviceTestOld_SUCCESS: "ADD_DeviceTestOld_SUCCESS",
  ADD_DeviceTestOld_FAILURE: "ADD_DeviceTestOld_FAILURE",
  addDeviceTestOldDataRequest: (request) => ({
    type: DeviceTestActions.ADD_DeviceTestOld_REQUEST,
    payload: request,
  }),
  addDeviceTestOldDataSuccess: (DeviceTest) => ({
    type: DeviceTestActions.ADD_DeviceTestOld_SUCCESS,
    payload: DeviceTest,
  }),
  addDeviceTestOldDataFailure: (error) => ({
    type: DeviceTestActions.ADD_DeviceTestOld_FAILURE,
    payload: error,
  }),

  // Edit DeviceTestNew
  EDIT_DeviceTestNew_REQUEST: "EDIT_DeviceTestNew_REQUEST",
  EDIT_DeviceTestNew_SUCCESS: "EDIT_DeviceTestNew_SUCCESS",
  EDIT_DeviceTestNew_FAILURE: "EDIT_DeviceTestNew_FAILURE",
  editDeviceTestNewDataRequest: (request) => ({
    type: DeviceTestActions.EDIT_DeviceTestNew_REQUEST,
    payload: request,
  }),
  editDeviceTestNewDataSuccess: (DeviceTest) => ({
    type: DeviceTestActions.EDIT_DeviceTestNew_SUCCESS,
    payload: DeviceTest,
  }),
  editDeviceTestNewDataFailure: (error) => ({
    type: DeviceTestActions.EDIT_DeviceTestNew_FAILURE,
    payload: error,
  }),

  // Edit DeviceTestOld
  EDIT_DeviceTestOld_REQUEST: "EDIT_DeviceTestOld_REQUEST",
  EDIT_DeviceTestOld_SUCCESS: "EDIT_DeviceTestOld_SUCCESS",
  EDIT_DeviceTestOld_FAILURE: "EDIT_DeviceTestOld_FAILURE",
  editDeviceTestOldDataRequest: (request) => ({
    type: DeviceTestActions.EDIT_DeviceTestOld_REQUEST,
    payload: request,
  }),
  editDeviceTestOldDataSuccess: (DeviceTest) => ({
    type: DeviceTestActions.EDIT_DeviceTestOld_SUCCESS,
    payload: DeviceTest,
  }),
  editDeviceTestOldDataFailure: (error) => ({
    type: DeviceTestActions.EDIT_DeviceTestOld_FAILURE,
    payload: error,
  }),

  // Delete DeviceTest
  DELETE_DeviceTest_REQUEST: "DELETE_DeviceTest_REQUEST",
  DELETE_DeviceTest_SUCCESS: "DELETE_DeviceTest_SUCCESS",
  DELETE_DeviceTest_FAILURE: "DELETE_DeviceTest_FAILURE",
  deleteDeviceTestDataRequest: (request) => ({
    type: DeviceTestActions.DELETE_DeviceTest_REQUEST,
    payload: request,
  }),
  deleteDeviceTestDataSuccess: (DeviceTest) => ({
    type: DeviceTestActions.DELETE_DeviceTest_SUCCESS,
    payload: DeviceTest,
  }),
  deleteDeviceTestDataFailure: (error) => ({
    type: DeviceTestActions.DELETE_DeviceTest_FAILURE,
    payload: error,
  }),

  //export error device preset list new
  EXPORT_Error_DeviceTestNew_REQUEST: "EXPORT_Error_DeviceTestNew_REQUEST",
  EXPORTDeviceTestDataNewRequest: (request) => ({
    type: DeviceTestActions.EXPORT_Error_DeviceTestNew_REQUEST,
    payload: request,
  }),

  //export error device preset list old
  EXPORT_Error_DeviceTestOld_REQUEST: "EXPORT_Error_DeviceTestOld_REQUEST",
  EXPORTDeviceTestDataOldRequest: (request) => ({
    type: DeviceTestActions.EXPORT_Error_DeviceTestOld_REQUEST,
    payload: request,
  }),

  //warehousing 入库 新型号 (到设备预设列表)
  Wrehousing_DeviceTestNew_REQUEST: "Warehousing_DeviceTestNew_REQUEST",
  WarehousingDeviceTestNewRequest: (request) => ({
    type: DeviceTestActions.Wrehousing_DeviceTestNew_REQUEST,
    payload: request,
  }),

  //warehousing 入库 老型号 (到设备列表)
  Warehousing_DeviceTestOld_REQUEST: "Warehousing_DeviceTestOld_REQUEST",
  WarehousingDeviceTestOldRequest: (request) => ({
    type: DeviceTestActions.Warehousing_DeviceTestOld_REQUEST,
    payload: request,
  }),

  //ReCheck 重检(不分新老型号)
  ReCheck_DeviceTest_REQUEST: "ReCheck_DeviceTest_REQUEST",
  ReCheckDeviceTestRequest: (request) => ({
    type: DeviceTestActions.ReCheck_DeviceTest_REQUEST,
    payload: request,
  }),

  //SaveResult 录入结果(不分新老型号)
  SaveResult_DeviceTest_REQUEST: "SaveResult_DeviceTest_REQUEST",
  SaveResultDeviceTestRequest: (request) => ({
    type: DeviceTestActions.SaveResult_DeviceTest_REQUEST,
    payload: request,
  }),

  //查看老型号详情数据
  view_OldDeviceTest_Detail: "view_OldDeviceTest_Detail",
  viewOldDeviceTestDetail: (data) => ({
    type: DeviceTestActions.view_OldDeviceTest_Detail,
    payload: data,
  }),
  //查看新型号详情数据
  view_NewDeviceTest_Detail: "view_NewDeviceTest_Detail",
  viewNewDeviceTestDetail: (data) => ({
    type: DeviceTestActions.view_NewDeviceTest_Detail,
    payload: data,
  }),
};

export default DeviceTestActions;
