import EditProductTemplateActions2 from "./actions";

const INITIAL_DATA = {
  create_Device: "0",
  create_Module: "0",
  create_Sec: "0",
  create_Setting: "0",

  delete_Module: "0",
  delete_Sec: "0",

  serviceList: [],
  propertyList: [],
  ModularList: [],
  dataALL: [],
  languageList: [],
  totalLanguage: 0,
  data: {},
  secondaryList: [],
  // totalList:0,
  loading: true,
  error: null,
  reset: false,
};
export default function EditProductTemplateReducer(
  state = INITIAL_DATA,
  action,
) {
  switch (action.type) {
    // all
    case EditProductTemplateActions2.POST_SaveAllInfoTemplate_REQUEST2:
      return {
        ...state,
        create_Device: "1",
        // create_Setting: '1',
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.POST_SaveAllInfoTemplate_SUCCESS2:
      return {
        ...state,
        create_Device: "2",
        // create_Setting: '2',
        loading: false,
        error: null,
      };
    case EditProductTemplateActions2.POST_SaveAllInfoTemplate_ERROR2:
      return {
        ...state,
        create_Device: "3",
        // create_Setting: '3',
        loading: false,
        error: action.payload,
      };

    //
    case EditProductTemplateActions2.GET_Modular_List_REQUEST2:
      return {
        ...state,
        ModularList: [],
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.GET_Modular_List_SUCCESS2:
      return {
        ...state,
        ModularList: action.payload,
        loading: false,
        error: null,
      };
    case EditProductTemplateActions2.GET_Modular_List_ERROR2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    //
    case EditProductTemplateActions2.GET_SecondarySelect_List_REQUEST2:
      return {
        ...state,
        secondaryList: [],
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.GET_SecondarySelect_List_SUCCESS2:
      return {
        ...state,
        secondaryList: action.payload,
        loading: false,
        error: null,
      };
    case EditProductTemplateActions2.GET_SecondarySelect_List_ERROR2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EditProductTemplateActions2.GET_Params_List_REQUEST2:
      return {
        ...state,
        propertyList: [],
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.GET_Params_List_SUCCESS2:
      let newArr = JSON.parse(JSON.stringify(action.payload)); //数组是引用类型, 深拷贝一下
      // newArr.forEach(e => {
      // 	delete e.accessMode;
      // 	delete e.dataType;
      // 	delete e.desc;
      // 	delete e.lengthProperty;
      // 	delete e.nameLanguage;
      // 	delete e.required;
      // 	delete e.specs;
      // 	delete e.tslVersionId;
      // })

      return {
        ...state,
        propertyList: newArr,
        loading: false,
        error: null,
      };
    case EditProductTemplateActions2.GET_Params_List_ERROR2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EditProductTemplateActions2.GET_Service_List_REQUEST2:
      return {
        ...state,
        serviceList: [],
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.GET_Service_List_SUCCESS2:
      return {
        ...state,
        serviceList: action.payload,
        loading: false,
        error: null,
      };
    case EditProductTemplateActions2.GET_Service_List_ERROR2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // edit
    //
    case EditProductTemplateActions2.EDIT_EditProductTemplate_List_REQUEST2:
      return {
        ...state,
        create_Device: "1",
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.EDIT_EditProductTemplate_List_SUCCESS2:
      return {
        ...state,
        create_Device: "2",
        loading: false,
        error: action.payload,
      };
    case EditProductTemplateActions2.EDIT_EditProductTemplate_List_ERROR2:
      return {
        ...state,
        create_Device: "3",
        loading: false,
        error: action.payload,
      };
    //
    case EditProductTemplateActions2.POST_SaveModuleTemplate_REQUEST2:
      return {
        ...state,
        // data: {},
        create_Module: "1",
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.POST_SaveModuleTemplate_SUCCESS2:
      return {
        ...state,
        create_Module: "2",
        loading: false,
        error: action.payload,
      };
    case EditProductTemplateActions2.POST_SaveModuleTemplate_ERROR2:
      return {
        ...state,
        create_Module: "3",
        loading: false,
        error: action.payload,
      };
    //
    case EditProductTemplateActions2.POST_SaveSecondTemplate_REQUEST2:
      return {
        ...state,
        // data: {},
        create_Sec: "1",
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.POST_SaveSecondTemplate_SUCCESS2:
      return {
        ...state,
        create_Sec: "2",
        loading: false,
        error: action.payload,
      };
    case EditProductTemplateActions2.POST_SaveSecondTemplate_ERROR2:
      return {
        ...state,
        create_Sec: "3",
        loading: false,
        error: action.payload,
      };
    //
    case EditProductTemplateActions2.POST_SaveSettingsTemplate_REQUEST2:
      return {
        ...state,
        // data: {},
        create_Setting: "1",
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.POST_SaveSettingsTemplate_SUCCESS2:
      return {
        ...state,
        loading: false,
        create_Setting: "2",
        error: action.payload,
      };
    case EditProductTemplateActions2.POST_SaveSettingsTemplate_ERROR2:
      return {
        ...state,
        loading: false,
        create_Setting: "3",
        error: action.payload,
      };

    // delete
    //
    case EditProductTemplateActions2.POST_DeleteModuleTemplate_REQUEST2:
      return {
        ...state,
        // data: {},
        delete_Module: "1",
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.POST_DeleteModuleTemplate_SUCCESS2:
      return {
        ...state,
        delete_Module: "2",
        loading: false,
        error: action.payload,
      };
    case EditProductTemplateActions2.POST_DeleteModuleTemplate_ERROR2:
      return {
        ...state,
        delete_Module: "3",
        loading: false,
        error: action.payload,
      };
    //
    case EditProductTemplateActions2.POST_DeleteSecondTemplate_REQUEST2:
      return {
        ...state,
        // data: {},
        delete_Sec: "1",
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.POST_DeleteSecondTemplate_SUCCESS2:
      return {
        ...state,
        delete_Sec: "2",
        loading: false,
        error: action.payload,
      };
    case EditProductTemplateActions2.POST_DeleteSecondTemplate_ERROR2:
      return {
        ...state,
        delete_Sec: "3",
        loading: false,
        error: action.payload,
      };

    case EditProductTemplateActions2.GET_EditProductTemplate_List_REQUEST2:
      return {
        ...state,
        data: {},
        loading: true,
        error: null,
      };
    case EditProductTemplateActions2.GET_EditProductTemplate_List_SUCCESS2:
      // let data = Object.assign([], action.payload);

      // data.forEach((element, index) => {
      // 	element.key = index;
      // })
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case EditProductTemplateActions2.GET_EditProductTemplate_List_ERROR2:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
