import userActions from "./actions";

const INITIAL_DATA = {
  backToSignin: false,
  data: [],
  users: [],
  reset_pwd_data: [],
  change_pwd_data: [],
  loading: true,
  error: null,
  reset: false,
};
export default function userReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case userActions.Send_resetPassword_Request:
      return {
        ...state,
        backToSignin: false,
        loading: true,
        error: null,
      };
    case userActions.Send_resetPassword_Success:
      return {
        ...state,
        backToSignin: action.payload,
        loading: false,
        error: null,
      };
    case userActions.Send_resetPassword_Failure:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case userActions.FETCH_USER_DATA_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case userActions.FETCH_USER_DATA_SUCCESS:
      let data = Object.assign([], action.payload);
      data.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case userActions.FETCH_USER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case userActions.FETCH_ALL_USER_REQUEST:
      return {
        ...state,
        users: [],
        loading: true,
        error: null,
      };
    case userActions.FETCH_ALL_USER_SUCCESS:
      let users = Object.assign([], action.payload);
      users.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    case userActions.FETCH_ALL_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    //RESET PASSWORD
    case userActions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        users: [],
        loading: true,
        error: null,
      };
    case userActions.RESET_REDIRECT:
      return {
        ...state,
        reset: !state.reset,
      };
    case userActions.RESET_PASSWORD_SUCCESS:
      let reset_pwd_data = Object.assign([], action.payload);
      reset_pwd_data.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    case userActions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // CHANGE PASSWORD
    case userActions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    case userActions.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
