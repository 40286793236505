import ApiService from "./Api";
import { R_BASE_URL2 } from "./requestUrl";

const BASE_URL = R_BASE_URL2;

const client = new ApiService({ baseURL: BASE_URL });

const CloudHawkApi = {
  //**********  product template ---main page **************
  // addList: (params) => client.post("/web/role/add", params),
  editlanguageList: (params) =>
    client.post("/web/template/language/edit", params), //编辑语言详情
  getlanguageList: (params) =>
    client.post("/web/template/language/page", params), //主页语言列表
  getlanguageDetailList: (params) =>
    client.post("/web/template/language/info", params), //语言配置列表
  getlanguageALLList: () => client.get("/web/languages/select", {}), //语言列表下拉框
  isPublishTemp: (params) =>
    client.post("/web/template/language/state", params), //发布语言模板
  dellanguageList: (params) =>
    client.get(`/web/template/language/del?id=${params.id}`),

  //**********  product template ---Detail page **************
  getTemplateInfoList: (params) => client.post("/web/template/info", params), //获取模板页面所有的信息,包括次级页面
  editTemplateDetailList: (params) =>
    client.post("/web/template/detail/save", params), //保存设备详情页面
  saveTemplateSetList: (params) =>
    client.post("/web/template/setting/save", params), //保存设置页面
  saveAllInfoTemplate: (params) =>
    client.post("/web/template/info/save", params), //保存 设备详情和设置 页面
  saveTemplateSecPage: (params) =>
    client.post("/web/template/sec/save", params), // 模板-次级页面保存

  //**********  product template Power ---Detail page **************
  getTemplatePowerInfoList: (params) =>
    client.getParams("/web/power/template/info", params), //获取模板页面所有的信息,包括次级页面

  editTemplatePowerDetailList: (params) =>
    client.post("/web/power/template/detail/save", params), //
  saveTemplateModularPowerSetList: (params) =>
    client.post("/web/power/template/modular/save", params), //模板页面
  saveTemplateSecondPowerSetList: (params) =>
    client.post("/web/power/template/sec/save", params), //次级页面
  saveTemplateSettingsPowerSetList: (params) =>
    client.post("/web/power/template/setting/save", params), //设置页面-保存

  deleteTemplateModularPowerSetList: (params) =>
    client.post("/web/power/template/modular/del", params), //模板页面
  deleteTemplateSecondPowerSetList: (params) =>
    client.post("/web/power/template/sec/del", params), //次级页面

  saveAllInfoTemplatePower: (params) =>
    client.post("/web/power/template/info/save", params), //保存 设备详情和设置 页面

  //**********  serviceList and paramsList **************
  getModularList: (params) =>
    client.get(
      `/web/power/template/modular/select?productId=${params.productId}`,
    ), //模板下拉列表
  getSecondarySelect: () => client.get("/web/template/secondary/select"), //获取次级页面列表
  getSecondarySelect2: (params) =>
    client.get(
      `/web/power/template/sec/page/select?productId=${params.productId}`,
    ), //次级页下拉列表

  getServiceList: (params) =>
    client.getParams(`/web/product/tsl/service/list`, params), //获取服务下拉框
  getParamsList: (params) =>
    client.getParams(`/web/product/tsl/property/list`, params), //获取属性下拉框
  exportLanguage: (params) =>
    client.download(
      `/web/template/language/export?productId=${params.productId}`,
    ), //语言导出web/template/language/export
  inLanguage: (params) =>
    client.get(`/web/template/language/import?productId=${params.productId}`), //语言导入

  // ********** Language Controller 基础管理-语言配置 **************
  getLanguages: (params) =>
    client.getParams(`/web/languages/page`, {
      pageNum: 1,
      pageSize: 10,
      ...params,
    }),
  createLanguage: (params) => client.post("/web/languages/add", params),
  editLanguage: (params) => client.post("/web/languages/update", params),
  deleteLanguage: (params) =>
    client.get(`/web/languages/delete?id=${params.id}`),

  // ********** Language Controller app登录管理- 广告页管理**************
  getAds: (params) => client.post("/web/adPopup/page", params),
  createAd: (params) => client.post("/web/adPopup/add", params),
  editAd: (params) => client.post("/web/adPopup/update", params),
  deleteAd: (params) => client.get(`/web/adPopup/del?id=${params.id}`),

  // ********** Language Controller app登录管理- 欢迎页管理**************
  getWelPage: (params) => client.post("/web/welcomePage/page", params),
  createWelPage: (params) => client.post("/web/welcomePage/add", params),
  editWelPage: (params) => client.post("/web/welcomePage/update", params),
  deleteWelPage: (params) => client.get(`/web/welcomePage/del?id=${params.id}`),

  // ********** Device management 设备管理- 设备预设列表**************
  getDevicePresetPage: (params) =>
    client.post("/web/device/preset/page", params),
  createDevicePreset: (params) => client.post("/web/device/preset/add", params),
  editDevicePreset: (params) =>
    client.post("/web/device/preset/update", params),
  deleteDevicePreset: (params) =>
    client.get(`/web/device/preset/del?id=${params.id}`),
  exportErrorDevicePresetList: (params) =>
    client.downloadParamsPost("/web/device/preset/export", params), //异常结果导出 模板下载

  // ********** Device Test list 设备管理- 产测设备列表**************
  getDeviceTestNewPage: (params) =>
    client.post("/web/device/test/new/page", params),
  getDeviceTestOldPage: (params) =>
    client.post("/web/device/test/old/page", params),
  createDeviceTestNew: (params) =>
    client.post("/web/device/test/new/add", params),
  editDeviceTestNew: (params) =>
    client.post("/web/device/test/new/update", params),
  createDeviceTestOld: (params) =>
    client.post("/web/device/test/old/add", params),
  editDeviceTestOld: (params) =>
    client.post("/web/device/test/old/update", params),
  deleteDeviceTestNew: (params) =>
    client.get(`/web/device/test/new/del?id=${params.id}`),
  deleteDeviceTestOld: (params) =>
    client.get(`/web/device/test/old/del?id=${params.id}`),

  wareHousingDeviceTestNew: (params) =>
    client.post("/web/device/test/toStorage", params), //新型号入库->到设备预设列表
  wareHousingDeviceTestOld: (params) =>
    client.post("/web/device/test/toDevice", params), //老型号入库->到设备列表
  reCheckDeviceTestNew: (params) =>
    client.post("/web/device/test/new/recheck", params), //重检-新型号
  reCheckDeviceTestOld: (params) =>
    client.post("/web/device/test/old/recheck", params), //重检-旧型号
  saveResultDeviceTestNew: (params) =>
    client.post("/web/device/test/new/saveResult", params), //录入结果-新型号
  saveResultDeviceTestOld: (params) =>
    client.post("/web/device/test/old/saveResult", params), //录入结果-旧型号
  exportErrorDeviceTestNew: (params) =>
    client.downloadParamsPost("/web/device/test/new/export", params), //新型号-异常结果导出 模板下载
  exportErrorDeviceTestOld: (params) =>
    client.downloadParamsPost("/web/device/test/old/export", params), //老型号-异常结果导出 模板下载

  //**********---get IA address apply ---IA地址获取(申请) **************
  getIAPageList: (params) => client.getParams(`/web/ia/apply/page`, params),
  addIAPageList: (params) => client.post("/web/ia/apply/add", params),
  recoveryIAPageList: (params) =>
    client.get(`/web/ia/apply/recovery?id=${params.id}`),
  exportIAPage: (params) =>
    client.download(`/web/ia/apply/export?id=${params.id}`), //export
  exportIAPageList: (params) =>
    client.downloadParamsPost("/web/ia/apply/batch/export", params), //export list
  deleteIAAddressApply: (params) =>
    client.get(`/web/ia/apply/delete?id=${params.id}`),
  //**********---get IA address manage --- IA地址管理**************
  getIAManagePageList: (params) =>
    client.getParams(`/web/ia/manage/page`, params),
  addIAManagePageList: (params) => client.post("/web/ia/manage/add", params),
  editIAManagePageList: (params) =>
    client.post("/web/ia/manage/update", params),
  delIAPageList: (params) =>
    client.get(`/web/ia/manage/delete?id=${params.id}`),
  detailIAPageList: (params) =>
    client.get(`/web/ia/manage/detail?id=${params.id}`),
  selectIAPageList: () => client.get("/web/ia/manage/select"),

  //**********---device manage-fault manage 设备管理-故障管理 ---**************
  getfaultPage: (params) => client.post("/web/fault/record/page", params),
  editfaultPage: (params) => client.post("/web/fault/record/batchEdit", params),
  exportFaultList: (params) =>
    client.downloadParamsPost("/web/fault/record/export", params),
  getProductSelectList: () => client.get("/web/product/select/all"), //产品型号下拉列表,新老型号整合
  getCountrySelectList: () => client.get("/web/countries/select"), //国别下拉框

  //**********---sys logManagement --- logManagement**************
  getWeblogPageList: (params) => client.getParams(`/web/log/list`, params),
  getApplogPageList: (params) => client.getParams(`/web/log/app/list`, params),

  //**********---message Center --- message template**************
  getMessTemplatePage: (params) =>
    client.post("/web/notification/template/page", params),
  addMessTemplate: (params) =>
    client.post("/web/notification/template/add", params),
  editMessTemplate: (params) =>
    client.post("/web/notification/template/update", params),
  deleteMessTemplate: (params) =>
    client.get(`/web/notification/template/del?id=${params.id}`),
  getMessSubList: (params) =>
    client.get(`/web/notification/template/subList?parentId=${params.id}`), //获取子语言列表
  addSubLangTemp: (params) =>
    client.post("/web/notification/template/addSub", params), //添加子语言模板
  editSubLangTemp: (params) =>
    client.post("/web/notification/template/update", params), //编辑子语言模板

  //**********---message Center Power --- message template**************
  // getMessTemplatePowerPage: (params) => client.post("/web/notification/template/page", params),
  // addMessTemplatePower: (params) => client.post("/web/notification/template/add", params),
  // editMessTemplatePower: (params) => client.post("/web/notification/template/update", params),
  // deleteMessTemplatePower: (params) => client.get(`/web/notification/template/del?id=${params.id}`),
  // getMessSubListPower: (params) => client.get(`/web/notification/template/subList?parentId=${params.id}`),//获取子语言列表
  // addSubLangTempPower: (params) => client.post("/web/notification/template/addSub", params), //添加子语言模板
  // editSubLangTempPower: (params) => client.post("/web/notification/template/update", params), //编辑子语言模板

  //**********---message Center --- message Log**************
  getMessLogPage: (params) =>
    client.post("/web/notification/task/log/page", params),

  //**********---message Center --- message List**************
  getMessListPage: (params) => client.post("/web/message/page", params),
  readMessList: (params) => client.post("/web/message/batch/read", params),
  deleteMessList: (params) => client.post("/web/message/del", params),
  getMessUnReadCount: () => client.get("/web/message/news/count"), //获取角标未读消息数量
  editMessState: (params) =>
    client.get(`/web/message/news/read?id=${params.id}`), //修改消息列表  未读-->已读

  //**********---message Center --- message Push**************
  getMessPushPage: (params) =>
    client.post("/web/notification/task/page", params),
  addMessPushPage: (params) =>
    client.post("/web/notification/task/add", params),
  editMessPushPage: (params) =>
    client.post("/web/notification/task/edit", params),
  pushMessPush: (params) =>
    client.get(`/web/notification/task/push?id=${params.id}`), //发送状态,草稿变为已发送
  getUserSelectList: (params) =>
    client.get(`/web/account/select?name=${params.name}`), //个人中心-用户下拉框
  deleteMessPush: (params) =>
    client.get(`/web/notification/task/delete?id=${params.id}`), //个人中心-用户下拉框

  //**********---个人中心-个人信息-提交修改 ---**************
  editMyProfile: (params) => client.post("/web/account/update/info", params),

  //**********---个人中心-重置密码-提交修改 ---**************
  personalResetPwd: (params) =>
    client.post("/web/account/personal/reset/password", params),

  //**********---登录页面-忘记密码-获取验证码 ---**************
  getVerificationCode: (params) =>
    client.get(`/web/account/verification/code?account=${params.account}`),
  sendResetPassRequset: (params) =>
    client.post("/web/account/verification/forgotPwd", params),

  //**********---售后管理 售后服务(老版本反馈)---**************
  getFeedBackList: (params) => client.getParams(`/web/queries/page`, params), //分页查询数量
  getFeedBackCount: () => client.get("/web/queries/statistics"), //各类数量统计
  changeWorkOrderStatus: (params) => client.post("/web/queries/handle", params), //更改工单状态--待处理->处理中
  closeWorkOrder: (params) => client.post("/web/queries/update/status", params), //关闭/打开 工单
  replyWorkOrder: (params) => client.post("/web/queries/reply", params), //回复工单消息
  relateWorkOrder: (params) => client.post("/web/queries/related", params), //关联工单
  deleteFeedbackList: (params) =>
    client.get(`/web/queries/delete?id=${params.id}`), //删除工单

  //**********---售后管理 规则管理---**************
  getRuleManageList: (params) =>
    client.get(
      `/web/notification/rule/page?pageNum=${params.pageNum}&pageSize=${
        params.pageSize
      }&name=${params.name || ""}&alarmType=${params.alarmType || ""}&method=${
        params.methods || ""
      }`,
    ), //分页查询数量
  addRuleManage: (params) => client.post("/web/notification/rule/add", params),
  deleteRuleManage: (params) =>
    client.getParams("/web/notification/rule/delete", params),
  editRuleManage: (params) =>
    client.post("/web/notification/rule/update", params),
  isEnableRuleManage: (params) =>
    client.get(
      `/web/notification/rule/update/status?id=${params.id}&status=${params.status}`,
    ), //启用,禁用
  getRuleManageDetail: (params) =>
    client.get(`/web/notification/rule/detail?id=${params.id}`), //详情
  getEventList: (params) =>
    client.get(`/web/product/tsl/event/list?productId=${params.productId}`), //事件列表-该产品已发布的事件
  getPropertyList: (params) =>
    client.get(`/web/product/tsl/property/list?productId=${params.productId}`), //属性列表-该产品已发布的属性
  getMessageTemplate: () => client.get("/web/notification/template/select"), //下拉模板
  getSysAdminSelectList: () => client.get("/web/account/adminSelect"), //web系统所有管理员下拉框
  getSysUserSelectList: (params) =>
    client.getParams("/web/account/userSelect", params), // 通知用户下拉框
};
export default CloudHawkApi;
