import { call, all, takeEvery, put } from "redux-saga/effects";
import SysMenuActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk2";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: "" + response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
  });
};

function* getAllMenuList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getAllMenuList);
    yield put(SysMenuActions.fetchGETAllMenuListSuccess(response.data));
  } catch (error) {
    yield put(SysMenuActions.fetchGETAllMenuListError(error));
  }
}

function* getMenuList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getMenuList, payload);
    if (!response.success) {
      Error(response);
    }
    yield put(SysMenuActions.fetchGETMenuListSuccess(response.data));
  } catch (error) {
    yield put(SysMenuActions.fetchGETMenuListError(error));
  }
}

function* getSubMenuList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getSubMenuList, payload);
    if (!response.success) {
      Error(response);
    }
    yield put(SysMenuActions.fetchGETSubMenuListSuccess(response.data));
  } catch (error) {
    yield put(SysMenuActions.fetchGETSubMenuListError(error));
  }
}

function* addMenuList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addMenuList, payload);
    if (response.success) {
      alert(response);
      yield put(SysMenuActions.fetchADDMenuListSuccess(response.data));
    } else {
      Error(response);
      yield put(SysMenuActions.fetchADDMenuListError(response));
    }
  } catch (error) {
    yield put(SysMenuActions.fetchADDMenuListError(error));
  }
  // finally {
  // 	const params = {
  // 		pageSize: payload.pageSize,
  // 		pageNum: 1,
  // 	}
  // 	yield put(SysMenuActions.fetchGETAllMenuList());
  // 	yield put(SysMenuActions.fetchGETMenuList(params));
  // }
}

function* editMenuList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editMenuList, payload);
    if (response.success) {
      alert(response);
      yield put(SysMenuActions.fetchEDITMenuListSuccess(response.data));
    } else {
      Error(response);
      yield put(SysMenuActions.fetchEDITMenuListError(response));
    }
  } catch (error) {
    // yield put(SysMenuActions.fetchGETMenuListError(error));
    yield put(SysMenuActions.fetchEDITMenuListError(error));
  }
  // finally {
  // 	const params = {
  // 		pageSize: payload.pageSize,
  // 		pageNum: payload.pageNum,
  // 	}
  // 	yield put(SysMenuActions.fetchGETAllMenuList());
  // 	yield put(SysMenuActions.fetchGETMenuList(params));
  // }
}

function* delMenuList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.delMenuList, payload);
    if (response.success) {
      alert(response);
      yield put(SysMenuActions.fetchDELMenuListSuccess(response.data));
    } else {
      Error(response);
      yield put(SysMenuActions.fetchDELMenuListError(response));
    }
  } catch (error) {
    yield put(SysMenuActions.fetchDELMenuListError(error));
  }
  // finally {
  // 	const params = {
  // 		pageSize: payload.pageSize,
  // 		pageNum: payload.pageNum,
  // 	}
  // 	yield put(SysMenuActions.fetchGETAllMenuList());
  // 	yield put(SysMenuActions.fetchGETMenuList(params));
  // }
}
export default function* userSaga() {
  yield all([
    takeEvery(SysMenuActions.GET_Menu_List_REQUEST, getMenuList),
    takeEvery(SysMenuActions.GET_Sub_Menu_List_REQUEST, getSubMenuList),
    takeEvery(SysMenuActions.GET_ALL_Menu_List_REQUEST, getAllMenuList),
    takeEvery(SysMenuActions.ADD_Menu_List_REQUEST, addMenuList),
    takeEvery(SysMenuActions.EDIT_Menu_List_REQUEST, editMenuList),
    takeEvery(SysMenuActions.DEL_Menu_List_REQUEST, delMenuList),
  ]);
}
