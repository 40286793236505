const DevicePresetActions = {
  //Fetch DevicePreset Data
  FETCH_DevicePreset_DATA_REQUEST: "FETCH_DevicePreset_DATA_REQUEST",
  FETCH_DevicePreset_DATA_SUCCESS: "FETCH_DevicePreset_DATA_SUCCESS",
  FETCH_DevicePreset_DATA_FAILURE: "FETCH_DevicePreset_DATA_FAILURE",
  SET_DevicePreset_DATA: "SET_DevicePreset_DATA",
  fetchDevicePresetDataRequest: (request) => ({
    type: DevicePresetActions.FETCH_DevicePreset_DATA_REQUEST,
    payload: request,
  }),
  fetchDevicePresetDataSuccess: (DevicePreset) => ({
    type: DevicePresetActions.FETCH_DevicePreset_DATA_SUCCESS,
    payload: DevicePreset,
  }),
  fetchDevicePresetDataFailure: (error) => ({
    type: DevicePresetActions.FETCH_DevicePreset_DATA_FAILURE,
    payload: error,
  }),

  // Add DevicePreset
  ADD_DevicePreset_REQUEST: "ADD_DevicePreset_REQUEST",
  ADD_DevicePreset_SUCCESS: "ADD_DevicePreset_SUCCESS",
  ADD_DevicePreset_FAILURE: "ADD_DevicePreset_FAILURE",
  addDevicePresetDataRequest: (request) => ({
    type: DevicePresetActions.ADD_DevicePreset_REQUEST,
    payload: request,
  }),
  addDevicePresetDataSuccess: (DevicePreset) => ({
    type: DevicePresetActions.ADD_DevicePreset_SUCCESS,
    payload: DevicePreset,
  }),
  addDevicePresetDataFailure: (error) => ({
    type: DevicePresetActions.ADD_DevicePreset_FAILURE,
    payload: error,
  }),

  // Edit DevicePreset
  EDIT_DevicePreset_REQUEST: "EDIT_DevicePreset_REQUEST",
  EDIT_DevicePreset_SUCCESS: "EDIT_DevicePreset_SUCCESS",
  EDIT_DevicePreset_FAILURE: "EDIT_DevicePreset_FAILURE",
  editDevicePresetDataRequest: (request) => ({
    type: DevicePresetActions.EDIT_DevicePreset_REQUEST,
    payload: request,
  }),
  editDevicePresetDataSuccess: (DevicePreset) => ({
    type: DevicePresetActions.EDIT_DevicePreset_SUCCESS,
    payload: DevicePreset,
  }),
  editDevicePresetDataFailure: (error) => ({
    type: DevicePresetActions.EDIT_DevicePreset_FAILURE,
    payload: error,
  }),

  // Delete DevicePreset
  DELETE_DevicePreset_REQUEST: "DELETE_DevicePreset_REQUEST",
  DELETE_DevicePreset_SUCCESS: "DELETE_DevicePreset_SUCCESS",
  DELETE_DevicePreset_FAILURE: "DELETE_DevicePreset_FAILURE",
  deleteDevicePresetDataRequest: (request) => ({
    type: DevicePresetActions.DELETE_DevicePreset_REQUEST,
    payload: request,
  }),
  deleteDevicePresetDataSuccess: (DevicePreset) => ({
    type: DevicePresetActions.DELETE_DevicePreset_SUCCESS,
    payload: DevicePreset,
  }),
  deleteDevicePresetDataFailure: (error) => ({
    type: DevicePresetActions.DELETE_DevicePreset_FAILURE,
    payload: error,
  }),

  //export error device preset list
  EXPORT_Error_DevicePreset_REQUEST: "EXPORT_Error_DevicePreset_REQUEST",
  EXPORTDevicePresetDataRequest: (request) => ({
    type: DevicePresetActions.EXPORT_Error_DevicePreset_REQUEST,
    payload: request,
  }),
};

export default DevicePresetActions;
