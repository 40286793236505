import { call, all, takeEvery, put } from "redux-saga/effects";
import firmwareDataActions from "./firmwareDataActions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";

function* getFirmwareDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getFirmwares, payload);
    yield put(firmwareDataActions.getFirmwareDataSuccess(response.data));
  } catch (error) {
    yield put(firmwareDataActions.getFirmwareDataFailure(error));
  }
}
function* getFirmwareDataDeleteRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteFirmware, payload);
    yield put(firmwareDataActions.getFirmwareDataDeleteSuccess(payload));
  } catch (error) {
    yield put(firmwareDataActions.getFirmwareDataDeleteFailure(error));
  }
}

export default function* firmwareDataSaga() {
  yield all([
    takeEvery(
      firmwareDataActions.GET_FIRMWARE_DATA_REQUEST,
      getFirmwareDataRequest,
    ),
    takeEvery(
      firmwareDataActions.GET_FIRMWARE_DATA_DELETE_REQUEST,
      getFirmwareDataDeleteRequest,
    ),
  ]);
}
