const localTestUrl = "https://cloudhawk-test.yunext.com";
// const localTestUrl = "https://cms.yardforceapp.com";
// const localUrl = 'http://192.168.1.178:5000';
// const localUrl = 'http://www.hadlinks.com:8810';
// const localUrl = 'https://yapi.yunext.com/mock/498';
// const localUrl2 = "http://192.168.1.79:8810";
// const localUrl2 = "http://192.168.1.79:8810";
// const localUrl2 = "http://10.13.13.227:8810";
// const localUrl2 = 'https://cms.yardforceapp.com';

const R_BASE_URL =
  window.location.host === "localhost:3000" ||
  window.location.host === "www.hadlinks.com:3000"
    ? `${localTestUrl}/api/v1/`
    : `${window.location.origin}/api/v1/`;

let R_BASE_URL2 = `${window.location.origin}/api/v2/`;

if (
  window.location.host === "localhost:3000" ||
  window.location.host === "www.hadlinks.com:3000"
) {
  R_BASE_URL2 = `${localTestUrl}/api/v2/`;
  // R_BASE_URL2 = `http://192.168.1.79:8810/api/v2/`;
}

// const R_IMAGE_URL = "https://cdn.yardforceapp.com/image/400/400/";
const R_IMAGE_URL =
  window.location.host === "localhost:3000" ||
  window.location.host === "www.hadlinks.com:3000" ||
  window.location.host === "cloudhawk-test.yunext.com"
    ? "https://cloudhawk-test.s3.ap-southeast-1.amazonaws.com/"
    : "https://cdn.yardforceapp.com/image/400/400/";
const R_IMAGE_URL_1200 =
  window.location.host === "localhost:3000" ||
  window.location.host === "www.hadlinks.com:3000" ||
  window.location.host === "cloudhawk-test.yunext.com"
    ? "https://cloudhawk-test.s3.ap-southeast-1.amazonaws.com/"
    : "https://cdn.yardforceapp.com/image/1200/1200/";

export { R_BASE_URL, R_BASE_URL2, R_IMAGE_URL, R_IMAGE_URL_1200 };
