// saga.js
import { call, all, takeEvery, put, select } from "redux-saga/effects";
import DevicePresetActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";
import qs from "qs";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    // message:"Msg:"+response.msg,
    message: "" + response.msg,
  });
};
function* fetchDevicePresetDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getDevicePresetPage, payload);
    yield put(DevicePresetActions.fetchDevicePresetDataSuccess(response.data));
  } catch (error) {
    yield put(DevicePresetActions.fetchDevicePresetDataFailure(error));
  }
}

function* addDevicePresetDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.createDevicePreset,
      payload.params1,
    );

    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(DevicePresetActions.addDevicePresetDataFailure(error));
  } finally {
    yield put(
      DevicePresetActions.fetchDevicePresetDataRequest(payload.params2),
    );
  }
}

// EDIT DevicePreset
function* editDevicePresetDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editDevicePreset, payload.params1);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(DevicePresetActions.editDevicePresetDataFailure(error));
  } finally {
    yield put(
      DevicePresetActions.fetchDevicePresetDataRequest(payload.params2),
    );
  }
}

function* deleteDevicePresetDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteDevicePreset, payload);
    if (response.success) {
      const total = yield select(
        (state) => state.devicePreset.DevicePresetdataTotal,
      );
      if (
        payload["params2"]["pageNum"] >
        Math.ceil((total - 1) / payload["params2"]["pageSize"])
      ) {
        //last page
        payload["params2"]["pageNum"] = payload["params2"]["pageNum"] - 1;
        yield put(
          DevicePresetActions.fetchDevicePresetDataRequest(payload["params2"]),
        );
      } else {
        //this page
        yield put(
          DevicePresetActions.fetchDevicePresetDataRequest(payload["params2"]),
        );
      }
      alert(response);
    } else {
      yield put(
        DevicePresetActions.fetchDevicePresetDataRequest(payload["params2"]),
      );
      Error(response);
    }
  } catch (error) {
    yield put(DevicePresetActions.deleteDevicePresetDataFailure(error));
  }
}
function* exportErrorDevicePresetList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.exportErrorDevicePresetList,
      payload,
    );
    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);
  } catch (error) {
    yield put(DevicePresetActions.editDevicePresetDataFailure(error));
  }
}

export default function* DevicePresetSaga() {
  yield all([
    takeEvery(
      DevicePresetActions.FETCH_DevicePreset_DATA_REQUEST,
      fetchDevicePresetDataRequest,
    ),
    takeEvery(
      DevicePresetActions.ADD_DevicePreset_REQUEST,
      addDevicePresetDataRequest,
    ),
    takeEvery(
      DevicePresetActions.EDIT_DevicePreset_REQUEST,
      editDevicePresetDataRequest,
    ),
    takeEvery(
      DevicePresetActions.DELETE_DevicePreset_REQUEST,
      deleteDevicePresetDataRequest,
    ),
    takeEvery(
      DevicePresetActions.EXPORT_Error_DevicePreset_REQUEST,
      exportErrorDevicePresetList,
    ),
  ]);
}
