const productActions = {
  // Add Product
  ADD_PRODUCT_REQUEST: "ADD_PRODUCT_REQUEST",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",
  addproductDataRequest: (request) => ({
    type: productActions.ADD_PRODUCT_REQUEST,
    payload: request,
  }),
  addproductDataSuccess: (product) => ({
    type: productActions.ADD_PRODUCT_SUCCESS,
    payload: product,
  }),
  addproductDataFailure: (error) => ({
    type: productActions.ADD_PRODUCT_FAILURE,
    payload: error,
  }),

  // Upload Product Data File
  ADD_PRODUCT_DATA_FILE_REQUEST: "ADD_PRODUCT_DATA_FILE_REQUEST",
  ADD_PRODUCT_DATA_FILE_SUCCESS: "ADD_PRODUCT_DATA_FILE_SUCCESS",
  ADD_PRODUCT_DATA_FILE_FAILURE: "ADD_PRODUCT_DATA_FILE_FAILURE",
  addproductDataFileRequest: (request) => ({
    type: productActions.ADD_PRODUCT_DATA_FILE_REQUEST,
    payload: request,
  }),
  addproductDataFileSuccess: (product) => ({
    type: productActions.ADD_PRODUCT_DATA_FILE_SUCCESS,
    payload: product,
  }),
  addproductDataFileFailure: (error) => ({
    type: productActions.ADD_PRODUCT_DATA_FILE_FAILURE,
    payload: error,
  }),

  //Fetch PRODUCT Data
  FETCH_PRODUCT_DATA_REQUEST: "FETCH_PRODUCT_DATA_REQUEST",
  FETCH_PRODUCT_DATA_SUCCESS: "FETCH_PRODUCT_DATA_SUCCESS",
  FETCH_PRODUCT_DATA_FAILURE: "FETCH_PRODUCT_DATA_FAILURE",
  fetchproductDataRequest: () => ({
    type: productActions.FETCH_PRODUCT_DATA_REQUEST,
  }),
  fetchproductDataSuccess: (product) => ({
    type: productActions.FETCH_PRODUCT_DATA_SUCCESS,
    payload: product,
  }),
  fetchproductDataFailure: (error) => ({
    type: productActions.FETCH_PRODUCT_DATA_FAILURE,
    payload: error,
  }),

  //Fetch communication type
  FETCH_COMMUNICATION_LIST_REQUEST: "FETCH_COMMUNICATION_LIST_REQUEST",
  FETCH_COMMUNICATION_LIST_SUCCESS: "FETCH_COMMUNICATION_LIST_SUCCESS",
  FETCH_COMMUNICATION_LIST_FAILURE: "FETCH_COMMUNICATION_LIST_FAILURE",
  fetchcommunicationlistRequest: (request) => ({
    type: productActions.FETCH_COMMUNICATION_LIST_REQUEST,
    payload: request,
  }),
  fetchcommunicationlistSuccess: (product) => ({
    type: productActions.FETCH_COMMUNICATION_LIST_SUCCESS,
    payload: product,
  }),
  fetchcommunicationlistFailure: (error) => ({
    type: productActions.FETCH_COMMUNICATION_LIST_FAILURE,
    payload: error,
  }),

  //Fetch communication type
  FETCH_COMMUNICATION_TYPE_REQUEST: "FETCH_COMMUNICATION_TYPE_REQUEST",
  FETCH_COMMUNICATION_TYPE_SUCCESS: "FETCH_COMMUNICATION_TYPE_SUCCESS",
  FETCH_COMMUNICATION_TYPE_FAILURE: "FETCH_COMMUNICATION_TYPE_FAILURE",
  fetchcommunicationtypeRequest: (request) => ({
    type: productActions.FETCH_COMMUNICATION_TYPE_REQUEST,
    payload: request,
  }),
  fetchcommunicationtypeSuccess: (product) => ({
    type: productActions.FETCH_COMMUNICATION_TYPE_SUCCESS,
    payload: product,
  }),
  fetchcommunicationtypeFailure: (error) => ({
    type: productActions.FETCH_COMMUNICATION_TYPE_FAILURE,
    payload: error,
  }),

  //Fetch Brands
  FETCH_BRANDS_REQUEST: "FETCH_BRANDS_REQUEST",
  FETCH_BRANDS_SUCCESS: "FETCH_BRANDS_SUCCESS",
  FETCH_BRANDS_FAILURE: "FETCH_BRANDS_FAILURE",
  fetchBrandsRequest: (request) => ({
    type: productActions.FETCH_BRANDS_REQUEST,
    payload: request,
  }),
  fetchBrandsSuccess: (product) => ({
    type: productActions.FETCH_BRANDS_SUCCESS,
    payload: product,
  }),
  fetchBrandsFailure: (error) => ({
    type: productActions.FETCH_BRANDS_FAILURE,
    payload: error,
  }),

  //get Categories
  FETCH_CATEGORY_REQUEST: "FETCH_CATEGORY_REQUEST",
  FETCH_CATEGORY_SUCCESS: "FETCH_CATEGORY_SUCCESS",
  FETCH_CATEGORY_FAILURE: "FETCH_CATEGORY_FAILURE",
  fetchCategoriesRequest: (request) => ({
    type: productActions.FETCH_CATEGORY_REQUEST,
    payload: request,
  }),
  fetchCategoriesSuccess: (product) => ({
    type: productActions.FETCH_CATEGORY_SUCCESS,
    payload: product,
  }),
  fetchCategoriesFailure: (error) => ({
    type: productActions.FETCH_CATEGORY_FAILURE,
    payload: error,
  }),

  //get Models
  FETCH_MODEL_REQUEST: "FETCH_MODEL_REQUEST",
  FETCH_MODEL_SUCCESS: "FETCH_MODEL_SUCCESS",
  FETCH_MODEL_FAILURE: "FETCH_MODEL_FAILURE",
  fetchModelsRequest: (request) => ({
    type: productActions.FETCH_MODEL_REQUEST,
    payload: request,
  }),
  fetchModelsSuccess: (product) => ({
    type: productActions.FETCH_MODEL_SUCCESS,
    payload: product,
  }),
  fetchModelsFailure: (error) => ({
    type: productActions.FETCH_MODEL_FAILURE,
    payload: error,
  }),

  //get ModelByCategoryId
  FETCH_MODEL_BY_CATEGORY_ID_REQUEST: "FETCH_MODEL_BY_CATEGORY_ID_REQUEST",
  FETCH_MODEL_BY_CATEGORY_ID_SUCCESS: "FETCH_MODEL_BY_CATEGORY_ID_SUCCESS",
  FETCH_MODEL_BY_CATEGORY_ID_FAILURE: "FETCH_MODEL_BY_CATEGORY_ID_FAILURE",
  fetchModelByCategoryIdRequest: (request) => ({
    type: productActions.FETCH_MODEL_BY_CATEGORY_ID_REQUEST,
    payload: request,
  }),
  fetchModelByCategoryIdSuccess: (product) => ({
    type: productActions.FETCH_MODEL_BY_CATEGORY_ID_SUCCESS,
    payload: product,
  }),
  fetchModelByCategoryIdFailure: (error) => ({
    type: productActions.FETCH_MODEL_BY_CATEGORY_ID_FAILURE,
    payload: error,
  }),

  //get Actions
  FETCH_PRODUCT_ACTIONS_REQUEST: "FETCH_PRODUCT_ACTIONS_REQUEST",
  FETCH_PRODUCT_ACTIONS_SUCCESS: "FETCH_PRODUCT_ACTIONS_SUCCESS",
  FETCH_PRODUCT_ACTIONS_FAILURE: "FETCH_PRODUCT_ACTIONS_FAILURE",
  fetchProdcutActionRequest: (request) => ({
    type: productActions.FETCH_PRODUCT_ACTIONS_REQUEST,
    payload: request,
  }),
  fetchProdcutActionSuccess: (product) => ({
    type: productActions.FETCH_PRODUCT_ACTIONS_SUCCESS,
    payload: product,
  }),
  fetchProdcutActionFailure: (error) => ({
    type: productActions.FETCH_PRODUCT_ACTIONS_FAILURE,
    payload: error,
  }),

  //get AllActionTypes
  FETCH_PRODUCT_ACTIONS_TYPES_REQUEST: "FETCH_PRODUCT_ACTIONS_TYPES_REQUEST",
  FETCH_PRODUCT_ACTIONS_TYPES_SUCCESS: "FETCH_PRODUCT_ACTIONS_TYPES_SUCCESS",
  FETCH_PRODUCT_ACTIONS_TYPES_FAILURE: "FETCH_PRODUCT_ACTIONS_TYPES_FAILURE",
  fetchProdcutActionTypesRequest: (request) => ({
    type: productActions.FETCH_PRODUCT_ACTIONS_TYPES_REQUEST,
    payload: request,
  }),
  fetchProdcutActionTypesSuccess: (product) => ({
    type: productActions.FETCH_PRODUCT_ACTIONS_TYPES_SUCCESS,
    payload: product,
  }),
  fetchProdcutActionTypesFailure: (error) => ({
    type: productActions.FETCH_PRODUCT_ACTIONS_TYPES_FAILURE,
    payload: error,
  }),

  // GET Product
  GET_PRODUCT_REQUEST: "GET_PRODUCT_REQUEST",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
  getproductDataRequest: (request) => ({
    type: productActions.GET_PRODUCT_REQUEST,
    payload: request,
  }),
  getproductDataSuccess: (product) => ({
    type: productActions.GET_PRODUCT_SUCCESS,
    payload: product,
  }),
  getproductDataFailure: (error) => ({
    type: productActions.GET_PRODUCT_FAILURE,
    payload: error,
  }),

  // Edit Product
  EDIT_PRODUCT_REQUEST: "EDIT_PRODUCT_REQUEST",
  EDIT_PRODUCT_SUCCESS: "EDIT_PRODUCT_SUCCESS",
  EDIT_PRODUCT_FAILURE: "EDIT_PRODUCT_FAILURE",
  editproductDataRequest: (request) => ({
    type: productActions.EDIT_PRODUCT_REQUEST,
    payload: request,
  }),
  editproductDataSuccess: (product) => ({
    type: productActions.EDIT_PRODUCT_SUCCESS,
    payload: product,
  }),
  editproductDataFailure: (error) => ({
    type: productActions.EDIT_PRODUCT_FAILURE,
    payload: error,
  }),

  // Delete Product
  DELETE_PRODUCT_REQUEST: "DELETE_PRODUCT_REQUEST",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",
  deleteproductDataRequest: (request) => ({
    type: productActions.DELETE_PRODUCT_REQUEST,
    payload: request,
  }),
  deleteproductDataSuccess: (product) => ({
    type: productActions.DELETE_PRODUCT_SUCCESS,
    payload: product,
  }),
  deleteproductDataFailure: (error) => ({
    type: productActions.DELETE_PRODUCT_FAILURE,
    payload: error,
  }),
};

export default productActions;
