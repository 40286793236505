const actions = {
  //
  //  *********  select FIRMWARE Firmware APP App *******   //
  SELECT_FIRMWARE_APP_REQUEST: "SELECT_FIRMWARE_APP_REQUEST",
  SELECT_FIRMWARE_APP_SUCCESS: "SELECT_FIRMWARE_APP_SUCCESS",
  SELECT_FIRMWARE_APP_FAILURE: "SELECT_FIRMWARE_APP_FAILURE",
  selectFirmwareAppRequest: (request) => ({
    type: actions.SELECT_FIRMWARE_APP_REQUEST,
    payload: request,
  }),
  selectFirmwareAppSuccess: (res) => ({
    type: actions.SELECT_FIRMWARE_APP_SUCCESS,
    payload: res,
  }),
  selectFirmwareAppFailure: (error) => ({
    type: actions.SELECT_FIRMWARE_APP_FAILURE,
    payload: error,
  }),

  //
  // create
  //  *********  create firmware Firmware APP App *******   //
  CREATE_FIRMWARE_APP_REQUEST: "CREATE_FIRMWARE_APP_REQUEST",
  CREATE_FIRMWARE_APP_SUCCESS: "CREATE_FIRMWARE_APP_SUCCESS",
  CREATE_FIRMWARE_APP_FAILURE: "CREATE_FIRMWARE_APP_FAILURE",
  createFirmwareAppRequest: (request) => ({
    type: actions.CREATE_FIRMWARE_APP_REQUEST,
    payload: request,
  }),
  createFirmwareAppSuccess: (res) => ({
    type: actions.CREATE_FIRMWARE_APP_SUCCESS,
    payload: res,
  }),
  createFirmwareAppFailure: (error) => ({
    type: actions.CREATE_FIRMWARE_APP_FAILURE,
    payload: error,
  }),
  //  *********  create firmware Firmware APP App UPDATE Update *******   //
  CREATE_FIRMWARE_APP_UPDATE_REQUEST: "CREATE_FIRMWARE_APP_UPDATE_REQUEST",
  CREATE_FIRMWARE_APP_UPDATE_SUCCESS: "CREATE_FIRMWARE_APP_UPDATE_SUCCESS",
  CREATE_FIRMWARE_APP_UPDATE_FAILURE: "CREATE_FIRMWARE_APP_UPDATE_FAILURE",
  createFirmwareAppUpdateRequest: (request) => ({
    type: actions.CREATE_FIRMWARE_APP_UPDATE_REQUEST,
    payload: request,
  }),
  createFirmwareAppUpdateSuccess: (res) => ({
    type: actions.CREATE_FIRMWARE_APP_UPDATE_SUCCESS,
    payload: res,
  }),
  createFirmwareAppUpdateFailure: (error) => ({
    type: actions.CREATE_FIRMWARE_APP_UPDATE_FAILURE,
    payload: error,
  }),

  //
  // edit
  //  *********  edit FIRMWARE Firmware APP App *******   //
  EDIT_FIRMWARE_APP_REQUEST: "EDIT_FIRMWARE_APP_REQUEST",
  EDIT_FIRMWARE_APP_SUCCESS: "EDIT_FIRMWARE_APP_SUCCESS",
  EDIT_FIRMWARE_APP_FAILURE: "EDIT_FIRMWARE_APP_FAILURE",
  editFirmwareAppRequest: (request) => ({
    type: actions.EDIT_FIRMWARE_APP_REQUEST,
    payload: request,
  }),
  editFirmwareAppSuccess: (res) => ({
    type: actions.EDIT_FIRMWARE_APP_SUCCESS,
    payload: res,
  }),
  editFirmwareAppFailure: (error) => ({
    type: actions.EDIT_FIRMWARE_APP_FAILURE,
    payload: error,
  }),
  //  *********  edit FIRMWARE Firmware APP App UPDATE Update *******   //
  EDIT_FIRMWARE_APP_UPDATE_REQUEST: "EDIT_FIRMWARE_APP_UPDATE_REQUEST",
  EDIT_FIRMWARE_APP_UPDATE_SUCCESS: "EDIT_FIRMWARE_APP_UPDATE_SUCCESS",
  EDIT_FIRMWARE_APP_UPDATE_FAILURE: "EDIT_FIRMWARE_APP_UPDATE_FAILURE",
  editFirmwareAppUpdateRequest: (request) => ({
    type: actions.EDIT_FIRMWARE_APP_UPDATE_REQUEST,
    payload: request,
  }),
  editFirmwareAppUpdateSuccess: (res) => ({
    type: actions.EDIT_FIRMWARE_APP_UPDATE_SUCCESS,
    payload: res,
  }),
  editFirmwareAppUpdateFailure: (error) => ({
    type: actions.EDIT_FIRMWARE_APP_UPDATE_FAILURE,
    payload: error,
  }),
  //  *********  edit FIRMWARE Firmware APP App UPDATE Update *******   //
  EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_REQUEST:
    "EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_REQUEST",
  EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_SUCCESS:
    "EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_SUCCESS",
  EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_FAILURE:
    "EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_FAILURE",
  editFirmwareAppUpdateLanguageRequest: (request) => ({
    type: actions.EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_REQUEST,
    payload: request,
  }),
  editFirmwareAppUpdateLanguageSuccess: (res) => ({
    type: actions.EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_SUCCESS,
    payload: res,
  }),
  editFirmwareAppUpdateLanguageFailure: (error) => ({
    type: actions.EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_FAILURE,
    payload: error,
  }),

  //
  // delete
  //  *********  delete FIRMWARE Firmware APP App *******   //
  DELETE_FIRMWARE_APP_REQUEST: "DELETE_FIRMWARE_APP_REQUEST",
  DELETE_FIRMWARE_APP_SUCCESS: "DELETE_FIRMWARE_APP_SUCCESS",
  DELETE_FIRMWARE_APP_FAILURE: "DELETE_FIRMWARE_APP_FAILURE",
  deleteFirmwareAppRequest: (request) => ({
    type: actions.DELETE_FIRMWARE_APP_REQUEST,
    payload: request,
  }),
  deleteFirmwareAppSuccess: (res) => ({
    type: actions.DELETE_FIRMWARE_APP_SUCCESS,
    payload: res,
  }),
  deleteFirmwareAppFailure: (error) => ({
    type: actions.DELETE_FIRMWARE_APP_FAILURE,
    payload: error,
  }),
  //  *********  delete FIRMWARE Firmware APP App UPDATE Update *******   //
  DELETE_FIRMWARE_APP_UPDATE_REQUEST: "DELETE_FIRMWARE_APP_UPDATE_REQUEST",
  DELETE_FIRMWARE_APP_UPDATE_SUCCESS: "DELETE_FIRMWARE_APP_UPDATE_SUCCESS",
  DELETE_FIRMWARE_APP_UPDATE_FAILURE: "DELETE_FIRMWARE_APP_UPDATE_FAILURE",
  deleteFirmwareAppUpdateRequest: (request) => ({
    type: actions.DELETE_FIRMWARE_APP_UPDATE_REQUEST,
    payload: request,
  }),
  deleteFirmwareAppUpdateSuccess: (res) => ({
    type: actions.DELETE_FIRMWARE_APP_UPDATE_SUCCESS,
    payload: res,
  }),
  deleteFirmwareAppUpdateFailure: (error) => ({
    type: actions.DELETE_FIRMWARE_APP_UPDATE_FAILURE,
    payload: error,
  }),

  //
  //  *********  fetch FIRMWARE Firmware APP App *******   //
  FETCH_FIRMWARE_APP_REQUEST: "FETCH_FIRMWARE_APP_REQUEST",
  FETCH_FIRMWARE_APP_SUCCESS: "FETCH_FIRMWARE_APP_SUCCESS",
  FETCH_FIRMWARE_APP_FAILURE: "FETCH_FIRMWARE_APP_FAILURE",
  fetchFirmwareAppRequest: (request) => ({
    type: actions.FETCH_FIRMWARE_APP_REQUEST,
    payload: request,
  }),
  fetchFirmwareAppSuccess: (res) => ({
    type: actions.FETCH_FIRMWARE_APP_SUCCESS,
    payload: res,
  }),
  fetchFirmwareAppFailure: (error) => ({
    type: actions.FETCH_FIRMWARE_APP_FAILURE,
    payload: error,
  }),
  //  *********  fetch FIRMWARE Firmware APP App UPDATE Update *******   //
  FETCH_FIRMWARE_APP_UPDATE_REQUEST: "FETCH_FIRMWARE_APP_UPDATE_REQUEST",
  FETCH_FIRMWARE_APP_UPDATE_SUCCESS: "FETCH_FIRMWARE_APP_UPDATE_SUCCESS",
  FETCH_FIRMWARE_APP_UPDATE_FAILURE: "FETCH_FIRMWARE_APP_UPDATE_FAILURE",
  fetchFirmwareAppUpdateRequest: (request) => ({
    type: actions.FETCH_FIRMWARE_APP_UPDATE_REQUEST,
    payload: request,
  }),
  fetchFirmwareAppUpdateSuccess: (res) => ({
    type: actions.FETCH_FIRMWARE_APP_UPDATE_SUCCESS,
    payload: res,
  }),
  fetchFirmwareAppUpdateFailure: (error) => ({
    type: actions.FETCH_FIRMWARE_APP_UPDATE_FAILURE,
    payload: error,
  }),

  //
  //  *********  enable FIRMWARE Firmware  APP App UPDATE Update *******   //
  ENABLE_FIRMWARE_APP_UPDATE_REQUEST: "ENABLE_FIRMWARE_APP_UPDATE_REQUEST",
  ENABLE_FIRMWARE_APP_UPDATE_SUCCESS: "ENABLE_FIRMWARE_APP_UPDATE_SUCCESS",
  ENABLE_FIRMWARE_APP_UPDATE_FAILURE: "ENABLE_FIRMWARE_APP_UPDATE_FAILURE",
  enableFirmwareAppUpdateRequest: (request) => ({
    type: actions.ENABLE_FIRMWARE_APP_UPDATE_REQUEST,
    payload: request,
  }),
  enableFirmwareAppUpdateSuccess: (res) => ({
    type: actions.ENABLE_FIRMWARE_APP_UPDATE_SUCCESS,
    payload: res,
  }),
  enableFirmwareAppUpdateFailure: (error) => ({
    type: actions.ENABLE_FIRMWARE_APP_UPDATE_FAILURE,
    payload: error,
  }),

  //
  //  *********  disable FIRMWARE Firmware  APP App UPDATE Update *******   //
  DISABLE_FIRMWARE_APP_UPDATE_REQUEST: "DISABLE_FIRMWARE_APP_UPDATE_REQUEST",
  DISABLE_FIRMWARE_APP_UPDATE_SUCCESS: "DISABLE_FIRMWARE_APP_UPDATE_SUCCESS",
  DISABLE_FIRMWARE_APP_UPDATE_FAILURE: "DISABLE_FIRMWARE_APP_UPDATE_FAILURE",
  disableFirmwareAppUpdateRequest: (request) => ({
    type: actions.DISABLE_FIRMWARE_APP_UPDATE_REQUEST,
    payload: request,
  }),
  disableFirmwareAppUpdateSuccess: (res) => ({
    type: actions.DISABLE_FIRMWARE_APP_UPDATE_SUCCESS,
    payload: res,
  }),
  disableFirmwareAppUpdateFailure: (error) => ({
    type: actions.DISABLE_FIRMWARE_APP_UPDATE_FAILURE,
    payload: error,
  }),

  //
  // DOWNLOAD
  // APP App
  DOWNLOAD_VERSION_APP_REQUEST: "DOWNLOAD_VERSION_APP_REQUEST",
  DOWNLOAD_VERSION_APP_SUCCESS: "DOWNLOAD_VERSION_APP_SUCCESS",
  DOWNLOAD_VERSION_APP_FAILURE: "DOWNLOAD_VERSION_APP_FAILURE",
  downloadVersionAppRequest: (request) => {
    return {
      type: actions.DOWNLOAD_VERSION_APP_REQUEST,
      payload: request,
    };
  },
  downloadVersionAppSuccess: (data) => {
    return {
      type: actions.DOWNLOAD_VERSION_APP_SUCCESS,
      payload: data,
    };
  },
  downloadVersionAppFailure: (error) => ({
    type: actions.DOWNLOAD_VERSION_APP_FAILURE,
    payload: error,
  }),
  // APP App  UPDATE Update
  DOWNLOAD_VERSION_APP_UPDATE_REQUEST: "DOWNLOAD_VERSION_APP_UPDATE_REQUEST",
  DOWNLOAD_VERSION_APP_UPDATE_SUCCESS: "DOWNLOAD_VERSION_APP_UPDATE_SUCCESS",
  DOWNLOAD_VERSION_APP_UPDATE_FAILURE: "DOWNLOAD_VERSION_APP_UPDATE_FAILURE",
  downloadVersionAppUpdateRequest: (request) => {
    return {
      type: actions.DOWNLOAD_VERSION_APP_UPDATE_REQUEST,
      payload: request,
    };
  },
  downloadVersionAppUpdateSuccess: (data) => {
    return {
      type: actions.DOWNLOAD_VERSION_APP_UPDATE_SUCCESS,
      payload: data,
    };
  },
  downloadVersionAppUpdateFailure: (error) => ({
    type: actions.DOWNLOAD_VERSION_APP_UPDATE_FAILURE,
    payload: error,
  }),
};

export default actions;
