import productActions from "./actions";
import { getDateFormatted } from "@iso/lib/helpers/dateFormat";
import { uniqueRowKey } from "@iso/lib/helpers/uniqueKey";

const INITIAL_DATA = {
  // 0/未操作 1/操作中 2/操作成功 3/操作失败
  copyStatus: "0", //  copy
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除
  detailStatus: "0", // 详情

  productData: [],
  productDataFile: [],
  addProduct: [],
  commList: [],
  commType: [],
  brandsList: [],
  categoriesList: [],
  model: [],
  modelCategory: [],
  productActions: [],
  productActionType: [],
  editProduct: [],
  getProduct: [],
  deleteProduct: [],
  loading: true,
  productCreateSuccess: true,
  error: null,
};
export default function productReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    //  *********  Add Product *******   //
    case productActions.ADD_PRODUCT_REQUEST:
      return {
        ...state,
        createStatus: "1",
        addProduct: [],
        productCreateSuccess: true,
        error: null,
      };
    case productActions.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        createStatus: "2",
        addProduct: action.payload,
        productCreateSuccess: false,
        error: null,
      };
    case productActions.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        createStatus: "3",
        loading: false,
        error: action.payload,
      };

    //  *********  Add Product Data File *******   //
    case productActions.ADD_PRODUCT_DATA_FILE_REQUEST:
      return {
        ...state,
        productDataFile: [],
        loading: true,
        error: null,
      };
    case productActions.ADD_PRODUCT_DATA_FILE_SUCCESS:
      return {
        ...state,
        productDataFile: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Fetch Product *******   //
    case productActions.FETCH_PRODUCT_DATA_REQUEST:
      return {
        ...state,
        productData: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_PRODUCT_DATA_SUCCESS:
      let data = Object.assign([], action.payload);
      data.forEach((element, index) => {
        element.key = index;
        const date = new Date(element.createdAt);
        element.createdAt = getDateFormatted(date);
      });
      return {
        ...state,
        productData: data,
        loading: false,
        error: null,
      };

    //  *********  fetch Communication List  *******   //
    case productActions.FETCH_COMMUNICATION_LIST_REQUEST:
      return {
        ...state,
        commList: [],
        loading: false,
        error: null,
      };
    case productActions.FETCH_COMMUNICATION_LIST_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        commList: action.payload,
        loading: false,
        error: null,
      };

    //  *********  fetch Communication type List  *******   //
    case productActions.FETCH_COMMUNICATION_TYPE_REQUEST:
      return {
        ...state,
        commType: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_COMMUNICATION_TYPE_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        commType: action.payload,
        loading: false,
        error: null,
      };

    //  *********  fetch Brands List *******   //
    case productActions.FETCH_BRANDS_REQUEST:
      return {
        ...state,
        brandsList: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_BRANDS_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        brandsList: action.payload,
        loading: false,
        error: null,
      };

    //  *********  fetch Categories List *******   //
    case productActions.FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        categoriesList: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_CATEGORY_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        categoriesList: action.payload,
        loading: false,
        error: null,
      };

    //  *********  fetch Models List *******   //
    case productActions.FETCH_MODEL_REQUEST:
      return {
        ...state,
        model: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_MODEL_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        model: action.payload,
        loading: false,
        error: null,
      };

    //  *********  fetch Models ByCategoryId List *******   //
    case productActions.FETCH_MODEL_BY_CATEGORY_ID_REQUEST:
      return {
        ...state,
        modelCategory: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_MODEL_BY_CATEGORY_ID_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        modelCategory: action.payload,
        loading: false,
        error: null,
      };

    //  *********  fetch Prodcut Action List *******   //
    case productActions.FETCH_PRODUCT_ACTIONS_REQUEST:
      return {
        ...state,
        productActions: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_PRODUCT_ACTIONS_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        productActions: action.payload,
        loading: false,
        error: null,
      };

    //  *********  fetch Prodcut Action List *******   //
    case productActions.FETCH_PRODUCT_ACTIONS_TYPES_REQUEST:
      return {
        ...state,
        productActionType: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_PRODUCT_ACTIONS_TYPES_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        productActionType: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Get Product *******   //
    case productActions.GET_PRODUCT_REQUEST:
      return {
        ...state,
        getProduct: [],
        loading: true,
        error: null,
      };
    case productActions.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        getProduct: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Edit Product *******   //
    case productActions.EDIT_PRODUCT_REQUEST:
      return {
        ...state,
        editStatus: "1",
        editProduct: [],
        loading: true,
        error: null,
      };
    case productActions.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        editProduct: action.payload,
        loading: false,
        error: null,
      };
    case productActions.EDIT_PRODUCT_FAILURE:
      return {
        ...state,
        editStatus: "3",
        editProduct: [],
        loading: false,
        error: null,
      };

    //  *********  Delete Product *******   //
    case productActions.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        deleteProduct: [],
        loading: true,
        error: null,
      };
    case productActions.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        deleteProduct: action.payload,
        loading: false,
        error: null,
      };

    //  ********* Product Error*******   //
    case productActions.FETCH_PRODUCT_DATA_FAILURE:
    case productActions.ADD_PRODUCT_DATA_FILE_FAILURE:
    case productActions.FETCH_COMMUNICATION_LIST_FAILURE:
    case productActions.FETCH_COMMUNICATION_TYPE_FAILURE:
    case productActions.FETCH_BRANDS_FAILURE:
    case productActions.FETCH_CATEGORY_FAILURE:
    case productActions.FETCH_MODEL_FAILURE:
    case productActions.FETCH_MODEL_BY_CATEGORY_ID_FAILURE:
    case productActions.FETCH_PRODUCT_ACTIONS_FAILURE:
    case productActions.FETCH_PRODUCT_ACTIONS_TYPES_FAILURE:
    case productActions.GET_PRODUCT_FAILURE:
    case productActions.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
