import { call, all, takeEvery, put } from "redux-saga/effects";
import productActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";
import Notification from "@iso/components/Notification";

const alert = (response) => {
  Notification("success", response.message);
};

const Error = (response) => {
  Notification("error", response.message);
};

function* addproductRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createProduct, payload);
    if (response.success) {
      yield put(productActions.addproductDataSuccess(response.data));
      fetchproductDataEffect();
      alert(response);
    } else {
      Error(response);
      yield put(productActions.addproductDataFailure(response));
    }
    yield put(productActions.fetchproductDataEffect());
  } catch (error) {
    yield put(productActions.addproductDataFailure(error));
  }
}

function* addproductDataFileRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.productDataUpload, payload);
    yield put(productActions.addproductDataFileSuccess(response.data));
  } catch (error) {
    yield put(productActions.addproductDataFileFailure(error));
  }
}

function* fetchproductDataEffect() {
  try {
    const response = yield call(CloudHawkApi.getProducts);
    yield put(productActions.fetchproductDataSuccess(response.data));
  } catch (error) {
    yield put(productActions.fetchproductDataFailure(error));
  }
}

function* fetchCommunicationListRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getCommTypes, payload);
    yield put(productActions.fetchcommunicationlistSuccess(response.data));
  } catch (error) {
    yield put(productActions.fetchcommunicationlistFailure(error));
  }
}

function* fetchCommunicationTypeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getCommByType, payload);
    yield put(productActions.fetchcommunicationtypeSuccess(response.data));
  } catch (error) {
    yield put(productActions.fetchcommunicationtypeFailure(error));
  }
}

function* fetchBrandsRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getBrands, payload);
    yield put(productActions.fetchBrandsSuccess(response.data));
  } catch (error) {
    yield put(productActions.fetchBrandsFailure(error));
  }
}

function* fetchCategoriesRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getCategories, payload);
    yield put(productActions.fetchCategoriesSuccess(response.data));
  } catch (error) {
    yield put(productActions.fetchCategoriesFailure(error));
  }
}

function* fetchModelsRequest() {
  try {
    const response = yield call(CloudHawkApi.getModels);
    yield put(productActions.fetchModelsSuccess(response.data));
  } catch (error) {
    yield put(productActions.fetchModelsFailure(error));
  }
}

function* fetchModelByCategoryIdRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getModelByCategoryId, payload);
    yield put(productActions.fetchModelByCategoryIdSuccess(response.data));
  } catch (error) {
    yield put(productActions.fetchModelByCategoryIdFailure(error));
  }
}

function* fetchProdcutActionRequest() {
  try {
    const response = yield call(CloudHawkApi.getActions);
    yield put(productActions.fetchProdcutActionSuccess(response.data));
  } catch (error) {
    yield put(productActions.fetchProdcutActionFailure(error));
  }
}

function* fetchProdcutActionTypesRequest() {
  try {
    const response = yield call(CloudHawkApi.getAllActionTypes);
    yield put(productActions.fetchProdcutActionTypesSuccess(response.data));
  } catch (error) {
    yield put(productActions.fetchProdcutActionTypesFailure(error));
  }
}

function* getproductRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getProductById, payload);
    yield put(productActions.getproductDataSuccess(response.data));
  } catch (error) {
    yield put(productActions.getproductDataFailure(error));
  }
}

function* editproductRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editProduct, payload);
    if (response && response.success) {
      alert(response);
    } else {
      Notification("error", "Error");
    }
    yield put(productActions.editproductDataSuccess(response.data));
  } catch (error) {
    yield put(productActions.editproductDataFailure(error));
  }
}

function* deleteproductRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteProduct, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
    yield put(productActions.deleteproductDataSuccess(response.data));
  } catch (error) {
    yield put(productActions.deleteproductDataFailure(error));
  }
}

export default function* productSaga() {
  yield all([
    takeEvery(productActions.ADD_PRODUCT_REQUEST, addproductRequest),
    takeEvery(
      productActions.ADD_PRODUCT_DATA_FILE_REQUEST,
      addproductDataFileRequest,
    ),
    takeEvery(
      productActions.FETCH_PRODUCT_DATA_REQUEST,
      fetchproductDataEffect,
    ),
    takeEvery(
      productActions.FETCH_COMMUNICATION_LIST_REQUEST,
      fetchCommunicationListRequest,
    ),
    takeEvery(
      productActions.FETCH_COMMUNICATION_TYPE_REQUEST,
      fetchCommunicationTypeRequest,
    ),
    takeEvery(productActions.FETCH_BRANDS_REQUEST, fetchBrandsRequest),
    takeEvery(productActions.FETCH_CATEGORY_REQUEST, fetchCategoriesRequest),
    takeEvery(productActions.FETCH_MODEL_REQUEST, fetchModelsRequest),
    takeEvery(
      productActions.FETCH_MODEL_BY_CATEGORY_ID_REQUEST,
      fetchModelByCategoryIdRequest,
    ),
    takeEvery(
      productActions.FETCH_PRODUCT_ACTIONS_REQUEST,
      fetchProdcutActionRequest,
    ),
    takeEvery(
      productActions.FETCH_PRODUCT_ACTIONS_TYPES_REQUEST,
      fetchProdcutActionTypesRequest,
    ),
    takeEvery(productActions.GET_PRODUCT_REQUEST, getproductRequest),
    takeEvery(productActions.EDIT_PRODUCT_REQUEST, editproductRequest),
    takeEvery(productActions.DELETE_PRODUCT_REQUEST, deleteproductRequest),
  ]);
}
