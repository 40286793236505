import { call, all, takeEvery, put, select } from "redux-saga/effects";
import IAAddressManageActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    // message:"errorCode:"+response.code+";Msg:"+response.msg,
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

function* getIAAddressManageList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getIAManagePageList, payload);
    if (response.success) {
      yield put(
        IAAddressManageActions.fetchGETIAAddressManageListSuccess(
          response.data,
        ),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(IAAddressManageActions.fetchGETIAAddressManageListError(error));
  }
}

//添加
function* addIAAddressManageList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.addIAManagePageList,
      payload.params1,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    // yield put(IAAddressManageActions.fetchGETIAAddressManageListError(error));
  } finally {
    yield put(
      IAAddressManageActions.fetchGETIAAddressManageList(payload.params2),
    );
  }
}

function* editIAAddressManageList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editIAManagePageList, payload);
    if (response.success) {
      yield put(IAAddressManageActions.fetchGETIAAddressManageList({}));
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {}
}

//删除
function* delIAAddressManageList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.delIAPageList, payload);
    const total = yield select((state) => state.IAAddressManage.totalList);
    if (response.success) {
      if (
        payload.params2.pageNum >
        Math.ceil((total - 1) / payload.params2.pageSize)
      ) {
        payload.params2.pageNum = payload.params2.pageNum - 1;
      }
      yield put(
        IAAddressManageActions.fetchGETIAAddressManageList(payload.params2),
      );

      alert(response);
    } else {
      Error(response);
      yield put(
        IAAddressManageActions.fetchGETIAAddressManageList(payload.params2),
      );
    }
  } catch (error) {
    yield put(
      IAAddressManageActions.fetchGETIAAddressManageList(payload.params2),
    );
  }
}

function* getDetailIAAddressManageList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.detailIAPageList, payload);
    if (response.success) {
      yield put(
        IAAddressManageActions.fetchGETIAAddressManageDetailSuccess(
          response.data,
        ),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(IAAddressManageActions.fetchGETIAAddressManageDetailError(error));
  }
}

//下拉列表
function* getSelectIAAddressManageList() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.selectIAPageList);
    if (response.success) {
      yield put(
        IAAddressManageActions.fetchGETIAAddressManageSelectSuccess(
          response.data,
        ),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(IAAddressManageActions.fetchGETIAAddressManageSelectError(error));
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(
      IAAddressManageActions.GET_IAAddressManage_List_REQUEST,
      getIAAddressManageList,
    ),
    takeEvery(
      IAAddressManageActions.ADD_IAAddressManage_List_REQUEST,
      addIAAddressManageList,
    ),
    takeEvery(
      IAAddressManageActions.EDIT_IAAddressManage_List_REQUEST,
      editIAAddressManageList,
    ),
    takeEvery(
      IAAddressManageActions.DEL_IAAddressManage_List_REQUEST,
      delIAAddressManageList,
    ),
    takeEvery(
      IAAddressManageActions.GET_IAAddressManage_Detail_REQUEST,
      getDetailIAAddressManageList,
    ),
    takeEvery(
      IAAddressManageActions.GET_IAAddressManage_Select_REQUEST,
      getSelectIAAddressManageList,
    ),
  ]);
}
