// saga.js
import { call, all, takeEvery, put } from "redux-saga/effects";
import actions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";
import CloudHawkApi2 from "@iso/config/Services/CloudHawk2";
import statusAction from "../apiStatus/actions";
import Notification from "@iso/components/Notification";

//
// create
//  *********  create FAQ Faq *******   //
function* createFaqRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createFaq, payload);
    if (response.code === 100) {
      yield put(actions.createFaqSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createFaqFailure(response));
    }
  } catch (error) {
    yield put(actions.createFaqFailure(error));
  }
}
//  *********  create FAQ Faq Child child *******   //
function* createFaqChildRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createFaqChild, payload);
    if (response.code === 100) {
      yield put(actions.createFaqChildSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createFaqChildFailure(response));
    }
  } catch (error) {
    yield put(actions.createFaqChildFailure(error));
  }
}
//  *********  create HELP Help *******   //
function* createHelpRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createHelp, payload);
    if (response.code === 100) {
      yield put(actions.createHelpSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createHelpFailure(response));
    }
  } catch (error) {
    yield put(actions.createHelpFailure(error));
  }
}
//  *********  create HELP Help Child child *******   //
function* createHelpChildRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createHelpChild, payload);
    if (response.code === 100) {
      yield put(actions.createHelpSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createHelpFailure(response));
    }
  } catch (error) {
    yield put(actions.createHelpFailure(error));
  }
}

//
// edit
//  *********  edit FAQ Faq *******   //
function* editFaqRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editFaq, payload);
    if (response.code === 100) {
      yield put(actions.editFaqSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFaqFailure(response));
    }
  } catch (error) {
    yield put(actions.editFaqFailure(error));
  }
}
//  *********  edit FAQ Faq Child child *******   //
function* editFaqChildRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editFaqChild, payload);
    if (response.code === 100) {
      yield put(actions.editFaqSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFaqFailure(response));
    }
  } catch (error) {
    yield put(actions.editFaqFailure(error));
  }
}
//  *********  edit HELP Help *******   //
function* editHelpRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editHelp, payload);
    if (response.code === 100) {
      yield put(actions.editHelpSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editHelpFailure(response));
    }
  } catch (error) {
    yield put(actions.editHelpFailure(error));
  }
}
//  *********  edit HELP Help Child child *******   //
function* editHelpChildRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editHelpChild, payload);
    if (response.code === 100) {
      yield put(actions.editHelpSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editHelpFailure(response));
    }
  } catch (error) {
    yield put(actions.editHelpFailure(error));
  }
}

//
// delete
//  *********  delete FAQ Faq *******   //
function* deleteFaqRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteFaq, payload);
    if (response.code === 100) {
      yield put(actions.deleteFaqSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteFaqFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteFaqFailure(error));
  }
}
//  *********  delete FAQ Faq Child child *******   //
function* deleteFaqChildRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteFaqChild, payload);
    if (response.code === 100) {
      yield put(actions.deleteFaqSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteFaqFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteFaqFailure(error));
  }
}
//  *********  delete HELP Help *******   //
function* deleteHelpRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteHelp, payload);
    if (response.code === 100) {
      yield put(actions.deleteHelpSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteHelpFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteHelpFailure(error));
  }
}
//  *********  delete HELP Help Child child *******   //
function* deleteHelpChildRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteHelpChild, payload);
    if (response.code === 100) {
      yield put(actions.deleteHelpSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteHelpFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteHelpFailure(error));
  }
}

//
//  ********* fetch FAQ Faq *******   //
function* fetchFaqRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchFaq, payload);
    if (response.code === 100) {
      yield put(actions.fetchFaqSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchFaqFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchFaqFailure(error));
  }
}
//  *********  enable FAQ Faq *******   //
function* enableFaqRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.enableFaq, payload);
    if (response.code === 100) {
      yield put(actions.enableFaqSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.enableFaqFailure(response));
    }
  } catch (error) {
    yield put(actions.enableFaqFailure(error));
  }
}
//  *********  disable FAQ Faq *******   //
function* disableFaqRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.disableFaq, payload);
    if (response.code === 100) {
      yield put(actions.disableFaqSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.disableFaqFailure(response));
    }
  } catch (error) {
    yield put(actions.disableFaqFailure(error));
  }
}
//  ********* fetch HELP Help *******   //
function* fetchHelpRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchHelp, payload);
    if (response.code === 100) {
      yield put(actions.fetchHelpSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchHelpFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchHelpFailure(error));
  }
}
//  *********  enable HELP Help *******   //
function* enableHelpRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.enableHelp, payload);
    if (response.code === 100) {
      yield put(actions.enableHelpSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.enableHelpFailure(response));
    }
  } catch (error) {
    yield put(actions.enableHelpFailure(error));
  }
}
//  *********  disable HELP Help *******   //
function* disableHelpRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.disableHelp, payload);
    if (response.code === 100) {
      yield put(actions.disableHelpSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.disableHelpFailure(response));
    }
  } catch (error) {
    yield put(actions.disableHelpFailure(error));
  }
}

function* fetchfaqDataEffect(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getFaqs, payload);
    yield put(actions.fetchfaqDataSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchfaqDataFailure(error));
  }
}

function* fetchFilterTypeDataEffect(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getUniqueTypes, payload);
    yield put(actions.fetchFilterTypeDataSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchFilterTypeDataFailure(error));
  }
}

function* fetchFilterFaqDataEffect(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getFilterFaqs, payload);
    yield put(actions.fetchFilterFaqDataSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchFilterFaqDataFailure(error));
  }
}

// function* addfaqRequest(request) {
// 	yield put(statusAction.cloudHawkApiStatusClear());
// 	const { payload } = request;
// 	try {
// 		const response = yield call(CloudHawkApi.createFaq, payload);
// 		Notification(
// 			"success",
// 			response.message
// 		);
// 		yield put(actions.addfaqDataSuccess(response.data));
// 	} catch (error) {
// 		yield put(actions.addfaqDataFailure(error));
// 	}
// }

// function* editfaqRequest(request) {
// 	yield put(statusAction.cloudHawkApiStatusClear());
// 	const { payload } = request;
// 	try {
// 		const response = yield call(CloudHawkApi.editFaq, payload);
// 		Notification(
// 			"success",
// 			response.message
// 		);
// 		window.location.reload()
// 		yield put(actions.editfaqDataSuccess(response.data));
// 	} catch (error) {
// 		yield put(actions.editfaqDataFailure(error));
// 	}
// }

// function* deletefaqRequest(request) {
// 	yield put(statusAction.cloudHawkApiStatusClear());
// 	const { payload } = request;
// 	try {
// 		const response = yield call(CloudHawkApi.deleteFaq, payload);
// 		Notification(
// 			"success",
// 			response.message
// 		);
// 		yield put(actions.deletefaqDataSuccess(response.data));
// 	} catch (error) {
// 		yield put(actions.deletefaqDataFailure(error));
// 	}
// }

export default function* faqSaga() {
  yield all([
    // create
    takeEvery(actions.CREATE_FAQ_REQUEST, createFaqRequest),
    takeEvery(actions.CREATE_FAQ_CHILD_REQUEST, createFaqChildRequest),
    takeEvery(actions.CREATE_HELP_REQUEST, createHelpRequest),
    takeEvery(actions.CREATE_HELP_CHILD_REQUEST, createHelpChildRequest),
    // edit
    takeEvery(actions.EDIT_FAQ_REQUEST, editFaqRequest),
    takeEvery(actions.EDIT_FAQ_CHILD_REQUEST, editFaqChildRequest),
    takeEvery(actions.EDIT_HELP_REQUEST, editHelpRequest),
    takeEvery(actions.EDIT_HELP_CHILD_REQUEST, editHelpChildRequest),
    // delete
    takeEvery(actions.DELETE_FAQ_REQUEST, deleteFaqRequest),
    takeEvery(actions.DELETE_FAQ_CHILD_REQUEST, deleteFaqChildRequest),
    takeEvery(actions.DELETE_HELP_REQUEST, deleteHelpRequest),
    takeEvery(actions.DELETE_HELP_CHILD_REQUEST, deleteHelpChildRequest),
    // fetch
    takeEvery(actions.FETCH_FAQ_REQUEST, fetchFaqRequest),
    takeEvery(actions.FETCH_HELP_REQUEST, fetchHelpRequest),
    // enable
    takeEvery(actions.ENABLE_FAQ_REQUEST, enableFaqRequest),
    takeEvery(actions.ENABLE_HELP_REQUEST, enableHelpRequest),
    // disable
    takeEvery(actions.DISABLE_FAQ_REQUEST, disableFaqRequest),
    takeEvery(actions.DISABLE_HELP_REQUEST, disableHelpRequest),

    takeEvery(actions.FETCH_FAQ_DATA_REQUEST, fetchfaqDataEffect),
    takeEvery(actions.FETCH_FILTERTYPE_DATA_REQUEST, fetchFilterTypeDataEffect),
    takeEvery(actions.FETCH_FILTERFAQ_DATA_REQUEST, fetchFilterFaqDataEffect),
    // takeEvery(actions.ADD_FAQ_REQUEST, addfaqRequest),
    // takeEvery(actions.EDIT_FAQ_REQUEST, editfaqRequest),
    // takeEvery(actions.DELETE_FAQ_REQUEST, deletefaqRequest),
  ]);
}
