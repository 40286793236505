import countryActions from "./actions";

const INITIAL_DATA = {
  Countries: [],
  Country: [],
  addCountry: [],
  editCountry: [],
  deleteCountry: [],
  loading: true,
  error: null,
};
export default function CountryReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case countryActions.GET_COUNTRY_REQUEST:
      return {
        ...state,
        Countries: [],
        loading: true,
        error: null,
      };
    case countryActions.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        Countries: action.payload,
        loading: false,
        error: null,
      };

    case countryActions.GET_COUNTRY_BY_ID_REQUEST:
      return {
        ...state,
        Country: [],
        loading: true,
        error: null,
      };
    case countryActions.GET_COUNTRY_BY_ID_SUCCESS:
      return {
        ...state,
        Country: action.payload,
        loading: false,
        error: null,
      };

    case countryActions.ADD_COUNTRY_REQUEST:
      return {
        ...state,
        addCountry: [],
        loading: true,
        error: null,
      };
    case countryActions.ADD_COUNTRY_SUCCESS:
      return {
        ...state,
        addCountry: action.payload,
        loading: false,
        error: null,
      };

    case countryActions.EDIT_COUNTRY_REQUEST:
      return {
        ...state,
        editCountry: [],
        loading: true,
        error: null,
      };
    case countryActions.EDIT_COUNTRY_SUCCESS:
      return {
        ...state,
        editCountry: action.payload,
        loading: false,
        error: null,
      };

    case countryActions.DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        deleteCountry: [],
        loading: true,
        error: null,
      };
    case countryActions.DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        deleteCountry: action.payload,
        loading: false,
        error: null,
      };
    case countryActions.GET_COUNTRY_FAILURE:
    case countryActions.ADD_COUNTRY_FAILURE:
    case countryActions.EDIT_COUNTRY_FAILURE:
    case countryActions.GET_COUNTRY_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
