import ApiService from "./Api";
import { R_BASE_URL2 } from "./requestUrl";

const BASE_URL = R_BASE_URL2;

const client = new ApiService({ baseURL: BASE_URL });

const CloudHawkApi = {
  // language
  getLanguages: () => client.get(`web/languages/select`),

  // === Firmware ===
  // select
  selectFirmware: (params) => client.getParams("web/fota/version/list", params),
  selectOrder: () => client.get("web/device/order/number/list"),
  selectBatch: (params) =>
    client.getParams("web/device/batch/number/list", params),
  selectFirmwareDtu: (params) => client.getParams("", params),
  // create
  createFirmware: (params) => client.post("web/fota/version/insert", params),
  createFirmwareUpdateNew: (params) =>
    client.post("web/fota/task/insert", params),
  createFirmwareUpdateOld: (params) =>
    client.post("web/fota/firmware/insert", params),
  // detail
  detailFirmware: (params) => client.getParams("", params),
  detailFirmwareUpdateNew: (params) =>
    client.getParams("web/fota/task/detail", params),
  detailFirmwareUpdateHistoryNew: (params) =>
    client.getParams("web/fota/task/history", params),
  detailFirmwareUpdateOld: (params) => client.getParams("", params),
  // edit
  editFirmware: (params) => client.post("web/fota/version/update", params),
  editFirmwareUpdateNew: (params) =>
    client.post("web/fota/task/update", params),
  editFirmwareUpdateOld: (params) =>
    client.post("web/fota/firmware/update", params),
  editFirmwareUpdateNewLanguage: (params) =>
    client.post("web/fota/task/update/language", params),
  editFirmwareUpdateOldLanguage: (params) =>
    client.post("web/fota/firmware/update/language", params),
  // delete
  deleteFirmware: (params) =>
    client.getParams("web/fota/version/remove", params),
  deleteFirmwareUpdateNew: (params) =>
    client.getParams("web/fota/task/remove", params),
  deleteFirmwareUpdateOld: (params) =>
    client.getParams("web/fota/firmware/remove", params),
  // fetch
  fetchFirmware: (params) => client.getParams("web/fota/version/page", params),
  fetchFirmwareUpdateNew: (params) =>
    client.getParams("web/fota/task/page", params),
  fetchFirmwareUpdateInNew: (params) =>
    client.getParams("web/fota/task/page/device", params),
  fetchFirmwareUpdateOld: (params) =>
    client.getParams("web/fota/firmware/page", params),
  // enable
  enableFirmware: (params) =>
    client.getParams("web/fota/version/enable", params),
  enableFirmwareUpdateNew: (params) =>
    client.getParams("web/fota/task/start", params),
  enableFirmwareUpdateOld: (params) =>
    client.getParams("web/fota/firmware/start", params),
  // disable
  disableFirmware: (params) =>
    client.getParams("web/fota/version/disable", params),
  disableFirmwareUpdateNew: (params) =>
    client.getParams("web/fota/task/stop", params),
  disableFirmwareUpdateOld: (params) =>
    client.getParams("web/fota/firmware/stop", params),
  // pass
  goFirmwarePass: (params) =>
    client.getParams("web/fota/task/examine/approve", params),
  // refuse
  goFirmwareRefuse: (params) =>
    client.getParams("web/fota/task/examine/reject", params),
  // downloadVersion
  downloadImportDevice: () =>
    client.download("web/fota/download/template/excel"),
  downloadVersion: (params) =>
    client.downloadParams("web/fota/download/firmware", params),
  downloadVersionUpdateNew: (params) => client.downloadParams("", params),
  downloadVersionUpdateOld: (params) => client.downloadParams("", params),

  // === Firmware app ===
  // select
  selectFirmwareApp: () => client.get("web/app/version/select"),
  // create
  createFirmwareApp: (params) => client.post("web/app/version/add", params),
  createFirmwareAppUpdate: (params) =>
    client.post("web/app/upgrade/task/add", params),
  // detail

  // edit
  editFirmwareApp: (params) => client.post("web/app/version/update", params),
  editFirmwareAppUpdate: (params) =>
    client.post("web/app/upgrade/task/update", params),
  editFirmwareAppUpdateLanguage: (params) =>
    client.post("web/app/upgrade/task/update/language", params),
  // delete
  deleteFirmwareApp: (params) =>
    client.getParams("web/app/version/delete", params),
  deleteFirmwareAppUpdate: (params) =>
    client.getParams("web/app/upgrade/task/delete", params),
  // fetch
  fetchFirmwareApp: (params) =>
    client.getParams("web/app/version/page", params),
  fetchFirmwareAppUpdate: (params) =>
    client.getParams("web/app/upgrade/task/page", params),
  // enable
  enableFirmwareAppUpdate: (params) =>
    client.getParams("web/app/upgrade/task/update/status", params),
  // disable
  disableFirmwareAppUpdate: (params) =>
    client.getParams("web/app/upgrade/task/update/status", params),
  // pass
  // goFirmwarePass: (params) => client.getParams("web/fota/task/examine/approve", params),
  // refuse
  // goFirmwareRefuse: (params) => client.getParams("web/fota/task/examine/reject", params),
  // downloadVersion
  downloadVersionApp: (params) => client.downloadParams("", params),
  downloadVersionAppUpdate: (params) => client.downloadParams("", params),

  // === Country ===
  getCountry: (params) => client.getParams("web/countries/page", params),
  addCountry: (params) => client.post("web/countries/add", params),
  editCountry: (params) => client.post("web/countries/update", params),
  deleteCountry: (params) => client.getParams("web/countries/delete", params),

  // === FAQ help ===
  // list
  fetchFaq: (params) => client.getParams("web/faqs/page", params),
  fetchHelp: (params) => client.getParams("web/course/page", params),
  // create
  createFaq: (params) => client.post("web/faqs/add", params),
  createFaqChild: (params) => client.post("web/faqs/second/add", params),
  createHelp: (params) => client.post("web/course/add", params),
  createHelpChild: (params) => client.post("web/course/second/add", params),
  // edit
  editFaq: (params) => client.post("web/faqs/update", params),
  editFaqChild: (params) => client.post("web/faqs/second/update", params),
  editHelp: (params) => client.post("web/course/update", params),
  editHelpChild: (params) => client.post("web/course/second/update", params),
  // delete
  deleteFaq: (params) => client.getParams("web/faqs/delete", params),
  deleteFaqChild: (params) => client.getParams("web/faqs/delete", params),
  deleteHelp: (params) => client.getParams("web/course/delete", params),
  deleteHelpChild: (params) => client.getParams("web/course/delete", params),
  // enable disable
  enableFaq: (params) => client.getParams("web/faqs/update/status", params),
  disableFaq: (params) => client.getParams("web/faqs/update/status", params),
  enableHelp: (params) => client.getParams("web/course/update/status", params),
  disableHelp: (params) => client.getParams("web/course/update/status", params),

  // ********** ManageUsers Controller **************
  // getManageUsers: (params) => client.post(`web/app/user/page`, params),
  user_device_record: (params) =>
    client.getParams(`web/app/user/device/record`, params),
  getManageUsersSelect: (params) =>
    client.getParams(`web/app/user/select`, params),
  getManageUsers: (params) => client.getParams(`web/app/user/page`, params),
  editManageUsers: (params) => client.post("web/app/user/update", params),
  deleteManageUsers: (params) =>
    client.getParams(`web/app/user/delete`, params),
  goImportDownload: () => client.download("web/app/user/import/template"),

  // ********** 后台管理员账号列表(下拉) **********
  getUsers: () => client.get(`web/account/adminSelect`),

  // ********** Product Item Controller **************
  createBrand: (params) => client.post("web/brands/add", params),
  editBrand: (params) => client.post("web/brands/update", params),
  // deleteBrand: (params) => client.post("web/brands/delete", params),
  deleteBrand: (params) => client.getParams(`web/brands/delete`, params),

  createCategory: (params) => client.post("web/categories/add", params),
  editCategory: (params) => client.post("web/categories/update", params),
  // deleteCategory: (params) => client.post("web/categories/delete", params),
  deleteCategory: (params) => client.getParams(`web/categories/delete`, params),

  createModel: (params) => client.post("web/models/add", params),
  editModel: (params) => client.post("web/models/update", params),
  // deleteModel: (params) => client.post("web/models/delete", params),
  deleteModel: (params) => client.getParams(`web/models/delete`, params),

  createSeries: (params) => client.post("web/series/add", params),
  editSeries: (params) => client.post("web/series/update", params),
  // deleteSeries: (params) => client.post("web/series/delete", params),
  deleteSeries: (params) => client.getParams(`web/series/delete`, params),

  createClient: (params) => client.post("web/client/model/add", params),
  editClient: (params) => client.post("web/client/model/update", params),
  // deleteClient: (params) => client.post("web/series/delete", params),
  deleteClient: (params) => client.getParams(`web/client/model/delete`, params),

  upDeviceTestOrderBatch: (params) =>
    client.post("web/device/test/warehousing", params),
  getDeviceTestOrder: (params) =>
    client.getParams(`web/device/test/orderNumber/select`, params),
  getDeviceTestBatch: (params) =>
    client.getParams(`web/device/test/batchNumber/select`, params),

  productsGetV1pClient: (params) =>
    client.getParams(`web/products/page`, params),
  productsCreateV1pClient: (params) => client.post(`web/products/add`, params),
  productsEditV1pClient: (params) => client.post(`web/products/update`, params),
  productsDeleteV1pClient: (params) =>
    client.getParams(`web/products/delete`, params),

  // createTypes: (params) => client.imgPost("web/product/add", params),
  copyTypes: (params) => client.post("web/product/copy", params),
  createTypes: (params) => client.post("web/product/add", params),
  editTypes: (params) => client.post("web/product/update", params),
  deleteTypes: (params) => client.getParams(`web/product/delete`, params),

  // Tsl 属性 1
  createTsl1: (params) => client.post("web/product/tsl/add/property", params),
  editTsl1: (params) => client.post("web/product/tsl/update/property", params),
  // deleteTsl1: (params) => client.post("web/product/tsl/del/property", params),
  deleteTsl1: (params) =>
    client.getParams(`web/product/tsl/del/property`, params),
  detailTsl1: (params) =>
    client.getParams(`web/product/tsl/get/property`, params),
  // Tsl 服务 2
  createTsl2: (params) => client.post("web/product/tsl/add/service", params),
  editTsl2: (params) => client.post("web/product/tsl/update/service", params),
  // deleteTsl2: (params) => client.post("web/product/tsl/del/service", params),
  deleteTsl2: (params) =>
    client.getParams(`web/product/tsl/del/service`, params),
  detailTsl2: (params) =>
    client.getParams(`web/product/tsl/get/service`, params),
  // Tsl 事件 3
  createTsl3: (params) => client.post("web/product/tsl/add/event", params),
  editTsl3: (params) => client.post("web/product/tsl/update/event", params),
  // deleteTsl3: (params) => client.post("web/product/tsl/del/event", params),
  deleteTsl3: (params) => client.getParams(`web/product/tsl/del/event`, params),
  detailTsl3: (params) => client.getParams(`web/product/tsl/get/event`, params),
  // 发布草稿
  // createTslCraft: (params) => client.post("web/product/tsl/publish", params),
  createTslCraft: (params) =>
    client.getParams(`web/product/tsl/publish`, params),

  recoveryTsl: (params) => client.getParams(`web/product/tsl/recovery`, params),

  // ********** Product Controller **************
  // 物模型 获取产品型号对应的组件信息
  getSeriesSelectAim: (params) =>
    client.getParams(`web/product/select/series`, params),
  // 物模型 历史版本列表
  getHistorySelect: (params) =>
    client.getParams(`web/product/tsl/version/select`, params),
  // 物模型 属性列表
  getPropertySelect: (params) =>
    client.getParams(`web/product/tsl/list/property`, params),

  checkFactoryCode: (params) =>
    client.getParams(`web/product/check/product`, params),

  getBrandsSelect: () => client.get(`web/brands/select`),
  getBrands: (params) => client.getParams(`web/brands/page`, params),
  detailBrand: (params) => client.getParams(`web/brands/detail`, params),

  getCategoriesSelect: () => client.get(`web/categories/select`),
  getCategories: (params) => client.getParams(`web/categories/page`, params),
  detailCategory: (params) => client.getParams(`web/categories/detail`, params),

  getModelsSelect: () => client.get(`web/models/select`),
  getModels: (params) => client.getParams(`web/models/page`, params),
  detailModel: (params) => client.getParams(`web/models/detail`, params),

  getSeriesSelect: () => client.get(`web/series/select`),
  getSeries: (params) => client.getParams(`web/series/page`, params),
  detailSeries: (params) => client.getParams(`web/series/detail`, params),

  getClientSelect: () => client.get(`web/client/model/select`),
  getClient: (params) => client.getParams(`web/client/model/page`, params),
  detailClient: (params) => client.getParams(`web/client/model/detail`, params),

  getTypes: (params) => client.getParams(`web/product/page`, params),
  detailTypes: (params) => client.getParams(`web/product/detail`, params),
  detailTypesV1: (params) => client.getParams(`web/products/detail`, params),

  // 物模型
  tslFunction: (params) =>
    client.getParams(`web/product/tsl/function/list`, params),

  // 设备
  selectEqAllProductType: () => client.get(`web/product/select/all`),

  fetchEqNew: (params) => client.getParams(`web/device/page`, params),
  createEqNew: (params) => client.post(`web/device/add`, params),
  detailEqNew: (params) => client.getParams(`web/device/detail`, params),
  deleteEqNew: (params) => client.getParams(`web/device/delete`, params),
  concatEqNew: (params) => client.getParams(`web/device/user/list`, params),
  selectEqNewProductType: () => client.get(`web/product/select`),

  fetchEqOld: (params) => client.getParams(`web/orders/page`, params),
  editEqOld: (params) => client.post(`web/orders/update`, params),
  detailEqOld: (params) => client.getParams(`web/orders/detail`, params),
  deleteEqOld: (params) => client.getParams(`web/orders/delete`, params),
  concatEqOld: (params) => client.getParams(`web/orders/user/list`, params),
  unbindEqOld: (params) => client.getParams(`web/orders/unbind`, params), // unbind
  selectEqOldProductBrand: () => client.get(`web/product/select`), // 产品品牌
  selectEqOldProductCategory: () => client.get(`web/product/select`), // Category
  selectEqOldProductModel: () => client.get(`web/product/select`), // Model
  selectEqOldProductType: () => client.get(`web/products/select`), // 产品型号
  selectSupplier: () => client.get(`web/supplier/select`), // 厂商下拉列表

  // 厂商管理
  fetchSupplier: (params) => client.getParams(`web/supplier/page`, params),
  createSupplier: (params) => client.post(`web/supplier/add`, params),
  editSupplier: (params) => client.post(`web/supplier/update`, params),
  deleteSupplier: (params) => client.getParams(`web/supplier/delete`, params),

  /**
   * new
   * @getModelMenu 查询产品类型列表和对应的系列
   * @exportEqNew 设备列表 - 导出
   * @exportEqNewA 关联设备列表 - 导出
   * @exportEqNewEQ 上下线记录 - 导出
   * @exportEqNewDR 设备上报日志 - 导出
   * @exportEqNewDO 设备操作日志 - 导出
   * @returns
   */
  getModelMenu: () => client.get(`web/categories/relation/model/list`),
  getProductTsl: (params) =>
    client.getParams(`web/product/tsl/property/list`, params),
  exportEqNew: (params) => client.downloadParams("web/device/export", params),
  exportEqNewA: (params) => client.downloadParams("web/device/export", params),
  exportEqNewEQ: (params) => client.downloadParams("web/device/export", params),
  exportEqNewDR: (params) =>
    client.downloadParams("web/device/log/up/export", params),
  exportEqNewDO: (params) =>
    client.downloadParams("web/device/log/user/opt/export", params),
  /**
   * old
   * @exportEqOld 设备列表 - 导出
   * @exportEqOldError 设备列表 - 导出 - 异常结果导出
   * @returns
   */
  exportEqOld: () => client.download("web/orders/import/template"),
  exportEqOldList: (params) =>
    client.downloadParams("web/orders/export/list", params),
  exportEqOldError: (params) =>
    client.downloadPost("web/orders/export", params),
  unbindManageUsers: (params) => client.getParams("web/device/unbind", params),

  // ManageUsers 导出异常信息
  exportManageUsersError: (params) =>
    client.downloadPost("web/app/user/export/error", params),

  // 获取 设备管理/详情 list
  getEqNewTslSelect: (params) =>
    client.getParams(`web/device/tsl/property/list`, params),
  getEqNewAssociatedSelect: (params) =>
    client.getParams(`web/device/relation/list`, params),
  getEqNewOnlineRecordSelect: (params) =>
    client.getParams(`web/device/log/online/page`, params),
  getEqNewDeviceReportSelect: (params) =>
    client.getParams(`web/device/log/up/page`, params),
  getEqNewDeviceOperationSelect: (params) =>
    client.getParams(`web/device/log/user/opt/page`, params),
  getEqNewSeriesChangeSelect: (params) =>
    client.getParams(`web/series/change/his/page`, params),
  //
  getEqOldChargeSelect: (params) =>
    client.getParams(`web/device/log/charge/record/page`, params),
  getEqOldCutGrassSelect: (params) =>
    client.getParams(`web/device/log/cutting/record/page`, params),
  getEqOldFaultSelect: (params) =>
    client.getParams(`web/device/log/fault/record/page`, params),
  //
  getEqNewOnlineRecordSelectOld: (params) =>
    client.getParams(`web/brands/page`, params),
  getEqNewDeviceReportSelectOld: (params) =>
    client.getParams(`web/brands/select`, params),

  //**********  systemManage---Menu **************
  getAllMenuList: () => client.get("/web/menu/tree"), //树形结构
  getMenuList: (params) => client.post("/web/menu/page", params),
  getSubMenuList: (params) => client.get(`/web/menu/children?id=${params.id}`),
  addMenuList: (params) => client.post("/web/menu/add", params),
  editMenuList: (params) => client.post("/web/menu/update", params),
  delMenuList: (params) => client.get(`/web/menu/del?id=${params.id}`),

  //**********  systemManage---role **************
  addRoleList: (params) => client.post("/web/role/add", params),
  editRoleList: (params) => client.post("/web/role/update", params),
  getRoleList: (params) => client.post("/web/role/page", params),
  getRoleALLList: () => client.get("/web/role/select", {}),
  delRoleList: (params) => client.get(`/web/role/del?id=${params.id}`),

  //**********  systemManage---department **************
  addDepartmentList: (params) => client.post("/web/department/add", params),
  editDepartmentList: (params) => client.post("/web/department/update", params),
  getDepartmentList: (params) => client.post("/web/department/page", params),
  getDepartmentALLList: () => client.get("/web/department/select", {}),
  delDepartmentList: (params) =>
    client.get(`/web/department/del?id=${params.id}`),

  //**********  systemManage---customer **************
  addCustomerList: (params) => client.post("/web/customer/add", params),
  editCustomerList: (params) => client.post("/web/customer/update", params),
  getCustomerList: (params) => client.post("/web/customer/page", params),
  getCustomerALLList: () => client.get("/web/customer/select", {}),
  delCustomerList: (params) => client.get(`/web/customer/del?id=${params.id}`),

  //**********  systemManage---user **************
  addUserList: (params) => client.post("/web/account/add", params),
  editUserList: (params) => client.post("/web/account/update", params),
  getUserList: (params) => client.post("/web/account/page", params),
  delUserList: (params) => client.get(`/web/account/del?id=${params.id}`),
  resetPwdUserList: (params) =>
    client.post("/web/account/reset/password", params),
  getDepartmentSelect: () => client.get("/web/department/select"),

  //**********  login--- **************
  signInDashboard: (params) => client.post("/web/account/login", params),

  //**********--- ALL Brands--- **************be used in create sysuser(redux)
  getBrandsALLList: () => client.get("/web/brands/select", {}),

  //**********--- ALL categories--- **************be used in create sysuser(redux)
  getCategoriesALLList: () => client.get("/web/categories/select", {}),

  //**********--- ALL Product--- **************be used in create sysuser(redux)
  getProductALLList: (params) => client.post("/web/product/list", params),

  // *** 开放接口管理 *** //
  open_api_page: (params) =>
    client.getParams(`web/open/api/account/page`, params),
  open_api_add: (params) => client.post(`web/open/api/account/add`, params),
  open_api_edit: (params) => client.post(`web/open/api/account/edit`, params),
  open_api_detail: (params) =>
    client.getParams(`web/open/api/account/detail`, params),
  open_api_reset_key: (params) =>
    client.getParams(`web/open/api/account/reset/secret`, params),
  open_api_delete: (params) =>
    client.getParams(`web/open/api/account/delete`, params),
  open_api_save_ip: (params) =>
    client.post(`web/open/api/account/save/ip`, params),
  open_api_save_access: (params) =>
    client.post(`web/open/api/account/save/access`, params),
  open_api_api_list: (params) =>
    client.getParams(`web/open/api/account/api/list`, params),

  // product
  distribution_export: (params) =>
    client.downloadParams(`web/device/analysis/distribution/export`, params),
  // product
  PU_product: () => client.getParams(`web/product/select/all`),
  // country
  PU_country: () => client.getParams(`web/base/analysis/country/select`),
  // ProductStatistics
  P_communication: () =>
    client.getParams(`web/device/analysis/communication/type`),
  P_newly_added: (params) =>
    client.getParams(`web/device/analysis/newly/added`, params),
  P_activation: (params) =>
    client.getParams(`web/device/analysis/activation`, params),
  P_distribution: (params) =>
    client.getParams(`web/device/analysis/distribution`, params),
  P_realtime_online: () =>
    client.getParams(`web/device/analysis/realtime/online`),
  P_version_division: (params) =>
    client.getParams(`web/device/analysis/version/division`, params),
  P_product_usage: (params) =>
    client.getParams(`web/device/analysis/product/usage`, params),
  P_product_usage2: (params) =>
    client.getParams(`web/device/analysis/realtime/online`, params),
  P_fault: (params) => client.getParams(`web/device/analysis/fault`, params),
  // StatisticsUser
  U_terminal_type: () => client.getParams(`web/user/analysis/terminal/type`),
  U_newly_added: (params) =>
    client.getParams(`web/user/analysis/newly/added`, params),
  U_realtime_online: () =>
    client.getParams(`web/user/analysis/realtime/online`),
  U_version_division: () =>
    client.getParams(`web/user/analysis/version/division`),
  U_activation: (params) =>
    client.getParams(`web/user/analysis/activation`, params),
  U_group_distribution: () =>
    client.getParams(`web/user/analysis/group/distribution`),
};

export default CloudHawkApi;
