import brandActions from "./brandActions";

const INITIAL_DATA = {
  brand: [],
  loading: false,
  error: null,
};
export default function brandReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case brandActions.GET_BRAND_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case brandActions.GET_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload,
        loading: false,
        error: null,
      };
    case brandActions.GET_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
