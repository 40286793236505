import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import feedbackActions from "@iso/redux/feedback/actions";
import Input from "../uielements/input";
import Modal from "@iso/components/Feedback/Modal";
import { getDateFormatted } from "@iso/lib/helpers/dateFormat";
import { Fieldset, Form, Label } from "../../containers/Chat/Messages.styles";
import Tooltip from "@iso/components/uielements/tooltip";
import { limitText } from "@iso/lib/helpers/limitText";
import { Empty } from "antd";

export default function (props) {
	const divRef = useRef(null);

	const userFromName = props.object.fromUser.displayName;
	const userProfile = useSelector((state) => state.profile.profile);

	const dispatch = useDispatch();

	const [userfeedback, replyFeedback] = useState({
		value: props.values,
		visible: false,
		data: props.object,
		answer: "",
		queryId: "",
		fromUserId: "",
	});

	const { value, data, visible, answer, queryId, fromUserId } = userfeedback;

	const userPic =
		"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg";

	const handleChange = (event) => {
		const value = event.target.value;
		replyFeedback({ ...userfeedback, answer: value });
	};

	const showModal = () => {
		replyFeedback({
			...userfeedback,
			visible: true,
			value: props.values,
			queryId: queryId,
			fromUserId: fromUserId,
		});
	};

	const handleCancel = () => {
		replyFeedback({ ...userfeedback, visible: false, value: props.values });
	};

	const handleOk = () => {
		const replyData = {
			params: {
				answer: answer,
				qid: queryId,
				toId: fromUserId,
			},
			data: {
				feedback: data,
			},
		};
		const changeStatus = {
			type: 2,
			id: queryId,
		};

		let feedbackReply = {
			answer: answer,
			queryCreatedAt: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
			userId: userProfile && userProfile._id,
		};
		value.push(feedbackReply);

		dispatch(feedbackActions.replyfeedDataRequest(replyData));
		!!data.isOpen &&
			dispatch(feedbackActions.changeFeedbackStatusRequest(changeStatus));
		const obj = {
			type: 1,
		};
		setTimeout(() => {
			dispatch(feedbackActions.fetchfeedbackDataRequest(obj));
		}, 3000);
		replyFeedback({ ...userfeedback, visible: true, value: props.values });

		if (divRef.current) {
			divRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const alignSelfRight = {
		float: "right",
		margin: 20,
		width: "100%",
	};

	const imageContainerRight = {
		float: "right",
	};

	const imageStyleRight = {
		width: 42,
		height: 42,
		borderRadius: 100,
	};

	const timeStyleRight = {
		textAlign: "right",
		fontSize: 10,
		marginTop: 2,
	};

	const messageStyleRight = {
		backgroundColor: "#54b967",
		borderRadius: "12px",
		fontSize: "14px",
		padding: "10px",
		color: "#fff",
		textAlign: "center",
		maxWidth: "150px",
		float: "right",
	};

	const alignSelfLeft = {
		float: "left",
		margin: 20,
		width: "100%",
	};

	const imageContainerLeft = {
		float: "left",
	};

	const imageStyleLeft = {
		width: 42,
		height: 42,
		borderRadius: 100,
	};

	const timeStyleLeft = {
		marginTop: 30,
		textAlign: "left",
	};

	const messageStyleLeft = {
		backgroundColor: "#54b967",
		borderRadius: "12px",
		fontSize: "14px",
		padding: "10px",
		color: "#fff",
		textAlign: "center",
		maxWidth: "150px",
		float: "left",
	};

	const pointerStyle = {
		cursor: "pointer",
	};

	const marginTop = {
		marginTop: "20px",
	};

	const feebackStyle = {
		margin: "0 0 20px 0",
		border: "1px solid  #ebecf1",
		height: "500px",
		overflow: "auto",
	};

	const feebackh4Style = {
		padding: "10px",
		border: "1px solid  #ebecf1",
		background: "#ebecf1",
	};

	return (
		<div>
			{value.length === 0 ? (
				data.question.length > 21 ? (
					<Tooltip title={data.question}>
						<p
							style={pointerStyle}
							onClick={() => showModal()}
						>
							{limitText(data.question, 21)}
						</p>
					</Tooltip>
				) : (
					<p
						style={pointerStyle}
						onClick={() => showModal()}
					>
						{data.question}
					</p>
				)
			) : value[value.length - 1].answer.length > 21 ? (
				<Tooltip title={value[value.length - 1].answer}>
					<p
						style={pointerStyle}
						onClick={() => showModal()}
					>
						{data.latestAnswer}
					</p>
				</Tooltip>
			) : (
				<p
					style={pointerStyle}
					onClick={() => showModal()}
				>
					{data.latestAnswer}
				</p>
			)}
			<Modal
				visible={visible}
				onClose={handleCancel}
				title={data.productId ? data.productId.productName : ""}
				okText="submit"
				cancelText="close"
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<div style={feebackStyle}>
					<ul>
						<h4 style={feebackh4Style}> {data.question} </h4>
						{!!value && value.length > 0 ? (
							value.map((data, index) => (
								<li key={index}>
									{userProfile && userProfile._id === data.userId ? (
										<div style={alignSelfLeft}>
											<div
												ref={divRef}
												style={imageContainerLeft}
											>
												<img
													onDragStart={(e) => {
														if (e && e.preventDefault) {
															e.preventDefault();
														} else {
															window.event.returnValue = false;
														}
													}}
													style={imageStyleLeft}
													alt="user"
													src={userPic}
												/>
											</div>
											<div style={timeStyleLeft}>
												{getDateFormatted(new Date(data.queryCreatedAt))}
											</div>
											<div style={messageStyleLeft}>{data.answer}</div>
										</div>
									) : (
										<div style={alignSelfRight}>
											<div
												ref={divRef}
												style={imageContainerRight}
											>
												<img
													onDragStart={(e) => {
														if (e && e.preventDefault) {
															e.preventDefault();
														} else {
															window.event.returnValue = false;
														}
													}}
													style={imageStyleRight}
													alt="user"
													src={userPic}
												/>
											</div>
											<div style={timeStyleRight}>
												{getDateFormatted(new Date(data.queryCreatedAt))} <br />
											</div>
											<div style={timeStyleRight}>{userFromName}</div>
											<div style={messageStyleRight}>{data.answer}</div>
										</div>
									)}
								</li>
							))
						) : (
							<div style={marginTop}>
								<Empty />
							</div>
						)}
					</ul>
				</div>
				<Form>
					<Fieldset>
						<Label>Reply</Label>
						<Input
							allowClear={true}
							label="Name"
							placeholder="your answer"
							onChange={handleChange}
						/>
					</Fieldset>
				</Form>
			</Modal>
		</div>
	);
}
