import Enlang from './entries/en-US';
import Zhlang from './entries/zh-Hans-CN';
import Delang from './entries/de_DE';
import { addLocaleData } from 'react-intl';

const AppLocale = {
  en: Enlang,
  zh: Zhlang,
  de: Delang,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.de.data);

export default AppLocale;