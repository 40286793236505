import axios from "axios";
import { encrypt, decrypt } from "./dataSecure";
const API_ROOT = process.env.URL || "http://localhost:3000/";
const TIMEOUT = 200 * 1000;

const HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "x-api-key": process.env.REACT_APP_API_KEY,
  // env: "pro",
};
const Authorization = localStorage.getItem("id_token");

class ApiService {
  constructor({
    baseURL = API_ROOT,
    timeout = TIMEOUT,
    headers = HEADERS,
    auth,
  }) {
    const client = axios.create({
      baseURL,
      timeout,
      headers,
      auth,
    });
    //请求拦截器
    client.interceptors.request.use(
      function (config) {
        const language =
          localStorage.getItem("lang") === "english"
            ? "en"
            : localStorage.getItem("lang") === "german"
            ? "de"
            : "zh";
        const AcceptLanguage =
          localStorage.getItem("lang") === "english"
            ? "en-US,en;q=0.9,om;q=0.8"
            : localStorage.getItem("lang") === "german"
            ? "de-DE,de;q=0.9,om;q=0.8"
            : "zh-CN,zh;q=0.9,om;q=0.8";

        if (Authorization) {
          config.headers.Authorization = Authorization;
        }
        config.headers["Accept-Language"] = AcceptLanguage;
        config.headers["language"] = language;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );

    // 响应拦截器
    client.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // 如果发生了错误，判断是否是401
        if (error.response.status === 401) {
          // 开始处理
          // console.log("响应拦截器-错误-401", error);
          // message.error("The token has expired, please log in again!")
          localStorage.setItem("id_token", "");
          setTimeout(() => {
            window.location.href = "/signin";
          }, 0);
          return Promise.reject(error);
        }
      },
    );

    this.client = client;
  }

  handleSuccess(response) {
    return response;
  }

  handleError(error) {
    return Promise.reject(error);
  }

  get(path) {
    return this.client.get(path).then((response) => {
      return response.data.encryptedData && response.data.iv
        ? decrypt(response.data)
        : response.data;
    });
  }

  getParams(path, payload) {
    if (payload && payload.startTime) {
      payload.startTimestamp = new Date(payload.startTime).getTime();
    }
    if (payload && payload.endTime) {
      payload.endTimestamp = new Date(payload.endTime).getTime();
    }
    let _url = path;
    if (payload) {
      _url += "?";
      for (const key in payload) {
        let _data =
          payload[key] !== undefined && payload[key] !== null
            ? `${payload[key]}`
            : "";
        _data = _data.replace(/(^\s*)|(\s*$)/g, "");
        _data = encodeURIComponent(_data);
        _url += `${key}=${_data}&`;
      }
      _url = _url.replace(/&$/g, "");
    }

    return this.client.get(_url).then((response) => {
      return response.data.encryptedData && response.data.iv
        ? decrypt(response.data)
        : response.data;
    });
  }

  post(path, payload) {
    if (payload && payload.startTime) {
      payload.startTimestamp = new Date(payload.startTime).getTime();
    }
    if (payload && payload.endTime) {
      payload.endTimestamp = new Date(payload.endTime).getTime();
    }
    return this.client
      .post(path, encrypt(JSON.stringify(payload)))
      .then((response) => {
        return response.data.encryptedData && response.data.iv
          ? decrypt(response.data)
          : response.data;
      });
  }

  imgPost(path, payload) {
    if (payload && payload.startTime) {
      payload.startTimestamp = new Date(payload.startTime).getTime();
    }
    if (payload && payload.endTime) {
      payload.endTimestamp = new Date(payload.endTime).getTime();
    }
    return this.client.post(path, payload).then((response) => {
      return response.data.encryptedData && response.data.iv
        ? decrypt(response.data)
        : response.data;
    });
  }

  put(path, payload) {
    if (payload && payload.startTime) {
      payload.startTimestamp = new Date(payload.startTime).getTime();
    }
    if (payload && payload.endTime) {
      payload.endTimestamp = new Date(payload.endTime).getTime();
    }
    return this.client
      .put(path, encrypt(JSON.stringify(payload)))
      .then((response) => {
        return response.data.encryptedData && response.data.iv
          ? decrypt(response.data)
          : response.data;
      });
  }

  patch(path, payload) {
    if (payload && payload.startTime) {
      payload.startTimestamp = new Date(payload.startTime).getTime();
    }
    if (payload && payload.endTime) {
      payload.endTimestamp = new Date(payload.endTime).getTime();
    }
    return this.client
      .patch(path, encrypt(JSON.stringify(payload)))
      .then((response) => {
        return response.data.encryptedData && response.data.iv
          ? decrypt(response.data)
          : response.data;
      });
  }

  delete(path) {
    return this.client.delete(path).then((response) => {
      return response.data.encryptedData && response.data.iv
        ? decrypt(response.data)
        : response.data;
    });
  }

  download(path) {
    return this.client
      .get(path, {
        responseType: "blob",
      })
      .then((response) => response);
  }

  downloadPost(path, payload) {
    if (payload && payload.startTime) {
      payload.startTimestamp = new Date(payload.startTime).getTime();
    }
    if (payload && payload.endTime) {
      payload.endTimestamp = new Date(payload.endTime).getTime();
    }
    return this.client
      .post(path, encrypt(JSON.stringify(payload)), {
        responseType: "blob",
      })
      .then((response) => response);
  }

  downloadParams(path, payload) {
    if (payload && payload.startTime) {
      payload.startTimestamp = new Date(payload.startTime).getTime();
    }
    if (payload && payload.endTime) {
      payload.endTimestamp = new Date(payload.endTime).getTime();
    }
    let _url = path + "?";
    for (const key in payload) {
      let _data = payload[key] ? `${payload[key]}` : "";
      _data = _data.replace(/(^\s*)|(\s*$)/g, "");
      _data = encodeURIComponent(_data);
      _url += `${key}=${_data}&`;
    }
    _url = _url.replace(/&$/g, "");

    return this.client
      .get(_url, {
        responseType: "blob",
      })
      .then((response) => response);
  }
  downloadParamsPost(path, payload) {
    if (payload && payload.startTime) {
      payload.startTimestamp = new Date(payload.startTime).getTime();
    }
    if (payload && payload.endTime) {
      payload.endTimestamp = new Date(payload.endTime).getTime();
    }
    return this.client
      .post(path, encrypt(JSON.stringify(payload)), {
        responseType: "blob",
      })
      .then((response) => response);
  }
}

export default ApiService;
