import { call, all, takeEvery, put } from "redux-saga/effects";
import countryActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";

function* getCountryRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getAppCountries, payload);
    yield put(countryActions.getCountrySuccess(response.data));
  } catch (error) {
    yield put(countryActions.getCountryFailure(error));
  }
}

function* getCountryByIdRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getCountryById, payload);
    yield put(countryActions.getCountryByIdSuccess(response.data));
  } catch (error) {
    yield put(countryActions.getCountryFailure(error));
  }
}

export default function* countrySaga() {
  yield all([
    takeEvery(countryActions.GET_COUNTRY_REQUEST, getCountryRequest),
    takeEvery(countryActions.GET_COUNTRY_BY_ID_REQUEST, getCountryByIdRequest),
  ]);
}
