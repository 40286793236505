import { call, all, takeEvery, put } from "redux-saga/effects";
import userStatisticsActions from "./action";
import CloudHawkApi from "@iso/config/Services/CloudHawk";

function* getUserStatisticsRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getUserStatistics, payload);
    yield put(userStatisticsActions.getUserStatisticsSuccess(response.data));
  } catch (error) {
    yield put(userStatisticsActions.getUserStatisticsFailure(error));
  }
}

export default function* userStatisticsSaga() {
  yield all([
    takeEvery(
      userStatisticsActions.GET_USER_STATISTICS_REQUEST,
      getUserStatisticsRequest,
    ),
  ]);
}
