import { call, all, takeEvery, put, select } from "redux-saga/effects";
import SysUserActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk2";
import CloudHawkApi3 from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import authAction from "@iso/redux/auth/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg || "success",
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

function* getProductALLList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getProductALLList, payload);
    yield put(SysUserActions.fetchGETProductALLListSuccess(response.data));
    if (!response.success) {
      Error(response);
    }
  } catch (error) {
    yield put(SysUserActions.fetchGETProductALLListError(error));
  }
}

function* getCategoriesALLList() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getCategoriesALLList);
    yield put(SysUserActions.fetchGETCategoriesALLListSuccess(response.data));
  } catch (error) {
    yield put(SysUserActions.fetchGETCategoriesALLListError(error));
  }
}
function* fetchGETDepartmentRequest() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getDepartmentSelect);
    yield put(SysUserActions.fetchGETDepartmentSuccess(response.data));
  } catch (error) {
    yield put(SysUserActions.fetchGETDepartmentError(error));
  }
}

function* getBrandsALLList() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getBrandsALLList);
    yield put(SysUserActions.fetchGETBrandsALLListSuccess(response.data));
  } catch (error) {
    yield put(SysUserActions.fetchGETBrandsALLListError(error));
  }
}

function* getUserList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getUserList, payload);
    yield put(SysUserActions.fetchGETUserListSuccess(response.data));
  } catch (error) {
    yield put(SysUserActions.fetchGETUserListError(error));
  }
}

function* addUserList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addUserList, payload);
    if (response.success) {
      alert(response);
      yield put(SysUserActions.fetchADDUserListSuccess(response.data));
    } else {
      Error(response);
      yield put(SysUserActions.fetchADDUserListError(response));
    }
  } catch (error) {
    // yield put(SysUserActions.fetchGETUserListError(error));
    yield put(SysUserActions.fetchADDUserListError(error));
  }
  // finally {
  // 	const params = {
  // 		pageSize: payload.pageSize,
  // 		pageNum: 1,
  // 	}
  // 	yield put(SysUserActions.fetchGETUserList(params));
  // }
}

function* editUserList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editUserList, payload);
    if (response.success) {
      alert(response);
      yield put(SysUserActions.fetchEDITUserListSuccess(response.data));
    } else {
      Error(response);
      yield put(SysUserActions.fetchEDITUserListError(response));
    }
  } catch (error) {
    // yield put(SysUserActions.fetchGETUserListError(error));
    yield put(SysUserActions.fetchEDITUserListError(error));
  }
  // finally {
  // 	const params = {
  // 		pageNum: payload.pageNum,
  // 		account: payload.searchname,
  // 		state: payload.searchstate,
  // 		pageSize: payload.pageSize
  // 	}

  // 	yield put(SysUserActions.fetchGETUserList(params));
  // }
}

function* editMyProfile(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.editMyProfile, payload);
    if (response.success) {
      localStorage.setItem("tokenUser", JSON.stringify(response.data));
      localStorage.setItem("profilePic", response.data.profilePic);
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {}
}

//个人中心页面-重置密码成功
function* personalResetPWD(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.personalResetPwd, payload);
    if (response.success) {
      response.msg = "reset password success!";
      alert(response);
      //登出
      yield put(authAction.logout());
    } else {
      Error(response);
    }
  } catch (error) {}
}

//系统用户管理页面-重置密码成功
function* resetPWDUserList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.resetPwdUserList, payload.params1);
    if (response.success) {
      // response.msg = "reset password success!"
      alert(response);
      if (payload.id === localStorage.getItem("Id")) {
        //登出
        yield put(authAction.logout());
      }
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(SysUserActions.fetchGETUserList(payload.params2));
  }
}

function* delUserList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.delUserList, payload);
    if (response.success) {
      const total = yield select((state) => state.SysUser.totalList);
      if (payload.pageNum > Math.ceil((total - 1) / payload.pageSize)) {
        //last page
        const params = {
          account: payload.account,
          pageNum: payload.pageNum - 1,
          pageSize: payload.pageSize,
          state: payload.state,
          departmentId: payload.departmentId,
        };
        yield put(SysUserActions.fetchGETUserList(params));
      } else {
        //this page
        const params = {
          account: payload.account,
          pageNum: payload.pageNum,
          state: payload.state,
          pageSize: payload.pageSize,
          departmentId: payload.departmentId,
        };
        yield put(SysUserActions.fetchGETUserList(params));
      }

      alert(response);
    } else {
      Error(response);
      const params = {
        account: payload.account,
        pageNum: payload.pageNum,
        state: payload.state,
        pageSize: payload.pageSize,
        departmentId: payload.departmentId,
      };
      yield put(SysUserActions.fetchGETUserList(params));
    }
  } catch (error) {}
}
export default function* userSaga() {
  yield all([
    takeEvery(SysUserActions.GET_User_List_REQUEST, getUserList),
    takeEvery(SysUserActions.ADD_User_List_REQUEST, addUserList),
    takeEvery(SysUserActions.EDIT_User_List_REQUEST, editUserList),
    takeEvery(SysUserActions.ResetPWD_User_List_REQUEST, resetPWDUserList),
    takeEvery(SysUserActions.Personal_ResetPWD_REQUEST, personalResetPWD),
    takeEvery(SysUserActions.DEL_User_List_REQUEST, delUserList),
    takeEvery(
      SysUserActions.GET_CategoriesALL_List_REQUEST,
      getCategoriesALLList,
    ),
    takeEvery(SysUserActions.GET_BrandsALL_List_REQUEST, getBrandsALLList),
    takeEvery(SysUserActions.GET_ProductALL_List_REQUEST, getProductALLList),
    takeEvery(SysUserActions.Fetch_Edit_MyProfile_REQUEST, editMyProfile),
    takeEvery(
      SysUserActions.GET_Department_SELECT_REQUEST,
      fetchGETDepartmentRequest,
    ),
  ]);
}
