function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
  const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const contactActions = {
  GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_ERROR: "GET_CONTACT_ERROR",
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",

  // Add ProfilePic //
  ADD_PROFILEPIC_REQUEST: "ADD_PROFILEPIC_REQUEST",
  ADD_PROFILEPIC_SUCCESS: "ADD_PROFILEPIC_SUCCESS",
  ADD_PROFILEPIC_ERROR: "ADD_PROFILEPIC_ERROR",
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",

  updateProfileRequest: (response, obj) => ({
    type: contactActions.UPDATE_PROFILE_REQUEST,
    payload: response,
    obj,
  }),

  addProfilepicRequest: () => ({
    type: contactActions.ADD_PROFILEPIC_REQUEST,
  }),

  addProfilepicSuccess: (response) => ({
    type: contactActions.ADD_PROFILEPIC_SUCCESS,
    payload: response,
  }),

  addProfilepicFailure: (error) => ({
    type: contactActions.ADD_PROFILEPIC_ERROR,
    message: error,
  }),

  // Add Contact //
  ADD_CONTACT: "ADD_CONTACT",
  ADD_CONTACT_REQUEST: "ADD_CONTACT_REQUEST",
  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  ADD_CONTACT_ERROR: "ADD_CONTACT_ERROR",

  EDIT_CONTACT: "EDIT_CONTACT",
  DELETE_CONTACT_REQUEST: "DELETE_CONTACT_REQUEST",
  CHANGE_CONTACT: "CHANGE_CONTACT",
  EDIT_VIEW: "EDIT_VIEW",

  getContactRequest: () => ({
    type: contactActions.GET_CONTACT_REQUEST,
  }),

  getContactSuccess: (response) => ({
    type: contactActions.GET_CONTACT_SUCCESS,
    payload: response,
  }),

  getContactFailure: (error) => ({
    type: contactActions.GET_CONTACT_ERROR,
    message: error,
  }),
  getUserRequest: (response) => ({
    type: contactActions.GET_USER_REQUEST,
    payload: response,
  }),
  getUserSuccess: (response) => ({
    type: contactActions.GET_USER_SUCCESS,
    payload: response,
  }),

  changeContact: (id) => ({
    type: contactActions.CHANGE_CONTACT,
    id,
  }),
  addContact: () => {
    return (dispatch) => {
      dispatch({
        type: contactActions.ADD_CONTACT,
      });
    };
  },

  addContactRequest: (request) => ({
    type: contactActions.ADD_CONTACT_REQUEST,
    payload: request,
  }),

  addContactSuccess: (response) => ({
    type: contactActions.ADD_CONTACT_SUCCESS,
    payload: response,
  }),

  addContactFailure: (error) => ({
    type: contactActions.ADD_CONTACT_ERROR,
    message: error,
  }),

  editContact: (newContact) => {
    return (dispatch, getState) => {
      const contacts = getState().Contacts.contacts;
      const newContacts = [];
      contacts.forEach((contact) => {
        if (contact.id === newContact.id) {
          newContacts.push(newContact);
        } else {
          newContacts.push(contact);
        }
      });
      dispatch({
        type: contactActions.EDIT_CONTACT,
        contacts: newContacts.sort(ascendingSort),
      });
    };
  },
  deleteContact: (response) => ({
    type: contactActions.DELETE_CONTACT_REQUEST,
    payload: response,
  }),
};
export default contactActions;
