import EditProductTemplateActions from "./actions";

const INITIAL_DATA = {
  editStatus: "0",

  serviceList: [],
  serviceListState: 0,
  propertyList: [],
  propertyListState: 0,
  dataALL: [],
  languageList: [],
  totalLanguage: 0,
  data: null,
  secondaryList: [],
  // totalList:0,
  loading: true,
  error: null,
  reset: false,
};
export default function EditProductTemplateReducer(
  state = INITIAL_DATA,
  action,
) {
  switch (action.type) {
    case EditProductTemplateActions.POST_SaveSettingsSecTemplate_REQUEST:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case EditProductTemplateActions.POST_SaveSettingsSecTemplate_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        loading: !true,
        error: null,
      };
    case EditProductTemplateActions.POST_SaveSettingsSecTemplate_ERROR:
      return {
        ...state,
        editStatus: "3",
        loading: !true,
        error: null,
      };

    case EditProductTemplateActions.GET_SecondarySelect_List_REQUEST:
      return {
        ...state,
        secondaryList: [],
        loading: true,
        error: null,
      };
    case EditProductTemplateActions.GET_SecondarySelect_List_SUCCESS:
      return {
        ...state,
        secondaryList: action.payload,
        loading: false,
        error: null,
      };
    case EditProductTemplateActions.GET_SecondarySelect_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EditProductTemplateActions.GET_Params_List_REQUEST:
      return {
        ...state,
        propertyList: [],
        propertyListState: 0,
        loading: true,
        error: null,
      };
    case EditProductTemplateActions.GET_Params_List_SUCCESS:
      let newArr = JSON.parse(JSON.stringify(action.payload)); //数组是引用类型, 深拷贝一下
      // newArr.forEach((e) => {
      // 	delete e.accessMode;
      // 	delete e.dataType;
      // 	delete e.desc;
      // 	delete e.lengthProperty;
      // 	delete e.nameLanguage;
      // 	delete e.required;
      // 	delete e.specs;
      // 	delete e.tslVersionId;
      // });

      return {
        ...state,
        propertyList: newArr,
        propertyListState: 1,
        loading: false,
        error: null,
      };
    case EditProductTemplateActions.GET_Params_List_ERROR:
      return {
        ...state,
        loading: false,
        propertyListState: 2,
        error: action.payload,
      };

    case EditProductTemplateActions.GET_Service_List_REQUEST:
      return {
        ...state,
        serviceList: [],
        serviceListState: 0,
        loading: true,
        error: null,
      };
    case EditProductTemplateActions.GET_Service_List_SUCCESS:
      return {
        ...state,
        serviceList: action.payload,
        serviceListState: 1,
        loading: false,
        error: null,
      };
    case EditProductTemplateActions.GET_Service_List_ERROR:
      return {
        ...state,
        serviceListState: 2,
        loading: false,
        error: action.payload,
      };

    case EditProductTemplateActions.GET_EditProductTemplate_List_REQUEST:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case EditProductTemplateActions.GET_EditProductTemplate_List_SUCCESS:
      // let data = Object.assign([], action.payload);
      // data.forEach((element, index) => {
      //   element.key = index;
      // });
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case EditProductTemplateActions.GET_EditProductTemplate_List_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };

    case EditProductTemplateActions.EDIT_EditProductTemplate_List_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EditProductTemplateActions.EDIT_EditProductTemplate_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EditProductTemplateActions.EDIT_EditProductTemplate_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
