// 开放接口管理
const actions = {
  // 分页查询列表
  OPEN_API_PAGE_REQUEST: "OPEN_API_PAGE_REQUEST",
  OPEN_API_PAGE_SUCCESS: "OPEN_API_PAGE_SUCCESS",
  OPEN_API_PAGE_FAILURE: "OPEN_API_PAGE_FAILURE",
  open_api_page_request: (request) => ({
    type: actions.OPEN_API_PAGE_REQUEST,
    payload: request,
  }),
  open_api_page_success: (product) => ({
    type: actions.OPEN_API_PAGE_SUCCESS,
    payload: product,
  }),
  open_api_page_failure: (error) => ({
    type: actions.OPEN_API_PAGE_FAILURE,
    payload: error,
  }),

  // 添加
  OPEN_API_ADD_REQUEST: "OPEN_API_ADD_REQUEST",
  OPEN_API_ADD_SUCCESS: "OPEN_API_ADD_SUCCESS",
  OPEN_API_ADD_FAILURE: "OPEN_API_ADD_FAILURE",
  open_api_add_request: (request) => ({
    type: actions.OPEN_API_ADD_REQUEST,
    payload: request,
  }),
  open_api_add_success: (product) => ({
    type: actions.OPEN_API_ADD_SUCCESS,
    payload: product,
  }),
  open_api_add_failure: (error) => ({
    type: actions.OPEN_API_ADD_FAILURE,
    payload: error,
  }),

  // 编辑
  OPEN_API_EDIT_REQUEST: "OPEN_API_EDIT_REQUEST",
  OPEN_API_EDIT_SUCCESS: "OPEN_API_EDIT_SUCCESS",
  OPEN_API_EDIT_FAILURE: "OPEN_API_EDIT_FAILURE",
  open_api_edit_request: (request) => ({
    type: actions.OPEN_API_EDIT_REQUEST,
    payload: request,
  }),
  open_api_edit_success: (product) => ({
    type: actions.OPEN_API_EDIT_SUCCESS,
    payload: product,
  }),
  open_api_edit_failure: (error) => ({
    type: actions.OPEN_API_EDIT_FAILURE,
    payload: error,
  }),

  // 详情
  OPEN_API_DETAIL_REQUEST: "OPEN_API_DETAIL_REQUEST",
  OPEN_API_DETAIL_SUCCESS: "OPEN_API_DETAIL_SUCCESS",
  OPEN_API_DETAIL_FAILURE: "OPEN_API_DETAIL_FAILURE",
  open_api_detail_request: (request) => ({
    type: actions.OPEN_API_DETAIL_REQUEST,
    payload: request,
  }),
  open_api_detail_success: (product) => ({
    type: actions.OPEN_API_DETAIL_SUCCESS,
    payload: product,
  }),
  open_api_detail_failure: (error) => ({
    type: actions.OPEN_API_DETAIL_FAILURE,
    payload: error,
  }),

  // 重置key
  OPEN_API_RESET_KEY_REQUEST: "OPEN_API_RESET_KEY_REQUEST",
  OPEN_API_RESET_KEY_SUCCESS: "OPEN_API_RESET_KEY_SUCCESS",
  OPEN_API_RESET_KEY_FAILURE: "OPEN_API_RESET_KEY_FAILURE",
  open_api_reset_key_request: (request) => ({
    type: actions.OPEN_API_RESET_KEY_REQUEST,
    payload: request,
  }),
  open_api_reset_key_success: (product) => ({
    type: actions.OPEN_API_RESET_KEY_SUCCESS,
    payload: product,
  }),
  open_api_reset_key_failure: (error) => ({
    type: actions.OPEN_API_RESET_KEY_FAILURE,
    payload: error,
  }),

  // 删除
  OPEN_API_DELETE_REQUEST: "OPEN_API_DELETE_REQUEST",
  OPEN_API_DELETE_SUCCESS: "OPEN_API_DELETE_SUCCESS",
  OPEN_API_DELETE_FAILURE: "OPEN_API_DELETE_FAILURE",
  open_api_delete_request: (request) => ({
    type: actions.OPEN_API_DELETE_REQUEST,
    payload: request,
  }),
  open_api_delete_success: (product) => ({
    type: actions.OPEN_API_DELETE_SUCCESS,
    payload: product,
  }),
  open_api_delete_failure: (error) => ({
    type: actions.OPEN_API_DELETE_FAILURE,
    payload: error,
  }),

  // 保存IP白名单
  OPEN_API_SAVE_IP_REQUEST: "OPEN_API_SAVE_IP_REQUEST",
  OPEN_API_SAVE_IP_SUCCESS: "OPEN_API_SAVE_IP_SUCCESS",
  OPEN_API_SAVE_IP_FAILURE: "OPEN_API_SAVE_IP_FAILURE",
  open_api_save_ip_request: (request) => ({
    type: actions.OPEN_API_SAVE_IP_REQUEST,
    payload: request,
  }),
  open_api_save_ip_success: (product) => ({
    type: actions.OPEN_API_SAVE_IP_SUCCESS,
    payload: product,
  }),
  open_api_save_ip_failure: (error) => ({
    type: actions.OPEN_API_SAVE_IP_FAILURE,
    payload: error,
  }),

  // 保存访问频率
  OPEN_API_SAVE_SECCESS_REQUEST: "OPEN_API_SAVE_SECCESS_REQUEST",
  OPEN_API_SAVE_SECCESS_SUCCESS: "OPEN_API_SAVE_SECCESS_SUCCESS",
  OPEN_API_SAVE_SECCESS_FAILURE: "OPEN_API_SAVE_SECCESS_FAILURE",
  open_api_save_access_request: (request) => ({
    type: actions.OPEN_API_SAVE_SECCESS_REQUEST,
    payload: request,
  }),
  open_api_save_access_success: (product) => ({
    type: actions.OPEN_API_SAVE_SECCESS_SUCCESS,
    payload: product,
  }),
  open_api_save_access_failure: (error) => ({
    type: actions.OPEN_API_SAVE_SECCESS_FAILURE,
    payload: error,
  }),

  // 接口选择列表
  OPEN_API_API_LIST_REQUEST: "OPEN_API_API_LIST_REQUEST",
  OPEN_API_API_LIST_SUCCESS: "OPEN_API_API_LIST_SUCCESS",
  OPEN_API_API_LIST_FAILURE: "OPEN_API_API_LIST_FAILURE",
  open_api_api_list_request: (request) => ({
    type: actions.OPEN_API_API_LIST_REQUEST,
    payload: request,
  }),
  open_api_api_list_success: (product) => ({
    type: actions.OPEN_API_API_LIST_SUCCESS,
    payload: product,
  }),
  open_api_api_list_failure: (error) => ({
    type: actions.OPEN_API_API_LIST_FAILURE,
    payload: error,
  }),
};

export default actions;
