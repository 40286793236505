import ApiService from "./Api";
import { R_BASE_URL } from "./requestUrl";

const BASE_URL = R_BASE_URL;

const client = new ApiService({ baseURL: BASE_URL });

const CloudHawkApi = {
  // *********** Auth Controller ********
  signup: (params) => client.post("/signUp", params),
  signIn: (params) => client.post("/signIn", params),
  guestSignIn: (params) => client.post("/guestSignIn", params),
  signInDashboard: (params) => client.post("/signInDashboard", params),
  signUpDashboard: (params) => client.post("/signUpDashboard", params),
  changePasswordRequest: (params) =>
    client.post("/forgotPasswordDashboard", params),
  directChangePassword: (params) =>
    client.post("/directChangePasswordEmail", params),
  passwordChange: (params) => client.post("/passwordChange", params),
  logoutUser: (params) => client.post("/logoutUser", params),
  logoutGuestUser: (params) => client.post("/logoutGuestUser", params),
  addProfilePic: (params) => client.post("/addProfilePic", params),
  deleteUser: (params) => client.post("/deleteUser", params),
  getUserById: (params) => client.post("/getUserById", params),
  updateprofile: (params) =>
    client.post(`/editUserProfile/${params.id}`, params.data),

  // ******************* Countries*******************//
  getCountryById: (params) => client.post("/getCountryById", params),
  getCountries: () => client.get("/getCountries"),
  getAppCountries: () => client.get("/getAppCountries"),
  createCountry: (params) => client.post("/createCountry", params),
  editMallCountry: (params) => client.post("/editCountry", params),
  deleteCountry: (params) => client.post("/deleteCountry", params),

  // ********** Language Controller **************
  getLanguages: () => client.get("getLanguages/0/1000"),
  createLanguage: (params) => client.post("createLanguage", params),
  editLanguage: (params) => client.post("editLanguage", params),
  deleteLanguage: (params) => client.post("deleteLanguage", params),

  // *********** Profile ********
  getAdminUser: () => client.get("/getAdminUsers/0/10000000"),
  addAdminUser: (params) => client.post("/addAdminUser/", params),
  editAdminUser: (params) => client.post(`/editAdminUser/${params.id}`, params),
  deleteAdminUser: (params) => client.post("deleteAdminUser", params),

  // *********** Feedback / query Controller ********
  getAllFeedback: (params) => client.post("getQueries/0/1000000000", params),
  getFeedback: () => client.get("getQueryById"),
  getUserFeedback: () => client.get("getQueryByUserId"),
  addAnswerToQuery: (params) => client.post("addAnswerToQuery", params),
  changeFeedbackStatus: (params) => client.post("changeQueryStatus", params),
  closedFeedback: (params) => client.post("deleteQuery", params),

  // *********** ORDER Controller ********
  downloadExcel: () => client.get("generateExcelSheetForProductUpload"),
  uploadExcel: (params) => client.imgPost("productDataUpload", params),
  getOrders: (params) => client.post("getOrders/0/10000", params),
  deleteOrder: (params) => client.post("deleteOrder", params),
  removeDevice: (params) => client.post("removeDevice", params),
  editOrder: (params) => client.post("editOrder", params),
  downloadFaultRecord: (params) => client.post("downloadFaultRecord", params),

  // ********** FAQ Controller **************
  getFaqs: (params) => client.post(`getFaqsForDashboard/0/1000000`, params),
  getUniqueTypes: () => client.get("getUniqueTypes"),
  getFilterFaqs: (params) => client.post("getFilterFaqs/0/10", params),
  createFaq: (params) => client.imgPost("createFaq", params),
  editFaq: (params) => client.imgPost("editFaq", params),
  deleteFaq: (params) => client.post("deleteFaq", params),

  // ********** Product Controller **************
  createProduct: (params) => client.imgPost("createProduct", params),
  getProducts: () => client.get("getProducts/0/1000000000000"),
  getProdByCategory: (params) => client.post("getProdByCategory", params),
  getCommTypes: () => client.get("/getCommTypes"),
  getCommByType: (params) => client.post("/getCommByType", params),
  getBrands: () => client.get("/getBrands"),
  getCategories: () => client.get("/getCategories"),
  getModels: () => client.get("/getModels"),
  getModelByCategoryId: (params) =>
    client.post("/getModelByCategoryId", params),
  getActions: () => client.get("/getActions"),
  getAllActionTypes: () => client.get("/getAllActionTypes"),
  getProductById: (params) => client.post("getProductById", params),
  editProduct: (params) => client.imgPost("editProduct", params),
  deleteProduct: (params) => client.post("deleteProduct", params),
  productDataUpload: (params) => client.post("productDataUpload", params),

  // *********** Mall Management ********
  getMallBrands: () => client.get("/getMallBrands"),
  getMallCategories: () => client.get("/getMallCategories"),
  getMallProducts: (params) => client.post("/getMallProducts/0/100000", params),

  // ********** Mall Promotion Controller **************
  createMallPromotion: (params) =>
    client.imgPost("createMallPromotion", params),
  getMallPromotionDetails: (params) =>
    client.post("getMallPromotionDetails", params),
  getMallPromotions: () => client.get("getMallPromotions"),
  editMallPromotion: (params) => client.imgPost("editMallPromotion", params),
  deleteMallPromotion: (params) => client.post("deleteMallPromotion", params),

  // ********** Product Item Controller **************
  createBrand: (params) => client.post("createBrand", params),
  editBrand: (params) => client.post("editBrand", params),
  deleteBrand: (params) => client.post("deleteBrand", params),
  createCategory: (params) => client.post("createCategory", params),
  editCategory: (params) => client.post("editCategory", params),
  deleteCategory: (params) => client.post("deleteCategory", params),
  createModel: (params) => client.post("createModel", params),
  editModel: (params) => client.post("editModel", params),
  deleteModel: (params) => client.post("deleteModel", params),
  createSeries: (params) => client.post("createSeries", params),
  editSeries: (params) => client.post("editSeries", params),
  deleteSeries: (params) => client.post("deleteSeries", params),
  createAction: (params) => client.post("createAction", params),
  editAction: (params) => client.post("editAction", params),
  deleteAction: (params) => client.post("deleteAction", params),
  createCommType: (params) => client.post("createCommType", params),
  editCommType: (params) => client.post("editCommType", params),
  deleteCommType: (params) => client.post("deleteCommType", params),

  // **********get Product Item Controller **************
  getProductBrand: (params, apiName) => client.post(apiName, params),

  // ********** Ads Controller **************
  getAds: () => client.get("getAds"),
  createAd: (params) => client.imgPost("createAd", params),
  editAd: (params) => client.imgPost("editAd", params),
  deleteAd: (params) => client.post("deleteAd", params),

  // ********** AppUsers Controller **************
  getAppUsers: (params) => client.post("getAllUsers/0/1000000", params),
  getUserProducts: (params) =>
    client.post("getUserProductList/0/1000000", params),

  // ********** User Controller **************
  getAllUsers: () => client.get("getAllUsers"),

  // ********** user Statistics **************
  getUserStatistics: (params) => client.post("getUserStatistics", params),

  // ********** product Statistics **************
  //getOrderStatistics: () => client.get("getOrderStatistics"),
  getOrderStatistics: (params) => client.post("getOrderStatistics", params),

  // ********** top bar notification **************
  getQueryOpenCountNotify: () => client.get("/getQueryOpenCountNotify"),
  // ********** product statistics page get categories name by brand id **************
  getCategoryByBrandId: (params) =>
    client.post("/getCategoryByBrandId", params),

  // ********** product statistics page get product name by categories id **************
  getProductsByModels: (params) => client.post("/getProductsByModels", params),

  // ********** firmware upload page **************
  createFirmware: (params) => client.imgPost("/createFirmware", params),

  // ********** firmware data page **************
  getFirmwares: (params) => client.post("/getFirmwares/0/10", params),

  // ********** delete firmware data  **************
  deleteFirmware: (params) => client.post("/deleteFirmware", params),
  localisationFirmware: (params) =>
    client.post("/firmware/firmware-put-new-file", params),
  // //systemManage
  //   getMenuList:(params)=> client.get("/web/menu/page", params),
  //   addMenuList: (params) => client.post("/web/menu/add", params),
  //   delMenuList: (params) => client.post("/web/menu/delete", params),
};

export default CloudHawkApi;
