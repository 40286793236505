const notificationsActions = {
  //Fetch notifications
  GET_NOTIFICATION_REQUEST: "GET_NOTIFICATION_REQUEST",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILURE: "GET_NOTIFICATION_FAILURE",
  getNotificationRequest: (request) => {
    return {
      type: notificationsActions.GET_NOTIFICATION_REQUEST,
      payload: request,
    };
  },
  getNotificationSuccess: (notification) => {
    return {
      type: notificationsActions.GET_NOTIFICATION_SUCCESS,
      payload: notification,
    };
  },
  getNotificationFailure: (error) => ({
    type: notificationsActions.GET_NOTIFICATION_FAILURE,
    payload: error,
  }),
};

export default notificationsActions;
