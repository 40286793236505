import languageActions from "./actions";
import { getDateFormatted } from "@iso/lib/helpers/dateFormat";

const INITIAL_DATA = {
  // 0/未操作 1/操作中 2/操作成功 3/操作失败
  recoveryStatus: "0", //  recovery
  copyStatus: "0", //  copy
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除
  detailStatus: "0", // 详情

  countryTotal: 0,
  countryData: [],

  selectData: [],
  data: [],
  total: 0,
  addLanguage: [],
  editLanguage: [],
  loading: true,
  error: null,
};
export default function languageReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case languageActions.SELECT_LANGUAGE_REQUEST:
      return {
        ...state,
        selectData: [],
        data: [],
        loading: true,
        error: null,
      };
    case languageActions.SELECT_LANGUAGE_SUCCESS:
      return {
        ...state,
        selectData: action.payload,
        data: action.payload,
        loading: false,
        error: null,
      };
    case languageActions.SELECT_LANGUAGE_FAILURE:
      return {
        ...state,
        selectData: [],
        data: [],
        loading: false,
        error: action.payload,
      };

    case languageActions.FETCH_LANGUAGE_DATA_REQUEST:
      return {
        ...state,
        data: [],
        total: 0,
        loading: true,
        error: null,
      };
    case languageActions.FETCH_LANGUAGE_DATA_SUCCESS:
      let data = Object.assign([], action.payload);
      data.forEach((element, index) => {
        element.key = index;
        const date = new Date(element.createdAt);
        element.createdAt = getDateFormatted(date);
      });

      return {
        ...state,
        data: action.payload.list,
        total: action.payload.total,
        loading: false,
        error: null,
      };
    case languageActions.FETCH_LANGUAGE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    //
    case languageActions.FETCH_COUNTRY_DATA_REQUEST:
      return {
        ...state,
        countryData: [],
        countryTotal: 0,
        loading: true,
        error: null,
      };
    case languageActions.FETCH_COUNTRY_DATA_SUCCESS:
      return {
        ...state,
        countryData: action.payload.list,
        countryTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case languageActions.FETCH_COUNTRY_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case languageActions.ADD_LANGUAGE_REQUEST:
      return {
        ...state,
        data: [],
        addLanguage: true,
        error: null,
      };
    case languageActions.ADD_LANGUAGE_SUCCESS:
      return {
        ...state,
        addLanguage: action.payload,
        loading: false,
        error: null,
      };
    case languageActions.ADD_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case languageActions.ADD_COUNTRY_REQUEST:
      return {
        ...state,
        createStatus: "1",
        // data: [],
        // addLanguage: true,
        loading: true,
        error: null,
      };
    case languageActions.ADD_COUNTRY_SUCCESS:
      return {
        ...state,
        createStatus: "2",
        // addLanguage: action.payload,
        loading: false,
        error: null,
      };
    case languageActions.ADD_COUNTRY_FAILURE:
      return {
        ...state,
        createStatus: "3",
        loading: false,
        error: action.payload,
      };

    case languageActions.EDIT_LANGUAGE_REQUEST:
      return {
        ...state,
        editLanguage: [],
        loading: true,
        error: null,
      };
    case languageActions.EDIT_LANGUAGE_SUCCESS:
      return {
        ...state,
        editLanguage: action.payload,
        loading: false,
        error: null,
      };
    case languageActions.EDIT_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case languageActions.EDIT_COUNTRY_REQUEST:
      return {
        ...state,
        editStatus: "1",
        // editLanguage: [],
        loading: true,
        error: null,
      };
    case languageActions.EDIT_COUNTRY_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        // editLanguage: action.payload,
        loading: false,
        error: null,
      };
    case languageActions.EDIT_COUNTRY_FAILURE:
      return {
        ...state,
        editStatus: "3",
        loading: false,
        error: action.payload,
      };

    case languageActions.DELETE_LANGUAGE_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case languageActions.DELETE_LANGUAGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case languageActions.DELETE_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    //
    case languageActions.DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        // data: [],
        loading: true,
        error: null,
      };
    case languageActions.DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
        // data: action.payload,
        loading: false,
        error: null,
      };
    case languageActions.DELETE_COUNTRY_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
