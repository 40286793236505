// saga.js
import { call, all, takeEvery, put } from "redux-saga/effects";
import messagePushActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";
import notificationsActions from "@iso/redux/notifications/notificationsActions";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

//消息列表
function* fetchmessageListDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getMessListPage, payload);
    yield put(messagePushActions.fetchmessageListDataSuccess(response.data));
  } catch (error) {
    yield put(messagePushActions.fetchmessageListDataFailure(error));
  }
}

//消息-日志列表
function* fetchmessageLogDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getMessLogPage, payload);
    if (response.success) {
      yield put(messagePushActions.fetchmessageLogDataSuccess(response.data));
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(messagePushActions.fetchmessageLogDataFailure(error));
  }
}

//消息-推送列表
function* fetchmessagePushDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getMessPushPage, payload);
    yield put(messagePushActions.fetchmessagePushDataSuccess(response.data));
  } catch (error) {
    yield put(messagePushActions.fetchmessagePushDataFailure(error));
  }
}

function* addmessagePushDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addMessPushPage, payload.params1);

    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
    // yield put(messagePushActions.addmessagePushDataSuccess(response.data));
  } catch (error) {
    yield put(messagePushActions.addmessagePushDataFailure(error));
  } finally {
    yield put(messagePushActions.fetchmessagePushDataRequest(payload.params2));
  }
}

// EDIT messagePush
function* editmessagePushDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editMessPushPage, payload.params1);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(messagePushActions.editmessagePushDataFailure(error));
  } finally {
    yield put(messagePushActions.fetchmessagePushDataRequest(payload.params2));
  }
}

//PUSH messagePush  发送
function* pushMessagePushDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.pushMessPush, payload.params1);
    if (response.success) {
      alert(response);

      // dispatch(notificationsActions.getNotificationRequest())
      yield put(notificationsActions.getNotificationRequest());
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(messagePushActions.fetchmessagePushDataRequest(payload.params2));
  }
}

//删除消息推送
function* DeleteMessagePushRecord(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteMessPush, payload.params1);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(messagePushActions.fetchmessagePushDataRequest(payload.params2));
  }
}
//消息列表-批量 read
function* readmessageListDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.readMessList, payload.idList);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(messagePushActions.fetchmessageListDataRequest(payload.params2));
  }
}
//消息列表-批量删除返回第一页
function* deletemessageListDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteMessList, payload.idList);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(messagePushActions.fetchmessageListDataRequest(payload.params2));
  }
}
//获取国家下拉框
function* getCountrySelectList() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getCountrySelectList);
    if (response.success) {
      yield put(
        messagePushActions.fetchGETCountrySelectListSuccess(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(messagePushActions.fetchGETCountrySelectListError(error));
  }
}

//获取用户下拉框
function* getUserSelectList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getUserSelectList, payload);
    if (response.success) {
      yield put(
        messagePushActions.fetchGETUserSelectListSuccess(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(messagePushActions.fetchGETUserSelectListError(error));
  }
}

//消息列表-点击详情,从未读 到 已读
function* editMessListStatus(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editMessState, payload.params1);
    if (response.success) {
      // alert(response)
      yield put(
        messagePushActions.fetchmessageListDataRequest(payload.params2),
      );
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    // yield put(notificationsActions.getNotificationRequest({}));
  }
}

export default function* messagePushSaga() {
  yield all([
    takeEvery(
      messagePushActions.FETCH_messagePush_DATA_REQUEST,
      fetchmessagePushDataRequest,
    ),
    takeEvery(
      messagePushActions.FETCH_messageLog_DATA_REQUEST,
      fetchmessageLogDataRequest,
    ),
    takeEvery(
      messagePushActions.ADD_messagePush_REQUEST,
      addmessagePushDataRequest,
    ),
    takeEvery(
      messagePushActions.EDIT_messagePush_REQUEST,
      editmessagePushDataRequest,
    ),
    takeEvery(
      messagePushActions.Push_MessPush_Data_REQUEST,
      pushMessagePushDataRequest,
    ),
    takeEvery(
      messagePushActions.READ_messageList_REQUEST,
      readmessageListDataRequest,
    ),
    takeEvery(
      messagePushActions.DELETE_messageList_REQUEST,
      deletemessageListDataRequest,
    ),
    takeEvery(
      messagePushActions.FETCH_messageList_DATA_REQUEST,
      fetchmessageListDataRequest,
    ),
    takeEvery(
      messagePushActions.GET_CountrySelect_List_REQUEST,
      getCountrySelectList,
    ),
    takeEvery(
      messagePushActions.GET_UserSelect_List_REQUEST,
      getUserSelectList,
    ),
    takeEvery(
      messagePushActions.Edit_MessList_Status_REQUEST,
      editMessListStatus,
    ),
    takeEvery(
      messagePushActions.Delete_MessagePush_Record_Request,
      DeleteMessagePushRecord,
    ),
  ]);
}
