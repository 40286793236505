// saga.js
import { call, all, takeEvery, put, select } from "redux-saga/effects";
import messageTemplateActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

function* editSubTempRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editSubLangTemp, payload.params1);

    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
    // yield put(messageTemplateActions.addmessageTemplateDataSuccess(response.data));
  } catch (error) {
  } finally {
    yield put(
      messageTemplateActions.fetchmessageTemplateDataRequest(payload.params2),
    );
  }
}
function* addSubTempRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addSubLangTemp, payload.params1);

    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
    // yield put(messageTemplateActions.addmessageTemplateDataSuccess(response.data));
  } catch (error) {
  } finally {
    yield put(
      messageTemplateActions.fetchmessageTemplateDataRequest(payload.params2),
    );
  }
}

function* getSubTempRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getMessSubList, payload);
    yield put(messageTemplateActions.fetchSubTempDataSuccess(response.data));
  } catch (error) {
    yield put(messageTemplateActions.fetchSubTempDataFailure(error));
  }
}

function* fetchmessageTemplateDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getMessTemplatePage, payload);
    yield put(
      messageTemplateActions.fetchmessageTemplateDataSuccess(response.data),
    );
  } catch (error) {
    yield put(messageTemplateActions.fetchmessageTemplateDataFailure(error));
  }
}

function* addmessageTemplateDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addMessTemplate, payload.params1);

    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
    // yield put(messageTemplateActions.addmessageTemplateDataSuccess(response.data));
  } catch (error) {
    yield put(messageTemplateActions.addmessageTemplateDataFailure(error));
  } finally {
    yield put(
      messageTemplateActions.fetchmessageTemplateDataRequest(payload.params2),
    );
  }
}

// EDIT messageTemplate
function* editmessageTemplateDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editMessTemplate, payload.params1);
    if (response.success) {
      alert(response);
      if (payload["isExpendItem"]) {
        yield put(
          messageTemplateActions.fetchSubTempDataRequest(payload.params3),
        );
      }
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(messageTemplateActions.editmessageTemplateDataFailure(error));
  } finally {
    yield put(
      messageTemplateActions.fetchmessageTemplateDataRequest(payload.params2),
    );
  }
}

function* deletemessageTemplateDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteMessTemplate, payload);
    if (response.success) {
      const total = yield select(
        (state) => state.messageTemplate.messageTemplatedataTotal,
      );
      if (
        payload.params2.pageNum >
        Math.ceil((total - 1) / payload.params2.pageSize)
      ) {
        //last page
        payload.params2.pageNum = payload.params2.pageNum - 1;
        yield put(
          messageTemplateActions.fetchmessageTemplateDataRequest(
            payload.params2,
          ),
        );
      } else {
        //this page
        yield put(
          messageTemplateActions.fetchmessageTemplateDataRequest(
            payload.params2,
          ),
        );
      }
      alert(response);
    } else {
      yield put(
        messageTemplateActions.fetchmessageTemplateDataRequest(payload.params2),
      );
      Error(response);
    }
  } catch (error) {
    yield put(messageTemplateActions.deletemessageTemplateDataFailure(error));
  }
}

export default function* messageTemplateSaga() {
  yield all([
    takeEvery(
      messageTemplateActions.FETCH_messageTemplate_DATA_REQUEST,
      fetchmessageTemplateDataRequest,
    ),
    takeEvery(
      messageTemplateActions.ADD_messageTemplate_REQUEST,
      addmessageTemplateDataRequest,
    ),
    takeEvery(
      messageTemplateActions.EDIT_messageTemplate_REQUEST,
      editmessageTemplateDataRequest,
    ),
    takeEvery(
      messageTemplateActions.DELETE_messageTemplate_REQUEST,
      deletemessageTemplateDataRequest,
    ),
    takeEvery(
      messageTemplateActions.FETCH_SubTemp_DATA_REQUEST,
      getSubTempRequest,
    ),
    takeEvery(messageTemplateActions.ADD_SubTemp_REQUEST, addSubTempRequest),
    takeEvery(messageTemplateActions.Edit_SubTemp_REQUEST, editSubTempRequest),
  ]);
}
