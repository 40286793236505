const actions = {
  //
  //  ********* 查询产品类型列表和对应的系列 *******   //
  SELECT_MODEL_MENU_REQUEST: "SELECT_MODEL_MENU_REQUEST",
  SELECT_MODEL_MENU_SUCCESS: "SELECT_MODEL_MENU_SUCCESS",
  SELECT_MODEL_MENU_FAILURE: "SELECT_MODEL_MENU_FAILURE",
  selectModelMenuRequest: (res) => ({
    type: actions.SELECT_MODEL_MENU_REQUEST,
    payload: res,
  }),
  selectModelMenuSuccess: (res) => ({
    type: actions.SELECT_MODEL_MENU_SUCCESS,
    payload: res,
  }),
  selectModelMenuFailure: (error) => ({
    type: actions.SELECT_MODEL_MENU_FAILURE,
    payload: error,
  }),
  //  ********* 属性列表（该产品已发布的属性）web/product/tsl/property/list *******   //
  SELECT_PRODUCT_TSL_REQUEST: "SELECT_PRODUCT_TSL_REQUEST",
  SELECT_PRODUCT_TSL_SUCCESS: "SELECT_PRODUCT_TSL_SUCCESS",
  SELECT_PRODUCT_TSL_FAILURE: "SELECT_PRODUCT_TSL_FAILURE",
  selectProductTslRequest: (res) => ({
    type: actions.SELECT_PRODUCT_TSL_REQUEST,
    payload: res,
  }),
  selectProductTslSuccess: (res) => ({
    type: actions.SELECT_PRODUCT_TSL_SUCCESS,
    payload: res,
  }),
  selectProductTslFailure: (error) => ({
    type: actions.SELECT_PRODUCT_TSL_FAILURE,
    payload: error,
  }),

  //
  //  ********* 设备列表 new 导出 *******   //
  EXPORT_EQ_NEW_REQUEST: "EXPORT_EQ_NEW_REQUEST",
  EXPORT_EQ_NEW_SUCCESS: "EXPORT_EQ_NEW_SUCCESS",
  EXPORT_EQ_NEW_FAILURE: "EXPORT_EQ_NEW_FAILURE",
  exportEqNewTslRequest: (res) => ({
    type: actions.EXPORT_EQ_NEW_REQUEST,
    payload: res,
  }),
  exportEqNewTslSuccess: (res) => ({
    type: actions.EXPORT_EQ_NEW_SUCCESS,
    payload: res,
  }),
  exportEqNewTslFailure: (error) => ({
    type: actions.EXPORT_EQ_NEW_FAILURE,
    payload: error,
  }),
  //  ********* 关联设备列表 new 导出 *******   //
  EXPORT_EQ_NEW_A_REQUEST: "EXPORT_EQ_NEW_A_REQUEST",
  EXPORT_EQ_NEW_A_SUCCESS: "EXPORT_EQ_NEW_A_SUCCESS",
  EXPORT_EQ_NEW_A_FAILURE: "EXPORT_EQ_NEW_A_FAILURE",
  exportEqNewAssociatedRequest: (res) => ({
    type: actions.EXPORT_EQ_NEW_A_REQUEST,
    payload: res,
  }),
  exportEqNewAssociatedSuccess: (res) => ({
    type: actions.EXPORT_EQ_NEW_A_SUCCESS,
    payload: res,
  }),
  exportEqNewAssociatedFailure: (error) => ({
    type: actions.EXPORT_EQ_NEW_A_FAILURE,
    payload: error,
  }),
  //  ********* 上下线记录 new 导出 *******   //
  EXPORT_EQ_NEW_OR_REQUEST: "EXPORT_EQ_NEW_OR_REQUEST",
  EXPORT_EQ_NEW_OR_SUCCESS: "EXPORT_EQ_NEW_OR_SUCCESS",
  EXPORT_EQ_NEW_OR_FAILURE: "EXPORT_EQ_NEW_OR_FAILURE",
  exportEqNewOnlineRecordRequest: (res) => ({
    type: actions.EXPORT_EQ_NEW_OR_REQUEST,
    payload: res,
  }),
  exportEqNewOnlineRecordSuccess: (res) => ({
    type: actions.EXPORT_EQ_NEW_OR_SUCCESS,
    payload: res,
  }),
  exportEqNewOnlineRecordFailure: (error) => ({
    type: actions.EXPORT_EQ_NEW_OR_FAILURE,
    payload: error,
  }),
  //  ********* 设备上报日志 new 导出 *******   //
  EXPORT_EQ_NEW_DR_REQUEST: "EXPORT_EQ_NEW_DR_REQUEST",
  EXPORT_EQ_NEW_DR_SUCCESS: "EXPORT_EQ_NEW_DR_SUCCESS",
  EXPORT_EQ_NEW_DR_FAILURE: "EXPORT_EQ_NEW_DR_FAILURE",
  exportEqNewDeviceReportRequest: (res) => ({
    type: actions.EXPORT_EQ_NEW_DR_REQUEST,
    payload: res,
  }),
  exportEqNewDeviceReportSuccess: (res) => ({
    type: actions.EXPORT_EQ_NEW_DR_SUCCESS,
    payload: res,
  }),
  exportEqNewDeviceReportFailure: (error) => ({
    type: actions.EXPORT_EQ_NEW_DR_FAILURE,
    payload: error,
  }),
  //  ********* 设备操作日志 new 导出 *******   //
  EXPORT_EQ_NEW_DO_REQUEST: "EXPORT_EQ_NEW_DO_REQUEST",
  EXPORT_EQ_NEW_DO_SUCCESS: "EXPORT_EQ_NEW_DO_SUCCESS",
  EXPORT_EQ_NEW_DO_FAILURE: "EXPORT_EQ_NEW_DO_FAILURE",
  exportEqNewDeviceOperationRequest: (res) => ({
    type: actions.EXPORT_EQ_NEW_DO_REQUEST,
    payload: res,
  }),
  exportEqNewDeviceOperationSuccess: (res) => ({
    type: actions.EXPORT_EQ_NEW_DO_SUCCESS,
    payload: res,
  }),
  exportEqNewDeviceOperationFailure: (error) => ({
    type: actions.EXPORT_EQ_NEW_DO_FAILURE,
    payload: error,
  }),

  //  ********* 设备列表 old 导出 *******   //
  EXPORT_EQ_OLD_LIST_REQUEST: "EXPORT_EQ_OLD_LIST_REQUEST",
  EXPORT_EQ_OLD_LIST_SUCCESS: "EXPORT_EQ_OLD_LIST_SUCCESS",
  EXPORT_EQ_OLD_LIST_FAILURE: "EXPORT_EQ_OLD_LIST_FAILURE",
  exportEqOldTslListRequest: (res) => ({
    type: actions.EXPORT_EQ_OLD_LIST_REQUEST,
    payload: res,
  }),
  exportEqOldTslListSuccess: (res) => ({
    type: actions.EXPORT_EQ_OLD_LIST_SUCCESS,
    payload: res,
  }),
  exportEqOldTslListFailure: (error) => ({
    type: actions.EXPORT_EQ_OLD_LIST_FAILURE,
    payload: error,
  }),
  //  ********* 设备列表 old 模板下载 *******   //
  EXPORT_EQ_OLD_REQUEST: "EXPORT_EQ_OLD_REQUEST",
  EXPORT_EQ_OLD_SUCCESS: "EXPORT_EQ_OLD_SUCCESS",
  EXPORT_EQ_OLD_FAILURE: "EXPORT_EQ_OLD_FAILURE",
  exportEqOldTslRequest: (res) => ({
    type: actions.EXPORT_EQ_OLD_REQUEST,
    payload: res,
  }),
  exportEqOldTslSuccess: (res) => ({
    type: actions.EXPORT_EQ_OLD_SUCCESS,
    payload: res,
  }),
  exportEqOldTslFailure: (error) => ({
    type: actions.EXPORT_EQ_OLD_FAILURE,
    payload: error,
  }),

  //  ********* 设备列表 old 模板下载 *******   //
  UNBIND_USER_REQUEST: "UNBIND_USER_REQUEST",
  UNBIND_USER_SUCCESS: "UNBIND_USER_SUCCESS",
  UNBIND_USER_FAILURE: "UNBIND_USER_FAILURE",
  unbindManageUsersRequest: (res) => ({
    type: actions.UNBIND_USER_REQUEST,
    payload: res,
  }),
  unbindManageUsersSuccess: (res) => ({
    type: actions.UNBIND_USER_SUCCESS,
    payload: res,
  }),
  unbindManageUsersFailure: (error) => ({
    type: actions.UNBIND_USER_FAILURE,
    payload: error,
  }),

  //  ********* 设备列表 old 异常结果导出 *******   //
  EXPORT_EQ_OLD_ERROE_REQUEST: "EXPORT_EQ_OLD_ERROE_REQUEST",
  EXPORT_EQ_OLD_ERROE_SUCCESS: "EXPORT_EQ_OLD_ERROE_SUCCESS",
  EXPORT_EQ_OLD_ERROE_FAILURE: "EXPORT_EQ_OLD_ERROE_FAILURE",
  exportEqOldErrorRequest: (res) => ({
    type: actions.EXPORT_EQ_OLD_ERROE_REQUEST,
    payload: res,
  }),
  exportEqOldErrorSuccess: (res) => ({
    type: actions.EXPORT_EQ_OLD_ERROE_SUCCESS,
    payload: res,
  }),
  exportEqOldErrorFailure: (error) => ({
    type: actions.EXPORT_EQ_OLD_ERROE_FAILURE,
    payload: error,
  }),

  //  ********* ManageUsers 导出异常信息 *******   //
  EXPORT_MANAGE_USER_ERROE_REQUEST: "EXPORT_MANAGE_USER_ERROE_REQUEST",
  EXPORT_MANAGE_USER_ERROE_SUCCESS: "EXPORT_MANAGE_USER_ERROE_SUCCESS",
  EXPORT_MANAGE_USER_ERROE_FAILURE: "EXPORT_MANAGE_USER_ERROE_FAILURE",
  exportManageUsersErrorRequest: (res) => ({
    type: actions.EXPORT_MANAGE_USER_ERROE_REQUEST,
    payload: res,
  }),
  exportManageUsersErrorSuccess: (res) => ({
    type: actions.EXPORT_MANAGE_USER_ERROE_SUCCESS,
    payload: res,
  }),
  exportManageUsersErrorFailure: (error) => ({
    type: actions.EXPORT_MANAGE_USER_ERROE_FAILURE,
    payload: error,
  }),

  //
  // 获取 设备管理/详情 list
  //  *********  select 物模型属性 *******   //
  SELECT_EQ_NEW_T_REQUEST: "SELECT_EQ_NEW_T_REQUEST",
  SELECT_EQ_NEW_T_SUCCESS: "SELECT_EQ_NEW_T_SUCCESS",
  SELECT_EQ_NEW_T_FAILURE: "SELECT_EQ_NEW_T_FAILURE",
  selectEqNewTslRequest: (res) => ({
    type: actions.SELECT_EQ_NEW_T_REQUEST,
    payload: res,
  }),
  selectEqNewTslSuccess: (res) => ({
    type: actions.SELECT_EQ_NEW_T_SUCCESS,
    payload: res,
  }),
  selectEqNewTslFailure: (error) => ({
    type: actions.SELECT_EQ_NEW_T_FAILURE,
    payload: error,
  }),
  //  *********  select 关联设备列表 *******   //
  SELECT_EQ_NEW_A_REQUEST: "SELECT_EQ_NEW_A_REQUEST",
  SELECT_EQ_NEW_A_SUCCESS: "SELECT_EQ_NEW_A_SUCCESS",
  SELECT_EQ_NEW_A_FAILURE: "SELECT_EQ_NEW_A_FAILURE",
  selectEqNewAssociatedRequest: (res) => ({
    type: actions.SELECT_EQ_NEW_A_REQUEST,
    payload: res,
  }),
  selectEqNewAssociatedSuccess: (res) => ({
    type: actions.SELECT_EQ_NEW_A_SUCCESS,
    payload: res,
  }),
  selectEqNewAssociatedFailure: (error) => ({
    type: actions.SELECT_EQ_NEW_A_FAILURE,
    payload: error,
  }),
  //  *********  select 上下线记录 *******   //
  SELECT_EQ_NEW_OR_REQUEST: "SELECT_EQ_NEW_OR_REQUEST",
  SELECT_EQ_NEW_OR_SUCCESS: "SELECT_EQ_NEW_OR_SUCCESS",
  SELECT_EQ_NEW_OR_FAILURE: "SELECT_EQ_NEW_OR_FAILURE",
  selectEqNewOnlineRecordRequest: (res) => ({
    type: actions.SELECT_EQ_NEW_OR_REQUEST,
    payload: res,
  }),
  selectEqNewOnlineRecordSuccess: (res) => ({
    type: actions.SELECT_EQ_NEW_OR_SUCCESS,
    payload: res,
  }),
  selectEqNewOnlineRecordFailure: (error) => ({
    type: actions.SELECT_EQ_NEW_OR_FAILURE,
    payload: error,
  }),
  //  *********  select 充电记录 *******   //
  SELECT_EQ_OLD_C_REQUEST: "SELECT_EQ_OLD_C_REQUEST",
  SELECT_EQ_OLD_C_SUCCESS: "SELECT_EQ_OLD_C_SUCCESS",
  SELECT_EQ_OLD_C_FAILURE: "SELECT_EQ_OLD_C_FAILURE",
  selectEqOldChargeRequest: (res) => ({
    type: actions.SELECT_EQ_OLD_C_REQUEST,
    payload: res,
  }),
  selectEqOldChargeSuccess: (res) => ({
    type: actions.SELECT_EQ_OLD_C_SUCCESS,
    payload: res,
  }),
  selectEqOldChargeFailure: (error) => ({
    type: actions.SELECT_EQ_OLD_C_FAILURE,
    payload: error,
  }),
  //  *********  select 割草记录 *******   //
  SELECT_EQ_OLD_CG_REQUEST: "SELECT_EQ_OLD_CG_REQUEST",
  SELECT_EQ_OLD_CG_SUCCESS: "SELECT_EQ_OLD_CG_SUCCESS",
  SSELECT_EQ_OLD_CG_FAILURE: "SSELECT_EQ_OLD_CG_FAILURE",
  selectEqOldCutGrassRequest: (res) => ({
    type: actions.SELECT_EQ_OLD_CG_REQUEST,
    payload: res,
  }),
  selectEqOldCutGrassSuccess: (res) => ({
    type: actions.SELECT_EQ_OLD_CG_SUCCESS,
    payload: res,
  }),
  selectEqOldCutGrassFailure: (error) => ({
    type: actions.SSELECT_EQ_OLD_CG_FAILURE,
    payload: error,
  }),
  //  *********  select 故障记录 *******   //
  SELECT_EQ_OLD_F_REQUEST: "SELECT_EQ_OLD_F_REQUEST",
  SELECT_EQ_OLD_F_SUCCESS: "SELECT_EQ_OLD_F_SUCCESS",
  SELECT_EQ_OLD_F_FAILURE: "SELECT_EQ_OLD_F_FAILURE",
  selectEqOldFaultRequest: (res) => ({
    type: actions.SELECT_EQ_OLD_F_REQUEST,
    payload: res,
  }),
  selectEqOldFaultSuccess: (res) => ({
    type: actions.SELECT_EQ_OLD_F_SUCCESS,
    payload: res,
  }),
  selectEqOldFaultFailure: (error) => ({
    type: actions.SELECT_EQ_OLD_F_FAILURE,
    payload: error,
  }),
  //  *********  select 设备上报日志 *******   //
  SELECT_EQ_NEW_DR_REQUEST: "SELECT_EQ_NEW_DR_REQUEST",
  SELECT_EQ_NEW_DR_SUCCESS: "SELECT_EQ_NEW_DR_SUCCESS",
  SELECT_EQ_NEW_DR_FAILURE: "SELECT_EQ_NEW_DR_FAILURE",
  selectEqNewDeviceReportRequest: (res) => ({
    type: actions.SELECT_EQ_NEW_DR_REQUEST,
    payload: res,
  }),
  selectEqNewDeviceReportSuccess: (res) => ({
    type: actions.SELECT_EQ_NEW_DR_SUCCESS,
    payload: res,
  }),
  selectEqNewDeviceReportFailure: (error) => ({
    type: actions.SELECT_EQ_NEW_DR_FAILURE,
    payload: error,
  }),
  //  *********  select 设备操作日志 *******   //
  SELECT_EQ_NEW_DO_REQUEST: "SELECT_EQ_NEW_DO_REQUEST",
  SELECT_EQ_NEW_DO_SUCCESS: "SELECT_EQ_NEW_DO_SUCCESS",
  SELECT_EQ_NEW_DO_FAILURE: "SELECT_EQ_NEW_DO_FAILURE",
  selectEqNewDeviceOperationRequest: (res) => ({
    type: actions.SELECT_EQ_NEW_DO_REQUEST,
    payload: res,
  }),
  selectEqNewDeviceOperationSuccess: (res) => ({
    type: actions.SELECT_EQ_NEW_DO_SUCCESS,
    payload: res,
  }),
  selectEqNewDeviceOperationFailure: (error) => ({
    type: actions.SELECT_EQ_NEW_DO_FAILURE,
    payload: error,
  }),
  //  *********  select 组件更换记录 *******   //
  SELECT_EQ_NEW_SC_REQUEST: "SELECT_EQ_NEW_SC_REQUEST",
  SELECT_EQ_NEW_SC_SUCCESS: "SELECT_EQ_NEW_SC_SUCCESS",
  SELECT_EQ_NEW_SC_FAILURE: "SELECT_EQ_NEW_SC_FAILURE",
  selectEqNewSeriesChangeRequest: (res) => ({
    type: actions.SELECT_EQ_NEW_SC_REQUEST,
    payload: res,
  }),
  selectEqNewSeriesChangeSuccess: (res) => ({
    type: actions.SELECT_EQ_NEW_SC_SUCCESS,
    payload: res,
  }),
  selectEqNewSeriesChangeFailure: (error) => ({
    type: actions.SELECT_EQ_NEW_SC_FAILURE,
    payload: error,
  }),
  //  *********  select 上下线记录 old *******   //
  SELECT_EQ_NEW_OR_OLD_REQUEST: "SELECT_EQ_NEW_OR_OLD_REQUEST",
  SELECT_EQ_NEW_OR_OLD_SUCCESS: "SELECT_EQ_NEW_OR_OLD_SUCCESS",
  SELECT_EQ_NEW_OR_OLD_FAILURE: "SELECT_EQ_NEW_OR_OLD_FAILURE",
  selectEqNewOnlineRecordOldRequest: (res) => ({
    type: actions.SELECT_EQ_NEW_OR_OLD_REQUEST,
    payload: res,
  }),
  selectEqNewOnlineRecordOldSuccess: (res) => ({
    type: actions.SELECT_EQ_NEW_OR_OLD_SUCCESS,
    payload: res,
  }),
  selectEqNewOnlineRecordOldFailure: (error) => ({
    type: actions.SELECT_EQ_NEW_OR_OLD_FAILURE,
    payload: error,
  }),
  //  *********  select 设备上报日志 old *******   //
  SELECT_EQ_NEW_DR_OLD_REQUEST: "SELECT_EQ_NEW_DR_OLD_REQUEST",
  SELECT_EQ_NEW_DR_OLD_SUCCESS: "SELECT_EQ_NEW_DR_OLD_SUCCESS",
  SELECT_EQ_NEW_DR_OLD_FAILURE: "SELECT_EQ_NEW_DR_OLD_FAILURE",
  selectEqNewDeviceReportOldRequest: (res) => ({
    type: actions.SELECT_EQ_NEW_DR_OLD_REQUEST,
    payload: res,
  }),
  selectEqNewDeviceReportOldSuccess: (res) => ({
    type: actions.SELECT_EQ_NEW_DR_OLD_SUCCESS,
    payload: res,
  }),
  selectEqNewDeviceReportOldFailure: (error) => ({
    type: actions.SELECT_EQ_NEW_DR_OLD_FAILURE,
    payload: error,
  }),

  //
  //  ********* 获取 new+old 产品型号 select *******   //
  SELECT_EQ_ALL_PRODUCT_TYPE_REQUEST: "SELECT_EQ_ALL_PRODUCT_TYPE_REQUEST",
  SELECT_EQ_ALL_PRODUCT_TYPE_SUCCESS: "SELECT_EQ_ALL_PRODUCT_TYPE_SUCCESS",
  SELECT_EQ_ALL_PRODUCT_TYPE_FAILURE: "SELECT_EQ_ALL_PRODUCT_TYPE_FAILURE",
  selectEqAllProductTypeRequest: (request) => ({
    type: actions.SELECT_EQ_ALL_PRODUCT_TYPE_REQUEST,
    payload: request,
  }),
  selectEqAllProductTypeSuccess: (product) => ({
    type: actions.SELECT_EQ_ALL_PRODUCT_TYPE_SUCCESS,
    payload: product,
  }),
  selectEqAllProductTypeFailure: (error) => ({
    type: actions.SELECT_EQ_ALL_PRODUCT_TYPE_FAILURE,
    payload: error,
  }),
  //  ********* 获取 new 产品型号 select *******   //
  SELECT_EQ_NEW_PRODUCT_TYPE_REQUEST: "SELECT_EQ_NEW_PRODUCT_TYPE_REQUEST",
  SELECT_EQ_NEW_PRODUCT_TYPE_SUCCESS: "SELECT_EQ_NEW_PRODUCT_TYPE_SUCCESS",
  SELECT_EQ_NEW_PRODUCT_TYPE_FAILURE: "SELECT_EQ_NEW_PRODUCT_TYPE_FAILURE",
  selectEqNewProductTypeRequest: (request) => ({
    type: actions.SELECT_EQ_NEW_PRODUCT_TYPE_REQUEST,
    payload: request,
  }),
  selectEqNewProductTypeSuccess: (product) => ({
    type: actions.SELECT_EQ_NEW_PRODUCT_TYPE_SUCCESS,
    payload: product,
  }),
  selectEqNewProductTypeFailure: (error) => ({
    type: actions.SELECT_EQ_NEW_PRODUCT_TYPE_FAILURE,
    payload: error,
  }),
  //  ********* 获取 old 产品型号 select *******   //
  SELECT_EQ_OLD_PRODUCT_TYPE_REQUEST: "SELECT_EQ_OLD_PRODUCT_TYPE_REQUEST",
  SELECT_EQ_OLD_PRODUCT_TYPE_SUCCESS: "SELECT_EQ_OLD_PRODUCT_TYPE_SUCCESS",
  SELECT_EQ_OLD_PRODUCT_TYPE_FAILURE: "SELECT_EQ_OLD_PRODUCT_TYPE_FAILURE",
  selectEqOldProductTypeRequest: (request) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_TYPE_REQUEST,
    payload: request,
  }),
  selectEqOldProductTypeSuccess: (product) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_TYPE_SUCCESS,
    payload: product,
  }),
  selectEqOldProductTypeFailure: (error) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_TYPE_FAILURE,
    payload: error,
  }),
  //  ********* 获取 old 产品品牌 select *******   //
  SELECT_EQ_OLD_PRODUCT_BRAND_REQUEST: "SELECT_EQ_OLD_PRODUCT_BRAND_REQUEST",
  SELECT_EQ_OLD_PRODUCT_BRAND_SUCCESS: "SELECT_EQ_OLD_PRODUCT_BRAND_SUCCESS",
  SELECT_EQ_OLD_PRODUCT_BRAND_FAILURE: "SELECT_EQ_OLD_PRODUCT_BRAND_FAILURE",
  selectEqOldProductBrandRequest: (request) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_BRAND_REQUEST,
    payload: request,
  }),
  selectEqOldProductBrandSuccess: (product) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_BRAND_SUCCESS,
    payload: product,
  }),
  selectEqOldProductBrandFailure: (error) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_BRAND_FAILURE,
    payload: error,
  }),
  //  ********* 获取 old 产品类型 Category select *******   //
  SELECT_EQ_OLD_PRODUCT_CATEGORY_REQUEST:
    "SELECT_EQ_OLD_PRODUCT_CATEGORY_REQUEST",
  SELECT_EQ_OLD_PRODUCT_CATEGORY_SUCCESS:
    "SELECT_EQ_OLD_PRODUCT_CATEGORY_SUCCESS",
  SELECT_EQ_OLD_PRODUCT_CATEGORY_FAILURE:
    "SELECT_EQ_OLD_PRODUCT_CATEGORY_FAILURE",
  selectEqOldProductCategoryRequest: (request) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_CATEGORY_REQUEST,
    payload: request,
  }),
  selectEqOldProductCategorySuccess: (product) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_CATEGORY_SUCCESS,
    payload: product,
  }),
  selectEqOldProductCategoryFailure: (error) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_CATEGORY_FAILURE,
    payload: error,
  }),
  //  ********* 获取 old 产品系列 Model select *******   //
  SELECT_EQ_OLD_PRODUCT_MODEL_REQUEST: "SELECT_EQ_OLD_PRODUCT_MODEL_REQUEST",
  SELECT_EQ_OLD_PRODUCT_MODEL_SUCCESS: "SELECT_EQ_OLD_PRODUCT_MODEL_SUCCESS",
  SELECT_EQ_OLD_PRODUCT_MODEL_FAILURE: "SELECT_EQ_OLD_PRODUCT_MODEL_FAILURE",
  selectEqOldProductModelRequest: (request) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_MODEL_REQUEST,
    payload: request,
  }),
  selectEqOldProductModelSuccess: (product) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_MODEL_SUCCESS,
    payload: product,
  }),
  selectEqOldProductModelFailure: (error) => ({
    type: actions.SELECT_EQ_OLD_PRODUCT_MODEL_FAILURE,
    payload: error,
  }),
  //  ********* 获取 ia厂商管理 Supplier select *******   //
  SELECT_SUPPLIER_REQUEST: "SELECT_SUPPLIER_REQUEST",
  SELECT_SUPPLIER_SUCCESS: "SELECT_SUPPLIER_SUCCESS",
  SELECT_SUPPLIER_FAILURE: "SELECT_SUPPLIER_FAILURE",
  selectSupplierRequest: (request) => ({
    type: actions.SELECT_SUPPLIER_REQUEST,
    payload: request,
  }),
  selectSupplierSuccess: (product) => ({
    type: actions.SELECT_SUPPLIER_SUCCESS,
    payload: product,
  }),
  selectSupplierFailure: (error) => ({
    type: actions.SELECT_SUPPLIER_FAILURE,
    payload: error,
  }),

  //  ********* create new 平台设备 *******   //
  CREATE_EQ_NEW_REQUEST: "CREATE_EQ_NEW_REQUEST",
  CREATE_EQ_NEW_SUCCESS: "CREATE_EQ_NEW_SUCCESS",
  CREATE_EQ_NEW_FAILURE: "CREATE_EQ_NEW_FAILURE",
  createEqNewRequest: (request) => ({
    type: actions.CREATE_EQ_NEW_REQUEST,
    payload: request,
  }),
  createEqNewSuccess: (product) => ({
    type: actions.CREATE_EQ_NEW_SUCCESS,
    payload: product,
  }),
  createEqNewFailure: (error) => ({
    type: actions.CREATE_EQ_NEW_FAILURE,
    payload: error,
  }),
  //  ********* create 厂商管理 SUPPLIER Supplier *******   //
  CREATE_SUPPLIER_REQUEST: "CREATE_SUPPLIER_REQUEST",
  CREATE_SUPPLIER_SUCCESS: "CREATE_SUPPLIER_SUCCESS",
  CREATE_SUPPLIER_FAILURE: "CREATE_SUPPLIER_FAILURE",
  createSupplierRequest: (request) => ({
    type: actions.CREATE_SUPPLIER_REQUEST,
    payload: request,
  }),
  createSupplierSuccess: (product) => ({
    type: actions.CREATE_SUPPLIER_SUCCESS,
    payload: product,
  }),
  createSupplierFailure: (error) => ({
    type: actions.CREATE_SUPPLIER_FAILURE,
    payload: error,
  }),

  //  ********* edit old 平台设备 *******   //
  EDIT_EQ_OLD_REQUEST: "EDIT_EQ_OLD_REQUEST",
  EDIT_EQ_OLD_SUCCESS: "EDIT_EQ_OLD_SUCCESS",
  EDIT_EQ_OLD_FAILURE: "EDIT_EQ_OLD_FAILURE",
  editEqOldRequest: (request) => ({
    type: actions.EDIT_EQ_OLD_REQUEST,
    payload: request,
  }),
  editEqOldSuccess: (product) => ({
    type: actions.EDIT_EQ_OLD_SUCCESS,
    payload: product,
  }),
  editEqOldFailure: (error) => ({
    type: actions.EDIT_EQ_OLD_FAILURE,
    payload: error,
  }),
  //  ********* edit 厂商管理 SUPPLIER Supplier  *******   //
  EDIT_SUPPLIER_REQUEST: "EDIT_SUPPLIER_REQUEST",
  EDIT_SUPPLIER_SUCCESS: "EDIT_SUPPLIER_SUCCESS",
  EDIT_SUPPLIER_FAILURE: "EDIT_SUPPLIER_FAILURE",
  editSupplierRequest: (request) => ({
    type: actions.EDIT_SUPPLIER_REQUEST,
    payload: request,
  }),
  editSupplierSuccess: (product) => ({
    type: actions.EDIT_SUPPLIER_SUCCESS,
    payload: product,
  }),
  editSupplierFailure: (error) => ({
    type: actions.EDIT_SUPPLIER_FAILURE,
    payload: error,
  }),

  //  ********* 获取 新平台设备 Detail *******   //
  DETAIL_EQ_NEW_REQUEST: "DETAIL_EQ_NEW_REQUEST",
  DETAIL_EQ_NEW_SUCCESS: "DETAIL_EQ_NEW_SUCCESS",
  DETAIL_EQ_NEW_FAILURE: "DETAIL_EQ_NEW_FAILURE",
  detailEqNewRequest: (request) => ({
    type: actions.DETAIL_EQ_NEW_REQUEST,
    payload: request,
  }),
  detailEqNewSuccess: (product) => ({
    type: actions.DETAIL_EQ_NEW_SUCCESS,
    payload: product,
  }),
  detailEqNewFailure: (error) => ({
    type: actions.DETAIL_EQ_NEW_FAILURE,
    payload: error,
  }),
  //  ********* 获取 新平台设备 关联用户 Concat *******   //
  CONCAT_EQ_NEW_REQUEST: "CONCAT_EQ_NEW_REQUEST",
  CONCAT_EQ_NEW_SUCCESS: "CONCAT_EQ_NEW_SUCCESS",
  CONCAT_EQ_NEW_FAILURE: "CONCAT_EQ_NEW_FAILURE",
  concatEqNewRequest: (request) => ({
    type: actions.CONCAT_EQ_NEW_REQUEST,
    payload: request,
  }),
  concatEqNewSuccess: (product) => ({
    type: actions.CONCAT_EQ_NEW_SUCCESS,
    payload: product,
  }),
  concatEqNewFailure: (error) => ({
    type: actions.CONCAT_EQ_NEW_FAILURE,
    payload: error,
  }),
  //  ********* 获取 老平台设备 Detail *******   //
  DETAIL_EQ_OLD_REQUEST: "DETAIL_EQ_OLD_REQUEST",
  DETAIL_EQ_OLD_SUCCESS: "DETAIL_EQ_OLD_SUCCESS",
  DETAIL_EQ_OLD_FAILURE: "DETAIL_EQ_OLD_FAILURE",
  detailEqOldRequest: (request) => ({
    type: actions.DETAIL_EQ_OLD_REQUEST,
    payload: request,
  }),
  detailEqOldSuccess: (product) => ({
    type: actions.DETAIL_EQ_OLD_SUCCESS,
    payload: product,
  }),
  detailEqOldFailure: (error) => ({
    type: actions.DETAIL_EQ_OLD_FAILURE,
    payload: error,
  }),
  //  ********* 获取 老平台设备 关联用户 Concat *******   //
  CONCAT_EQ_OLD_REQUEST: "CONCAT_EQ_OLD_REQUEST",
  CONCAT_EQ_OLD_SUCCESS: "CONCAT_EQ_OLD_SUCCESS",
  CONCAT_EQ_OLD_FAILURE: "CONCAT_EQ_OLD_FAILURE",
  concatEqOldRequest: (request) => ({
    type: actions.CONCAT_EQ_OLD_REQUEST,
    payload: request,
  }),
  concatEqOldSuccess: (product) => ({
    type: actions.CONCAT_EQ_OLD_SUCCESS,
    payload: product,
  }),
  concatEqOldFailure: (error) => ({
    type: actions.CONCAT_EQ_OLD_FAILURE,
    payload: error,
  }),

  //  ********* 获取 新平台设备 list *******   //
  FETCH_EQ_NEW_REQUEST: "FETCH_EQ_NEW_REQUEST",
  FETCH_EQ_NEW_SUCCESS: "FETCH_EQ_NEW_SUCCESS",
  FETCH_EQ_NEW_FAILURE: "FETCH_EQ_NEW_FAILURE",
  fetchEqNewRequest: (request) => ({
    type: actions.FETCH_EQ_NEW_REQUEST,
    payload: request,
  }),
  fetchEqNewSuccess: (product) => ({
    type: actions.FETCH_EQ_NEW_SUCCESS,
    payload: product,
  }),
  fetchEqNewFailure: (error) => ({
    type: actions.FETCH_EQ_NEW_FAILURE,
    payload: error,
  }),
  //  ********* 获取 老平台设备 list *******   //
  FETCH_EQ_OLD_REQUEST: "FETCH_EQ_OLD_REQUEST",
  FETCH_EQ_OLD_SUCCESS: "FETCH_EQ_OLD_SUCCESS",
  FETCH_EQ_OLD_FAILURE: "FETCH_EQ_OLD_FAILURE",
  fetchEqOldRequest: (request) => ({
    type: actions.FETCH_EQ_OLD_REQUEST,
    payload: request,
  }),
  fetchEqOldSuccess: (product) => ({
    type: actions.FETCH_EQ_OLD_SUCCESS,
    payload: product,
  }),
  fetchEqOldFailure: (error) => ({
    type: actions.FETCH_EQ_OLD_FAILURE,
    payload: error,
  }),
  //  ********* 获取 厂商管理 list *******   //
  FETCH_SUPPLIER_REQUEST: "FETCH_SUPPLIER_REQUEST",
  FETCH_SUPPLIER_SUCCESS: "FETCH_SUPPLIER_SUCCESS",
  FETCH_SUPPLIER_FAILURE: "FETCH_SUPPLIER_FAILURE",
  fetchSupplierRequest: (request) => ({
    type: actions.FETCH_SUPPLIER_REQUEST,
    payload: request,
  }),
  fetchSupplierSuccess: (product) => ({
    type: actions.FETCH_SUPPLIER_SUCCESS,
    payload: product,
  }),
  fetchSupplierFailure: (error) => ({
    type: actions.FETCH_SUPPLIER_FAILURE,
    payload: error,
  }),

  //
  //  ********* delete 新平台设备 *******   //
  DELETE_EQ_NEW_REQUEST: "DELETE_EQ_NEW_REQUEST",
  DELETE_EQ_NEW_SUCCESS: "DELETE_EQ_NEW_SUCCESS",
  DELETE_EQ_NEW_FAILURE: "DELETE_EQ_NEW_FAILURE",
  deleteEqNewRequest: (request) => ({
    type: actions.DELETE_EQ_NEW_REQUEST,
    payload: request,
  }),
  deleteEqNewSuccess: (product) => ({
    type: actions.DELETE_EQ_NEW_SUCCESS,
    payload: product,
  }),
  deleteEqNewFailure: (error) => ({
    type: actions.DELETE_EQ_NEW_FAILURE,
    payload: error,
  }),
  //  ********* delete 老平台设备 *******   //
  DELETE_EQ_OLD_REQUEST: "DELETE_EQ_OLD_REQUEST",
  DELETE_EQ_OLD_SUCCESS: "DELETE_EQ_OLD_SUCCESS",
  DELETE_EQ_OLD_FAILURE: "DELETE_EQ_OLD_FAILURE",
  deleteEqOldRequest: (request) => ({
    type: actions.DELETE_EQ_OLD_REQUEST,
    payload: request,
  }),
  deleteEqOldSuccess: (product) => ({
    type: actions.DELETE_EQ_OLD_SUCCESS,
    payload: product,
  }),
  deleteEqOldFailure: (error) => ({
    type: actions.DELETE_EQ_OLD_FAILURE,
    payload: error,
  }),
  //  ********* delete 厂商管理 Supplier *******   //
  DELETE_SUPPLIER_REQUEST: "DELETE_SUPPLIER_REQUEST",
  DELETE_SUPPLIER_SUCCESS: "DELETE_SUPPLIER_SUCCESS",
  DELETE_SUPPLIER_FAILURE: "DELETE_SUPPLIER_FAILURE",
  deleteSupplierRequest: (request) => ({
    type: actions.DELETE_SUPPLIER_REQUEST,
    payload: request,
  }),
  deleteSupplierSuccess: (product) => ({
    type: actions.DELETE_SUPPLIER_SUCCESS,
    payload: product,
  }),
  deleteSupplierFailure: (error) => ({
    type: actions.DELETE_SUPPLIER_FAILURE,
    payload: error,
  }),

  //  ********* unbind 老平台设备 *******   //
  UNBIND_EQ_OLD_REQUEST: "UNBIND_EQ_OLD_REQUEST",
  UNBIND_EQ_OLD_SUCCESS: "UNBIND_EQ_OLD_SUCCESS",
  UNBIND_EQ_OLD_FAILURE: "UNBIND_EQ_OLD_FAILURE",
  unbindEqOldRequest: (request) => ({
    type: actions.UNBIND_EQ_OLD_REQUEST,
    payload: request,
  }),
  unbindEqOldSuccess: (product) => ({
    type: actions.UNBIND_EQ_OLD_SUCCESS,
    payload: product,
  }),
  unbindEqOldFailure: (error) => ({
    type: actions.UNBIND_EQ_OLD_FAILURE,
    payload: error,
  }),
};

export default actions;
