import orderActions from "./actions";

const INITIAL_DATA = {
  excelURL: "",
  loading: true,
  productCreateSuccess: true,
  error: null,
  orders: [],
  jsonData: [],
};
export default function orderReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case orderActions.JSON_TO_EXCEL_REQUEST:
      return {
        ...state,
        jsonData: [],
        error: null,
      };
    case orderActions.JSON_TO_EXCEL_SUCCESS:
      return {
        ...state,
        jsonData: action.payload,
        error: null,
      };
    case orderActions.GET_ORDERS_REQUEST:
      return {
        ...state,
        orders: [],
        loading: true,
        error: null,
      };
    case orderActions.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
        error: null,
      };
    case orderActions.EDIT_ORDER_REQUEST:
      return {
        ...state,
        orders: [],
        loading: true,
        error: null,
      };
    case orderActions.EDIT_ORDER_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
        error: null,
      };
    case orderActions.EDIT_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
