import productStatisticsActions from "./action";

const INITIAL_DATA = {
  product: [],
  loading: false,
  error: null,
};
export default function productStatisticsReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case productStatisticsActions.GET_PRODUCT_STATISTICS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case productStatisticsActions.GET_PRODUCT_STATISTICS_SUCCESS:
      return {
        ...state,
        product: action.payload,
        loading: false,
        error: null,
      };
    case productStatisticsActions.GET_PRODUCT_STATISTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
