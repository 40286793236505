const EditProductTemplateActions = {
  GET_EditProductTemplate_List_REQUEST: "GET_EditProductTemplate_List_REQUEST",
  GET_EditProductTemplate_List_SUCCESS: "GET_EditProductTemplate_List_SUCCESS",
  GET_EditProductTemplate_List_ERROR: "GET_EditProductTemplate_List_ERROR",

  EDIT_EditProductTemplate_List_REQUEST:
    "EDIT_EditProductTemplate_List_REQUEST",
  EDIT_EditProductTemplate_List_SUCCESS:
    "EDIT_EditProductTemplate_List_SUCCESS",
  EDIT_EditProductTemplate_List_ERROR: "EDIT_EditProductTemplate_List_ERROR",

  GET_Service_List_REQUEST: "GET_Service_List_REQUEST",
  GET_Service_List_SUCCESS: "GET_Service_List_SUCCESS",
  GET_Service_List_ERROR: "GET_Service_List_ERROR",

  GET_Params_List_REQUEST: "GET_Params_List_REQUEST",
  GET_Params_List_SUCCESS: "GET_Params_List_SUCCESS",
  GET_Params_List_ERROR: "GET_Params_List_ERROR",

  GET_SecondarySelect_List_REQUEST: "GET_SecondarySelect_List_REQUEST",
  GET_SecondarySelect_List_SUCCESS: "GET_SecondarySelect_List_SUCCESS",
  GET_SecondarySelect_List_ERROR: "GET_SecondarySelect_List_ERROR",

  POST_SaveSettingsTemplate_REQUEST: "POST_SaveSettingsTemplate_REQUEST",
  POST_SaveSettingsTemplate_SUCCESS: "POST_SaveSettingsTemplate_SUCCESS",
  POST_SaveSettingsTemplate_ERROR: "POST_SaveSettingsTemplate_ERROR",

  POST_SaveSettingsSecTemplate_REQUEST: "POST_SaveSettingsSecTemplate_REQUEST",
  POST_SaveSettingsSecTemplate_SUCCESS: "POST_SaveSettingsSecTemplate_SUCCESS",
  POST_SaveSettingsSecTemplate_ERROR: "POST_SaveSettingsSecTemplate_ERROR",
  saveTemplateSecPage: (request) => ({
    type: EditProductTemplateActions.POST_SaveSettingsSecTemplate_REQUEST,
    payload: request,
  }),
  saveTemplateSecPageSUCCESS: (data) => ({
    type: EditProductTemplateActions.POST_SaveSettingsSecTemplate_SUCCESS,
    payload: data,
  }),
  saveTemplateSecPageERROR: (error) => ({
    type: EditProductTemplateActions.POST_SaveSettingsSecTemplate_ERROR,
    payload: error,
  }),

  POST_SaveAllInfoTemplate_REQUEST: "POST_SaveAllInfoTemplate_REQUEST",

  fetchSaveAllInfoTemplate: (request) => ({
    type: EditProductTemplateActions.POST_SaveAllInfoTemplate_REQUEST,
    payload: request,
  }),

  fetchPOSTSaveSettingsTemplate: (request) => ({
    type: EditProductTemplateActions.POST_SaveSettingsTemplate_REQUEST,
    payload: request,
  }),

  fetchPOSTSaveSettingsTemplateSUCCESS: (data) => ({
    type: EditProductTemplateActions.POST_SaveSettingsTemplate_SUCCESS,
    payload: data,
  }),
  fetchPOSTSaveSettingsTemplateERROR: (error) => ({
    type: EditProductTemplateActions.POST_SaveSettingsTemplate_ERROR,
    payload: error,
  }),

  fetchGETSecondarySelectList: () => ({
    type: EditProductTemplateActions.GET_SecondarySelect_List_REQUEST,
  }),

  fetchGETSecondarySelectListSuccess: (data) => ({
    type: EditProductTemplateActions.GET_SecondarySelect_List_SUCCESS,
    payload: data,
  }),
  fetchGETSecondarySelectListError: (error) => ({
    type: EditProductTemplateActions.GET_SecondarySelect_List_ERROR,
    payload: error,
  }),

  fetchGETParamsList: (request) => ({
    type: EditProductTemplateActions.GET_Params_List_REQUEST,
    payload: request,
  }),

  fetchGETParamsListSuccess: (data) => ({
    type: EditProductTemplateActions.GET_Params_List_SUCCESS,
    payload: data,
  }),
  fetchGETParamsListError: (error) => ({
    type: EditProductTemplateActions.GET_Params_List_ERROR,
    payload: error,
  }),
  fetchGETServiceList: (request) => ({
    type: EditProductTemplateActions.GET_Service_List_REQUEST,
    payload: request,
  }),

  fetchGETServiceListSuccess: (data) => ({
    type: EditProductTemplateActions.GET_Service_List_SUCCESS,
    payload: data,
  }),
  fetchGETServiceListError: (error) => ({
    type: EditProductTemplateActions.GET_Service_List_ERROR,
    payload: error,
  }),

  fetchGETEditProductTemplateList: (request) => ({
    type: EditProductTemplateActions.GET_EditProductTemplate_List_REQUEST,
    payload: request,
  }),

  fetchGETEditProductTemplateListSuccess: (data) => ({
    type: EditProductTemplateActions.GET_EditProductTemplate_List_SUCCESS,
    payload: data,
  }),
  fetchGETEditProductTemplateListError: (error) => ({
    type: EditProductTemplateActions.GET_EditProductTemplate_List_ERROR,
    payload: error,
  }),

  fetchEDITEditProductTemplateList: (request) => ({
    type: EditProductTemplateActions.EDIT_EditProductTemplate_List_REQUEST,
    payload: request,
  }),
  fetchEDITEditProductTemplateListSuccess: (data) => ({
    type: EditProductTemplateActions.EDIT_EditProductTemplate_List_SUCCESS,
    payload: data,
  }),
  fetchEDITEditProductTemplateListError: (error) => ({
    type: EditProductTemplateActions.EDIT_EditProductTemplate_List_ERROR,
    payload: error,
  }),
};

export default EditProductTemplateActions;
