import { call, all, takeEvery, put, fork } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import { getToken, clearToken } from "@iso/lib/helpers/utility";
import CloudHawkApi2 from "@iso/config/Services/CloudHawk2";
import actions from "./actions";
import Notification from "@iso/components/Notification";

const history = createBrowserHistory();
// const fakeApiCall = true; // auth0 or express JWT

/*
export function* loginRequest() {
	yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
		yield put(statusAction.cloudHawkApiStatusClear());
		const { params } = payload;
		try {
			const response = yield call(CloudHawkApi.signInDashboard, params);
			localStorage.setItem('Id', response.data.data._id);
			if (response) {
				localStorage.setItem('user', params.email);
				//此处刷新，language会被重置
				// setTimeout(() => {
				//   window.location.reload()
				// }, 700)
			}
			yield put({
				type: actions.LOGIN_SUCCESS,
				token: response.data.token,
				userId: response.data.data._id,
				loginData: response.data,
			});
		} catch (error) {
			if (error) {
				Notification(
					"error",
					"Invalid username or password"
				);
			}
			yield put(statusAction.cloudHawkApiStatusError("Invalid username or password"));
		}
	});
}
*/

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    // yield put(statusAction.cloudHawkApiStatusClear());
    const { params } = payload;
    const obj = {
      account: params.email,
      pwd: params.password,
      code: params.code,
    };
    try {
      //暂时用mock接口,正式发布需改回2
      const response = yield call(CloudHawkApi2.signInDashboard, obj);
      if (response.success) {
        localStorage.setItem("user", params.email);
        localStorage.setItem("menus", JSON.stringify(response.data.menus));
        localStorage.setItem("Id", response.data.tokenUser.id);
        localStorage.setItem(
          "tokenUser",
          JSON.stringify(response.data.tokenUser),
        );
        localStorage.setItem(
          "lastLoginTime",
          response.data.tokenUser.lastLoginTime,
        );
        localStorage.setItem(
          "lastLoginIp",
          response.data.tokenUser.lastLoginIp,
        );
        localStorage.setItem("profilePic", response.data.tokenUser.profilePic);
        localStorage.setItem("id_token", response.data.token);
        localStorage.setItem("id_user", response.data.tokenUser.id);
        //此处刷新，language会被重置
        // setTimeout(() => {
        // 	window.location.reload()
        // }, 700)
        yield put({
          menu: response.data.menus,
          type: actions.LOGIN_SUCCESS,
          token: response.data.token,
          userId: response.data.tokenUser.id,
          loginData: response.data,
          isPushFeedBack: true,
        });
      } else {
        Notification("error", response.msg);

        yield put({
          type: actions.LOGIN_ERROR,
        });
      }
    } catch (error) {
      // if (error) {
      // 	Notification("error", "Invalid username or password");
      // }

      // yield put(
      // 	statusAction.cloudHawkApiStatusError("Invalid username or password"),
      // );
      yield put({
        type: actions.LOGIN_ERROR,
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("id_token", payload.token);
    yield localStorage.setItem("id_user", payload.userId);
    // let isPushFeedBack = yield select(state => state.Auth.isPushFeedBack)
    // if (isPushFeedBack) {
    // 	yield put(actions.loginError({ isPushFeedBack: false }))
    // 	// history.push('/dashboard/Feedback')
    // }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {
    //  yield put(actions.loginError(false))
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    history.push("/");
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
