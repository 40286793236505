import { all } from "redux-saga/effects";
import authSagas from "@iso/redux/auth/saga";
import contactSagas from "@iso/redux/contacts/saga";
import mailSagas from "@iso/redux/mail/saga";
import cardsSagas from "@iso/redux/card/saga";
import chatSagas from "@iso/redux/chat/sagas";
import articles from "@iso/redux/articles/sagas";
import investors from "@iso/redux/investors/sagas";
import feedbackSaga from "@iso/redux/feedback/saga";
import languageSaga from "@iso/redux/language/saga";
import faqSaga from "@iso/redux/faq/saga";
import productSaga from "@iso/redux/product/saga";
import productSagaV2 from "@iso/redux/productV2/saga";
import equipmentSaga from "@iso/redux/equipment/saga";
import adsSaga from "@iso/redux/ads/saga";
import userSaga from "@iso/redux/user/saga";
import productItemSaga from "@iso/redux/productItems/saga";
import countrySaga from "@iso/redux/country/saga";
import mallManagementSaga from "@iso/redux/mallManagement/saga";
import orderSaga from "@iso/redux/orders/saga";
import manageusersSaga from "@iso/redux/ManageUsers/saga";
import userStatisticsSaga from "@iso/redux/userStatistics/saga";
import StatisticsProductSaga from "@iso/redux/StatisticsProduct/saga";
import StatisticsUserSaga from "@iso/redux/StatisticsUser/saga";
import productStatisticsSaga from "@iso/redux/productStatistics/saga";
import notificationsSaga from "@iso/redux/notifications/notificationsSaga";
import brandSaga from "@iso/redux/brandName/brandSaga";
import modalSaga from "@iso/redux/modelName/modalSaga";
import firmwareSaga from "@iso/redux/firmware/firmwareSaga";
import firmwareAppSaga from "@iso/redux/firmwareApp/firmwareSaga";
import firmwareDataSaga from "@iso/redux/firmwareData/firmwareDataSaga";
import SysMenuSaga from "@iso/redux/sysMenu/saga";
import SysRoleSaga from "@iso/redux/sysRole/saga";
import SysDepartmentSaga from "@iso/redux/sysDepartment/saga";
import SysCustomerSaga from "@iso/redux/sysCustomer/saga";
import SysUserSaga from "@iso/redux/sysUser/saga";
import IAAddressApplySaga from "@iso/redux/IAAddressApply/saga";
import IAAddressManageSaga from "@iso/redux/IAAddressManage/saga";
import setTemplateSaga from "@iso/redux/setTemplate/saga";
import editProductTemplateSaga from "@iso/redux/editProductTemplate/saga";
import editProductTemplatePowerSaga from "@iso/redux/editProductTemplatePower/saga";
import welcomePageSaga from "@iso/redux/welcomePage/saga";
import devicePresetSaga from "@iso/redux/devicePresetList/saga";
import deviceTestSaga from "@iso/redux/deviceTestList/saga";
import sysLogSaga from "@iso/redux/sysLog/saga";
import messageTemplateSaga from "@iso/redux/messageTemplate/saga";
import messagePushSaga from "@iso/redux/messagePush/saga";
import faultManagementSaga from "@iso/redux/faultManagement/saga";
import ruleManageSaga from "@iso/redux/ruleManage/saga";
import apiOpenSaga from "@iso/redux/apiOpen/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    contactSagas(),
    mailSagas(),
    cardsSagas(),
    chatSagas(),
    articles(),
    investors(),
    feedbackSaga(),
    languageSaga(),
    faqSaga(),
    productSaga(),
    productSagaV2(),
    equipmentSaga(),
    adsSaga(),
    userSaga(),
    productItemSaga(),
    countrySaga(),
    mallManagementSaga(),
    orderSaga(),
    manageusersSaga(),
    userStatisticsSaga(),
    StatisticsProductSaga(),
    StatisticsUserSaga(),
    productStatisticsSaga(),
    notificationsSaga(),
    brandSaga(),
    modalSaga(),
    firmwareSaga(),
    firmwareAppSaga(),
    firmwareDataSaga(),
    SysMenuSaga(),
    SysRoleSaga(),
    SysDepartmentSaga(),
    SysCustomerSaga(),
    SysUserSaga(),
    IAAddressApplySaga(),
    IAAddressManageSaga(),
    setTemplateSaga(),
    editProductTemplateSaga(),
    editProductTemplatePowerSaga(),
    welcomePageSaga(),
    devicePresetSaga(),
    deviceTestSaga(),
    sysLogSaga(),
    messageTemplateSaga(),
    messagePushSaga(),
    faultManagementSaga(),
    ruleManageSaga(),
    apiOpenSaga(),
  ]);
}
