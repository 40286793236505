import fakeData from "./data";
import contactActions from "./actions";

const contacts = new fakeData(10).getAll();

const initState = {
  contacts,
  adminUser: [],
  selectedId: null,
  editView: false,
  loading: false,
  error: false,
  userData: "",
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case contactActions.GET_CONTACT_REQUEST:
      return {
        ...state,
      };
    case contactActions.GET_CONTACT_SUCCESS:
      let data = Object.assign([], action.payload);
      data.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        adminUser: data,
        selectedId: data[0]._id,
      };
    case contactActions.GET_CONTACT_ERROR:
      return {
        ...state,
        error: action.message,
      };

    case contactActions.CHANGE_CONTACT:
      return {
        ...state,
        selectedId: action.id,
        editView: false,
      };

    case contactActions.ADD_CONTACT:
      return {
        ...state,
        contacts: action.contacts,
        selectedId: action.selectedId,
        editView: true,
      };

    case contactActions.GET_USER_REQUEST:
      return {
        ...state,
        userData: [],
        loading: true,
      };
    case contactActions.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    //ADD PROFILE PIC
    case contactActions.ADD_PROFILEPIC_REQUEST:
      return {
        ...state,
      };
    case contactActions.ADD_PROFILEPIC_SUCCESS:
      return {
        ...state,
        adminUser: action.payload,
      };
    case contactActions.ADD_PROFILEPIC_ERROR:
      return {
        ...state,
        error: action.message,
      };

    case contactActions.ADD_CONTACT_REQUEST:
      return {
        ...state,
      };
    case contactActions.ADD_CONTACT_SUCCESS:
      return {
        ...state,
        adminUser: action.payload,
      };
    case contactActions.ADD_CONTACT_ERROR:
      return {
        ...state,
        error: action.message,
      };

    case contactActions.EDIT_CONTACT:
      return {
        ...state,
        contacts: action.contacts,
      };
    case contactActions.DELETE__CONTACT:
      return {
        ...state,
        contacts: action.contacts,
        selectedId: action.selectedId,
      };
    case contactActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view,
      };
    default:
      return state;
  }
}

export { contacts };
