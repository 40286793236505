import actions from "./actions";
import { uniqueRowKey } from "@iso/lib/helpers/uniqueKey";

const INITIAL_DATA = {
  // 0/未操作 1/操作中 2/操作成功 3/操作失败
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除
  detailStatus: "0", // 详情
  unbindStatus: "0", // 解绑

  // 获取 Select
  historySelect: [],
  propertySelect: [],
  brandSelect: [],
  categorySelect: [],
  modelSelect: [],

  productEqAllTypeSelect: [], // 产品型号 new + old
  productEqNewTypeSelect: [], // 产品型号 new
  productEqOldTypeSelect: [], // 产品型号 old
  productEqOldBrandSelect: [], // 产品品牌 old
  productEqOldCategorySelect: [], // 产品类型 Category old
  productEqOldModelSelect: [], // 产品系列 Model  old
  supplierSelect: [], // ia厂商管理 Supplier select

  productModelMenu: [], // 查询产品类型列表和对应的系列
  productTsl: [], // 属性列表（该产品已发布的属性）
  loadingProductModelMenu: false, // loading

  //
  // 获取 detail
  eqNewDetail: {},
  loadingEqNewDetail: false, // new
  eqOldDetail: {},
  eqNewConcat: [], // new 关联用户
  loadingEqNewConcat: false, // new 关联用户

  eqOldConcat: [], // old 关联用户
  loadingEqOldConcat: false, // old 关联用户

  //
  // 获取 设备管理/详情 list
  tabsListTsl: [], // 物模型属性
  // loading
  loadingTabsListTsl: false,
  loadingTabsListAssociated: false,
  loadingTabsListOnlineRecord: false,
  loadingTabsListDeviceCharge: false,
  loadingTabsListDeviceCutGrass: false,
  loadingTabsListDeviceFault: false,
  loadingTabsListDeviceReport: false,
  loadingTabsListDeviceOperation: false,
  loadingTabsListSeriesChange: false,
  loadingTabsListOnlineRecordOld: false,
  loadingTabsListDeviceReportOld: false,

  tabsListAssociated: [], // 关联设备列表
  tabsListOnlineRecord: [], // 上下线记录
  tabsTotalOnlineRecord: 0, // 上下线记录 total
  tabsListDeviceReport: [], // 设备上报日志
  tabsTotalDeviceReport: 0, // 设备上报日志 total
  tabsListDeviceOperation: [], // 设备操作日志
  tabsTotalDeviceOperation: 0, // 设备操作日志 total
  tabsListSeriesChange: [], // 组件更换记录
  tabsTotalSeriesChange: 0, // 组件更换记录 total
  // old
  tabsListOnlineRecordOld: [], // 上下线记录 old
  tabsTotalOnlineRecordOld: 0, // 上下线记录 total old
  tabsListDeviceReportOld: [], // 设备上报日志 old

  tabsListDeviceCharge: [], // 充电记录 old
  tabsTotalDeviceCharge: 0, // 充电记录 total old

  tabsListDeviceCutGrass: [], // 割草记录 old
  tabsTotalDeviceCutGrast: 0, // 割草记录 total old

  tabsListDeviceFault: [], // 故障记录 old
  tabsTotalDeviceFault: 0, // 故障记录 total old

  //
  // 获取 List
  //  *********  获取 eq New List *******   //
  eqNewList: [],
  eqNewListTotal: 0,
  loadingEqNewList: false,
  //  *********  获取 eq Old List *******   //
  eqOldList: [],
  eqOldListTotal: 0,
  loadingEqOldList: false,
  //  *********  获取 厂商管理 List *******   //
  supplierListTotal: 0,
  supplierList: [],
  loadingSupplierList: true,

  loading: true,
  productCreateSuccess: true,
  error: null,
};
export default function productV2Reducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    //  ********* 新设备 用户 解绑 *******   //
    case actions.UNBIND_USER_REQUEST:
      return {
        ...state,
        unbindStatus: "1",
        loading: true,
        error: null,
      };
    case actions.UNBIND_USER_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        unbindStatus: "2",
        loading: false,
        error: null,
      };
    case actions.UNBIND_USER_FAILURE:
      return {
        ...state,
        unbindStatus: "3",
        loading: false,
        error: null,
      };

    //
    //  ********* 查询产品类型列表和对应的系列 *******   //
    case actions.SELECT_MODEL_MENU_REQUEST:
      return {
        ...state,
        productModelMenu: [],
        loadingProductModelMenu: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_MODEL_MENU_SUCCESS:
      uniqueRowKey(action.payload);
      let productModelMenu = action.payload || [];
      for (let i = 0; i < productModelMenu.length; i++) {
        productModelMenu[i].name = productModelMenu[i].categoryName;
        for (let j = 0; j < productModelMenu[i].modelList.length; j++) {
          productModelMenu[i].modelList[j].name =
            productModelMenu[i].modelList[j].modelNumber;
        }
      }
      return {
        ...state,
        productModelMenu: productModelMenu,
        loadingProductModelMenu: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_MODEL_MENU_FAILURE:
      return {
        ...state,
        productModelMenu: [],
        loadingProductModelMenu: false,
        loading: false,
        error: null,
      };
    //  ********* 属性列表（该产品已发布的属性） *******   //
    case actions.SELECT_PRODUCT_TSL_REQUEST:
      return {
        ...state,
        productTsl: [],
        loading: true,
        error: null,
      };
    case actions.SELECT_PRODUCT_TSL_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        productTsl: action.payload,
        loading: false,
        error: null,
      };
    case actions.SELECT_PRODUCT_TSL_FAILURE:
      return {
        ...state,
        productTsl: [],
        loading: false,
        error: null,
      };

    //
    //  ********* 设备列表 new 导出 *******   //
    case actions.EXPORT_EQ_NEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    //  ********* 关联设备列表 new 导出 *******   //
    case actions.EXPORT_EQ_NEW_A_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_A_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_A_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    //  ********* 上下线记录 new 导出 *******   //
    case actions.EXPORT_EQ_NEW_OR_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_OR_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_OR_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    //  ********* 设备上报日志 new 导出 *******   //
    case actions.EXPORT_EQ_NEW_DR_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_DR_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_DR_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    //  ********* 设备操作日志 new 导出 *******   //
    case actions.EXPORT_EQ_NEW_DO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_DO_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.EXPORT_EQ_NEW_DO_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };

    //  ********* 设备列表 old 导出 *******   //
    case actions.EXPORT_EQ_OLD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.EXPORT_EQ_OLD_LIST_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.EXPORT_EQ_OLD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    //  ********* 设备列表 old 模板下载 *******   //
    case actions.EXPORT_EQ_OLD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.EXPORT_EQ_OLD_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.EXPORT_EQ_OLD_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    //  ********* 设备列表 old 异常结果导出 *******   //
    case actions.EXPORT_EQ_OLD_ERROE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.EXPORT_EQ_OLD_ERROE_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.EXPORT_EQ_OLD_ERROE_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };

    //
    // 获取 设备管理/详情 list
    //  *********  select 物模型属性 *******   //
    case actions.SELECT_EQ_NEW_T_REQUEST:
      return {
        ...state,
        tabsListTsl: [],
        loadingTabsListTsl: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_NEW_T_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsListTsl: action.payload,
        loadingTabsListTsl: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_NEW_T_FAILURE:
      return {
        ...state,
        tabsListTsl: [],
        loadingTabsListTsl: false,
        loading: false,
        error: null,
      };
    //  *********  select 关联设备列表 *******   //
    case actions.SELECT_EQ_NEW_A_REQUEST:
      return {
        ...state,
        tabsListAssociated: [],
        loadingTabsListAssociated: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_NEW_A_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsListAssociated: action.payload,
        loadingTabsListAssociated: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_NEW_A_FAILURE:
      return {
        ...state,
        tabsListAssociated: [],
        loadingTabsListAssociated: false,
        loading: false,
        error: null,
      };
    //  *********  select 上下线记录 *******   //
    case actions.SELECT_EQ_NEW_OR_REQUEST:
      return {
        ...state,
        tabsTotalOnlineRecord: 0,
        tabsListOnlineRecord: [],
        loadingTabsListOnlineRecord: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_NEW_OR_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsTotalOnlineRecord: action.payload.total,
        tabsListOnlineRecord: action.payload.list,
        loadingTabsListOnlineRecord: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_NEW_OR_FAILURE:
      return {
        ...state,
        tabsTotalOnlineRecord: 0,
        tabsListOnlineRecord: [],
        loadingTabsListOnlineRecord: false,
        loading: false,
        error: null,
      };
    //  *********  select 充电记录 *******   //
    case actions.SELECT_EQ_OLD_C_REQUEST:
      return {
        ...state,
        tabsTotalDeviceCharge: 0,
        tabsListDeviceCharge: [],
        loadingTabsListDeviceCharge: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_OLD_C_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsTotalDeviceCharge: action.payload.total,
        tabsListDeviceCharge: action.payload.list,
        loadingTabsListDeviceCharge: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_OLD_C_FAILURE:
      return {
        ...state,
        tabsTotalDeviceCharge: 0,
        tabsListDeviceCharge: [],
        loadingTabsListDeviceCharge: false,
        loading: false,
        error: null,
      };
    //  *********  select 割草记录 *******   //
    case actions.SELECT_EQ_OLD_CG_REQUEST:
      return {
        ...state,
        tabsTotalDeviceCutGrast: 0,
        tabsListDeviceCutGrass: [],
        loadingTabsListDeviceCutGrass: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_OLD_CG_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsTotalDeviceCutGrast: action.payload.total,
        tabsListDeviceCutGrass: action.payload.list,
        loadingTabsListDeviceCutGrass: false,
        loading: false,
        error: null,
      };
    case actions.SSELECT_EQ_OLD_CG_FAILURE:
      return {
        ...state,
        tabsTotalDeviceCutGrast: 0,
        tabsListDeviceCutGrass: [],
        loadingTabsListDeviceCutGrass: false,
        loading: false,
        error: null,
      };
    //  *********  select 故障记录 *******   //
    case actions.SELECT_EQ_OLD_F_REQUEST:
      return {
        ...state,
        tabsTotalDeviceFault: 0,
        tabsListDeviceFault: [],
        loadingTabsListDeviceFault: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_OLD_F_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsTotalDeviceFault: action.payload.total,
        tabsListDeviceFault: action.payload.list,
        loadingTabsListDeviceFault: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_OLD_F_FAILURE:
      return {
        ...state,
        tabsTotalDeviceFault: 0,
        tabsListDeviceFault: [],
        loadingTabsListDeviceFault: false,
        loading: false,
        error: null,
      };
    //  *********  select 设备上报日志 *******   //
    case actions.SELECT_EQ_NEW_DR_REQUEST:
      return {
        ...state,
        tabsTotalDeviceReport: 0,
        tabsListDeviceReport: [],
        loadingTabsListDeviceReport: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_NEW_DR_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsTotalDeviceReport: action.payload.total,
        tabsListDeviceReport: action.payload.list,
        loadingTabsListDeviceReport: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_NEW_DR_FAILURE:
      return {
        ...state,
        tabsTotalDeviceReport: 0,
        tabsListDeviceReport: [],
        loadingTabsListDeviceReport: false,
        loading: false,
        error: null,
      };
    //  *********  select 设备操作日志 *******   //
    case actions.SELECT_EQ_NEW_DO_REQUEST:
      return {
        ...state,
        tabsTotalDeviceOperation: 0,
        tabsListDeviceOperation: [],
        loadingTabsListDeviceOperation: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_NEW_DO_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsTotalDeviceOperation: action.payload.total,
        tabsListDeviceOperation: action.payload.list,
        loadingTabsListDeviceOperation: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_NEW_DO_FAILURE:
      return {
        ...state,
        tabsTotalDeviceOperation: 0,
        tabsListDeviceOperation: [],
        loadingTabsListDeviceOperation: false,
        loading: false,
        error: null,
      };
    //  *********  select 组件更换记录 *******   //
    case actions.SELECT_EQ_NEW_SC_REQUEST:
      return {
        ...state,
        tabsTotalSeriesChange: 0,
        tabsListSeriesChange: [],
        loadingTabsListSeriesChange: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_NEW_SC_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsTotalSeriesChange: action.payload.total,
        tabsListSeriesChange: action.payload.list,
        loadingTabsListSeriesChange: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_NEW_SC_FAILURE:
      return {
        ...state,
        tabsTotalSeriesChange: 0,
        tabsListSeriesChange: [],
        loadingTabsListSeriesChange: false,
        loading: false,
        error: null,
      };
    // old
    //  *********  select 上下线记录 old *******   //
    case actions.SELECT_EQ_NEW_OR_OLD_REQUEST:
      return {
        ...state,
        tabsTotalOnlineRecordOld: 0,
        tabsListOnlineRecordOld: [],
        loadingTabsListOnlineRecordOld: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_NEW_OR_OLD_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsTotalOnlineRecordOld: action.payload.total,
        tabsListOnlineRecordOld: action.payload.list,
        loadingTabsListOnlineRecordOld: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_NEW_OR_OLD_FAILURE:
      return {
        ...state,
        tabsTotalOnlineRecordOld: 0,
        tabsListOnlineRecordOld: [],
        loadingTabsListOnlineRecordOld: false,
        loading: false,
        error: null,
      };
    //  *********  select 设备上报日志 old *******   //
    case actions.SELECT_EQ_NEW_DR_OLD_REQUEST:
      return {
        ...state,
        tabsListDeviceReportOld: [],
        loadingTabsListDeviceReportOld: true,
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_NEW_DR_OLD_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tabsListDeviceReportOld: action.payload,
        loadingTabsListDeviceReportOld: false,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_NEW_DR_OLD_FAILURE:
      return {
        ...state,
        tabsListDeviceReportOld: [],
        loadingTabsListDeviceReportOld: false,
        loading: false,
        error: null,
      };

    //
    // 获取 select
    //  *********  获取 eq New+Old TYPE *******   //
    case actions.SELECT_EQ_ALL_PRODUCT_TYPE_REQUEST:
      return {
        ...state,
        productEqAllTypeSelect: [],
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_ALL_PRODUCT_TYPE_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        productEqAllTypeSelect: action.payload,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_ALL_PRODUCT_TYPE_FAILURE:
      return {
        ...state,
        productEqAllTypeSelect: [],
        loading: false,
        error: null,
      };
    //  *********  获取 eq New TYPE *******   //
    case actions.SELECT_EQ_NEW_PRODUCT_TYPE_REQUEST:
      return {
        ...state,
        productEqNewTypeSelect: [],
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_NEW_PRODUCT_TYPE_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        productEqNewTypeSelect: action.payload,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_NEW_PRODUCT_TYPE_FAILURE:
      return {
        ...state,
        productEqNewTypeSelect: [],
        loading: false,
        error: null,
      };
    //  *********  获取 eq Old TYPE *******   //
    case actions.SELECT_EQ_OLD_PRODUCT_TYPE_REQUEST:
      return {
        ...state,
        productEqOldTypeSelect: [],
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_OLD_PRODUCT_TYPE_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        productEqOldTypeSelect: action.payload,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_OLD_PRODUCT_TYPE_FAILURE:
      return {
        ...state,
        productEqOldTypeSelect: [],
        loading: false,
        error: null,
      };
    //  *********  获取 eq Old BRAND *******   //
    case actions.SELECT_EQ_OLD_PRODUCT_BRAND_REQUEST:
      return {
        ...state,
        productEqOldBrandSelect: [],
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_OLD_PRODUCT_BRAND_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        productEqOldBrandSelect: action.payload,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_OLD_PRODUCT_BRAND_FAILURE:
      return {
        ...state,
        productEqOldBrandSelect: [],
        loading: false,
        error: null,
      };
    //  *********  获取 eq Old CATEGORY *******   //
    case actions.SELECT_EQ_OLD_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        productEqOldCategorySelect: [],
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_OLD_PRODUCT_CATEGORY_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        productEqOldCategorySelect: action.payload,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_OLD_PRODUCT_CATEGORY_FAILURE:
      return {
        ...state,
        productEqOldCategorySelect: [],
        loading: false,
        error: null,
      };
    //  *********  获取 eq Old Model *******   //
    case actions.SELECT_EQ_OLD_PRODUCT_MODEL_REQUEST:
      return {
        ...state,
        productEqOldModelSelect: [],
        loading: true,
        error: null,
      };
    case actions.SELECT_EQ_OLD_PRODUCT_MODEL_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        productEqOldModelSelect: action.payload,
        loading: false,
        error: null,
      };
    case actions.SELECT_EQ_OLD_PRODUCT_MODEL_FAILURE:
      return {
        ...state,
        productEqOldModelSelect: [],
        loading: false,
        error: null,
      };
    //  *********  获取 ia厂商管理 Supplier  supplierSelect *******   //
    case actions.SELECT_SUPPLIER_REQUEST:
      return {
        ...state,
        supplierSelect: [],
        loading: true,
        error: null,
      };
    case actions.SELECT_SUPPLIER_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        supplierSelect: action.payload,
        loading: false,
        error: null,
      };
    case actions.SELECT_SUPPLIER_FAILURE:
      return {
        ...state,
        supplierSelect: [],
        loading: false,
        error: null,
      };

    //
    // create
    //  ********* eq New create *******   //
    case actions.CREATE_EQ_NEW_REQUEST:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case actions.CREATE_EQ_NEW_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    case actions.CREATE_EQ_NEW_FAILURE:
      return {
        ...state,
        createStatus: "3",
        loading: false,
        error: null,
      };
    //  ********* 厂商管理 SUPPLIER Supplier create *******   //
    case actions.CREATE_SUPPLIER_REQUEST:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case actions.CREATE_SUPPLIER_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    case actions.CREATE_SUPPLIER_FAILURE:
      return {
        ...state,
        createStatus: "3",
        loading: false,
        error: null,
      };

    //
    // edit
    //  ********* eq old edit *******   //
    case actions.EDIT_EQ_OLD_REQUEST:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case actions.EDIT_EQ_OLD_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        editStatus: "2",
        loading: false,
        error: null,
      };
    case actions.EDIT_EQ_OLD_FAILURE:
      return {
        ...state,
        editStatus: "3",
        loading: false,
        error: null,
      };
    //  ********* 厂商管理 SUPPLIER Supplier edit *******   //
    case actions.EDIT_SUPPLIER_REQUEST:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case actions.EDIT_SUPPLIER_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        editStatus: "2",
        loading: false,
        error: null,
      };
    case actions.EDIT_SUPPLIER_FAILURE:
      return {
        ...state,
        editStatus: "3",
        loading: false,
        error: null,
      };

    //
    // 获取 detail
    //  *********  获取 eq New Detail *******   //
    case actions.DETAIL_EQ_NEW_REQUEST:
      return {
        ...state,
        eqNewDetail: {},
        loadingEqNewDetail: true,
        loading: true,
        error: null,
      };
    case actions.DETAIL_EQ_NEW_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        eqNewDetail: action.payload,
        loadingEqNewDetail: false,
        loading: false,
        error: null,
      };
    case actions.DETAIL_EQ_NEW_FAILURE:
      return {
        ...state,
        eqNewDetail: {},
        loadingEqNewDetail: false,
        loading: false,
        error: null,
      };
    //  *********  获取 eq Old Detail *******   //
    case actions.DETAIL_EQ_OLD_REQUEST:
      return {
        ...state,
        detailStatus: "1",
        eqOldDetail: {},
        loading: true,
        error: null,
      };
    case actions.DETAIL_EQ_OLD_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        detailStatus: "2",
        eqOldDetail: action.payload,
        loading: false,
        error: null,
      };
    case actions.DETAIL_EQ_OLD_FAILURE:
      return {
        ...state,
        detailStatus: "3",
        eqOldDetail: {},
        loading: false,
        error: null,
      };

    //  *********  获取 eq New concat *******   //
    case actions.CONCAT_EQ_NEW_REQUEST:
      return {
        ...state,
        eqNewConcat: [],
        loadingEqNewConcat: true,
        loading: true,
        error: null,
      };
    case actions.CONCAT_EQ_NEW_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        eqNewConcat: action.payload,
        loadingEqNewConcat: false,
        loading: false,
        error: null,
      };
    case actions.CONCAT_EQ_NEW_FAILURE:
      return {
        ...state,
        eqNewConcat: [],
        loadingEqNewConcat: false,
        loading: false,
        error: null,
      };
    //  *********  获取 eq Old concat *******   //
    case actions.CONCAT_EQ_OLD_REQUEST:
      return {
        ...state,
        eqOldConcat: [],
        loadingEqOldConcat: true,
        loading: true,
        error: null,
      };
    case actions.CONCAT_EQ_OLD_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        eqOldConcat: action.payload,
        loadingEqOldConcat: false,
        loading: false,
        error: null,
      };
    case actions.CONCAT_EQ_OLD_FAILURE:
      return {
        ...state,
        eqOldConcat: [],
        loadingEqOldConcat: false,
        loading: false,
        error: null,
      };

    //
    // 获取 List
    //  *********  获取 eq New List *******   //
    case actions.FETCH_EQ_NEW_REQUEST:
      return {
        ...state,
        eqNewListTotal: 0,
        eqNewList: [],
        loadingEqNewList: true,
        loading: true,
        error: null,
      };
    case actions.FETCH_EQ_NEW_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        eqNewListTotal: action.payload.total,
        eqNewList: action.payload.list,
        loadingEqNewList: false,
        loading: false,
        error: null,
      };
    case actions.FETCH_EQ_NEW_FAILURE:
      return {
        ...state,
        eqNewListTotal: 0,
        eqNewList: [],
        loadingEqNewList: false,
        loading: false,
        error: null,
      };
    //  *********  获取 eq Old List *******   //
    case actions.FETCH_EQ_OLD_REQUEST:
      return {
        ...state,
        eqOldListTotal: 0,
        eqOldList: [],
        loadingEqOldList: true,
        loading: true,
        error: null,
      };
    case actions.FETCH_EQ_OLD_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        eqOldListTotal: action.payload.total,
        eqOldList: action.payload.list,
        loadingEqOldList: false,
        loading: false,
        error: null,
      };
    case actions.FETCH_EQ_OLD_FAILURE:
      return {
        ...state,
        eqOldListTotal: 0,
        eqOldList: [],
        loadingEqOldList: false,
        loading: false,
        error: null,
      };
    //  *********  获取 厂商管理 List *******   //
    case actions.FETCH_SUPPLIER_REQUEST:
      return {
        ...state,
        supplierListTotal: 0,
        supplierList: [],
        loadingSupplierList: true,
        loading: true,
        error: null,
      };
    case actions.FETCH_SUPPLIER_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        supplierListTotal: action.payload.total,
        supplierList: action.payload.list,
        loadingSupplierList: false,
        loading: false,
        error: null,
      };
    case actions.FETCH_SUPPLIER_FAILURE:
      return {
        ...state,
        supplierListTotal: 0,
        supplierList: [],
        loadingSupplierList: false,
        loading: false,
        error: null,
      };

    //
    // delete
    //  ********* eq New delete *******   //
    case actions.DELETE_EQ_NEW_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case actions.DELETE_EQ_NEW_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    case actions.DELETE_EQ_NEW_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
        loading: false,
        error: null,
      };
    //  ********* eq Old delete *******   //
    case actions.DELETE_EQ_OLD_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case actions.DELETE_EQ_OLD_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    case actions.DELETE_EQ_OLD_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
        loading: false,
        error: null,
      };
    //  ********* 厂商管理 Supplier delete *******   //
    case actions.DELETE_SUPPLIER_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case actions.DELETE_SUPPLIER_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    case actions.DELETE_SUPPLIER_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
        loading: false,
        error: null,
      };

    //  ********* eq Old unbind *******   //
    case actions.UNBIND_EQ_OLD_REQUEST:
      return {
        ...state,
        unbindStatus: "1",
        loading: true,
        error: null,
      };
    case actions.UNBIND_EQ_OLD_SUCCESS:
      // uniqueRowKey(action.payload);
      return {
        ...state,
        unbindStatus: "2",
        loading: false,
        error: null,
      };
    case actions.UNBIND_EQ_OLD_FAILURE:
      return {
        ...state,
        unbindStatus: "3",
        loading: false,
        error: null,
      };

    default:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
  }
}
