import manageUsersActions from "./actions";

const INITIAL_DATA = {
  // 0/未操作 1/操作中 2/操作成功 3/操作失败
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除
  // uploadStatus: '0', //  上传

  user_device_record_list: [],
  user_device_record_list2: [],

  usersTotal: 0,
  users: [],

  manageUsersSelect: [],

  loadingUser: true,
  manageUsersTotal: 0,
  manageUsers: [],
  manageUsersDownload: {},

  loading: true,
  error: null,
  // userProducts: [],
};
export default function manageUsersReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    // ********** 后台管理员账号列表(下拉) **********
    case manageUsersActions.GET_USERS_REQUEST:
      return {
        ...state,
        users: [],
        error: null,
      };
    case manageUsersActions.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: null,
      };
    case manageUsersActions.GET_USERS_FAILURE:
      return {
        ...state,
        users: [],
        error: null,
      };

    case manageUsersActions.GET_MANAGE_USERS_SELECT_REQUEST:
      return {
        ...state,
        // editStatus: "0",
        // deleteStatus: "0",
        // uploadStatus: '0',
        manageUsersSelect: [],
        // loadingUser: true,
        error: null,
      };
    case manageUsersActions.GET_MANAGE_USERS_SELECT_SUCCESS:
      return {
        ...state,
        manageUsersSelect: action.payload,
        // loadingUser: false,
        error: null,
      };
    case manageUsersActions.GET_MANAGE_USERS_SELECT_FAILURE:
      return {
        ...state,
        manageUsersSelect: [],
        // loading: false,
        error: action.payload,
        // loadingUser: false,
      };

    case manageUsersActions.GET_MANAGE_USERS_REQUEST:
      return {
        ...state,
        editStatus: "0",
        deleteStatus: "0",
        // uploadStatus: '0',
        manageUsersTotal: 0,
        manageUsers: [],
        loadingUser: true,
        error: null,
      };
    case manageUsersActions.GET_MANAGE_USERS_SUCCESS:
      return {
        ...state,
        manageUsersTotal: action.payload.total,
        manageUsers: action.payload.list,
        loadingUser: false,
        error: null,
      };
    case manageUsersActions.GET_MANAGE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loadingUser: false,
      };

    // 编辑
    case manageUsersActions.EDIT_MANAGE_USERS_REQUEST:
      return {
        ...state,
        editStatus: "1",
        // manageUsers: [],
        error: null,
      };
    case manageUsersActions.EDIT_MANAGE_USERS_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        // manageUsers: action.payload,
        error: null,
      };

    // 删除
    case manageUsersActions.DELETE_MANAGE_USERS_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        // manageUsers: [],
        error: null,
      };
    case manageUsersActions.DELETE_MANAGE_USERS_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
        // manageUsers: action.payload,
        error: null,
      };

    case manageUsersActions.GO_IMPORT_DOWNLOAD_REQUEST:
      return {
        ...state,
        // uploadStatus: '1',
        manageUsersDownload: {},
        error: null,
      };
    case manageUsersActions.GO_IMPORT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        // uploadStatus: '2',
        manageUsersDownload: action.payload,
        error: null,
      };

    // case manageUsersActions.GET_USER_PRODUCT_REQUEST:
    // 	return {
    // 		...state,
    // 		userProducts: [],
    // 		error: null,
    // 	};
    // case manageUsersActions.GET_USER_PRODUCT_SUCCESS:
    // 	return {
    // 		...state,
    // 		userProducts: action.payload,
    // 		error: null,
    // 	};
    case manageUsersActions.EDIT_MANAGE_USERS_FAILURE:
      return {
        ...state,
        editStatus: "3",
        loading: false,
        error: action.payload,
      };
    case manageUsersActions.DELETE_MANAGE_USERS_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
        loading: false,
        error: action.payload,
      };
    case manageUsersActions.GO_IMPORT_DOWNLOAD_FAILURE:
      return {
        ...state,
        // uploadStatus: "3",
        loading: false,
        error: action.payload,
      };

    case manageUsersActions.USER_DEVICE_RECORD_REQUEST:
      return {
        ...state,
        user_device_record_list: [],
        loading: true,
        error: null,
      };
    case manageUsersActions.USER_DEVICE_RECORD_SUCCESS:
      return {
        ...state,
        user_device_record_list: action.payload,
        loading: false,
        error: null,
      };
    case manageUsersActions.USER_DEVICE_RECORD_FAILURE:
      return {
        ...state,
        user_device_record_list: [],
        loading: false,
        error: action.payload,
      };
    case manageUsersActions.USER_DEVICE_RECORD_REQUEST2:
      return {
        ...state,
        user_device_record_list2: [],
        loading: true,
        error: null,
      };
    case manageUsersActions.USER_DEVICE_RECORD_SUCCESS2:
      return {
        ...state,
        user_device_record_list2: action.payload,
        loading: false,
        error: null,
      };
    case manageUsersActions.USER_DEVICE_RECORD_FAILURE2:
      return {
        ...state,
        user_device_record_list2: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
