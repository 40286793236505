import { call, all, takeEvery, put } from "redux-saga/effects";
import modalActions from "./modalActions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";

function* getModalRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getProductsByModels, payload);
    yield put(modalActions.getModalSuccess(response.data));
  } catch (error) {
    yield put(modalActions.getModalFailure(error));
  }
}

export default function* modalSaga() {
  yield all([takeEvery(modalActions.GET_MODAL_ID_REQUEST, getModalRequest)]);
}
