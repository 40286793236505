import { all, takeEvery, fork, put, call } from "redux-saga/effects";
import CloudHawkApi from "@iso/config/Services/CloudHawk";
import contactActions from "./actions";
import Notification from "@iso/components/Notification";

const alert = (response) => {
  Notification("success", response.message);
};

function* getContactRequest() {
  try {
    const response = yield call(CloudHawkApi.getAdminUser);
    yield put(contactActions.getContactSuccess(response.data));
  } catch (error) {
    yield put(contactActions.getContactFailure(error));
  }
}

export function* addContact() {
  yield takeEvery(contactActions.ADD_CONTACT, function* () {});
}

//ADD PROFILE PIC

export function* addProfilepic() {
  yield takeEvery("ADD_PROFILEPIC_REQUEST", function* ({ payload }) {
    try {
      const response = yield call(CloudHawkApi.signUpDashboard, payload);
      yield put(contactActions.addProfilepicSuccess(response.data));
    } catch (error) {
      yield put(contactActions.addProfilepicFailure(error));
    }
  });
}

export function* addNewContact() {
  yield takeEvery("ADD_CONTACT_REQUEST", function* ({ payload }) {
    try {
      const response = yield call(CloudHawkApi.signUpDashboard, payload);
      alert(response);
      window.location.reload();
      yield put(contactActions.getUserSuccess(response.data));
      yield put(contactActions.getContactRequest());
    } catch (error) {
      yield put(contactActions.addContactFailure(error));
    }
  });
}
export function* getUserById(request, obj) {
  const { payload } = request;

  try {
    const response = yield call(CloudHawkApi.getUserById, payload, obj);
    yield put(contactActions.getUserSuccess(response.data));
  } catch (error) {
    yield put(contactActions.addContactFailure(error));
  }
}

export function* updateProfile(request) {
  const payload = {
    id: request.obj.id,
    data: request.payload,
  };
  try {
    const response = yield call(CloudHawkApi.updateprofile, payload);
    alert(response);
    window.location.reload();
    yield put(contactActions.getUserSuccess(response.data));
  } catch (error) {
    yield put(contactActions.addContactFailure(error));
  }
}
export function* editContact() {
  yield takeEvery(contactActions.EDIT_CONTACT, function* () {});
}
export function* deleteContact() {
  yield takeEvery(
    contactActions.DELETE_CONTACT_REQUEST,
    function* ({ payload }) {
      try {
        // const response = yield call(CloudHawkApi.deleteUser, payload);
        window.location.reload();
        yield put(contactActions.getContactRequest());
      } catch (error) {
        yield put(contactActions.addContactFailure(error));
      }
    },
  );
}

export default function* rootSaga() {
  yield all([
    //fork(getContactRequest),
    fork(addContact),
    fork(addNewContact),
    takeEvery(contactActions.GET_USER_REQUEST, getUserById),
    takeEvery(contactActions.GET_CONTACT_REQUEST, getContactRequest),
    takeEvery(contactActions.UPDATE_PROFILE_REQUEST, updateProfile),
    fork(editContact),
    fork(addProfilepic),
    fork(deleteContact),
  ]);
}
