import messagePushActions from "./actions";

const INITIAL_DATA = {
  userSelectList: [],
  countrySelectList: [],
  productSelectList: [],
  messageListdata: [],
  messageListdataTotal: 0,
  messageLogdata: [],
  messageLogdataTotal: 0,

  messagePushdataLoading: false,
  messagePushdataTotal: 0,
  messagePushdata: [],

  editmessagePush: [],
  addmessagePush: [],
  loading: true,
  error: null,
};
export default function messagePushReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case messagePushActions.GET_UserSelect_List_REQUEST:
      return {
        ...state,
        userSelectList: [],
        loading: true,
        error: null,
      };
    case messagePushActions.GET_UserSelect_List_SUCCESS:
      return {
        ...state,
        userSelectList: action.payload,
        loading: false,
        error: null,
      };
    case messagePushActions.GET_UserSelect_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case messagePushActions.GET_CountrySelect_List_REQUEST:
      return {
        ...state,
        countrySelectList: [],
        loading: true,
        error: null,
      };
    case messagePushActions.GET_CountrySelect_List_SUCCESS:
      return {
        ...state,
        countrySelectList: action.payload,
        loading: false,
        error: null,
      };
    case messagePushActions.GET_CountrySelect_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case messagePushActions.GET_productSelect_List_REQUEST:
      return {
        ...state,
        productSelectList: [],
        loading: true,
        error: null,
      };
    case messagePushActions.GET_productSelect_List_SUCCESS:
      return {
        ...state,
        productSelectList: action.payload,
        loading: false,
        error: null,
      };
    case messagePushActions.GET_productSelect_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case messagePushActions.FETCH_messageList_DATA_REQUEST:
      return {
        ...state,
        messageListdata: [],
        loading: true,
        error: null,
      };
    case messagePushActions.FETCH_messageList_DATA_SUCCESS:
      return {
        ...state,
        messageListdata: action.payload.list,
        messageListdataTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case messagePushActions.FETCH_messageList_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case messagePushActions.FETCH_messageLog_DATA_REQUEST:
      return {
        ...state,
        messageLogdata: [],
        loading: true,
        error: null,
      };
    case messagePushActions.FETCH_messageLog_DATA_SUCCESS:
      let messageLogdata = Object.assign([], action.payload);
      messageLogdata.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        messageLogdata: action.payload.list,
        messageLogdataTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case messagePushActions.FETCH_messageLog_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case messagePushActions.FETCH_messagePush_DATA_REQUEST:
      return {
        ...state,
        messagePushdata: [],
        messagePushdataTotal: 0,
        messagePushdataLoading: true,
        loading: true,
        error: null,
      };
    case messagePushActions.FETCH_messagePush_DATA_SUCCESS:
      // let messagePushdata = Object.assign([], action.payload);
      // messagePushdata.forEach((element, index) => {
      //   element.key = index;
      // });
      // for (let i = 0; i < messagePushdata.length; i++) {
      //   let totalCount = messagePushdata[i]["totalCount"] || 0;
      //   let successCount = messagePushdata[i]["successCount"] || 0;
      //   if (totalCount && successCount) {
      //   }
      // }
      let messagePushdata = action.payload.list;
      for (let i = 0; i < messagePushdata.length; i++) {
        let totalCount = messagePushdata[i]["totalCount"] || 0;
        let successCount = messagePushdata[i]["successCount"] || 0;
        if (totalCount) {
          messagePushdata[i].app_per =
            Math.floor((successCount / totalCount) * 10000) / 100 + "%";
        }
      }

      return {
        ...state,
        messagePushdata: action.payload.list,
        messagePushdataTotal: action.payload.total,
        messagePushdataLoading: false,
        loading: false,
        error: null,
      };
    case messagePushActions.FETCH_messagePush_DATA_FAILURE:
      return {
        ...state,
        messagePushdata: [],
        messagePushdataTotal: 0,
        messagePushdataLoading: false,
        loading: false,
        error: action.payload,
      };
    case messagePushActions.ADD_messagePush_REQUEST:
      return {
        ...state,
        addmessagePush: [],
        loading: true,
        error: null,
      };
    case messagePushActions.ADD_messagePush_SUCCESS:
      return {
        ...state,
        addmessagePush: action.payload,
        loading: false,
        error: null,
      };
    case messagePushActions.ADD_messagePush_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // EDIT messagePush
    case messagePushActions.EDIT_messagePush_REQUEST:
      return {
        ...state,
        editmessagePush: [],
        loading: true,
        error: null,
      };
    case messagePushActions.EDIT_messagePush_SUCCESS:
      return {
        ...state,
        editmessagePush: action.payload,
        loading: false,
        error: null,
      };
    case messagePushActions.EDIT_messagePush_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case messagePushActions.DELETE_messagePush_REQUEST:
      return {
        ...state,
        // messagePushdata: [],
        loading: true,
        error: null,
      };
    case messagePushActions.DELETE_messagePush_SUCCESS:
      return {
        ...state,
        // messagePushdata: action.payload,
        loading: false,
        error: null,
      };
    case messagePushActions.DELETE_messagePush_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
