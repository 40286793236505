import appLocaleData from "react-intl/locale-data/zh";
import zhMessages from "../locales/zh-Hans.json";
import zhCN from "antd/lib/locale-provider/zh_CN";

const ZhLan = {
  messages: {
    ...zhMessages,
  },
  antd: zhCN, //start date and end date changed the chinese language null,
  locale: "zh-Hans-CN",
  data: appLocaleData,
};
export default ZhLan;
