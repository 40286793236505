import actions from "./firmwareActions";

const INITIAL_DATA = {
  // 0/未操作 1/操作中 2/操作成功 3/操作失败
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除
  detailStatus: "0", // 详情
  disableStatus: "0", //
  enableStatus: "0", //

  appVersionSelect: [], //

  firmwareLoading: false,
  firmwareList: [],
  firmwareTotal: 0,

  firmwareAppLoading: false,
  firmwareAppList: [],
  firmwareAppTotal: 0,

  firmwareAppUpdateLoading: false,
  firmwareAppUpdateList: [],
  firmwareAppUpdateTotal: 0,

  firmware: [],
  loading: false,
  error: null,
};

export default function firmwareReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    // select SELECT
    //
    case actions.SELECT_FIRMWARE_APP_REQUEST:
      return {
        ...state,
        appVersionSelect: [],
      };
    case actions.SELECT_FIRMWARE_APP_SUCCESS:
      return {
        ...state,
        appVersionSelect: action.payload,
      };
    case actions.SELECT_FIRMWARE_APP_FAILURE:
      return {
        ...state,
        appVersionSelect: [],
      };

    // create
    //
    case actions.CREATE_FIRMWARE_APP_REQUEST:
      return {
        ...state,
        createStatus: "1",
      };
    case actions.CREATE_FIRMWARE_APP_SUCCESS:
      return {
        ...state,
        createStatus: "2",
      };
    case actions.CREATE_FIRMWARE_APP_FAILURE:
      return {
        ...state,
        createStatus: "3",
      };
    //
    case actions.CREATE_FIRMWARE_APP_UPDATE_REQUEST:
      return {
        ...state,
        createStatus: "1",
      };
    case actions.CREATE_FIRMWARE_APP_UPDATE_SUCCESS:
      return {
        ...state,
        createStatus: "2",
      };
    case actions.CREATE_FIRMWARE_APP_UPDATE_FAILURE:
      return {
        ...state,
        createStatus: "3",
      };

    // edit
    //
    case actions.EDIT_FIRMWARE_APP_REQUEST:
      return {
        ...state,
        editStatus: "1",
      };
    case actions.EDIT_FIRMWARE_APP_SUCCESS:
      return {
        ...state,
        editStatus: "2",
      };
    case actions.EDIT_FIRMWARE_APP_FAILURE:
      return {
        ...state,
        editStatus: "3",
      };
    //
    case actions.EDIT_FIRMWARE_APP_UPDATE_REQUEST:
      return {
        ...state,
        editStatus: "1",
      };
    case actions.EDIT_FIRMWARE_APP_UPDATE_SUCCESS:
      return {
        ...state,
        editStatus: "2",
      };
    case actions.EDIT_FIRMWARE_APP_UPDATE_FAILURE:
      return {
        ...state,
        editStatus: "3",
      };
    //
    case actions.EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_REQUEST:
      return {
        ...state,
        editStatus: "1",
      };
    case actions.EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        editStatus: "2",
      };
    case actions.EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_FAILURE:
      return {
        ...state,
        editStatus: "3",
      };

    // delete
    //
    case actions.DELETE_FIRMWARE_APP_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
      };
    case actions.DELETE_FIRMWARE_APP_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
      };
    case actions.DELETE_FIRMWARE_APP_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
      };
    //
    case actions.DELETE_FIRMWARE_APP_UPDATE_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
      };
    case actions.DELETE_FIRMWARE_APP_UPDATE_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
      };
    case actions.DELETE_FIRMWARE_APP_UPDATE_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
      };

    // fetch
    //
    case actions.FETCH_FIRMWARE_APP_REQUEST:
      return {
        ...state,
        firmwareAppLoading: true,
        firmwareAppList: [],
        firmwareAppTotal: 0,
      };
    case actions.FETCH_FIRMWARE_APP_SUCCESS:
      return {
        ...state,
        firmwareAppLoading: false,
        firmwareAppList: action.payload.list,
        firmwareAppTotal: action.payload.total,
      };
    case actions.FETCH_FIRMWARE_APP_FAILURE:
      return {
        ...state,
        firmwareAppLoading: false,
        firmwareAppList: [],
        firmwareAppTotal: 0,
      };
    //
    case actions.FETCH_FIRMWARE_APP_UPDATE_REQUEST:
      return {
        ...state,
        firmwareAppUpdateLoading: true,
        firmwareAppUpdateList: [],
        firmwareAppUpdateTotal: 0,
      };
    case actions.FETCH_FIRMWARE_APP_UPDATE_SUCCESS:
      return {
        ...state,
        firmwareAppUpdateLoading: false,
        firmwareAppUpdateList: action.payload.list,
        firmwareAppUpdateTotal: action.payload.total,
      };
    case actions.FETCH_FIRMWARE_APP_UPDATE_FAILURE:
      return {
        ...state,
        firmwareAppUpdateLoading: false,
        firmwareAppUpdateList: [],
        firmwareAppUpdateTotal: 0,
      };

    // enable
    //
    case actions.ENABLE_FIRMWARE_APP_UPDATE_REQUEST:
      return {
        ...state,
        enableStatus: "1",
      };
    case actions.ENABLE_FIRMWARE_APP_UPDATE_SUCCESS:
      return {
        ...state,
        enableStatus: "2",
      };
    case actions.ENABLE_FIRMWARE_APP_UPDATE_FAILURE:
      return {
        ...state,
        enableStatus: "3",
      };

    // disable
    //
    case actions.DISABLE_FIRMWARE_APP_UPDATE_REQUEST:
      return {
        ...state,
        disableStatus: "1",
      };
    case actions.DISABLE_FIRMWARE_APP_UPDATE_SUCCESS:
      return {
        ...state,
        disableStatus: "2",
      };
    case actions.DISABLE_FIRMWARE_APP_UPDATE_FAILURE:
      return {
        ...state,
        disableStatus: "3",
      };

    // DOWNLOAD
    //
    case actions.DOWNLOAD_VERSION_APP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_APP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_APP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    //
    case actions.DOWNLOAD_VERSION_APP_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_APP_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_APP_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
