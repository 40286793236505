// saga.js
import { call, all, takeEvery, put, select } from "redux-saga/effects";
import DeviceTestActions from "./actions";
import productActions from "../productV2/actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";
import qs from "qs";
import Notification from "@iso/components/Notification";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    // message: "Msg:" + response.msg,
    message: "" + response.msg,
  });
};

//get device test new
function* fetchDeviceTestNewDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getDeviceTestNewPage, payload);
    if (response.code === 100) {
      yield put(DeviceTestActions.fetchDeviceTestNewDataSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(DeviceTestActions.fetchDeviceTestNewDataFailure(response));
    }
  } catch (error) {
    yield put(DeviceTestActions.fetchDeviceTestNewDataFailure(error));
  }
}
//get device test old
function* fetchDeviceTestOldDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getDeviceTestOldPage, payload);
    if (response.code === 100) {
      yield put(DeviceTestActions.fetchDeviceTestOldDataSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(DeviceTestActions.fetchDeviceTestOldDataFailure(response));
    }
  } catch (error) {
    yield put(DeviceTestActions.fetchDeviceTestOldDataFailure(error));
  }
}

//add device test new
function* addDeviceTestNewDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.createDeviceTestNew,
      payload.params1,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(DeviceTestActions.fetchDeviceTestNewDataRequest(payload.params2));
    yield put(productActions.fetchDeviceTestOrderRequest({ isNew: true }));
    yield put(productActions.fetchDeviceTestBatchRequest({ isNew: true }));
  }
}

// EDIT DeviceTest new
function* editDeviceTestNewDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.editDeviceTestNew,
      payload.params1,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(DeviceTestActions.fetchDeviceTestNewDataRequest(payload.params2));
    yield put(productActions.fetchDeviceTestOrderRequest({ isNew: true }));
    yield put(productActions.fetchDeviceTestBatchRequest({ isNew: true }));
  }
}

//add device test old
function* addDeviceTestOldDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.createDeviceTestOld,
      payload.params1,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(DeviceTestActions.fetchDeviceTestOldDataRequest(payload.params2));
  }
}

// EDIT DeviceTest old
function* editDeviceTestOldDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.editDeviceTestOld,
      payload.params1,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(DeviceTestActions.fetchDeviceTestOldDataRequest(payload.params2));
  }
}

// Warehousing DeviceTest NEW
function* WarehousingDeviceTestNewRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.wareHousingDeviceTestNew,
      payload.idlist,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(DeviceTestActions.fetchDeviceTestNewDataRequest(payload.params2));
  }
}

// Warehousing DeviceTest Old
function* WarehousingDeviceTestOldRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.wareHousingDeviceTestOld,
      payload.idlist,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(DeviceTestActions.fetchDeviceTestOldDataRequest(payload.params2));
  }
}

//重检
function* ReCheckDeviceTestRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    if (payload.tableType === "New") {
      const response = yield call(
        CloudHawkApi.reCheckDeviceTestNew,
        payload.idlist,
      );
      if (response.success) {
        alert(response);
      } else {
        Error(response);
      }
    } else {
      const response = yield call(
        CloudHawkApi.reCheckDeviceTestOld,
        payload.idlist,
      );
      if (response.success) {
        alert(response);
      } else {
        Error(response);
      }
    }
  } catch (error) {
  } finally {
    if (payload.tableType === "New") {
      yield put(
        DeviceTestActions.fetchDeviceTestNewDataRequest(payload["params2"]),
      );
    } else {
      yield put(
        DeviceTestActions.fetchDeviceTestOldDataRequest(payload["params2"]),
      );
    }
  }
}

//重新录入结果
function* SaveResultDeviceTestRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    if (payload.tableType === "New") {
      const response = yield call(
        CloudHawkApi.saveResultDeviceTestNew,
        payload.params1,
      );
      if (response.success) {
        alert(response);
      } else {
        Error(response);
      }
    } else {
      const response = yield call(
        CloudHawkApi.saveResultDeviceTestOld,
        payload.params1,
      );
      if (response.success) {
        alert(response);
      } else {
        Error(response);
      }
    }
  } catch (error) {
  } finally {
    if (payload.tableType === "New") {
      yield put(
        DeviceTestActions.fetchDeviceTestNewDataRequest(payload["params2"]),
      );
    } else {
      yield put(
        DeviceTestActions.fetchDeviceTestOldDataRequest(payload["params2"]),
      );
    }
  }
}
//删除
function* deleteDeviceTestDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    if (payload.tableType === "New") {
      const response = yield call(CloudHawkApi.deleteDeviceTestNew, payload);
      const total = yield select(
        (state) => state.deviceTest.DeviceTestNewdataTotal,
      );
      if (response.success) {
        if (
          payload["params2"]["pageNum"] >
          Math.ceil((total - 1) / payload["params2"]["pageSize"])
        ) {
          //last page
          payload["params2"]["pageNum"] = payload["params2"]["pageNum"] - 1;
        }
        yield put(
          DeviceTestActions.fetchDeviceTestNewDataRequest(payload["params2"]),
        );
        alert(response);
      } else {
        yield put(
          DeviceTestActions.fetchDeviceTestNewDataRequest(payload["params2"]),
        );
        Error(response);
      }
    } else {
      const response = yield call(CloudHawkApi.deleteDeviceTestOld, payload);
      const total = yield select(
        (state) => state.deviceTest.DeviceTestOlddataTotal,
      );
      if (response.success) {
        if (
          payload["params2"]["pageNum"] >
          Math.ceil((total - 1) / payload["params2"]["pageSize"])
        ) {
          //last page
          payload["params2"]["pageNum"] = payload["params2"]["pageNum"] - 1;
        }
        yield put(
          DeviceTestActions.fetchDeviceTestOldDataRequest(payload["params2"]),
        );
        alert(response);
      } else {
        yield put(
          DeviceTestActions.fetchDeviceTestOldDataRequest(payload["params2"]),
        );
        Error(response);
      }
    }
  } catch (error) {}
}
function* exportErrorDeviceTestNew(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.exportErrorDeviceTestNew, payload);
    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);
  } catch (error) {}
}

function* exportErrorDeviceTestOld(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.exportErrorDeviceTestOld, payload);
    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);
  } catch (error) {}
}

export default function* DeviceTestSaga() {
  yield all([
    takeEvery(
      DeviceTestActions.FETCH_DeviceTestNew_DATA_REQUEST,
      fetchDeviceTestNewDataRequest,
    ),
    takeEvery(
      DeviceTestActions.FETCH_DeviceTestOld_DATA_REQUEST,
      fetchDeviceTestOldDataRequest,
    ),
    takeEvery(
      DeviceTestActions.ADD_DeviceTestNew_REQUEST,
      addDeviceTestNewDataRequest,
    ),
    takeEvery(
      DeviceTestActions.EDIT_DeviceTestNew_REQUEST,
      editDeviceTestNewDataRequest,
    ),
    takeEvery(
      DeviceTestActions.ADD_DeviceTestOld_REQUEST,
      addDeviceTestOldDataRequest,
    ),
    takeEvery(
      DeviceTestActions.EDIT_DeviceTestOld_REQUEST,
      editDeviceTestOldDataRequest,
    ),
    takeEvery(
      DeviceTestActions.DELETE_DeviceTest_REQUEST,
      deleteDeviceTestDataRequest,
    ),
    takeEvery(
      DeviceTestActions.EXPORT_Error_DeviceTestNew_REQUEST,
      exportErrorDeviceTestNew,
    ),
    takeEvery(
      DeviceTestActions.EXPORT_Error_DeviceTestOld_REQUEST,
      exportErrorDeviceTestOld,
    ),
    takeEvery(
      DeviceTestActions.Wrehousing_DeviceTestNew_REQUEST,
      WarehousingDeviceTestNewRequest,
    ),
    takeEvery(
      DeviceTestActions.Warehousing_DeviceTestOld_REQUEST,
      WarehousingDeviceTestOldRequest,
    ),
    takeEvery(
      DeviceTestActions.ReCheck_DeviceTest_REQUEST,
      ReCheckDeviceTestRequest,
    ),
    takeEvery(
      DeviceTestActions.SaveResult_DeviceTest_REQUEST,
      SaveResultDeviceTestRequest,
    ),
  ]);
}
