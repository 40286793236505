import { call, all, takeEvery, put, select } from "redux-saga/effects";
import SysCustomerActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk2";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
  });
};

function* getCustomerList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getCustomerList, payload);
    if (!response.success) {
      Error(response);
    }
    yield put(SysCustomerActions.fetchGETCustomerListSuccess(response.data));
  } catch (error) {
    yield put(SysCustomerActions.fetchGETCustomerListError(error));
  }
}
function* getCustomerALLList() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getCustomerALLList);
    yield put(SysCustomerActions.fetchGETCustomerALLListSuccess(response.data));
  } catch (error) {
    yield put(SysCustomerActions.fetchGETCustomerALLListError(error));
  }
}

function* addCustomerList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addCustomerList, payload);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    // yield put(SysCustomerActions.fetchGETCustomerListError(error));
  } finally {
    yield put(
      SysCustomerActions.fetchGETCustomerList({
        pageNum: payload.pageNum,
        pageSize: payload.pageSize,
      }),
    );
  }
}

function* editCustomerList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editCustomerList, payload);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    // yield put(SysCustomerActions.fetchGETCustomerListError(error));
  }
  yield put(
    SysCustomerActions.fetchGETCustomerList({
      pageNum: payload.pageNum,
      pageSize: payload.pageSize,
      name: payload.searchname,
    }),
  );
}

function* delCustomerList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.delCustomerList, payload);
    if (response.success) {
      const total = yield select((state) => state.SysCustomer.totalList);
      if (payload.pageNum > Math.ceil((total - 1) / payload.pageSize)) {
        //last page
        const params = {
          name: payload.name,
          pageNum: payload.pageNum - 1,
          pageSize: payload.pageSize,
        };
        yield put(SysCustomerActions.fetchGETCustomerList(params));
      } else {
        //this page
        const params = {
          name: payload.name,
          pageNum: payload.pageNum,
          pageSize: payload.pageSize,
        };
        yield put(SysCustomerActions.fetchGETCustomerList(params));
      }

      alert(response);
    } else {
      const params = {
        name: payload.name,
        pageNum: payload.pageNum,
        pageSize: payload.pageSize,
      };
      yield put(SysCustomerActions.fetchGETCustomerList(params));
      Error(response);
    }
  } catch (error) {}
}
export default function* userSaga() {
  yield all([
    takeEvery(SysCustomerActions.GET_Customer_List_REQUEST, getCustomerList),
    takeEvery(
      SysCustomerActions.GET_CustomerALL_List_REQUEST,
      getCustomerALLList,
    ),
    takeEvery(SysCustomerActions.ADD_Customer_List_REQUEST, addCustomerList),
    takeEvery(SysCustomerActions.EDIT_Customer_List_REQUEST, editCustomerList),
    takeEvery(SysCustomerActions.DEL_Customer_List_REQUEST, delCustomerList),
  ]);
}
