import { call, all, takeEvery, put } from "redux-saga/effects";
import FaultManageMentActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import qs from "qs";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

//获取新老型号下拉框
function* getProductSelectList() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getProductSelectList);
    if (response.success) {
      yield put(
        FaultManageMentActions.fetchGETproductSelectSuccess(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(FaultManageMentActions.fetchGETproductSelectListError(error));
  }
}

//获取已处理故障列表
function* getfaultHandledList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  payload["state"] = "Y";
  try {
    const response = yield call(CloudHawkApi.getfaultPage, payload);
    if (response.success) {
      yield put(
        FaultManageMentActions.fetchGETfaultHandledListSuccess(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(FaultManageMentActions.fetchGETfaultHandledListError(error));
  }
}
//获取待处理故障列表
function* getfaultNoHandlerList(request) {
  // yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  payload["state"] = "N";
  try {
    const response = yield call(CloudHawkApi.getfaultPage, payload);
    if (response.success) {
      yield put(
        FaultManageMentActions.fetchGETfaultNoHandlerListSuccess(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(FaultManageMentActions.fetchGETfaultNoHandlerListError(error));
  }
}
//待处理列表--批量处理
function* editfaultNoHandlerList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.editfaultPage,
      payload.params1.selectedRowKeysNoHandle,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(
      FaultManageMentActions.fetchGETfaultNoHandlerList(payload.params2),
    );
    yield put(
      FaultManageMentActions.fetchGETfaultHandledList(payload.params_ed),
    );
  }
}

//待处理列表--导出
function* exportfaultNoHandlerList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  payload["state"] = "N";
  try {
    const response = yield call(CloudHawkApi.exportFaultList, payload);
    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);
  } catch (error) {}
}

//已处理列表--导出
function* exportfaultHandledList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  payload["state"] = "Y";
  try {
    const response = yield call(CloudHawkApi.exportFaultList, payload);
    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);
  } catch (error) {}
}

export default function* userSaga() {
  yield all([
    takeEvery(
      FaultManageMentActions.GET_faultHandled_List_REQUEST,
      getfaultHandledList,
    ),
    takeEvery(
      FaultManageMentActions.GET_faultNoHandler_List_REQUEST,
      getfaultNoHandlerList,
    ),
    takeEvery(
      FaultManageMentActions.Edit_faultNoHandler_List_REQUEST,
      editfaultNoHandlerList,
    ),
    takeEvery(
      FaultManageMentActions.EXPORT_faultHandled_List_REQUEST,
      exportfaultHandledList,
    ),
    takeEvery(
      FaultManageMentActions.EXPORT_faultNoHandler_List_REQUEST,
      exportfaultNoHandlerList,
    ),
    takeEvery(
      FaultManageMentActions.GET_productSelect_List_REQUEST,
      getProductSelectList,
    ),
  ]);
}
