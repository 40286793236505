import language from "@iso/config/language.config";

import englishLang from "@iso/assets/images/flag/uk.svg";
import chineseLang from "@iso/assets/images/flag/china.svg";
import germanLang from "@iso/assets/images/flag/german.svg";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang,
    },
    {
      languageId: "chinese",
      locale: "zh",
      text: "Chinese",
      icon: chineseLang,
    },
    {
      languageId: "german",
      locale: "de",
      text: "German",
      icon: germanLang,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });

  localStorage.setItem("lang", lang);
  return selecetedLanguage;
}
export default config;
