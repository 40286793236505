const StatisticsProductActions = {
  // /api/v2/web/device/analysis/distribution/export
  export_distribution_REQUESTP: "export_distribution_REQUESTP",
  export_distribution_SUCCESSP: "export_distribution_SUCCESSP",
  export_distribution_FAILUREP: "export_distribution_FAILUREP",
  export_distributionRequestP: (request) => ({
    type: StatisticsProductActions.export_distribution_REQUESTP,
    payload: request,
  }),
  export_distributionSuccessP: (welComePage) => ({
    type: StatisticsProductActions.export_distribution_SUCCESSP,
    payload: welComePage,
  }),
  export_distributionFailureP: (error) => ({
    type: StatisticsProductActions.export_distribution_FAILUREP,
    payload: error,
  }),

  // product
  load_productSelect_REQUESTP: "load_productSelect_REQUESTP",
  load_productSelect_SUCCESSP: "load_productSelect_SUCCESSP",
  load_productSelect_FAILUREP: "load_productSelect_FAILUREP",
  load_productSelectRequestP: (request) => ({
    type: StatisticsProductActions.load_productSelect_REQUESTP,
    payload: request,
  }),
  load_productSelectSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_productSelect_SUCCESSP,
    payload: welComePage,
  }),
  load_productSelectFailureP: (error) => ({
    type: StatisticsProductActions.load_productSelect_FAILUREP,
    payload: error,
  }),

  // country
  load_countrySelect_REQUESTP: "load_countrySelect_REQUESTP",
  load_countrySelect_SUCCESSP: "load_countrySelect_SUCCESSP",
  load_countrySelect_FAILUREP: "load_countrySelect_FAILUREP",
  load_countrySelectRequestP: (request) => ({
    type: StatisticsProductActions.load_countrySelect_REQUESTP,
    payload: request,
  }),
  load_countrySelectSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_countrySelect_SUCCESSP,
    payload: welComePage,
  }),
  load_countrySelectFailureP: (error) => ({
    type: StatisticsProductActions.load_countrySelect_FAILUREP,
    payload: error,
  }),

  // 通讯类型 load_communication
  // /api/v2/web/device/analysis/communication/type
  load_communication_REQUESTP: "load_communication_REQUESTP",
  load_communication_SUCCESSP: "load_communication_SUCCESSP",
  load_communication_FAILUREP: "load_communication_FAILUREP",
  load_communicationRequestP: (request) => ({
    type: StatisticsProductActions.load_communication_REQUESTP,
    payload: request,
  }),
  load_communicationSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_communication_SUCCESSP,
    payload: welComePage,
  }),
  load_communicationFailureP: (error) => ({
    type: StatisticsProductActions.load_communication_FAILUREP,
    payload: error,
  }),

  // 设备新增
  // /api/v2/web/device/analysis/newly/added
  load_newly_added_REQUESTP: "load_newly_added_REQUESTP",
  load_newly_added_SUCCESSP: "load_newly_added_SUCCESSP",
  load_newly_added_FAILUREP: "load_newly_added_FAILUREP",
  load_newly_addedRequestP: (request) => ({
    type: StatisticsProductActions.load_newly_added_REQUESTP,
    payload: request,
  }),
  load_newly_addedSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_newly_added_SUCCESSP,
    payload: welComePage,
  }),
  load_newly_addedFailureP: (error) => ({
    type: StatisticsProductActions.load_newly_added_FAILUREP,
    payload: error,
  }),

  // 设备活跃度
  // /api/v2/web/device/analysis/activation
  load_activation_REQUESTP: "load_activation_REQUESTP",
  load_activation_SUCCESSP: "load_activation_SUCCESSP",
  load_activation_FAILUREP: "load_activation_FAILUREP",
  load_activationRequestP: (request) => ({
    type: StatisticsProductActions.load_activation_REQUESTP,
    payload: request,
  }),
  load_activationSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_activation_SUCCESSP,
    payload: welComePage,
  }),
  load_activationFailureP: (error) => ({
    type: StatisticsProductActions.load_activation_FAILUREP,
    payload: error,
  }),

  // 设备分布
  // /api/v2/web/device/analysis/distribution
  load_distribution_REQUESTP: "load_distribution_REQUESTP",
  load_distribution_SUCCESSP: "load_distribution_SUCCESSP",
  load_distribution_FAILUREP: "load_distribution_FAILUREP",
  load_distributionRequestP: (request) => ({
    type: StatisticsProductActions.load_distribution_REQUESTP,
    payload: request,
  }),
  load_distributionSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_distribution_SUCCESSP,
    payload: welComePage,
  }),
  load_distributionFailureP: (error) => ({
    type: StatisticsProductActions.load_distribution_FAILUREP,
    payload: error,
  }),

  // 设备实时在线
  // /api/v2/web/device/analysis/realtime/online
  load_realtime_online_REQUESTP: "load_realtime_online_REQUESTP",
  load_realtime_online_SUCCESSP: "load_realtime_online_SUCCESSP",
  load_realtime_online_FAILUREP: "load_realtime_online_FAILUREP",
  load_realtime_onlineRequestP: (request) => ({
    type: StatisticsProductActions.load_realtime_online_REQUESTP,
    payload: request,
  }),
  load_realtime_onlineSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_realtime_online_SUCCESSP,
    payload: welComePage,
  }),
  load_realtime_onlineFailureP: (error) => ({
    type: StatisticsProductActions.load_realtime_online_FAILUREP,
    payload: error,
  }),

  // 版本分布
  // /api/v2/web/device/analysis/version/division
  load_version_division_REQUESTP: "load_version_division_REQUESTP",
  load_version_division_SUCCESSP: "load_version_division_SUCCESSP",
  load_version_division_FAILUREP: "load_version_division_FAILUREP",
  load_version_divisionRequestP: (request) => ({
    type: StatisticsProductActions.load_version_division_REQUESTP,
    payload: request,
  }),
  load_version_divisionSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_version_division_SUCCESSP,
    payload: welComePage,
  }),
  load_version_divisionFailureP: (error) => ({
    type: StatisticsProductActions.load_version_division_FAILUREP,
    payload: error,
  }),

  // 产品型号
  // /api/v2/web/device/analysis/product/usage
  load_product_usage_REQUESTP: "load_product_usage_REQUESTP",
  load_product_usage_SUCCESSP: "load_product_usage_SUCCESSP",
  load_product_usage_FAILUREP: "load_product_usage_FAILUREP",
  load_product_usageRequestP: (request) => ({
    type: StatisticsProductActions.load_product_usage_REQUESTP,
    payload: request,
  }),
  load_product_usageSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_product_usage_SUCCESSP,
    payload: welComePage,
  }),
  load_product_usageFailureP: (error) => ({
    type: StatisticsProductActions.load_product_usage_FAILUREP,
    payload: error,
  }),

  // 设备故障
  // /api/v2/web/device/analysis/fault
  load_fault_REQUESTP: "load_fault_REQUESTP",
  load_fault_SUCCESSP: "load_fault_SUCCESSP",
  load_fault_FAILUREP: "load_fault_FAILUREP",
  load_faultRequestP: (request) => ({
    type: StatisticsProductActions.load_fault_REQUESTP,
    payload: request,
  }),
  load_faultSuccessP: (welComePage) => ({
    type: StatisticsProductActions.load_fault_SUCCESSP,
    payload: welComePage,
  }),
  load_faultFailureP: (error) => ({
    type: StatisticsProductActions.load_fault_FAILUREP,
    payload: error,
  }),
};

export default StatisticsProductActions;
