// saga.js
import { call, all, takeEvery, put } from "redux-saga/effects";
import feedbackActions from "./actions";
import CloudHawkApi3 from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import Notification from "@iso/components/Notification";

const alert = (response) => {
  Notification("success", response.msg);
};

function* fetchfeedbackDataEffect(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.getFeedBackList, payload);
    if (response.success) {
      yield put(feedbackActions.fetchfeedbackDataSuccess(response.data));
    }
  } catch (error) {
    yield put(feedbackActions.fetchfeedbackDataFailure(error));
  }
}

function* setfeedDataRequest(request) {
  const { payload } = request;
  try {
    yield put(feedbackActions.setfeedDataSuccess(payload));
  } catch (error) {
    yield put(feedbackActions.setfeedDataFailure(error));
  }
}

function* replyfeedbackRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    yield call(CloudHawkApi3.replyWorkOrder, payload);
    yield put(feedbackActions.replyfeedDataSuccess(payload.data));
    // yield call(fetchfeedbackDataEffect, { payload: { type: 1 } });
  } catch (error) {
    yield put(feedbackActions.replyfeedDataFailure(error));
  }
}

//删除已关闭的工单
function* deleteFeedbackRecord(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi3.deleteFeedbackList,
      payload.params1,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(feedbackActions.fetchfeedbackDataRequest(payload.params2));
    yield put(feedbackActions.fetGetFeedBackCount());
  }
}

//待处理--改为处理中
function* changeFeedbackStatusRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi3.changeWorkOrderStatus,
      payload.params1,
    );
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(feedbackActions.fetchfeedbackDataRequest(payload.params2));
    } else {
      Notification("error", response.msg);
      yield put(feedbackActions.changeFeedbackStatusFailure(response));
    }
    //yield put(feedbackActions.changeFeedbackStatusSuccess(response.data));
  } catch (error) {
    yield put(feedbackActions.changeFeedbackStatusFailure(error));
  } finally {
    yield put(feedbackActions.fetGetFeedBackCount());
  }
}

//打开 / 关闭
function* closedFeedbackStatusRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.closeWorkOrder, payload.params1);
    if (response.success) {
      alert(response);
      yield put(feedbackActions.fetchfeedbackDataRequest(payload.params2));
    }
  } catch (error) {
    yield put(feedbackActions.changeFeedbackStatusClosedFailure(error));
  } finally {
    yield put(feedbackActions.fetGetFeedBackCount());
  }
}
// v2-获取新的总数
function* getFeedbackCount() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi3.getFeedBackCount);
    if (response.success) {
      yield put(feedbackActions.fetGetFeedBackCountSuccess(response.data));
    }
  } catch (error) {}
}

function* getRelateWorkOrder(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.relateWorkOrder, payload.params1);
    if (response.success) {
      yield put(feedbackActions.fetchfeedbackDataRequest(payload.params2));
    }
  } catch (error) {}
}
export default function* profileSaga() {
  yield all([
    takeEvery(
      feedbackActions.FETCH_FEEDBACK_DATA_REQUEST,
      fetchfeedbackDataEffect,
    ),
    takeEvery(feedbackActions.SET_FEEDBACK_REQUEST, setfeedDataRequest),
    takeEvery(feedbackActions.REPLY_FEEDBACK_REQUEST, replyfeedbackRequest),
    takeEvery(
      feedbackActions.CHANGE_FEEDBACK_STATUS_REQUEST,
      changeFeedbackStatusRequest,
    ),
    takeEvery(
      feedbackActions.CHANGE_FEEDBACK_STATUS_CLOSED_REQUEST,
      closedFeedbackStatusRequest,
    ),
    takeEvery(feedbackActions.FETCH_FEEDBACK_COUNT_REQUEST, getFeedbackCount),
    takeEvery(feedbackActions.Relate_Work_Order_REQUEST, getRelateWorkOrder),
    takeEvery(feedbackActions.Delete_Feedback_REQUEST, deleteFeedbackRecord),
  ]);
}
