import { call, all, takeEvery, put } from "redux-saga/effects";
import SysLogActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

// const alert = (response) => {
//     notification.success(
//         {
//             message:response.msg,
//         }
//     );
// }

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

function* getWebLogList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getWeblogPageList, payload);
    if (response.success) {
      yield put(SysLogActions.fetchGETWebLogListSuccess(response.data));
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(SysLogActions.fetchGETWebLogListError(error));
  }
}

function* getAppLogList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getApplogPageList, payload);
    if (response.success) {
      yield put(SysLogActions.fetchGETAppLogListSuccess(response.data));
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(SysLogActions.fetchGETAppLogListError(error));
  }
}
export default function* userSaga() {
  yield all([
    takeEvery(SysLogActions.GET_WebLog_List_REQUEST, getWebLogList),
    takeEvery(SysLogActions.GET_AppLog_List_REQUEST, getAppLogList),
  ]);
}
