import productActions from "./actions";
import { uniqueRowKey } from "@iso/lib/helpers/uniqueKey";

const INITIAL_DATA = {
  // 0/未操作 1/操作中 2/操作成功 3/操作失败
  recoveryStatus: "0", //  recovery
  copyStatus: "0", //  copy
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除
  detailStatus: "0", // 详情
  upStatus: "0", //

  codeAble: "0", // 0 / 1 / 2

  // 获取 Select
  seriesSelectAim: [],
  seriesSelectAimSearh: [],
  historySelect: [],
  propertySelect: [],
  brandSelect: [],
  categorySelect: [],
  modelSelect: [],
  seriesSelect: [],
  clientSelect: [],

  productListV1pTotal: 0,
  productListV1p: [],
  productListV1pLoading: false,

  productData: [],
  productDataFile: [],
  addProduct: [],
  commList: [],
  commType: [],

  //
  // 获取 List
  //  *********  获取 Brands List *******   //
  brandsList: [],
  brandsListTotal: 0,
  brandsListLoading: false,
  //  *********  获取 Categories List *******   //
  categoriesList: [],
  categoriesListTotal: 0,
  categoriesListLoading: false,
  //  *********  获取 Models List *******   //
  modelList: [],
  modelListTotal: 0,
  modelListLoading: false,
  //  *********  获取 Series List *******   //
  seriesList: [],
  seriesListTotal: 0,
  seriesListLoading: false,
  //  *********  获取 client List *******   //
  clientList: [],
  clientListTotal: 0,
  clientListLoading: false,

  //
  // 详情
  //  *********  获取 Product Series 详情 v2 *******   //
  seriesDetail: {},
  //
  tslDetail: {},

  //获取产品型号下拉框
  typeSelectList: [],
  //
  // 产品型号
  //  *********  新增 产品型号 *******   //
  typeDetail: {},
  typeList: [],
  typeListTotal: 0,
  typeListLoading: false,

  //
  // 物模型
  //  ********* 功能列表 *******   //
  tslFunctionData: {},
  loadingTsl: false,

  deviceTestOrderList: [],
  deviceTestBatchList: [],

  modelCategory: [],
  productActions: [],
  productActionType: [],
  editProduct: [],
  getProduct: [],
  deleteProduct: [],
  loading: true,
  productCreateSuccess: true,
  error: null,
};
export default function productV2Reducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    //  ********* 根据订单号、批次号入库 *******   //
    case productActions.UP_DEVICE_ORDER_BATCH_REQUEST:
      return {
        ...state,
        error: null,
        upStatus: "1",
      };
    case productActions.UP_DEVICE_ORDER_BATCH_SUCCESS:
      return {
        ...state,
        error: null,
        upStatus: "2",
      };
    case productActions.UP_DEVICE_ORDER_BATCH_FAILURE:
      return {
        ...state,
        error: null,
        upStatus: "3",
      };
    //  ********* 产测订单号下拉 *******   //
    case productActions.FETCH_DEVICE_ORDER_REQUEST:
      return {
        ...state,
        error: null,
        deviceTestOrderList: [],
      };
    case productActions.FETCH_DEVICE_ORDER_SUCCESS:
      return {
        ...state,
        error: null,
        deviceTestOrderList: action.payload,
      };
    case productActions.FETCH_DEVICE_ORDER_FAILURE:
      return {
        ...state,
        error: null,
        deviceTestOrderList: [],
      };
    //  ********* 产测订单号下拉 *******   //
    case productActions.FETCH_DEVICE_BATCH_REQUEST:
      return {
        ...state,
        error: null,
        deviceTestBatchList: [],
      };
    case productActions.FETCH_DEVICE_BATCH_SUCCESS:
      return {
        ...state,
        error: null,
        deviceTestBatchList: action.payload,
      };
    case productActions.FETCH_DEVICE_BATCH_FAILURE:
      return {
        ...state,
        error: null,
        deviceTestBatchList: [],
      };

    // 产品管理-产品型号（老）共 (4) 个
    //  ********* 分页查询列表 *******   //
    case productActions.FETCH_PRODUCT_V1_P_REQUEST:
      return {
        ...state,
        productListV1pTotal: 0,
        productListV1p: [],
        productListV1pLoading: !false,
        error: null,
      };
    case productActions.FETCH_PRODUCT_V1_P_SUCCESS:
      return {
        ...state,
        productListV1pTotal: action.payload.total,
        productListV1p: action.payload.list,
        productListV1pLoading: false,
        error: null,
      };
    case productActions.FETCH_PRODUCT_V1_P_FAILURE:
      return {
        ...state,
        productListV1pTotal: 0,
        productListV1p: [],
        productListV1pLoading: false,
        error: null,
      };
    //  ********* 添加 *******   //
    case productActions.CREATE_PRODUCT_V1_P_REQUEST:
      return {
        ...state,
        createStatus: "1",
        loading: !false,
        error: null,
      };
    case productActions.CREATE_PRODUCT_V1_P_SUCCESS:
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    case productActions.CREATE_PRODUCT_V1_P_FAILURE:
      return {
        ...state,
        createStatus: "3",
        loading: false,
        error: null,
      };
    //  ********* 更新 *******   //
    case productActions.EDIT_PRODUCT_V1_P_REQUEST:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_PRODUCT_V1_P_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        loading: !true,
        error: null,
      };
    case productActions.EDIT_PRODUCT_V1_P_FAILURE:
      return {
        ...state,
        editStatus: "3",
        loading: !true,
        error: null,
      };
    //  ********* 删除 *******   //
    case productActions.DELETE_PRODUCT_V1_P_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_PRODUCT_V1_P_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
        loading: !true,
        error: null,
      };
    case productActions.DELETE_PRODUCT_V1_P_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
        loading: !true,
        error: null,
      };

    //  *********  *******   //
    case productActions.CHECK_FACTORYCODE_REQUEST2:
      return {
        ...state,
        codeAble: "0",
        loading: true,
        error: null,
      };
    case productActions.CHECK_FACTORYCODE_SUCCESS2:
      return {
        ...state,
        codeAble: action.payload,
        loading: false,
        error: null,
      };
    case productActions.CHECK_FACTORYCODE_FAILURE2:
      return {
        ...state,
        codeAble: "0",
        loading: false,
        error: null,
      };

    //  *********  新增 产品 文件 *******   //
    case productActions.ADD_PRODUCT_DATA_FILE_REQUEST2:
      return {
        ...state,
        productDataFile: [],
        loading: true,
        error: null,
      };
    case productActions.ADD_PRODUCT_DATA_FILE_SUCCESS2:
      return {
        ...state,
        productDataFile: action.payload,
        loading: false,
        error: null,
      };
    case productActions.ADD_PRODUCT_DATA_FILE_FAILURE2:
      return {
        ...state,
        productDataFile: [],
        loading: false,
        error: null,
      };

    //
    // 获取 Select
    //  *********  获取 Series Select 获取产品型号对应的组件信息 *******   //
    case productActions.FETCH_SERIES_SELECT_REQUEST:
      return {
        ...state,
        seriesSelectAim: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_SERIES_SELECT_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        seriesSelectAim: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 Series Select 获取产品型号对应的组件信息 search *******   //
    case productActions.FETCH_SERIES_SELECT_SEARCH_REQUEST:
      return {
        ...state,
        seriesSelectAimSearh: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_SERIES_SELECT_SEARCH_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        seriesSelectAimSearh: action.payload,
        loading: false,
        error: null,
      };

    //  *********  获取 history Select *******   //
    case productActions.FETCH_HISTORY_SELECT_REQUEST:
      return {
        ...state,
        historySelect: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_HISTORY_SELECT_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        historySelect: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 Property Select *******   //
    case productActions.FETCH_PROPERTY_SELECT_REQUEST:
      return {
        ...state,
        propertySelect: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_PROPERTY_SELECT_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        propertySelect: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 Brands Select *******   //
    case productActions.FETCH_BRANDS_SELECT_REQUEST2:
      return {
        ...state,
        brandSelect: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_BRANDS_SELECT_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        brandSelect: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 Categories Select *******   //
    case productActions.FETCH_CATEGORY_SELECT_REQUEST2:
      return {
        ...state,
        categorySelect: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_CATEGORY_SELECT_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        categorySelect: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 Models Select *******   //
    case productActions.FETCH_MODEL_SELECT_REQUEST2:
      return {
        ...state,
        modelSelect: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_MODEL_SELECT_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        modelSelect: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 Series Select *******   //
    case productActions.FETCH_SERIES_SELECT_REQUEST2:
      return {
        ...state,
        seriesSelect: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_SERIES_SELECT_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        seriesSelect: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 client Select *******   //
    case productActions.FETCH_CLIENT_SELECT_REQUEST2:
      return {
        ...state,
        clientSelect: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_CLIENT_SELECT_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        clientSelect: action.payload,
        loading: false,
        error: null,
      };

    //
    // 新增
    //  *********  新增 Product Brand v2 *******   //
    case productActions.CREATE_PRODUCT_BRAND_REQUEST2:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_PRODUCT_BRAND_SUCCESS2:
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  新增 Product Category v2 *******   //
    case productActions.CREATE_PRODUCT_CATEGORY_REQUEST2:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_PRODUCT_CATEGORY_SUCCESS2:
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  新增 Product Model v2 *******   //
    case productActions.CREATE_PRODUCT_MODEL_REQUEST2:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_PRODUCT_MODEL_SUCCESS2:
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  新增 Product Series v2 *******   //
    case productActions.CREATE_PRODUCT_SERIES_REQUEST2:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_PRODUCT_SERIES_SUCCESS2:
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  新增 Product client v2 *******   //
    case productActions.CREATE_PRODUCT_CLIENT_REQUEST2:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_PRODUCT_CLIENT_SUCCESS2:
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  新增 Tsl 属性/1 *******   //
    case productActions.CREATE_TSL_1_REQUEST:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_TSL_1_SUCCESS:
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  新增 Tsl 服务/2 *******   //
    case productActions.CREATE_TSL_2_REQUEST:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_TSL_2_SUCCESS:
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  新增 Tsl 事件/3 *******   //
    case productActions.CREATE_TSL_3_REQUEST:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_TSL_3_SUCCESS:
      return {
        ...state,
        createStatus: "2",
        loading: false,
        error: null,
      };

    //
    // 编辑
    //  ********* 编辑 Product Brand v2 *******   //
    case productActions.EDIT_PRODUCT_BRAND_REQUEST2:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_PRODUCT_BRAND_SUCCESS2:
      return {
        ...state,
        editStatus: "2",
        editProductBrand2: action.payload,
        loading: false,
        error: null,
      };
    //  *********  编辑 Product Category v2 *******   //
    case productActions.EDIT_PRODUCT_CATEGORY_REQUEST2:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_PRODUCT_CATEGORY_SUCCESS2:
      return {
        ...state,
        editStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  编辑 Product Model v2 *******   //
    case productActions.EDIT_PRODUCT_MODEL_REQUEST2:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_PRODUCT_MODEL_SUCCESS2:
      return {
        ...state,
        editStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  编辑 Product Series v2 *******   //
    case productActions.EDIT_PRODUCT_SERIES_REQUEST2:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_PRODUCT_SERIES_SUCCESS2:
      return {
        ...state,
        editStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  编辑 Product client v2 *******   //
    case productActions.EDIT_PRODUCT_CLIENT_REQUEST2:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_PRODUCT_CLIENT_SUCCESS2:
      return {
        ...state,
        editStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  编辑 Tsl 属性/1 *******   //
    case productActions.EDIT_TSL_1_REQUEST:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_TSL_1_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  编辑 Tsl 服务/2 *******   //
    case productActions.EDIT_TSL_2_REQUEST:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_TSL_2_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  编辑 Tsl 事件/3 *******   //
    case productActions.EDIT_TSL_3_REQUEST:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_TSL_3_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        loading: false,
        error: null,
      };

    //
    // 删除
    //  *********  删除 Product Brand v2 *******   //
    case productActions.DELETE_PRODUCT_BRAND_REQUEST2:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_PRODUCT_BRAND_SUCCESS2:
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  删除 Product Category v2 *******   //
    case productActions.DELETE_PRODUCT_CATEGORY_REQUEST2:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_PRODUCT_CATEGORY_SUCCESS2:
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  删除 Product Model v2 *******   //
    case productActions.DELETE_PRODUCT_MODEL_REQUEST2:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_PRODUCT_MODEL_SUCCESS2:
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  删除 Product Series v2 *******   //
    case productActions.DELETE_PRODUCT_SERIES_REQUEST2:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_PRODUCT_SERIES_SUCCESS2:
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  删除 Product client v2 *******   //
    case productActions.DELETE_PRODUCT_CLIENT_REQUEST2:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_PRODUCT_CLIENT_SUCCESS2:
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  删除 Tsl 属性/1 *******   //
    case productActions.DELETE_TSL_1_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_TSL_1_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  删除 Tsl 服务/2 *******   //
    case productActions.DELETE_TSL_2_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_TSL_2_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };
    //  *********  删除 Tsl 事件/3 *******   //
    case productActions.DELETE_TSL_3_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_TSL_3_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
        loading: false,
        error: null,
      };

    //
    // 获取 List
    //  *********  获取 Brands List *******   //
    case productActions.FETCH_BRANDS_REQUEST2:
      return {
        ...state,
        brandsListTotal: 0,
        brandsList: [],
        brandsListLoading: true,
        loading: true,
        error: null,
      };
    case productActions.FETCH_BRANDS_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        brandsListTotal: action.payload.total,
        brandsList: action.payload.list,
        brandsListLoading: false,
        loading: false,
        error: null,
      };
    case productActions.FETCH_BRANDS_FAILURE2: // 获取 Brands List
      return {
        ...state,
        brandsListTotal: 0,
        brandsList: [],
        brandsListLoading: false,
        loading: false,
        error: null,
      };
    //  *********  获取 Categories List *******   //
    case productActions.FETCH_CATEGORY_REQUEST2:
      return {
        ...state,
        categoriesListTotal: 0,
        categoriesList: [],
        categoriesListLoading: true,
        loading: true,
        error: null,
      };
    case productActions.FETCH_CATEGORY_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        categoriesListTotal: action.payload.total,
        categoriesList: action.payload.list,
        categoriesListLoading: false,
        loading: false,
        error: null,
      };
    case productActions.FETCH_CATEGORY_FAILURE2: // 获取 Categories List
      return {
        ...state,
        categoriesListTotal: 0,
        categoriesList: [],
        categoriesListLoading: false,
        loading: false,
        error: null,
      };
    //  *********  获取 Models List *******   //
    case productActions.FETCH_MODEL_REQUEST2:
      return {
        ...state,
        modelListTotal: 0,
        modelList: [],
        modelListLoading: true,
        loading: true,
        error: null,
      };
    case productActions.FETCH_MODEL_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        modelListTotal: action.payload.total,
        modelList: action.payload.list,
        modelListLoading: false,
        loading: false,
        error: null,
      };
    case productActions.FETCH_MODEL_FAILURE2: // 获取 Models List
      return {
        ...state,
        modelListTotal: 0,
        modelList: [],
        modelListLoading: false,
        loading: false,
        error: null,
      };
    //  *********  获取 Series List *******   //
    case productActions.FETCH_SERIES_REQUEST2:
      return {
        ...state,
        seriesListTotal: 0,
        seriesList: [],
        seriesListLoading: true,
        loading: true,
        error: null,
      };
    case productActions.FETCH_SERIES_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        seriesListTotal: action.payload.total,
        seriesList: action.payload.list,
        seriesListLoading: false,
        loading: false,
        error: null,
      };
    case productActions.FETCH_SERIES_FAILURE2: // 获取 Series List
      return {
        ...state,
        seriesListTotal: 0,
        seriesList: [],
        seriesListLoading: false,
        loading: false,
        error: null,
      };
    //  *********  获取 client List *******   //
    case productActions.FETCH_CLIENT_REQUEST2:
      return {
        ...state,
        clientListTotal: 0,
        clientList: [],
        clientListLoading: true,
        loading: true,
        error: null,
      };
    case productActions.FETCH_CLIENT_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        clientListTotal: action.payload.total,
        clientList: action.payload.list,
        clientListLoading: false,
        loading: false,
        error: null,
      };
    case productActions.FETCH_CLIENT_FAILURE2: // 获取 clint List
      return {
        ...state,
        clientListTotal: 0,
        clientList: [],
        clientListLoading: false,
        loading: false,
        error: null,
      };

    //
    // 详情
    //  *********  获取 Product Series 详情 v2 *******   //
    case productActions.DETAIL_PRODUCT_SERIES_REQUEST2:
      return {
        ...state,
        detailStatus: "1",
        seriesDetail: {},
        loading: true,
        error: null,
      };
    case productActions.DETAIL_PRODUCT_SERIES_SUCCESS2:
      return {
        ...state,
        detailStatus: "2",
        seriesDetail: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 Tsl 属性/1 详情 *******   //
    case productActions.DETAIL_TSL_1_REQUEST:
      return {
        ...state,
        detailStatus: "1",
        tslDetail: {},
        loading: true,
        error: null,
      };
    case productActions.DETAIL_TSL_1_SUCCESS:
      return {
        ...state,
        detailStatus: "2",
        tslDetail: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 Tsl 服务/2 详情 *******   //
    case productActions.DETAIL_TSL_2_REQUEST:
      return {
        ...state,
        detailStatus: "1",
        tslDetail: {},
        loading: true,
        error: null,
      };
    case productActions.DETAIL_TSL_2_SUCCESS:
      return {
        ...state,
        detailStatus: "2",
        tslDetail: action.payload,
        loading: false,
        error: null,
      };
    //  *********  获取 Tsl 事件/3 详情 *******   //
    case productActions.DETAIL_TSL_3_REQUEST:
      return {
        ...state,
        detailStatus: "1",
        tslDetail: {},
        loading: true,
        error: null,
      };
    case productActions.DETAIL_TSL_3_SUCCESS:
      return {
        ...state,
        detailStatus: "2",
        tslDetail: action.payload,
        loading: false,
        error: null,
      };

    //
    // 产品型号
    //  *********  copy 产品型号 *******   //
    case productActions.COPY_TYPE_REQUEST2:
      return {
        ...state,
        copyStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.COPY_TYPE_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        copyStatus: "2",
        loading: true,
        error: null,
      };
    //  *********  新增 产品型号 *******   //
    case productActions.CREATE_TYPE_REQUEST2:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_TYPE_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        createStatus: "2",
        loading: true,
        error: null,
      };
    //  *********  编辑 产品型号 *******   //
    case productActions.EDIT_TYPE_REQUEST2:
      return {
        ...state,
        editStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.EDIT_TYPE_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        editStatus: "2",
        loading: true,
        error: null,
      };
    //  *********  删除 产品型号 *******   //
    case productActions.DELETE_TYPE_REQUEST2:
      return {
        ...state,
        deleteStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.DELETE_TYPE_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        deleteStatus: "2",
        loading: true,
        error: null,
      };

    //  *********  获取 产品型号 List *******   //
    case productActions.FETCH_TYPE_SELECT:
      return {
        ...state,
        typeSelectList: [],
        loading: true,
        error: null,
      };
    case productActions.FETCH_TYPE_SELECT_SUSSESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        typeSelectList: action.payload,
        loading: false,
        error: null,
      };
    case productActions.FETCH_TYPE_SELECT_FAILURE: // 获取 产品型号 List
      return {
        ...state,
        typeSelectList: [],
        loading: false,
        error: null,
      };
    //  *********  获取 产品型号 List *******   //
    case productActions.FETCH_TYPE_REQUEST2:
      return {
        ...state,
        typeListTotal: 0,
        typeList: [],
        typeListLoading: true,
        loading: true,
        error: null,
      };
    case productActions.FETCH_TYPE_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        typeListTotal: action.payload.total,
        typeList: action.payload.list,
        typeListLoading: false,
        loading: false,
        error: null,
      };
    case productActions.FETCH_TYPE_FAILURE2: // 获取 产品型号 List
      return {
        ...state,
        typeListTotal: 0,
        typeList: [],
        typeListLoading: false,
        loading: false,
        error: null,
      };

    //  *********  获取 产品型号 detail *******   //
    case productActions.FETCH_TYPE_DETAIL_REQUEST2:
      return {
        ...state,
        typeDetail: {},
        loading: true,
        error: null,
      };
    case productActions.FETCH_TYPE_DETAIL_SUCCESS2:
      uniqueRowKey(action.payload);
      return {
        ...state,
        typeDetail: action.payload,
        loading: true,
        error: null,
      };

    //  *********  获取 产品型号 detail *******   //
    case productActions.FETCH_TYPE_DETAIL_REQUEST2_V1:
      return {
        ...state,
        typeDetail: {},
        loading: true,
        error: null,
      };
    case productActions.FETCH_TYPE_DETAIL_SUCCESS2_V1:
      uniqueRowKey(action.payload);
      return {
        ...state,
        typeDetail: action.payload,
        loading: true,
        error: null,
      };

    //
    // 物模型
    //  *********  获取 功能列表 list *******   //
    case productActions.FETCH_TSL_FUNCTION_REQUEST:
      return {
        ...state,
        tslFunctionData: {},
        loadingTsl: true,
        loading: true,
        error: null,
      };
    case productActions.FETCH_TSL_FUNCTION_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        tslFunctionData: action.payload,
        loadingTsl: false,
        loading: false,
        error: null,
      };
    case productActions.FETCH_TSL_FUNCTION_FAILURE: // 获取 功能列表 list
      return {
        ...state,
        tslFunctionData: [],
        loadingTsl: false,
        loading: false,
        error: null,
      };

    //  *********  获取 发布草稿 *******   //
    case productActions.CREATE_TSL_CRAFT_REQUEST:
      return {
        ...state,
        createStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.CREATE_TSL_CRAFT_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        createStatus: "2",
        loading: true,
        error: null,
      };

    //  *********  获取 发布草稿 *******   //
    case productActions.RECOVERY_TSL_REQUEST:
      return {
        ...state,
        recoveryStatus: "1",
        loading: true,
        error: null,
      };
    case productActions.RECOVERY_TSL_SUCCESS:
      uniqueRowKey(action.payload);
      return {
        ...state,
        recoveryStatus: "2",
        loading: true,
        error: null,
      };
    case productActions.RECOVERY_TSL_FAILURE:
      return {
        ...state,
        recoveryStatus: "3",
        loading: false,
        error: null,
      };

    //
    // error
    case "Select":
    case productActions.FETCH_SERIES_SELECT_FAILURE: // 获取 Series Select 获取产品型号对应的组件信息
    case productActions.FETCH_SERIES_SELECT_SEARCH_FAILURE: // 获取 Series Select 获取产品型号对应的组件信息 search
    case productActions.FETCH_HISTORY_SELECT_FAILURE: // 获取 history Select
    case productActions.FETCH_PROPERTY_SELECT_FAILURE: // 获取 Property Select
    case productActions.FETCH_BRANDS_SELECT_FAILURE2: // 获取 Brands Select
    case productActions.FETCH_CATEGORY_SELECT_FAILURE2: // 获取 Categories Select
    case productActions.FETCH_MODEL_SELECT_FAILURE2: // 获取 Models Select
    case productActions.FETCH_SERIES_SELECT_FAILURE2: // 获取 Series Select
    case productActions.FETCH_CLIENT_SELECT_FAILURE2: // 获取 clint Select
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "CREATE":
    case productActions.CREATE_PRODUCT_BRAND_FAILURE2: // 新增 Product Brand v2
    case productActions.CREATE_PRODUCT_CATEGORY_FAILURE2: // 新增 Product Category v2
    case productActions.CREATE_PRODUCT_MODEL_FAILURE2: // 新增 Product Model v2
    case productActions.CREATE_PRODUCT_SERIES_FAILURE2: // 新增 Product Series v2
    case productActions.CREATE_PRODUCT_CLIENT_FAILURE2: // 新增 Product clint v2
    case productActions.CREATE_TSL_1_FAILURE: // 新增 Tsl 属性/1
    case productActions.CREATE_TSL_2_FAILURE: // 新增 Tsl 服务/2
    case productActions.CREATE_TSL_3_FAILURE: // 新增 Tsl 事件/3
    case productActions.CREATE_TSL_CRAFT_FAILURE: // 新增 Tsl 发布草稿
    case productActions.CREATE_TYPE_FAILURE2: // 新增 产品型号
      return {
        ...state,
        createStatus: "3",
        loading: false,
        error: null,
      };
    case "EDIT":
    case productActions.EDIT_PRODUCT_BRAND_FAILURE2: // 编辑 Product Brand v2
    case productActions.EDIT_PRODUCT_CATEGORY_FAILURE2: // 编辑 Product Category v2
    case productActions.EDIT_PRODUCT_MODEL_FAILURE2: // 编辑 Product Model v2
    case productActions.EDIT_PRODUCT_SERIES_FAILURE2: // 编辑 Product Series v2
    case productActions.EDIT_PRODUCT_CLIENT_FAILURE2: // 编辑 Product clint v2
    case productActions.EDIT_TSL_1_FAILURE: // 编辑 Tsl 属性/1
    case productActions.EDIT_TSL_2_FAILURE: // 编辑 Tsl 服务/2
    case productActions.EDIT_TSL_3_FAILURE: // 编辑 Tsl 事件/3
    case productActions.EDIT_TYPE_FAILURE2: // 编辑 产品型号
      return {
        ...state,
        editStatus: "3",
        loading: false,
        error: null,
      };
    case "DELETE":
    case productActions.DELETE_PRODUCT_BRAND_FAILURE2: // 删除 Product Brand v2
    case productActions.DELETE_PRODUCT_CATEGORY_FAILURE2: // 删除 Product Category v2
    case productActions.DELETE_PRODUCT_MODEL_FAILURE2: // 删除 Product Model v2
    case productActions.DELETE_PRODUCT_SERIES_FAILURE2: // 删除 Product Series v2
    case productActions.DELETE_PRODUCT_CLIENT_FAILURE2: // 删除 Product clint v2
    case productActions.DELETE_TSL_1_FAILURE: // 删除 Tsl 属性/1
    case productActions.DELETE_TSL_2_FAILURE: // 删除 Tsl 服务/2
    case productActions.DELETE_TSL_3_FAILURE: // 删除 Tsl 事件/3
    case productActions.DELETE_TYPE_FAILURE2: // 编辑 产品型号
      return {
        ...state,
        deleteStatus: "3",
        loading: false,
        error: null,
      };
    case "DETAIL":
    case productActions.DETAIL_PRODUCT_SERIES_FAILURE2: // 获取 Product Series 详情 v2
    case productActions.DETAIL_TSL_1_FAILURE: // 获取 Tsl 属性/1 详情
    case productActions.DETAIL_TSL_2_FAILURE: // 获取 Tsl 服务/2 详情
    case productActions.DETAIL_TSL_3_FAILURE: // 获取 Tsl 事件/3 详情
    case productActions.FETCH_TYPE_DETAIL_FAILURE2: // 获取 产品型号 detail
    case productActions.FETCH_TYPE_DETAIL_FAILURE2_V1: // 获取 产品型号 detail
      return {
        ...state,
        detailStatus: "3",
        loading: false,
        error: null,
      };
    case productActions.COPY_TYPE_FAILURE2: // 新增 产品型号
      return {
        ...state,
        copyStatus: "3",
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}
