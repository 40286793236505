const productItemActions = {
  // Add Product Brand
  CREATE_PRODUCT_BRAND_REQUEST: "CREATE_PRODUCT_BRAND_REQUEST",
  CREATE_PRODUCT_BRAND_SUCCESS: "CREATE_PRODUCT_BRAND_SUCCESS",
  CREATE_PRODUCT_BRAND_FAILURE: "CREATE_PRODUCT_BRAND_FAILURE",
  createProductBrandRequest: (request) => ({
    type: productItemActions.CREATE_PRODUCT_BRAND_REQUEST,
    payload: request,
  }),
  createProductBrandSuccess: (productBrandItem) => ({
    type: productItemActions.CREATE_PRODUCT_BRAND_SUCCESS,
    payload: productBrandItem,
  }),
  createProductBrandFailure: (error) => ({
    type: productItemActions.CREATE_PRODUCT_BRAND_FAILURE,
    payload: error,
  }),

  // get Product Brand
  GET_PRODUCT_CREATED_ITEM_REQUEST: "GET_PRODUCT_CREATED_ITEM_REQUEST",
  GET_PRODUCT_CREATED_ITEM_SUCCESS: "GET_PRODUCT_CREATED_ITEM_SUCCESS",
  GET_PRODUCT_CREATED_ITEM_FAILURE: "GET_PRODUCT_CREATED_ITEM_FAILURE",
  getProductItemRequest: (request, apiName) => ({
    type: productItemActions.GET_PRODUCT_CREATED_ITEM_REQUEST,
    payload: request,
    path: apiName,
  }),
  getProductItemSuccess: (productBrandItem) => ({
    type: productItemActions.GET_PRODUCT_CREATED_ITEM_SUCCESS,
    payload: productBrandItem,
  }),
  getProductItemFailure: (error) => ({
    type: productItemActions.GET_PRODUCT_CREATED_ITEM_FAILURE,
    payload: error,
  }),

  // Edit Product Brand
  EDIT_PRODUCT_BRAND_REQUEST: "EDIT_PRODUCT_BRAND_REQUEST",
  EDIT_PRODUCT_BRAND_SUCCESS: "EDIT_PRODUCT_BRAND_SUCCESS",
  EDIT_PRODUCT_BRAND_FAILURE: "EDIT_PRODUCT_BRAND_FAILURE",
  editProductBrandRequest: (request) => ({
    type: productItemActions.EDIT_PRODUCT_BRAND_REQUEST,
    payload: request,
  }),
  editProductBrandSuccess: (productBrandItem) => ({
    type: productItemActions.EDIT_PRODUCT_BRAND_SUCCESS,
    payload: productBrandItem,
  }),
  editProductBrandFailure: (error) => ({
    type: productItemActions.EDIT_PRODUCT_BRAND_FAILURE,
    payload: error,
  }),

  // Delete Product Brand
  DELETE_PRODUCT_BRAND_REQUEST: "DELETE_PRODUCT_BRAND_REQUEST",
  DELETE_PRODUCT_BRAND_SUCCESS: "DELETE_PRODUCT_BRAND_SUCCESS",
  DELETE_PRODUCT_BRAND_FAILURE: "DELETE_PRODUCT_BRAND_FAILURE",
  deleteProductBrandRequest: (request) => ({
    type: productItemActions.DELETE_PRODUCT_BRAND_REQUEST,
    payload: request,
  }),
  deleteProductBrandSuccess: (productBrandItem) => ({
    type: productItemActions.DELETE_PRODUCT_BRAND_SUCCESS,
    payload: productBrandItem,
  }),
  deleteProductBrandFailure: (error) => ({
    type: productItemActions.DELETE_PRODUCT_BRAND_FAILURE,
    payload: error,
  }),

  //Add PRODUCT Category
  CREATE_PRODUCT_CATEGORY_REQUEST: "CREATE_PRODUCT_CATEGORY_REQUEST",
  CREATE_PRODUCT_CATEGORY_SUCCESS: "CREATE_PRODUCT_CATEGORY_SUCCESS",
  CREATE_PRODUCT_CATEGORY_FAILURE: "CREATE_PRODUCT_CATEGORY_FAILURE",
  createProductCategoryRequest: (request) => ({
    type: productItemActions.CREATE_PRODUCT_CATEGORY_REQUEST,
    payload: request,
  }),
  createProductCategorySuccess: (productCategoryItem) => ({
    type: productItemActions.CREATE_PRODUCT_CATEGORY_SUCCESS,
    payload: productCategoryItem,
  }),
  createProductCategoryFailure: (error) => ({
    type: productItemActions.CREATE_PRODUCT_CATEGORY_FAILURE,
    payload: error,
  }),

  // Edit Product Categrory
  EDIT_PRODUCT_CATEGORY_REQUEST: "EDIT_PRODUCT_CATEGORY_REQUEST",
  EDIT_PRODUCT_CATEGORY_SUCCESS: "EDIT_PRODUCT_CATEGORY_SUCCESS",
  EDIT_PRODUCT_CATEGORY_FAILURE: "EDIT_PRODUCT_CATEGORY_FAILURE",
  editProductCategoryRequest: (request) => ({
    type: productItemActions.EDIT_PRODUCT_CATEGORY_REQUEST,
    payload: request,
  }),
  editProductCategorySuccess: (productCategoryItem) => ({
    type: productItemActions.EDIT_PRODUCT_CATEGORY_SUCCESS,
    payload: productCategoryItem,
  }),
  editProductCategoryFailure: (error) => ({
    type: productItemActions.EDIT_PRODUCT_CATEGORY_FAILURE,
    payload: error,
  }),

  // Delete PRODUCT CATEGORY
  DELETE_PRODUCT_CATEGORY_REQUEST: "DELETE_PRODUCT_CATEGORY_REQUEST",
  DELETE_PRODUCT_CATEGORY_SUCCESS: "DELETE_PRODUCT_CATEGORY_SUCCESS",
  DELETE_PRODUCT_CATEGORY_FAILURE: "DELETE_PRODUCT_CATEGORY_FAILURE",
  deleteProductCategoryRequest: (request) => ({
    type: productItemActions.DELETE_PRODUCT_CATEGORY_REQUEST,
    payload: request,
  }),
  deleteProductCategorySuccess: (productCategoryItem) => ({
    type: productItemActions.DELETE_PRODUCT_CATEGORY_SUCCESS,
    payload: productCategoryItem,
  }),
  deleteProductCategoryFailure: (error) => ({
    type: productItemActions.DELETE_PRODUCT_CATEGORY_FAILURE,
    payload: error,
  }),

  //Add Product Models
  CREATE_PRODUCT_MODEL_REQUEST: "CREATE_PRODUCT_MODEL_REQUEST",
  CREATE_PRODUCT_MODEL_SUCCESS: "CREATE_PRODUCT_MODEL_SUCCESS",
  CREATE_PRODUCT_MODEL_FAILURE: "CREATE_PRODUCT_MODEL_FAILURE",
  createProductModelRequest: (request) => ({
    type: productItemActions.CREATE_PRODUCT_MODEL_REQUEST,
    payload: request,
  }),
  createProductModelSuccess: (productModelItem) => ({
    type: productItemActions.CREATE_PRODUCT_MODEL_SUCCESS,
    payload: productModelItem,
  }),
  createProductModelFailure: (error) => ({
    type: productItemActions.CREATE_PRODUCT_MODEL_FAILURE,
    payload: error,
  }),

  // Edit Product Model
  EDIT_PRODUCT_MODEL_REQUEST: "EDIT_PRODUCT_MODEL_REQUEST",
  EDIT_PRODUCT_MODEL_SUCCESS: "EDIT_PRODUCT_MODEL_SUCCESS",
  EDIT_PRODUCT_MODEL_FAILURE: "EDIT_PRODUCT_MODEL_FAILURE",
  editProductModelRequest: (request) => ({
    type: productItemActions.EDIT_PRODUCT_MODEL_REQUEST,
    payload: request,
  }),
  editProductModelSuccess: (productModelItem) => ({
    type: productItemActions.EDIT_PRODUCT_MODEL_SUCCESS,
    payload: productModelItem,
  }),
  editProductModelFailure: (error) => ({
    type: productItemActions.EDIT_PRODUCT_MODEL_FAILURE,
    payload: error,
  }),

  // Delete PRODUCT Model
  DELETE_PRODUCT_MODEL_REQUEST: "DELETE_PRODUCT_MODEL_REQUEST",
  DELETE_PRODUCT_MODEL_SUCCESS: "DELETE_PRODUCT_MODEL_SUCCESS",
  DELETE_PRODUCT_MODEL_FAILURE: "DELETE_PRODUCT_MODEL_FAILURE",
  deleteProductModelRequest: (request) => ({
    type: productItemActions.DELETE_PRODUCT_MODEL_REQUEST,
    payload: request,
  }),
  deleteProductModelSuccess: (productModelItem) => ({
    type: productItemActions.DELETE_PRODUCT_MODEL_SUCCESS,
    payload: productModelItem,
  }),
  deleteProductModelFailure: (error) => ({
    type: productItemActions.DELETE_PRODUCT_MODEL_FAILURE,
    payload: error,
  }),

  //Add Product Actions Types
  CREATE_PRODUCT_ACTIONS_TYPES_REQUEST: "CREATE_PRODUCT_ACTIONS_TYPES_REQUEST",
  CREATE_PRODUCT_ACTIONS_TYPES_SUCCESS: "CREATE_PRODUCT_ACTIONS_TYPES_SUCCESS",
  CREATE_PRODUCT_ACTIONS_TYPES_FAILURE: "CREATE_PRODUCT_ACTIONS_TYPES_FAILURE",
  createProductActionTypesRequest: (request) => ({
    type: productItemActions.CREATE_PRODUCT_ACTIONS_TYPES_REQUEST,
    payload: request,
  }),
  createProductActionTypesSuccess: (productActionTypesItem) => ({
    type: productItemActions.CREATE_PRODUCT_ACTIONS_TYPES_SUCCESS,
    payload: productActionTypesItem,
  }),
  createProductActionTypesFailure: (error) => ({
    type: productItemActions.CREATE_PRODUCT_ACTIONS_TYPES_FAILURE,
    payload: error,
  }),

  // Edit Product Action Types

  EDIT_PRODUCT_ACTIONS_TYPES_REQUEST: "EDIT_PRODUCT_ACTIONS_TYPES_REQUEST",
  EDIT_PRODUCT_ACTIONS_TYPES_SUCCESS: "EDIT_PRODUCT_ACTIONS_TYPES_SUCCESS",
  EDIT_PRODUCT_ACTIONS_TYPES_FAILURE: "EDIT_PRODUCT_ACTIONS_TYPES_FAILURE",
  editProductActionTypesRequest: (request) => ({
    type: productItemActions.EDIT_PRODUCT_ACTIONS_TYPES_REQUEST,
    payload: request,
  }),
  editProductActionTypesSuccess: (productActionTypesItem) => ({
    type: productItemActions.EDIT_PRODUCT_ACTIONS_TYPES_SUCCESS,
    payload: productActionTypesItem,
  }),
  editProductActionTypesFailure: (error) => ({
    type: productItemActions.EDIT_PRODUCT_ACTIONS_TYPES_FAILURE,
    payload: error,
  }),

  // Delete PRODUCT ACTIONS TYPES
  DELETE_PRODUCT_ACTIONS_TYPES_REQUEST: "DELETE_PRODUCT_ACTIONS_TYPES_REQUEST",
  DELETE_PRODUCT_ACTIONS_TYPES_SUCCESS: "DELETE_PRODUCT_ACTIONS_TYPES_SUCCESS",
  DELETE_PRODUCT_ACTIONS_TYPES_FAILURE: "DELETE_PRODUCT_ACTIONS_TYPES_FAILURE",
  deleteProductActionTypesRequest: (request) => ({
    type: productItemActions.DELETE_PRODUCT_ACTIONS_TYPES_REQUEST,
    payload: request,
  }),
  deleteProductActionTypesSuccess: (productActionTypesItem) => ({
    type: productItemActions.DELETE_PRODUCT_ACTIONS_TYPES_SUCCESS,
    payload: productActionTypesItem,
  }),
  deleteProductActionTypesFailure: (error) => ({
    type: productItemActions.DELETE_PRODUCT_ACTIONS_TYPES_FAILURE,
    payload: error,
  }),

  //Add communication type
  CREATE_PRODUCT_COMMUNICATION_TYPE_REQUEST:
    "CREATE_PRODUCT_COMMUNICATION_TYPE_REQUEST",
  CREATE_PRODUCT_COMMUNICATION_TYPE_SUCCESS:
    "CREATE_PRODUCT_COMMUNICATION_TYPE_SUCCESS",
  CREATE_PRODUCT_COMMUNICATION_TYPE_FAILURE:
    "CREATE_PRODUCT_COMMUNICATION_TYPE_FAILURE",
  createProductCommunicationtypeRequest: (request) => ({
    type: productItemActions.CREATE_PRODUCT_COMMUNICATION_TYPE_REQUEST,
    payload: request,
  }),
  createProductCommunicationtypeSuccess: (productCommunicationItem) => ({
    type: productItemActions.CREATE_PRODUCT_COMMUNICATION_TYPE_SUCCESS,
    payload: productCommunicationItem,
  }),
  createProductCommunicationtypeFailure: (error) => ({
    type: productItemActions.CREATE_PRODUCT_COMMUNICATION_TYPE_FAILURE,
    payload: error,
  }),

  // Edit communication type

  EDIT_PRODUCT_COMMUNICATION_TYPE_REQUEST:
    "EDIT_PRODUCT_COMMUNICATION_TYPE_REQUEST",
  EDIT_PRODUCT_COMMUNICATION_TYPE_SUCCESS:
    "EDIT_PRODUCT_COMMUNICATION_TYPE_SUCCESS",
  EDIT_PRODUCT_COMMUNICATION_TYPE_FAILURE:
    "EDIT_PRODUCT_COMMUNICATION_TYPE_AILURE",
  editProductCommunicationtypeRequest: (request) => ({
    type: productItemActions.EDIT_PRODUCT_COMMUNICATION_TYPE_REQUEST,
    payload: request,
  }),
  editProductCommunicationtypeSuccess: (productCommunicationItem) => ({
    type: productItemActions.EDIT_PRODUCT_COMMUNICATION_TYPE_SUCCESS,
    payload: productCommunicationItem,
  }),
  editProductCommunicationtypeFailure: (error) => ({
    type: productItemActions.EDIT_PRODUCT_COMMUNICATION_TYPE_FAILURE,
    payload: error,
  }),

  // Delete communication type
  DELETE_PRODUCT_COMMUNICATION_TYPE_REQUEST:
    "DELETE_PRODUCT_COMMUNICATION_TYPE_REQUEST",
  DELETE_PRODUCT_COMMUNICATION_TYPE_SUCCESS:
    "DELETE_PRODUCT_COMMUNICATION_TYPE_SUCCESS",
  DELETE_PRODUCT_COMMUNICATION_TYPE_FAILURE:
    "DELETE_PRODUCT_COMMUNICATION_TYPE_FAILURE",
  deleteProductCommunicationtypeRequest: (request) => ({
    type: productItemActions.DELETE_PRODUCT_COMMUNICATION_TYPE_REQUEST,
    payload: request,
  }),
  deleteProductCommunicationtypeSuccess: (productCommunicationItem) => ({
    type: productItemActions.DELETE_PRODUCT_COMMUNICATION_TYPE_SUCCESS,
    payload: productCommunicationItem,
  }),
  deleteProductCommunicationtypeFailure: (error) => ({
    type: productItemActions.DELETE_PRODUCT_COMMUNICATION_TYPE_FAILURE,
    payload: error,
  }),
};

export default productItemActions;
