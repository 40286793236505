import actions from "./actions";

const INITIAL_DATA = {
  // 0/未操作 1/操作中 2/操作成功 3/操作失败
  craftStatus: "0", //  草稿
  submitStatus: "0", //  发布
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除
  detailStatus: "0", // 详情
  disableStatus: "0", //
  enableStatus: "0", //

  faqLoading: false,
  faqList: [],
  faqTotal: 0,

  helpLoading: false,
  helpList: [],
  helpTotal: 0,

  data: [],
  filterTypeData: [],
  filterFaqData: [],
  addFaq: [],
  editFaq: [],
  loading: true,
  error: null,
};

export default function faqReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    // create
    case actions.CREATE_FAQ_REQUEST:
    case actions.CREATE_FAQ_CHILD_REQUEST:
    case actions.CREATE_HELP_REQUEST:
    case actions.CREATE_HELP_CHILD_REQUEST:
      return {
        ...state,
        craftStatus: "1",
        submitStatus: "1",
        createStatus: "1",
      };
    case actions.CREATE_FAQ_SUCCESS:
    case actions.CREATE_FAQ_CHILD_SUCCESS:
    case actions.CREATE_HELP_SUCCESS:
    case actions.CREATE_HELP_CHILD_SUCCESS:
      return {
        ...state,
        craftStatus: "2",
        submitStatus: "2",
        createStatus: "2",
      };
    case actions.CREATE_FAQ_FAILURE:
    case actions.CREATE_FAQ_CHILD_FAILURE:
    case actions.CREATE_HELP_FAILURE:
    case actions.CREATE_HELP_CHILD_FAILURE:
      return {
        ...state,
        craftStatus: "3",
        submitStatus: "3",
        createStatus: "3",
      };

    // edit
    case actions.EDIT_FAQ_REQUEST:
    case actions.EDIT_FAQ_CHILD_REQUEST:
    case actions.EDIT_HELP_REQUEST:
    case actions.EDIT_HELP_CHILD_REQUEST:
      return {
        ...state,
        editStatus: "1",
        craftStatus: "1",
        submitStatus: "1",
      };
    case actions.EDIT_FAQ_SUCCESS:
    case actions.EDIT_FAQ_CHILD_SUCCESS:
    case actions.EDIT_HELP_SUCCESS:
    case actions.EDIT_HELP_CHILD_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        craftStatus: "2",
        submitStatus: "2",
      };
    case actions.EDIT_FAQ_FAILURE:
    case actions.EDIT_FAQ_CHILD_FAILURE:
    case actions.EDIT_HELP_FAILURE:
    case actions.EDIT_HELP_CHILD_FAILURE:
      return {
        ...state,
        editStatus: "3",
        craftStatus: "3",
        submitStatus: "3",
      };

    // delete
    case actions.DELETE_FAQ_REQUEST:
    case actions.DELETE_FAQ_CHILD_REQUEST:
    case actions.DELETE_HELP_REQUEST:
    case actions.DELETE_HELP_CHILD_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
      };
    case actions.DELETE_FAQ_SUCCESS:
    case actions.DELETE_FAQ_CHILD_SUCCESS:
    case actions.DELETE_HELP_SUCCESS:
    case actions.DELETE_HELP_CHILD_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
      };
    case actions.DELETE_FAQ_FAILURE:
    case actions.DELETE_FAQ_CHILD_FAILURE:
    case actions.DELETE_HELP_FAILURE:
    case actions.DELETE_HELP_CHILD_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
      };

    // fetch
    case actions.FETCH_FAQ_REQUEST:
      return {
        ...state,
        faqLoading: true,
        faqList: [],
        faqTotal: 0,
      };
    case actions.FETCH_FAQ_SUCCESS:
      return {
        ...state,
        faqLoading: false,
        faqList: action.payload.list,
        faqTotal: action.payload.total,
      };
    case actions.FETCH_FAQ_FAILURE:
      return {
        ...state,
        faqLoading: false,
        faqList: [],
        faqTotal: 0,
      };
    // help
    case actions.FETCH_HELP_REQUEST:
      return {
        ...state,
        helpLoading: true,
        helpList: [],
        helpTotal: 0,
      };
    case actions.FETCH_HELP_SUCCESS:
      return {
        ...state,
        helpLoading: false,
        helpList: action.payload.list,
        helpTotal: action.payload.total,
      };
    case actions.FETCH_HELP_FAILURE:
      return {
        ...state,
        helpLoading: false,
        helpList: [],
        helpTotal: 0,
      };

    // enable
    case actions.ENABLE_FAQ_REQUEST:
    case actions.ENABLE_HELP_REQUEST:
      return {
        ...state,
        enableStatus: "1",
      };
    case actions.ENABLE_FAQ_SUCCESS:
    case actions.ENABLE_HELP_SUCCESS:
      return {
        ...state,
        enableStatus: "2",
      };
    case actions.ENABLE_FAQ_FAILURE:
    case actions.ENABLE_HELP_FAILURE:
      return {
        ...state,
        enableStatus: "3",
      };

    // disable
    case actions.DISABLE_FAQ_REQUEST:
    case actions.DISABLE_HELP_REQUEST:
      return {
        ...state,
        disableStatus: "1",
      };
    case actions.DISABLE_FAQ_SUCCESS:
    case actions.DISABLE_HELP_SUCCESS:
      return {
        ...state,
        disableStatus: "2",
      };
    case actions.DISABLE_FAQ_FAILURE:
    case actions.DISABLE_HELP_FAILURE:
      return {
        ...state,
        disableStatus: "3",
      };

    case actions.FETCH_FAQ_DATA_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case actions.FETCH_FAQ_DATA_SUCCESS:
      let data = Object.assign([], action.payload);
      data.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case actions.FETCH_FAQ_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actions.FETCH_FILTERTYPE_DATA_REQUEST:
      return {
        ...state,
        filterTypeData: [],
        loading: true,
        error: null,
      };
    case actions.FETCH_FILTERTYPE_DATA_SUCCESS:
      return {
        ...state,
        filterTypeData: action.payload,
        loading: false,
        error: null,
      };
    case actions.FETCH_FILTERTYPE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actions.FETCH_FILTERFAQ_DATA_REQUEST:
      return {
        ...state,
        filterFaqData: [],
        loading: true,
        error: null,
      };
    case actions.FETCH_FILTERFAQ_DATA_SUCCESS:
      return {
        ...state,
        filterFaqData: action.payload,
        loading: false,
        error: null,
      };
    case actions.FETCH_FILTERFAQ_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actions.ADD_FAQ_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case actions.ADD_FAQ_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case actions.ADD_FAQ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // case actions.EDIT_FAQ_REQUEST:
    // 	return {
    // 		...state,
    // 		data: [],
    // 		loading: true,
    // 		error: null,
    // 	};
    // case actions.EDIT_FAQ_SUCCESS:
    // 	return {
    // 		...state,
    // 		data: action.payload,
    // 		loading: false,
    // 		error: null,
    // 	};
    // case actions.EDIT_FAQ_FAILURE:
    // 	return {
    // 		...state,
    // 		loading: false,
    // 		error: action.payload,
    // 	};

    // case actions.DELETE_FAQ_REQUEST:
    // 	return {
    // 		...state,
    // 		data: [],
    // 		loading: true,
    // 		error: null,
    // 	};
    // case actions.DELETE_FAQ_SUCCESS:
    // 	return {
    // 		...state,
    // 		data: action.payload,
    // 		loading: false,
    // 		error: null,
    // 	};
    // case actions.DELETE_FAQ_FAILURE:
    // 	return {
    // 		...state,
    // 		loading: false,
    // 		error: action.payload,
    // 	};
    default:
      return state;
  }
}
