const welComePageActions = {
  //Fetch welComePage Data
  FETCH_welComePage_DATA_REQUEST: "FETCH_welComePage_DATA_REQUEST",
  FETCH_welComePage_DATA_SUCCESS: "FETCH_welComePage_DATA_SUCCESS",
  FETCH_welComePage_DATA_FAILURE: "FETCH_welComePage_DATA_FAILURE",
  SET_welComePage_DATA: "SET_welComePage_DATA",
  fetchwelComePageDataRequest: (request) => ({
    type: welComePageActions.FETCH_welComePage_DATA_REQUEST,
    payload: request,
  }),
  fetchwelComePageDataSuccess: (welComePage) => ({
    type: welComePageActions.FETCH_welComePage_DATA_SUCCESS,
    payload: welComePage,
  }),
  fetchwelComePageDataFailure: (error) => ({
    type: welComePageActions.FETCH_welComePage_DATA_FAILURE,
    payload: error,
  }),

  // Add welComePage
  ADD_welComePage_REQUEST: "ADD_welComePage_REQUEST",
  ADD_welComePage_SUCCESS: "ADD_welComePage_SUCCESS",
  ADD_welComePage_FAILURE: "ADD_welComePage_FAILURE",
  addwelComePageDataRequest: (request) => ({
    type: welComePageActions.ADD_welComePage_REQUEST,
    payload: request,
  }),
  addwelComePageDataSuccess: (welComePage) => ({
    type: welComePageActions.ADD_welComePage_SUCCESS,
    payload: welComePage,
  }),
  addwelComePageDataFailure: (error) => ({
    type: welComePageActions.ADD_welComePage_FAILURE,
    payload: error,
  }),

  // Edit welComePage
  EDIT_welComePage_REQUEST: "EDIT_welComePage_REQUEST",
  EDIT_welComePage_SUCCESS: "EDIT_welComePage_SUCCESS",
  EDIT_welComePage_FAILURE: "EDIT_welComePage_FAILURE",
  editwelComePageDataRequest: (request) => ({
    type: welComePageActions.EDIT_welComePage_REQUEST,
    payload: request,
  }),
  editwelComePageDataSuccess: (welComePage) => ({
    type: welComePageActions.EDIT_welComePage_SUCCESS,
    payload: welComePage,
  }),
  editwelComePageDataFailure: (error) => ({
    type: welComePageActions.EDIT_welComePage_FAILURE,
    payload: error,
  }),

  // Delete welComePage
  DELETE_welComePage_REQUEST: "DELETE_welComePage_REQUEST",
  DELETE_welComePage_SUCCESS: "DELETE_welComePage_SUCCESS",
  DELETE_welComePage_FAILURE: "DELETE_welComePage_FAILURE",
  deletewelComePageDataRequest: (request) => ({
    type: welComePageActions.DELETE_welComePage_REQUEST,
    payload: request,
  }),
  deletewelComePageDataSuccess: (welComePage) => ({
    type: welComePageActions.DELETE_welComePage_SUCCESS,
    payload: welComePage,
  }),
  deletewelComePageDataFailure: (error) => ({
    type: welComePageActions.DELETE_welComePage_FAILURE,
    payload: error,
  }),
};

export default welComePageActions;
