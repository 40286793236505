import { call, all, takeEvery, put } from "redux-saga/effects";
import actions from "./actions";
import CloudHawkApi2 from "@iso/config/Services/CloudHawk2";
import qs from "qs";

import Notification from "@iso/components/Notification";

// ********** 后台管理员账号列表(下拉) **********
function* getUsersRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getUsers, payload);
    if (response.code === 100) {
      yield put(actions.getUsersSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.getUsersFailure(response));
    }
  } catch (error) {
    yield put(actions.getUsersFailure(error));
  }
}

// ********** 用户(下拉) **********
function* getManageUsersSelectRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getManageUsersSelect, payload);
    if (response.code === 100) {
      yield put(actions.getManageUsersSelectSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.getManageUsersSelectFailure(response));
    }
  } catch (error) {
    yield put(actions.getManageUsersSelectFailure(error));
  }
}

function* getManageUsersRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getManageUsers, payload);
    if (response.code === 100) {
      yield put(actions.getManageUsersSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.getManageUsersFailure(response));
    }
  } catch (error) {
    yield put(actions.getManageUsersFailure(error));
  }
}

function* editManageUsersRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editManageUsers, payload);
    if (response.code === 100) {
      yield put(actions.editManageUsersSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editManageUsersFailure(response));
    }
  } catch (error) {
    yield put(actions.editManageUsersFailure(error));
  }
}

function* deleteManageUsersRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteManageUsers, payload);
    if (response.code === 100) {
      yield put(actions.deleteManageUsersSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteManageUsersFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteManageUsersFailure(error));
  }
}

function* goImportDownloadRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.goImportDownload, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.goImportDownloadSuccess(response.data));
  } catch (error) {
    yield put(actions.goImportDownloadFailure(error));
  }
}

function* user_device_record_Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.user_device_record, payload);
    if (response.code === 100) {
      yield put(actions.user_device_record_Success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.user_device_record_Failure(response));
    }
  } catch (error) {
    yield put(actions.user_device_record_Failure(error));
  }
}

function* user_device_record_Request2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.user_device_record, payload);
    if (response.code === 100) {
      yield put(actions.user_device_record_Success2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.user_device_record_Failure2(response));
    }
  } catch (error) {
    yield put(actions.user_device_record_Failure2(error));
  }
}

// function* getUserProductRequest(request) {
// 	const { payload } = request;
// 	try {
// 		const response = yield call(CloudHawkApi2.getUserProducts, payload);
// 		yield put(actions.getUserProductsSuccess(response.data));
// 	} catch (error) {
// 	}
// }

export default function* manageusersSaga() {
  yield all([
    takeEvery(actions.GET_USERS_REQUEST, getUsersRequest),
    takeEvery(
      actions.GET_MANAGE_USERS_SELECT_REQUEST,
      getManageUsersSelectRequest,
    ),
    takeEvery(actions.GET_MANAGE_USERS_REQUEST, getManageUsersRequest),
    takeEvery(actions.EDIT_MANAGE_USERS_REQUEST, editManageUsersRequest),
    takeEvery(actions.DELETE_MANAGE_USERS_REQUEST, deleteManageUsersRequest),
    takeEvery(actions.GO_IMPORT_DOWNLOAD_REQUEST, goImportDownloadRequest),
    takeEvery(actions.USER_DEVICE_RECORD_REQUEST, user_device_record_Request),
    takeEvery(actions.USER_DEVICE_RECORD_REQUEST2, user_device_record_Request2),
    // takeEvery(actions.GET_USER_PRODUCT_REQUEST, getUserProductRequest),
  ]);
}
