export function limitText(title, limit) {
    const newTitle = [];

    if (limit === -1) {
        return title;
    }

    if (title.length >= limit) {
        title.split('').reduce((acc, cur) => {
            if (acc + cur.length <= limit) {
                newTitle.push(cur);
            }
            return acc + cur.length;
        }, 0);
        return `${newTitle.join('') + '...'}`;
    }
    return title;
  }