const options = [
  {
    key: 'chat',
    label: 'sidebar.chat',
    leftIcon: 'ion-chatbubbles',
  },
  {
    key: 'maps',
    label: 'sidebar.maps',
    leftIcon: 'ion-map',
    children: [
      {
        key: 'googlemap',
        label: 'sidebar.googleMap',
      },
      {
        key: 'leafletmap',
        label: 'sidebar.leafletMap',
      },
    ],
  },
  {
    key: 'firestorecrud',
    label: 'sidebar.firestorecrud',
    leftIcon: 'ion-fireball',

    children: [
      {
        key: 'articles',
        label: 'sidebar.firestorecrudarticle',
      },
      {
        key: 'investors',
        label: 'sidebar.firestorecrudinvestor',
      },
    ],
  },
  {
    key: 'contacts',
    label: 'sidebar.contacts',
    leftIcon: 'ion-android-person-add',
  },
  {
    key: 'charts',
    label: 'sidebar.charts',
    leftIcon: 'ion-arrow-graph-up-right',
    children: [
      {
        key: 'googleChart',
        label: 'sidebar.googleCharts',
      },
      {
        key: 'reecharts',
        label: 'sidebar.recharts',
      },
      {
        key: 'reactChart2',
        label: 'sidebar.reactChart2',
      },
      {
        key: 'frappeChart',
        label: 'sidebar.frappeChart',
      },
    ],
  },
  {
    key: 'Forms',
    label: 'sidebar.forms',
    leftIcon: 'ion-android-mail',
    children: [
      {
        key: 'InputField',
        label: 'sidebar.input',
      },
      {
        key: 'editor',
        label: 'sidebar.editor',
      },
      {
        key: 'FormsWithValidation',
        label: 'sidebar.formsWithValidation',
      },
      {
        key: 'progress',
        label: 'sidebar.progress',
      },
      {
        key: 'button',
        label: 'sidebar.button',
      },
      {
        key: 'tab',
        label: 'sidebar.tab',
      },
      {
        key: 'checkbox',
        label: 'sidebar.checkbox',
      },
      {
        key: 'radiobox',
        label: 'sidebar.radiobox',
      },
      {
        key: 'selectbox',
        label: 'sidebar.selectbox',
      },
      {
        key: 'transfer',
        label: 'sidebar.transfer',
      },
      {
        key: 'autocomplete',
        label: 'sidebar.autocomplete',
      },
    ],
  },
  // {
  //   key: 'gridLayout',
  //   label: 'sidebar.boxOptions',
  //   leftIcon: 'ion-cube'
  // },
  {
    key: 'uielements',
    label: 'sidebar.uiElements',
    leftIcon: 'ion-leaf',
    children: [
      {
        key: 'op_badge',
        label: 'sidebar.badge',
      },
      {
        key: 'op_card',
        label: 'sidebar.card2',
      },
      {
        key: 'op_carousel',
        label: 'sidebar.corusel',
      },
      {
        key: 'op_collapse',
        label: 'sidebar.collapse',
      },
      {
        key: 'op_popover',
        label: 'sidebar.popover',
      },
      {
        key: 'op_tooltip',
        label: 'sidebar.tooltip',
      },
      {
        key: 'op_tag',
        label: 'sidebar.tag',
      },
      {
        key: 'op_timeline',
        label: 'sidebar.timeline',
      },
      {
        key: 'dropdown',
        label: 'sidebar.dropdown',
      },
      {
        key: 'pagination',
        label: 'sidebar.pagination',
      },
      {
        key: 'rating',
        label: 'sidebar.rating',
      },
      {
        key: 'tree',
        label: 'sidebar.tree',
      },
      {
        key: 'swiperslider',
        label: 'sidebar.swiperslider',
      },
    ],
  },
  {
    key: 'advancedUielements',
    label: 'sidebar.advancedElements',
    leftIcon: 'ion-flash',
    children: [
      {
        key: 'codeMirror',
        label: 'sidebar.codeMirror',
      },
      {
        key: 'uppy',
        label: 'sidebar.uppy',
      },
      {
        key: 'dropzone',
        label: 'sidebar.dropzone',
      },
    ],
  },
  {
    key: 'feedback',
    label: 'sidebar.feedback',
    leftIcon: 'ion-thumbsup',
    children: [
      {
        key: 'alert',
        label: 'sidebar.alert',
      },
      {
        key: 'modal',
        label: 'sidebar.modal',
      },
      {
        key: 'message',
        label: 'sidebar.message',
      },
      {
        key: 'notification',
        label: 'sidebar.notification',
      },
      {
        key: 'popConfirm',
        label: 'sidebar.popConfirm',
      },
      {
        key: 'spin',
        label: 'sidebar.spin',
      },
    ],
  },
  {
    key: 'table',
    label: 'sidebar.tables',
    leftIcon: 'ion-android-menu',
    children: [
      {
        key: 'table_ant',
        label: 'sidebar.antTables',
      },
    ],
  },
  {
    key: 'table',
    label: 'sidebar.tables',
    leftIcon: 'ion-android-menu',
    children: [
      {
        key: 'language',
        label: 'sidebar.language',
      },
    ],
  },
  {
    key: 'table',
    label: 'sidebar.tables',
    leftIcon: 'ion-android-menu',
    children: [
      {
        key: 'faq',
        label: 'sidebar.faq',
      },
    ],
  },

  // {
  //   key: 'comingSoon',
  //   label: 'sidebar.comingSoon',
  //    withoutDashboard: true
  // }


];
export default options;
