import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Mails from "@iso/redux/mail/reducer";
import Box from "@iso/redux/box/reducer";
import Contacts from "@iso/redux/contacts/reducer";
import Cards from "@iso/redux/card/reducer";
import Chat from "@iso/redux/chat/reducers";
import DynamicChartComponent from "@iso/redux/dynamicEchart/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import Articles from "@iso/redux/articles/reducers";
import Investors from "@iso/redux/investors/reducers";
import drawer from "@iso/redux/drawer/reducer";
import modal from "@iso/redux/modal/reducer";
import feedback from "@iso/redux/feedback/reducer";
import language from "@iso/redux/language/reducer";
import faq from "@iso/redux/faq/reducer";
import product from "@iso/redux/product/reducer";
import productV2 from "@iso/redux/productV2/reducer";
import equipment from "@iso/redux/equipment/reducer";
import ads from "@iso/redux/ads/reducer";
import user from "@iso/redux/user/reducer";
import productItem from "@iso/redux/productItems/reducer";
import country from "@iso/redux/country/reducer";
import mallManagement from "@iso/redux/mallManagement/reducer";
import orders from "@iso/redux/orders/reducer";
import ManageUser from "@iso/redux/ManageUsers/reducer";
import userStatistics from "@iso/redux/userStatistics/reducer";
import StatisticsProduct from "@iso/redux/StatisticsProduct/reducer";
import StatisticsUser from "@iso/redux/StatisticsUser/reducer";
import productStatistics from "@iso/redux/productStatistics/reducer";
import notifications from "@iso/redux/notifications/notificationReducer";
import brandName from "@iso/redux/brandName/brandReducer";
import modelName from "@iso/redux/modelName/modalReducer";
import firmware from "@iso/redux/firmware/firmwareReducer";
import firmwareApp from "@iso/redux/firmwareApp/firmwareReducer";
import firmwareData from "@iso/redux/firmwareData/firmwareDataReducer";
import SysMenu from "@iso/redux/sysMenu/reducer";
import SysRole from "@iso/redux/sysRole/reducer";
import SysDepartment from "@iso/redux/sysDepartment/reducer";
import SysCustomer from "@iso/redux/sysCustomer/reducer";
import SysUser from "@iso/redux/sysUser/reducer";
import IAAddressApply from "@iso/redux/IAAddressApply/reducer";
import IAAddressManage from "@iso/redux/IAAddressManage/reducer";
import setTemplate from "@iso/redux/setTemplate/reducer";
import editProductTemplate from "@iso/redux/editProductTemplate/reducer";
import editProductTemplatePower from "@iso/redux/editProductTemplatePower/reducer";
import welcomePage from "@iso/redux/welcomePage/reducer";
import devicePreset from "@iso/redux/devicePresetList/reducer";
import deviceTest from "@iso/redux/deviceTestList/reducer";
import sysLog from "@iso/redux/sysLog/reducer";
import messageTemplate from "@iso/redux/messageTemplate/reducer";
import messagePush from "@iso/redux/messagePush/reducer";
import faultManagement from "@iso/redux/faultManagement/reducer";
import ruleManage from "@iso/redux/ruleManage/reducer";
import apiOpen from "@iso/redux/apiOpen/reducer";

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Box,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Articles,
  Investors,
  modal,
  drawer,
  feedback,
  language,
  faq,
  product,
  productV2,
  equipment,
  ads,
  user,
  productItem,
  country,
  mallManagement,
  orders,
  ManageUser,
  userStatistics,
  StatisticsProduct,
  StatisticsUser,
  productStatistics,
  notifications,
  brandName,
  modelName,
  firmware,
  firmwareApp,
  firmwareData,
  SysMenu,
  SysRole,
  SysDepartment,
  SysCustomer,
  SysUser,
  IAAddressApply,
  IAAddressManage,
  setTemplate,
  editProductTemplate,
  editProductTemplatePower,
  welcomePage,
  devicePreset,
  deviceTest,
  sysLog,
  messageTemplate,
  messagePush,
  faultManagement,
  ruleManage,
  apiOpen,
});
