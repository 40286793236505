import { call, all, takeEvery, put, select } from "redux-saga/effects";
import Actions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk2";

// 不同终端类型用户
function* load_terminal_typeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.U_terminal_type);
    if (response.success) {
      yield put(Actions.load_terminal_typeSuccess(response.data));
    } else {
      yield put(Actions.load_terminal_typeFailure(response));
    }
  } catch (error) {
    yield put(Actions.load_terminal_typeFailure(error));
  }
}

// 用户新增
function* load_newly_addedRequest(request) {
  // yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.U_newly_added, payload);
    if (response.success) {
      yield put(Actions.load_newly_addedSuccess(response.data));
    } else {
      yield put(Actions.load_newly_addedFailure(response));
    }
  } catch (error) {
    yield put(Actions.load_newly_addedFailure(error));
  }
}

// 实时在线用户
function* load_realtime_onlineRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.U_realtime_online);
    if (response.success) {
      yield put(Actions.load_realtime_onlineSuccess(response.data));
    } else {
      yield put(Actions.load_realtime_onlineFailure(response));
    }
  } catch (error) {
    yield put(Actions.load_realtime_onlineFailure(error));
  }
}

// 版本分布
function* load_version_divisionRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.U_version_division);
    if (response.success) {
      yield put(Actions.load_version_divisionSuccess(response.data));
    } else {
      yield put(Actions.load_version_divisionFailure(response));
    }
  } catch (error) {
    yield put(Actions.load_version_divisionFailure(error));
  }
}

// 用户活跃度
function* load_activationRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.U_activation, payload);
    if (response.success) {
      yield put(Actions.load_activationSuccess(response.data));
    } else {
      yield put(Actions.load_activationFailure(response));
    }
  } catch (error) {
    yield put(Actions.load_activationFailure(error));
  }
}

// 用户群体分布
function* load_group_distributionRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.U_group_distribution);

    if (response.success) {
      yield put(Actions.load_group_distributionSuccess(response.data));
    } else {
      yield put(Actions.load_group_distributionFailure(response));
    }
  } catch (error) {
    yield put(Actions.load_group_distributionFailure(error));
  }
}

export default function* StatisticsProductSaga() {
  yield all([
    takeEvery(Actions.load_terminal_type_REQUEST, load_terminal_typeRequest),
    takeEvery(Actions.load_newly_added_REQUEST, load_newly_addedRequest),
    takeEvery(
      Actions.load_realtime_online_REQUEST,
      load_realtime_onlineRequest,
    ),
    takeEvery(
      Actions.load_version_division_REQUEST,
      load_version_divisionRequest,
    ),
    takeEvery(Actions.load_activation_REQUEST, load_activationRequest),
    takeEvery(
      Actions.load_group_distribution_REQUEST,
      load_group_distributionRequest,
    ),
  ]);
}
