const feedbackActions = {
  // Fetch Feedback COUNT //
  FETCH_FEEDBACK_COUNT_REQUEST: "FETCH_FEEDBACK_COUNT_REQUEST",
  FETCH_FEEDBACK_COUNT_SUCCESS: "FETCH_FEEDBACK_COUNT_SUCCESS",
  fetGetFeedBackCount: () => ({
    type: feedbackActions.FETCH_FEEDBACK_COUNT_REQUEST,
  }),
  fetGetFeedBackCountSuccess: (feedback) => ({
    type: feedbackActions.FETCH_FEEDBACK_COUNT_SUCCESS,
    payload: feedback,
  }),

  // Fetch Feedback Data //
  FETCH_FEEDBACK_DATA_REQUEST: "FETCH_FEEDBACK_DATA_REQUEST",
  FETCH_FEEDBACK_DATA_SUCCESS: "FETCH_FEEDBACK_DATA_SUCCESS",
  FETCH_FEEDBACK_DATA_FAILURE: "FETCH_FEEDBACK_DATA_FAILURE",
  fetchfeedbackDataRequest: (request) => ({
    type: feedbackActions.FETCH_FEEDBACK_DATA_REQUEST,
    payload: request,
  }),
  fetchfeedbackDataSuccess: (feedback) => ({
    type: feedbackActions.FETCH_FEEDBACK_DATA_SUCCESS,
    payload: feedback,
  }),
  fetchfeedbackDataFailure: (error) => ({
    type: feedbackActions.FETCH_FEEDBACK_DATA_FAILURE,
    payload: error,
  }),

  // set Feedback data //
  SET_FEEDBACK_REQUEST: "SET_FEEDBACK_REQUEST",
  SET_FEEDBACK_SUCCESS: "SET_FEEDBACK_SUCCESS",
  SET_FEEDBACK_FAILURE: "SET_FEEDBACK_FAILURE",
  setfeedDataRequest: (request) => ({
    type: feedbackActions.SET_FEEDBACK_REQUEST,
    payload: request,
  }),
  setfeedDataSuccess: (feedback) => ({
    type: feedbackActions.SET_FEEDBACK_SUCCESS,
    payload: feedback,
  }),
  setfeedDataFailure: (error) => ({
    type: feedbackActions.SET_FEEDBACK_FAILURE,
    payload: error,
  }),

  // reply Feedback //
  REPLY_FEEDBACK_REQUEST: "REPLY_FEEDBACK_REQUEST",
  REPLY_FEEDBACK_SUCCESS: "REPLY_FEEDBACK_SUCCESS",
  REPLY_FEEDBACK_FAILURE: "REPLY_FEEDBACK_FAILURE",
  replyfeedDataRequest: (request) => ({
    type: feedbackActions.REPLY_FEEDBACK_REQUEST,
    payload: request,
  }),
  replyfeedDataSuccess: (feedback) => ({
    type: feedbackActions.REPLY_FEEDBACK_SUCCESS,
    payload: feedback,
  }),
  replyfeedDataFailure: (error) => ({
    type: feedbackActions.REPLY_FEEDBACK_FAILURE,
    payload: error,
  }),

  // chnage Feedback status //
  CHANGE_FEEDBACK_STATUS_REQUEST: "CHANGE_FEEDBACK_STATUS_REQUEST",
  CHANGE_FEEDBACK_STATUS_SUCCESS: "CHANGE_FEEDBACK_STATUS_SUCCESS",
  CHANGE_FEEDBACK_STATUS_FAILURE: "CHANGE_FEEDBACK_STATUS_FAILURE",
  changeFeedbackStatusRequest: (request) => ({
    type: feedbackActions.CHANGE_FEEDBACK_STATUS_REQUEST,
    payload: request,
  }),
  changeFeedbackStatusSuccess: (feedback) => ({
    type: feedbackActions.CHANGE_FEEDBACK_STATUS_SUCCESS,
    payload: feedback,
  }),
  changeFeedbackStatusFailure: (error) => ({
    type: feedbackActions.CHANGE_FEEDBACK_STATUS_FAILURE,
    payload: error,
  }),

  // Close/Resolved Feedback //
  CHANGE_FEEDBACK_STATUS_CLOSED_REQUEST:
    "CHANGE_FEEDBACK_STATUS_CLOSED_REQUEST",
  CHANGE_FEEDBACK_STATUS_CLOSED_SUCCESS:
    "CHANGE_FEEDBACK_STATUS_CLOSED_SUCCESS",
  CHANGE_FEEDBACK_STATUS_CLOSED_FAILURE:
    "CHANGE_FEEDBACK_STATUS_CLOSED_FAILURE",
  changeFeedbackStatusClosedRequest: (request) => ({
    type: feedbackActions.CHANGE_FEEDBACK_STATUS_CLOSED_REQUEST,
    payload: request,
  }),
  changeFeedbackStatusClosedSuccess: (feedback) => ({
    type: feedbackActions.CHANGE_FEEDBACK_STATUS_CLOSED_SUCCESS,
    payload: feedback,
  }),
  changeFeedbackStatusClosedFailure: (error) => ({
    type: feedbackActions.CHANGE_FEEDBACK_STATUS_CLOSED_FAILURE,
    payload: error,
  }),

  //relate work order
  Relate_Work_Order_REQUEST: "Relate_Work_Order_REQUEST",
  relateWorkOrderRequset: (params) => ({
    type: feedbackActions.Relate_Work_Order_REQUEST,
    payload: params,
  }),
  //Delete Feedback LIST
  Delete_Feedback_REQUEST: "Delete_Feedback_REQUEST",
  deleteFeedbackListRequest: (params) => ({
    type: feedbackActions.Delete_Feedback_REQUEST,
    payload: params,
  }),
};

export default feedbackActions;
