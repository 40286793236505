import FaultManageMentActions from "./actions";

const INITIAL_DATA = {
  productSelectList: [],
  faultHandledData: [],
  faultHandledTotal: 0,
  faultNoHandlerData: [],
  faultNoHandlerTotal: 0,
  loadingHandled: true,
  loadingNoHandler: true,
  loading: true,
  error: null,
  reset: false,
};
export default function SysLogReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case FaultManageMentActions.GET_productSelect_List_REQUEST:
      return {
        ...state,
        productSelectList: [],
        loading: true,
        error: null,
      };
    case FaultManageMentActions.GET_productSelect_List_SUCCESS:
      return {
        ...state,
        productSelectList: action.payload,
        loading: false,
        error: null,
      };
    case FaultManageMentActions.GET_productSelect_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FaultManageMentActions.GET_faultHandled_List_REQUEST:
      return {
        ...state,
        faultHandledData: [],
        faultHandledTotal: 0,
        loadingHandled: true,
        error: null,
      };
    case FaultManageMentActions.GET_faultHandled_List_SUCCESS:
      return {
        ...state,
        faultHandledData: action.payload.list,
        faultHandledTotal: action.payload.total,
        loadingHandled: false,
        error: null,
      };
    case FaultManageMentActions.GET_faultHandled_List_ERROR:
      return {
        ...state,
        loadingHandled: false,
        error: action.payload,
      };
    case FaultManageMentActions.GET_faultNoHandler_List_REQUEST:
      return {
        ...state,
        faultNoHandlerData: [],
        faultNoHandlerTotal: 0,
        loadingNoHandler: true,
        error: null,
      };
    case FaultManageMentActions.GET_faultNoHandler_List_SUCCESS:
      return {
        ...state,
        faultNoHandlerData: action.payload.list,
        faultNoHandlerTotal: action.payload.total,
        loadingNoHandler: false,
        error: null,
      };
    case FaultManageMentActions.GET_faultNoHandler_List_ERROR:
      return {
        ...state,
        loadingNoHandler: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
