import { call, all, takeEvery, put } from "redux-saga/effects";
import brandActions from "./brandActions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";

function* getBrandRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getCategoryByBrandId, payload);
    yield put(brandActions.getBrandSuccess(response.data));
  } catch (error) {
    yield put(brandActions.getBrandFailure(error));
  }
}

export default function* brandSaga() {
  yield all([takeEvery(brandActions.GET_BRAND_ID_REQUEST, getBrandRequest)]);
}
