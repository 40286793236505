const IAAddressApplyActions = {
  GET_IAAddressApply_List_REQUEST: "GET_IAAddressApply_List_REQUEST",
  GET_IAAddressApply_List_SUCCESS: "GET_IAAddressApply_List_SUCCESS",
  GET_IAAddressApply_List_ERROR: "GET_IAAddressApply_List_ERROR",

  ADD_IAAddressApply_List_REQUEST: "ADD_IAAddressApply_List_REQUEST",
  ADD_IAAddressApply_List_SUCCESS: "ADD_IAAddressApply_List_SUCCESS",
  ADD_IAAddressApply_List_ERROR: "ADD_IAAddressApply_List_ERROR",

  Recovery_IAAddressApply_List_REQUEST: "Recovery_IAAddressApply_List_REQUEST",
  Recovery_IAAddressApply_List_SUCCESS: "Recovery_IAAddressApply_List_SUCCESS",
  Recovery_IAAddressApply_List_ERROR: "Recovery_IAAddressApply_List_ERROR",

  EXPORT_IAAddressApply_REQUEST: "EXPORT_IAAddressApply_REQUEST", //导出
  EXPORT_IAAddressApply_LIST_REQUEST: "EXPORT_IAAddressApply_LIST_REQUEST", //批量导出

  Del_IAAddressApply_List_REQUEST: "Del_IAAddressApply_List_REQUEST", //删除已回收的地址

  deleteIAAddressApplyList: (params) => ({
    type: IAAddressApplyActions.Del_IAAddressApply_List_REQUEST,
    payload: params,
  }),
  fetchGETIAAddressApplyList: (request) => ({
    type: IAAddressApplyActions.GET_IAAddressApply_List_REQUEST,
    payload: request,
  }),
  fetchGETIAAddressApplyListSuccess: (data) => ({
    type: IAAddressApplyActions.GET_IAAddressApply_List_SUCCESS,
    payload: data,
  }),
  fetchGETIAAddressApplyListError: (error) => ({
    type: IAAddressApplyActions.GET_IAAddressApply_List_ERROR,
    payload: error,
  }),

  fetchADDIAAddressApplyList: (request) => ({
    type: IAAddressApplyActions.ADD_IAAddressApply_List_REQUEST,
    payload: request,
  }),
  fetchADDIAAddressApplyListSuccess: (data) => ({
    type: IAAddressApplyActions.ADD_IAAddressApply_List_SUCCESS,
    payload: data,
  }),
  fetchADDIAAddressApplyListError: (error) => ({
    type: IAAddressApplyActions.ADD_IAAddressApply_List_ERROR,
    payload: error,
  }),

  fetchRecoveryIAAddressApplyList: (request) => ({
    type: IAAddressApplyActions.Recovery_IAAddressApply_List_REQUEST,
    payload: request,
  }),
  fetchRecoveryIAAddressApplyListSuccess: (data) => ({
    type: IAAddressApplyActions.Recovery_IAAddressApply_List_SUCCESS,
    payload: data,
  }),
  fetchRecoveryIAAddressApplyListError: (error) => ({
    type: IAAddressApplyActions.Recovery_IAAddressApply_List_ERROR,
    payload: error,
  }),
  //导出
  fetchExportIAAddressApply: (request) => ({
    type: IAAddressApplyActions.EXPORT_IAAddressApply_REQUEST,
    payload: request,
  }),
  //批量导出
  fetchExportIAAddressApplyList: (request) => ({
    type: IAAddressApplyActions.EXPORT_IAAddressApply_LIST_REQUEST,
    payload: request,
  }),
};

export default IAAddressApplyActions;
