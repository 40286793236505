import messageTemplateActions from "./actions";

const INITIAL_DATA = {
  SubTemplatedata: [],
  messageTemplatedataTotal: 0,
  messageTemplatedata: [],
  editmessageTemplate: [],
  addmessageTemplate: [],
  loading: true,
  error: null,
};
export default function messageTemplateReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case messageTemplateActions.FETCH_SubTemp_DATA_REQUEST:
      return {
        ...state,
        SubTemplatedata: [],
        loading: true,
        error: null,
      };
    case messageTemplateActions.FETCH_SubTemp_DATA_SUCCESS:
      return {
        ...state,
        SubTemplatedata: action.payload,
        loading: false,
        error: null,
      };
    case messageTemplateActions.FETCH_SubTemp_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case messageTemplateActions.FETCH_messageTemplate_DATA_REQUEST:
      return {
        ...state,
        messageTemplatedata: [],
        loading: true,
        error: null,
      };
    case messageTemplateActions.FETCH_messageTemplate_DATA_SUCCESS:
      let messageTemplatedata = Object.assign([], action.payload);
      messageTemplatedata.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        messageTemplatedata: action.payload.list,
        messageTemplatedataTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case messageTemplateActions.FETCH_messageTemplate_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case messageTemplateActions.ADD_messageTemplate_REQUEST:
      return {
        ...state,
        addmessageTemplate: [],
        loading: true,
        error: null,
      };
    case messageTemplateActions.ADD_messageTemplate_SUCCESS:
      return {
        ...state,
        addmessageTemplate: action.payload,
        loading: false,
        error: null,
      };
    case messageTemplateActions.ADD_messageTemplate_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // EDIT messageTemplate
    case messageTemplateActions.EDIT_messageTemplate_REQUEST:
      return {
        ...state,
        editmessageTemplate: [],
        loading: true,
        error: null,
      };
    case messageTemplateActions.EDIT_messageTemplate_SUCCESS:
      return {
        ...state,
        editmessageTemplate: action.payload,
        loading: false,
        error: null,
      };
    case messageTemplateActions.EDIT_messageTemplate_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case messageTemplateActions.DELETE_messageTemplate_REQUEST:
      return {
        ...state,
        messageTemplatedata: [],
        loading: true,
        error: null,
      };
    case messageTemplateActions.DELETE_messageTemplate_SUCCESS:
      return {
        ...state,
        messageTemplatedata: action.payload,
        loading: false,
        error: null,
      };
    case messageTemplateActions.DELETE_messageTemplate_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
