const SysDepartmentActions = {
  GET_Department_List_REQUEST: "GET_Department_List_REQUEST",
  GET_Department_List_SUCCESS: "GET_Department_List_SUCCESS",
  GET_Department_List_ERROR: "GET_Department_List_ERROR",

  ADD_Department_List_REQUEST: "ADD_Department_List_REQUEST",
  ADD_Department_List_SUCCESS: "ADD_Department_List_SUCCESS",
  ADD_Department_List_ERROR: "ADD_Department_List_ERROR",

  EDIT_Department_List_REQUEST: "EDIT_Department_List_REQUEST",
  EDIT_Department_List_SUCCESS: "EDIT_Department_List_SUCCESS",
  EDIT_Department_List_ERROR: "EDIT_Department_List_ERROR",

  DEL_Department_List_REQUEST: "DEL_Department_List_REQUEST",
  DEL_Department_List_SUCCESS: "DEL_Department_List_SUCCESS",
  DEL_Department_List_ERROR: "DEL_Department_List_ERROR",

  GET_DepartmentALL_List_REQUEST: "GET_DepartmentALL_List_REQUEST",
  GET_DepartmentALL_List_SUCCESS: "GET_DepartmentALL_List_SUCCESS",
  GET_DepartmentALL_List_ERROR: "GET_DepartmentALL_List_ERROR",
  fetchGETDepartmentALLList: () => ({
    type: SysDepartmentActions.GET_DepartmentALL_List_REQUEST,
    // payload: request
  }),
  fetchGETDepartmentALLListSuccess: (data) => ({
    type: SysDepartmentActions.GET_DepartmentALL_List_SUCCESS,
    payload: data,
  }),
  fetchGETDepartmentALLListError: (error) => ({
    type: SysDepartmentActions.GET_DepartmentALL_List_ERROR,
    payload: error,
  }),
  fetchGETDepartmentList: (request) => ({
    type: SysDepartmentActions.GET_Department_List_REQUEST,
    payload: request,
  }),
  fetchGETDepartmentListSuccess: (data) => ({
    type: SysDepartmentActions.GET_Department_List_SUCCESS,
    payload: data,
  }),
  fetchGETDepartmentListError: (error) => ({
    type: SysDepartmentActions.GET_Department_List_ERROR,
    payload: error,
  }),

  fetchADDDepartmentList: (request) => ({
    type: SysDepartmentActions.ADD_Department_List_REQUEST,
    payload: request,
  }),
  fetchADDDepartmentListSuccess: (data) => ({
    type: SysDepartmentActions.ADD_Department_List_SUCCESS,
    payload: data,
  }),
  fetchADDDepartmentListError: (error) => ({
    type: SysDepartmentActions.ADD_Department_List_ERROR,
    payload: error,
  }),

  fetchEDITDepartmentList: (request) => ({
    type: SysDepartmentActions.EDIT_Department_List_REQUEST,
    payload: request,
  }),
  fetchEDITDepartmentListSuccess: (data) => ({
    type: SysDepartmentActions.EDIT_Department_List_SUCCESS,
    payload: data,
  }),
  fetchEDITDepartmentListError: (error) => ({
    type: SysDepartmentActions.EDIT_Department_List_ERROR,
    payload: error,
  }),

  fetchDELDepartmentList: (request) => ({
    type: SysDepartmentActions.DEL_Department_List_REQUEST,
    payload: request,
  }),
};

export default SysDepartmentActions;
