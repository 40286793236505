import notificationsActions from "./notificationsActions";

const INITIAL_DATA = {
  notifications: 0,
  loading: false,
  error: null,
};
export default function notificationReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case notificationsActions.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case notificationsActions.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        loading: false,
        error: null,
      };
    case notificationsActions.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
