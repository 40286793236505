// saga.js
import { call, all, takeEvery, put, select } from "redux-saga/effects";
import languageActions from "./actions";
import CloudHawkApi3 from "@iso/config/Services/CloudHawk3";
import CloudHawkApi2 from "@iso/config/Services/CloudHawk2";
import statusAction from "../apiStatus/actions";
import Notification from "@iso/components/Notification";

const alert = (response) => {
  if (response.code === 100) {
    Notification("success", response.msg);
  } else {
    Notification("error", response.msg);
  }
};

function* selectlanguageRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi2.getLanguages);
    yield put(languageActions.selectlanguageSuccess(response.data));
  } catch (error) {
    yield put(languageActions.selectlanguageFailure(error));
  }
}

function* fetchCountryDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getCountry, payload);
    yield put(languageActions.fetchCountryDataSuccess(response.data));
  } catch (error) {
    yield put(languageActions.fetchCountryDataFailure(error));
  }
}
function* addCountryDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.addCountry, payload);
    yield put(languageActions.addCountryDataSuccess(response.data));
  } catch (error) {
    yield put(languageActions.addCountryDataFailure(error));
  }
}
function* editCountryDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editCountry, payload);
    yield put(languageActions.editCountryDataSuccess(response.data));
  } catch (error) {
    yield put(languageActions.editCountryDataFailure(error));
  }
}
function* deleteCountryDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteCountry, payload);
    yield put(languageActions.deleteCountryDataSuccess(response.data));
  } catch (error) {
    yield put(languageActions.deleteCountryDataFailure(error));
  }
}

function* fetchlanguageDataEffect(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.getLanguages, payload);
    if (response.success) {
      yield put(languageActions.fetchlanguageDataSuccess(response.data));
    }
  } catch (error) {
    yield put(languageActions.fetchlanguageDataFailure(error));
  }
}

function* addLanguageRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.createLanguage, payload.params1);
    if (response) {
      alert(response);
      yield put(languageActions.fetchlanguageDataRequest(payload.params2));
    }
  } catch (error) {
    yield put(languageActions.addLanguageDataFailure(error));
  }
}

function* editLanguageRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;

  try {
    const response = yield call(CloudHawkApi3.editLanguage, payload.params1);
    if (response) {
      alert(response);
      yield put(languageActions.fetchlanguageDataRequest(payload.params2));
    }
  } catch (error) {
    yield put(languageActions.editLanguageDataFailure(error));
  }
}

function* deleteLanguageRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.deleteLanguage, payload);
    if (response) {
      const total = yield select((state) => state.language.total);
      if (payload.pageNum > Math.ceil((total - 1) / 10)) {
        //last page
        payload.params2["pageNum"] = payload.params2["pageNum"] - 1;
        yield put(languageActions.fetchlanguageDataRequest(payload.params2));
      } else {
        //this page
        yield put(languageActions.fetchlanguageDataRequest(payload.params2));
      }
      alert(response);
    }
  } catch (error) {
    yield put(languageActions.deleteLanguageDataFailure(error));
  }
}

export default function* langauageSaga() {
  yield all([
    takeEvery(
      languageActions.FETCH_COUNTRY_DATA_REQUEST,
      fetchCountryDataRequest,
    ),
    takeEvery(languageActions.ADD_COUNTRY_REQUEST, addCountryDataRequest),
    takeEvery(languageActions.EDIT_COUNTRY_REQUEST, editCountryDataRequest),
    takeEvery(languageActions.DELETE_COUNTRY_REQUEST, deleteCountryDataRequest),

    takeEvery(languageActions.SELECT_LANGUAGE_REQUEST, selectlanguageRequest),
    takeEvery(
      languageActions.FETCH_LANGUAGE_DATA_REQUEST,
      fetchlanguageDataEffect,
    ),
    takeEvery(languageActions.ADD_LANGUAGE_REQUEST, addLanguageRequest),
    takeEvery(languageActions.EDIT_LANGUAGE_REQUEST, editLanguageRequest),
    takeEvery(languageActions.DELETE_LANGUAGE_REQUEST, deleteLanguageRequest),
  ]);
}
