const ruleManageActions = {
  DELETE_ruleManage_REQUEST: "DELETE_ruleManage_REQUEST",
  DELETE_ruleManage_SUCCESS: "DELETE_ruleManage_SUCCESS",
  DELETE_ruleManage_FAILURE: "DELETE_ruleManage_FAILURE",
  deleteRuleManageDataRequest: (request) => ({
    type: ruleManageActions.DELETE_ruleManage_REQUEST,
    payload: request,
  }),
  deleteRuleManageDataSuccess: (ruleManage) => ({
    type: ruleManageActions.DELETE_ruleManage_SUCCESS,
    payload: ruleManage,
  }),
  deleteRuleManageDataFailure: (error) => ({
    type: ruleManageActions.DELETE_ruleManage_FAILURE,
    payload: error,
  }),

  //Fetch ruleManage Data
  FETCH_ruleManage_DATA_REQUEST: "FETCH_ruleManage_DATA_REQUEST",
  FETCH_ruleManage_DATA_SUCCESS: "FETCH_ruleManage_DATA_SUCCESS",
  FETCH_ruleManage_DATA_FAILURE: "FETCH_ruleManage_DATA_FAILURE",
  SET_ruleManage_DATA: "SET_ruleManage_DATA",
  fetchruleManageDataRequest: (request) => ({
    type: ruleManageActions.FETCH_ruleManage_DATA_REQUEST,
    payload: request,
  }),
  fetchruleManageDataSuccess: (ruleManage) => ({
    type: ruleManageActions.FETCH_ruleManage_DATA_SUCCESS,
    payload: ruleManage,
  }),
  fetchruleManageDataFailure: (error) => ({
    type: ruleManageActions.FETCH_ruleManage_DATA_FAILURE,
    payload: error,
  }),

  // Add ruleManage
  ADD_ruleManage_REQUEST: "ADD_ruleManage_REQUEST",
  ADD_ruleManage_SUCCESS: "ADD_ruleManage_SUCCESS",
  ADD_ruleManage_FAILURE: "ADD_ruleManage_FAILURE",
  addruleManageDataRequest: (request) => ({
    type: ruleManageActions.ADD_ruleManage_REQUEST,
    payload: request,
  }),
  addruleManageDataSuccess: (ruleManage) => ({
    type: ruleManageActions.ADD_ruleManage_SUCCESS,
    payload: ruleManage,
  }),
  addruleManageDataFailure: (error) => ({
    type: ruleManageActions.ADD_ruleManage_FAILURE,
    payload: error,
  }),

  // Edit ruleManage
  EDIT_ruleManage_REQUEST: "EDIT_ruleManage_REQUEST",
  EDIT_ruleManage_SUCCESS: "EDIT_ruleManage_SUCCESS",
  EDIT_ruleManage_FAILURE: "EDIT_ruleManage_FAILURE",
  editruleManageDataRequest: (request) => ({
    type: ruleManageActions.EDIT_ruleManage_REQUEST,
    payload: request,
  }),
  editruleManageDataSuccess: (ruleManage) => ({
    type: ruleManageActions.EDIT_ruleManage_SUCCESS,
    payload: ruleManage,
  }),
  editruleManageDataFailure: (error) => ({
    type: ruleManageActions.EDIT_ruleManage_FAILURE,
    payload: error,
  }),

  //启用  禁用
  ISABLE_ruleManage_REQUEST: "ISABLE_ruleManage_REQUEST",
  isAbleruleManageDataRequest: (request) => ({
    type: ruleManageActions.ISABLE_ruleManage_REQUEST,
    payload: request,
  }),

  // Delete ruleManage
  GET_Event_REQUEST: "GET_Event_REQUEST",
  GET_Event_SUCCESS: "GET_Event_SUCCESS",
  GET_Event_FAILURE: "GET_Event_FAILURE",
  getEventListRequest: (request) => ({
    type: ruleManageActions.GET_Event_REQUEST,
    payload: request,
  }),
  getEventListSuccess: (ruleManage) => ({
    type: ruleManageActions.GET_Event_SUCCESS,
    payload: ruleManage,
  }),
  getEventListFailure: (error) => ({
    type: ruleManageActions.GET_Event_FAILURE,
    payload: error,
  }),

  GET_Property_REQUEST: "GET_Property_REQUEST",
  GET_Property_SUCCESS: "GET_Property_SUCCESS",
  GET_Property_FAILURE: "GET_Property_FAILURE",
  getPropertyListRequest: (request) => ({
    type: ruleManageActions.GET_Property_REQUEST,
    payload: request,
  }),
  getPropertyListSuccess: (ruleManage) => ({
    type: ruleManageActions.GET_Property_SUCCESS,
    payload: ruleManage,
  }),
  getPropertyListFailure: (error) => ({
    type: ruleManageActions.GET_Property_FAILURE,
    payload: error,
  }),

  GET_MessageTemp_REQUEST: "GET_MessageTemp_REQUEST",
  GET_MessageTemp_SUCCESS: "GET_MessageTemp_SUCCESS",
  GET_MessageTemp_FAILURE: "GET_MessageTemp_FAILURE",
  getMessageTempListRequest: () => ({
    type: ruleManageActions.GET_MessageTemp_REQUEST,
  }),
  getMessageTempListSuccess: (ruleManage) => ({
    type: ruleManageActions.GET_MessageTemp_SUCCESS,
    payload: ruleManage,
  }),
  getMessageTempListFailure: (error) => ({
    type: ruleManageActions.GET_MessageTemp_FAILURE,
    payload: error,
  }),

  // 系统管理员下拉框
  GET_SysAdminsList_REQUEST: "GET_SysAdminsList_REQUEST",
  GET_SysAdminsList_SUCCESS: "GET_SysAdminsList_SUCCESS",
  GET_SysAdminsList_FAILURE: "GET_SysAdminsList_FAILURE",
  getSysAdminsListRequest: () => ({
    type: ruleManageActions.GET_SysAdminsList_REQUEST,
  }),
  getSysAdminsListSuccess: (ruleManage) => ({
    type: ruleManageActions.GET_SysAdminsList_SUCCESS,
    payload: ruleManage,
  }),
  getSysAdminsListFailure: (error) => ({
    type: ruleManageActions.GET_SysAdminsList_FAILURE,
    payload: error,
  }),

  // 通知用户下拉框
  GET_SysUsersList_REQUEST: "GET_SysUsersList_REQUEST",
  GET_SysUsersList_SUCCESS: "GET_SysUsersList_SUCCESS",
  GET_SysUsersList_FAILURE: "GET_SysUsersList_FAILURE",
  getSysUsersListRequest: (request) => ({
    type: ruleManageActions.GET_SysUsersList_REQUEST,
    payload: request,
  }),
  getSysUsersListSuccess: (ruleManage) => ({
    type: ruleManageActions.GET_SysUsersList_SUCCESS,
    payload: ruleManage,
  }),
  getSysUsersListFailure: (error) => ({
    type: ruleManageActions.GET_SysUsersList_FAILURE,
    payload: error,
  }),
};

export default ruleManageActions;
