import StatisticsProductActions from "./actions";

const INITIAL_DATA = {
  country_select: [],
  product_select: [],

  communication_data_status: 0,
  newly_added_data_status: 0,
  activation_data_status: 0,
  distribution_data_status: 0,
  realtime_online_data_status: 0,
  version_division_data_status: 0,
  product_usage_data_status: 0,
  fault_data_status: 0,

  communication_data: null,
  newly_added_data: [],
  activation_data: [],
  distribution_data: [],
  realtime_online_data: {
    onlineInfoList: [],
    total: 0,
  },
  version_division_data: [],
  product_usage_data: [],
  fault_data: [],
  fault_data_add: 0,
  fault_data_total: 0,

  loading: true,
  error: null,
};
export default function PageReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    // product
    case StatisticsProductActions.load_productSelect_REQUESTP:
      return {
        ...state,
        product_select: [],
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_productSelect_SUCCESSP:
      return {
        ...state,
        product_select: action.payload,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_productSelect_FAILUREP:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // country
    case StatisticsProductActions.load_countrySelect_REQUESTP:
      return {
        ...state,
        country_select: [],
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_countrySelect_SUCCESSP:
      return {
        ...state,
        country_select: action.payload,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_countrySelect_FAILUREP:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 通讯类型
    case StatisticsProductActions.load_communication_REQUESTP:
      return {
        ...state,
        communication_data_status: 1,
        communication_data: null,
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_communication_SUCCESSP:
      return {
        ...state,
        communication_data_status: 2,
        communication_data: action.payload,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_communication_FAILUREP:
      return {
        ...state,
        communication_data_status: 3,
        loading: false,
        error: action.payload,
      };

    // 设备新增
    case StatisticsProductActions.load_newly_added_REQUESTP:
      return {
        ...state,
        newly_added_data: {
          addList: [],
          new: "+0",
          total: "0",
        },
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_newly_added_SUCCESSP:
      const res = action.payload;
      let addList = res["addList"];
      let _addList = addList.filter((item) => item.ordinateData > 0);
      if (_addList.length === 0) {
        addList = [];
        res["addList"] = addList;
      }
      if (addList && addList.length > 1) {
        const len = addList.length;
        res["new"] = addList[len - 1].ordinateData;
      } else {
        res["new"] = 0;
      }
      return {
        ...state,
        newly_added_data: res,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_newly_added_FAILUREP:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 设备活跃度
    case StatisticsProductActions.load_activation_REQUESTP:
      return {
        ...state,
        activation_data: [],
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_activation_SUCCESSP:
      return {
        ...state,
        activation_data: action.payload,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_activation_FAILUREP:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 设备分布
    case StatisticsProductActions.load_distribution_REQUESTP:
      return {
        ...state,
        distribution_data: [],
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_distribution_SUCCESSP:
      let list_distribution = action.payload;
      list_distribution = list_distribution.sort((a, b) => {
        let key = "quantity";
        if (a[key] > b[key]) {
          return -1;
        } else {
          return 1;
        }
      });

      for (let i = 0; i < list_distribution.length; i++) {
        list_distribution[i].percentF = list_distribution[i].percent
          ? (list_distribution[i].percent * 100).toFixed(1) + "%"
          : "0%";
      }
      return {
        ...state,
        distribution_data: list_distribution,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_distribution_FAILUREP:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 设备实时在线
    case StatisticsProductActions.load_realtime_online_REQUESTP:
      return {
        ...state,
        realtime_online_data: {
          onlineInfoList: [],
          total: 0,
        },
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_realtime_online_SUCCESSP:
      const realtime_online = action.payload;
      if (realtime_online && realtime_online.onlineInfoList) {
        for (let i = 0; i < realtime_online.onlineInfoList.length; i++) {
          realtime_online.onlineInfoList[i].percentF =
            (realtime_online.onlineInfoList[i].percent * 100).toFixed(1) + "%";
        }
      }
      return {
        ...state,
        realtime_online_data: realtime_online,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_realtime_online_FAILUREP:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 版本分布
    case StatisticsProductActions.load_version_division_REQUESTP:
      return {
        ...state,
        version_division_data: [],
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_version_division_SUCCESSP:
      // const res_vd = action.payload;
      // if (res_vd["addList"] && res_vd["addList"].length > 1) {
      //   const len = res_vd["addList"].length;
      //   const newVal =
      //     ~~res_vd["addList"][len - 1].ordinateData -
      //     ~~res_vd["addList"][len - 2].ordinateData;

      //   if (newVal < 0) {
      //     res_vd["new"] = "" + newVal;
      //   } else {
      //     res_vd["new"] = "+" + newVal;
      //   }
      // } else {
      //   res_vd["new"] = "+0";
      // }
      const list_vd = action.payload;
      for (let i = 0; i < list_vd.length; i++) {
        list_vd[i].percentF = list_vd[i].percent
          ? (list_vd[i].percent * 100).toFixed(1) + "%"
          : "0%";
      }
      return {
        ...state,
        version_division_data: list_vd,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_version_division_FAILUREP:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 产品型号
    case StatisticsProductActions.load_product_usage_REQUESTP:
      return {
        ...state,
        product_usage_data: [],
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_product_usage_SUCCESSP:
      const usage = action.payload;
      for (let i = 0; i < usage.length; i++) {
        usage[i].occupancyF = (usage[i].occupancy * 100).toFixed(1) + "%";
      }
      return {
        ...state,
        product_usage_data: usage,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_product_usage_FAILUREP:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // 设备故障
    case StatisticsProductActions.load_fault_REQUESTP:
      return {
        ...state,
        fault_data: [],
        fault_data_add: 0,
        fault_data_total: 0,
        loading: true,
        error: null,
      };
    case StatisticsProductActions.load_fault_SUCCESSP:
      const faultData = action.payload;
      let faultDataList = faultData.faultDataList
        ? JSON.parse(JSON.stringify(faultData.faultDataList))
        : [];
      faultDataList = faultData.faultDataList.filter(
        (item) => item.faultInfoList && item.faultInfoList.length,
      );
      if (!faultDataList.length) {
        faultData.faultDataList = [];
        faultData.faultInfoData = {};
      }
      return {
        ...state,
        fault_data_add: faultData.dayAddQuantity,
        fault_data_total: faultData.total,
        fault_data: faultData.faultDataList,
        loading: false,
        error: null,
      };
    case StatisticsProductActions.load_fault_FAILUREP:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
