const appUserActions = {
  // ********** 后台管理员账号列表(下拉) **********
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  getUsersRequest: (params) => ({
    type: appUserActions.GET_USERS_REQUEST,
    payload: params,
  }),
  getUsersSuccess: (product) => ({
    type: appUserActions.GET_USERS_SUCCESS,
    payload: product,
  }),
  getUsersFailure: (error) => ({
    type: appUserActions.GET_USERS_FAILURE,
    payload: error,
  }),
  // ********** 用户(下拉) **********
  GET_MANAGE_USERS_SELECT_REQUEST: "GET_MANAGE_USERS_SELECT_REQUEST",
  GET_MANAGE_USERS_SELECT_SUCCESS: "GET_MANAGE_USERS_SELECT_SUCCESS",
  GET_MANAGE_USERS_SELECT_FAILURE: "GET_MANAGE_USERS_SELECT_FAILURE",
  getManageUsersSelectRequest: (params) => ({
    type: appUserActions.GET_MANAGE_USERS_SELECT_REQUEST,
    payload: params,
  }),
  getManageUsersSelectSuccess: (product) => ({
    type: appUserActions.GET_MANAGE_USERS_SELECT_SUCCESS,
    payload: product,
  }),
  getManageUsersSelectFailure: (error) => ({
    type: appUserActions.GET_MANAGE_USERS_SELECT_FAILURE,
    payload: error,
  }),

  GET_MANAGE_USERS_REQUEST: "GET_MANAGE_USERS_REQUEST",
  GET_MANAGE_USERS_SUCCESS: "GET_MANAGE_USERS_SUCCESS",
  GET_MANAGE_USERS_FAILURE: "GET_MANAGE_USERS_FAILURE",
  getManageUsersRequest: (params) => ({
    type: appUserActions.GET_MANAGE_USERS_REQUEST,
    payload: params,
  }),
  getManageUsersSuccess: (product) => ({
    type: appUserActions.GET_MANAGE_USERS_SUCCESS,
    payload: product,
  }),
  getManageUsersFailure: (error) => ({
    type: appUserActions.GET_MANAGE_USERS_FAILURE,
    payload: error,
  }),

  EDIT_MANAGE_USERS_REQUEST: "EDIT_MANAGE_USERS_REQUEST",
  EDIT_MANAGE_USERS_SUCCESS: "EDIT_MANAGE_USERS_SUCCESS",
  EDIT_MANAGE_USERS_FAILURE: "EDIT_MANAGE_USERS_FAILURE",
  editManageUsersRequest: (params) => ({
    type: appUserActions.EDIT_MANAGE_USERS_REQUEST,
    payload: params,
  }),
  editManageUsersSuccess: (product) => ({
    type: appUserActions.EDIT_MANAGE_USERS_SUCCESS,
    payload: product,
  }),
  editManageUsersFailure: (error) => ({
    type: appUserActions.EDIT_MANAGE_USERS_FAILURE,
    payload: error,
  }),

  DELETE_MANAGE_USERS_REQUEST: "DELETE_MANAGE_USERS_REQUEST",
  DELETE_MANAGE_USERS_SUCCESS: "DELETE_MANAGE_USERS_SUCCESS",
  DELETE_MANAGE_USERS_FAILURE: "DELETE_MANAGE_USERS_FAILURE",
  deleteManageUsersRequest: (params) => ({
    type: appUserActions.DELETE_MANAGE_USERS_REQUEST,
    payload: params,
  }),
  deleteManageUsersSuccess: (product) => ({
    type: appUserActions.DELETE_MANAGE_USERS_SUCCESS,
    payload: product,
  }),
  deleteManageUsersFailure: (error) => ({
    type: appUserActions.DELETE_MANAGE_USERS_FAILURE,
    payload: error,
  }),

  GO_IMPORT_DOWNLOAD_REQUEST: "GO_IMPORT_DOWNLOAD_REQUEST",
  GO_IMPORT_DOWNLOAD_SUCCESS: "GO_IMPORT_DOWNLOAD_SUCCESS",
  GO_IMPORT_DOWNLOAD_FAILURE: "GO_IMPORT_DOWNLOAD_FAILURE",
  goImportDownloadRequest: (params) => ({
    type: appUserActions.GO_IMPORT_DOWNLOAD_REQUEST,
    payload: params,
  }),
  goImportDownloadSuccess: (product) => ({
    type: appUserActions.GO_IMPORT_DOWNLOAD_SUCCESS,
    payload: product,
  }),
  goImportDownloadFailure: (error) => ({
    type: appUserActions.GO_IMPORT_DOWNLOAD_FAILURE,
    payload: error,
  }),

  USER_DEVICE_RECORD_REQUEST: "USER_DEVICE_RECORD_REQUEST",
  USER_DEVICE_RECORD_SUCCESS: "USER_DEVICE_RECORD_SUCCESS",
  USER_DEVICE_RECORD_FAILURE: "USER_DEVICE_RECORD_FAILURE",
  user_device_record_Request: (params) => ({
    type: appUserActions.USER_DEVICE_RECORD_REQUEST,
    payload: params,
  }),
  user_device_record_Success: (product) => ({
    type: appUserActions.USER_DEVICE_RECORD_SUCCESS,
    payload: product,
  }),
  user_device_record_Failure: (error) => ({
    type: appUserActions.USER_DEVICE_RECORD_FAILURE,
    payload: error,
  }),

  USER_DEVICE_RECORD_REQUEST2: "USER_DEVICE_RECORD_REQUEST2",
  USER_DEVICE_RECORD_SUCCESS2: "USER_DEVICE_RECORD_SUCCESS2",
  USER_DEVICE_RECORD_FAILURE2: "USER_DEVICE_RECORD_FAILURE2",
  user_device_record_Request2: (params) => ({
    type: appUserActions.USER_DEVICE_RECORD_REQUEST2,
    payload: params,
  }),
  user_device_record_Success2: (product) => ({
    type: appUserActions.USER_DEVICE_RECORD_SUCCESS2,
    payload: product,
  }),
  user_device_record_Failure2: (error) => ({
    type: appUserActions.USER_DEVICE_RECORD_FAILURE2,
    payload: error,
  }),

  // GET_USER_PRODUCT_REQUEST: " GET_USER_PRODUCT_REQUEST",
  // GET_USER_PRODUCT_SUCCESS: " GET_USER_PRODUCT_SUCCESS",
  // getUserProductRequest: (data) => ({
  // 	type: appUserActions.GET_USER_PRODUCT_REQUEST,
  // 	payload: data
  // }),
  // getUserProductsSuccess: product => ({
  // 	type: appUserActions.GET_USER_PRODUCT_SUCCESS,
  // 	payload: product,
  // }),
};
export default appUserActions;
