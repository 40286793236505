import feedbackActions from "./actions";

const INITIAL_DATA = {
  total: 0,
  data: [],
  filterFeedback: [],
  replyFeedback: [],
  changeFeedbackStatus: [],
  closedFeedbackStatus: [],
  loading: true,
  loading_FEEDBACK: false,
  error: null,
  count: {},
  countInfo: {},
};
export default function feedbackReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case feedbackActions.FETCH_FEEDBACK_COUNT_REQUEST:
      return {
        ...state,
        countInfo: {},
        loading: true,
        error: null,
      };
    case feedbackActions.FETCH_FEEDBACK_COUNT_SUCCESS:
      return {
        ...state,
        countInfo: action.payload,
        loading: false,
        error: null,
      };

    // Fetch Feedback Data //
    case feedbackActions.FETCH_FEEDBACK_DATA_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
        //success: "",
      };
    case feedbackActions.FETCH_FEEDBACK_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.list,
        total: action.payload.total,
        loading: false,
        error: null,
        //success: check,
      };

    // set Feedback data //
    case feedbackActions.SET_FEEDBACK_REQUEST:
      return {
        ...state,
        filterFeedback: [],
      };
    case feedbackActions.SET_FEEDBACK_SUCCESS:
      const filterData = action.payload.data;
      const type = action.payload.type;
      const filterFeedback =
        !!filterData &&
        filterData.filter((item) => {
          if (type === "open" || type === 1) {
            return item.isOpen === true;
          } else if (type === "Responded") {
            return item.isResponded === true;
          } else if (type === "Closed") {
            return item.isClosed === true;
          } else if (type === "Total") {
            return item;
          }
          return true;
        });
      return {
        ...state,
        filterFeedback: filterFeedback ? filterFeedback : [],
        loading: false,
        error: null,
      };

    // reply Feedback //
    case feedbackActions.REPLY_FEEDBACK_REQUEST:
      return {
        ...state,
        loading: true,
        loading_FEEDBACK: !true,
        error: null,
      };
    case feedbackActions.REPLY_FEEDBACK_SUCCESS:
      return {
        ...state,
        replyFeedback: action.payload,
        loading: false,
        loading_FEEDBACK: true,
        error: null,
      };

    // Change Feedback status//
    case feedbackActions.CHANGE_FEEDBACK_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case feedbackActions.CHANGE_FEEDBACK_STATUS_SUCCESS:
      return {
        ...state,
        changeFeedbackStatus: action.payload,
        loading: false,
        error: null,
      };

    //  Feedback status Closed//
    case feedbackActions.CHANGE_FEEDBACK_STATUS_CLOSED_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case feedbackActions.CHANGE_FEEDBACK_STATUS_CLOSED_SUCCESS:
      return {
        ...state,
        closedFeedbackStatus: action.payload,
        loading: false,
        error: null,
      };

    // Feedback Module Error //////
    case feedbackActions.FETCH_FEEDBACK_DATA_FAILURE:
    case feedbackActions.SET_FEEDBACK_FAILURE:
    case feedbackActions.REPLY_FEEDBACK_FAILURE:
    case feedbackActions.CHANGE_FEEDBACK_STATUS_FAILURE:
    case feedbackActions.CHANGE_FEEDBACK_STATUS_CLOSED_FAILURE:
      return {
        ...state,
        loading: false,
        loading_FEEDBACK: !true,
        error: action.payload,
      };
    default:
      return state;
  }
}
