import IAAddressManageActions from "./actions";

const INITIAL_DATA = {
  selectList: [],
  detail: {},
  data: [],
  totalList: 0,
  loading: true,
  error: null,
  reset: false,
};
export default function IAAddressManageReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case IAAddressManageActions.GET_IAAddressManage_Select_REQUEST:
      return {
        ...state,
        selectList: [],
        loading: true,
        error: null,
      };
    case IAAddressManageActions.GET_IAAddressManage_Select_SUCCESS:
      return {
        ...state,
        selectList: action.payload,
        loading: false,
        error: null,
      };
    case IAAddressManageActions.GET_IAAddressManage_Select_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case IAAddressManageActions.GET_IAAddressManage_Detail_REQUEST:
      return {
        ...state,
        detail: {},
        loading: true,
        error: null,
      };
    case IAAddressManageActions.GET_IAAddressManage_Detail_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false,
        error: null,
      };
    case IAAddressManageActions.GET_IAAddressManage_Detail_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case IAAddressManageActions.GET_IAAddressManage_List_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case IAAddressManageActions.GET_IAAddressManage_List_SUCCESS:
      let data = Object.assign([], action.payload);

      data.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        data: action.payload.list,
        totalList: action.payload.total,
        loading: false,
        error: null,
      };
    case IAAddressManageActions.GET_IAAddressManage_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case IAAddressManageActions.ADD_IAAddressManage_List_REQUEST:
      return {
        ...state,
        data: [],
        totalList: 0,
        loading: true,
        error: null,
      };
    case IAAddressManageActions.ADD_IAAddressManage_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case IAAddressManageActions.ADD_IAAddressManage_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case IAAddressManageActions.EDIT_IAAddressManage_List_REQUEST:
      return {
        ...state,
        data: [],
        totalList: 0,
        loading: true,
        error: null,
      };
    case IAAddressManageActions.EDIT_IAAddressManage_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case IAAddressManageActions.EDIT_IAAddressManage_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case IAAddressManageActions.DEL_IAAddressManage_List_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case IAAddressManageActions.DEL_IAAddressManage_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case IAAddressManageActions.DEL_IAAddressManage_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
