import { call, all, takeEvery, put, select } from "redux-saga/effects";
import SysRoleActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk2";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
  });
};

function* getRoleALLList() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getRoleALLList);
    yield put(SysRoleActions.fetchGETRoleALLListSuccess(response.data));
  } catch (error) {
    yield put(SysRoleActions.fetchGETRoleALLListError(error));
  }
}

function* getRoleList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getRoleList, payload);
    if (!response.success) {
      Error(response);
    }
    yield put(SysRoleActions.fetchGETRoleListSuccess(response.data));
  } catch (error) {
    yield put(SysRoleActions.fetchGETRoleListError(error));
  }
}

function* addRoleList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addRoleList, payload);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(
      SysRoleActions.fetchGETRoleList({
        pageNum: payload.pageNum,
        pageSize: payload.pageSize,
      }),
    );
  }
}

function* editRoleList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editRoleList, payload);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(
      SysRoleActions.fetchGETRoleList({
        pageNum: payload.pageNum,
        pageSize: payload.pageSize,
        name: payload.searchname,
      }),
    );
  }
}

function* delRoleList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.delRoleList, payload);

    if (response.success) {
      const total = yield select((state) => state.SysRole.totalList);
      if (payload.pageNum > Math.ceil((total - 1) / payload.pageSize)) {
        //last page
        const params = {
          name: payload.name,
          pageNum: payload.pageNum - 1,
          pageSize: payload.pageSize,
        };
        yield put(SysRoleActions.fetchGETRoleList(params));
      } else {
        //this page
        const params = {
          name: payload.name,
          pageNum: payload.pageNum,
          pageSize: payload.pageSize,
        };
        yield put(SysRoleActions.fetchGETRoleList(params));
      }
      alert(response);
    } else {
      const params = {
        name: payload.name,
        pageNum: payload.pageNum,
        pageSize: payload.pageSize,
      };
      yield put(SysRoleActions.fetchGETRoleList(params));
      Error(response);
    }
  } catch (error) {}
}
export default function* userSaga() {
  yield all([
    takeEvery(SysRoleActions.GET_Role_List_REQUEST, getRoleList),
    takeEvery(SysRoleActions.GET_RoleALL_List_REQUEST, getRoleALLList),
    takeEvery(SysRoleActions.ADD_Role_List_REQUEST, addRoleList),
    takeEvery(SysRoleActions.EDIT_Role_List_REQUEST, editRoleList),
    takeEvery(SysRoleActions.DEL_Role_List_REQUEST, delRoleList),
  ]);
}
