import DevicePresetActions from "./actions";

const INITIAL_DATA = {
  DevicePresetdataTotal: 0,
  DevicePresetdata: [],
  editDevicePreset: [],
  addDevicePreset: [],
  loading: true,
  error: null,
};
export default function DevicePresetReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case DevicePresetActions.FETCH_DevicePreset_DATA_REQUEST:
      return {
        ...state,
        DevicePresetdata: [],
        loading: true,
        error: null,
      };
    case DevicePresetActions.FETCH_DevicePreset_DATA_SUCCESS:
      let DevicePresetdata = Object.assign([], action.payload);
      DevicePresetdata.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        DevicePresetdata: action.payload.list,
        DevicePresetdataTotal: action.payload.total,
        loading: false,
        error: null,
      };
    case DevicePresetActions.FETCH_DevicePreset_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DevicePresetActions.ADD_DevicePreset_REQUEST:
      return {
        ...state,
        addDevicePreset: [],
        loading: true,
        error: null,
      };
    case DevicePresetActions.ADD_DevicePreset_SUCCESS:
      return {
        ...state,
        addDevicePreset: action.payload,
        loading: false,
        error: null,
      };
    case DevicePresetActions.ADD_DevicePreset_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // EDIT DevicePreset
    case DevicePresetActions.EDIT_DevicePreset_REQUEST:
      return {
        ...state,
        editDevicePreset: [],
        loading: true,
        error: null,
      };
    case DevicePresetActions.EDIT_DevicePreset_SUCCESS:
      return {
        ...state,
        editDevicePreset: action.payload,
        loading: false,
        error: null,
      };
    case DevicePresetActions.EDIT_DevicePreset_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DevicePresetActions.DELETE_DevicePreset_REQUEST:
      return {
        ...state,
        DevicePresetdata: [],
        loading: true,
        error: null,
      };
    case DevicePresetActions.DELETE_DevicePreset_SUCCESS:
      return {
        ...state,
        DevicePresetdata: action.payload,
        loading: false,
        error: null,
      };
    case DevicePresetActions.DELETE_DevicePreset_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
