import { call, all, takeEvery, put, select } from "redux-saga/effects";
import StatisticsProductActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk2";
import qs from "qs";

function* export_distributionRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.distribution_export, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);
    yield put(
      StatisticsProductActions.export_distributionSuccessP(response.data),
    );
  } catch (error) {
    yield put(StatisticsProductActions.export_distributionFailureP(error));
  }
}

function* load_productSelectRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.PU_product);
    if (response.success) {
      yield put(
        StatisticsProductActions.load_productSelectSuccessP(response.data),
      );
    } else {
      yield put(StatisticsProductActions.load_productSelectFailureP(response));
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_productSelectFailureP(error));
  }
}

// country
function* load_countrySelectRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.PU_country);
    if (response.success) {
      yield put(
        StatisticsProductActions.load_countrySelectSuccessP(response.data),
      );
    } else {
      yield put(StatisticsProductActions.load_countrySelectFailureP(response));
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_countrySelectFailureP(error));
  }
}

// 通讯类型
function* load_communicationRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.P_communication);
    if (response.success) {
      yield put(
        StatisticsProductActions.load_communicationSuccessP(response.data),
      );
    } else {
      yield put(StatisticsProductActions.load_communicationFailureP(response));
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_communicationFailureP(error));
  }
}

// 设备新增
function* load_newly_addedRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.P_newly_added, payload);
    if (response.success) {
      yield put(
        StatisticsProductActions.load_newly_addedSuccessP(response.data),
      );
    } else {
      yield put(StatisticsProductActions.load_newly_addedFailureP(response));
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_newly_addedFailureP(error));
  }
}

// 设备活跃度
function* load_activationRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.P_activation, payload);
    if (response.success) {
      yield put(
        StatisticsProductActions.load_activationSuccessP(response.data),
      );
    } else {
      yield put(StatisticsProductActions.load_activationFailureP(response));
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_activationFailureP(error));
  }
}

// 设备分布
function* load_distributionRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.P_distribution, payload);
    if (response.success) {
      yield put(
        StatisticsProductActions.load_distributionSuccessP(response.data),
      );
    } else {
      yield put(StatisticsProductActions.load_distributionFailureP(response));
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_distributionFailureP(error));
  }
}

// 设备实时在线
function* load_realtime_onlineRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.P_realtime_online);
    if (response.success) {
      yield put(
        StatisticsProductActions.load_realtime_onlineSuccessP(response.data),
      );
    } else {
      yield put(
        StatisticsProductActions.load_realtime_onlineFailureP(response),
      );
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_realtime_onlineFailureP(error));
  }
}

// 版本分布
function* load_version_divisionRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.P_version_division, payload);
    if (response.success) {
      yield put(
        StatisticsProductActions.load_version_divisionSuccessP(response.data),
      );
    } else {
      yield put(
        StatisticsProductActions.load_version_divisionFailureP(response),
      );
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_version_divisionFailureP(error));
  }
}

// 产品型号
function* load_product_usageRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.P_product_usage, payload);
    if (response.success) {
      yield put(
        StatisticsProductActions.load_product_usageSuccessP(response.data),
      );
    } else {
      yield put(StatisticsProductActions.load_product_usageFailureP(response));
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_product_usageFailureP(error));
  }
}

// 设备故障
function* load_faultRequestP(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.P_fault, payload);
    if (response.success) {
      yield put(StatisticsProductActions.load_faultSuccessP(response.data));
    } else {
      yield put(StatisticsProductActions.load_faultFailureP(response));
    }
  } catch (error) {
    yield put(StatisticsProductActions.load_faultFailureP(error));
  }
}

export default function* StatisticsProductSaga() {
  yield all([
    takeEvery(
      StatisticsProductActions.export_distribution_REQUESTP,
      export_distributionRequestP,
    ),
    takeEvery(
      StatisticsProductActions.load_productSelect_REQUESTP,
      load_productSelectRequestP,
    ),
    takeEvery(
      StatisticsProductActions.load_countrySelect_REQUESTP,
      load_countrySelectRequestP,
    ),
    takeEvery(
      StatisticsProductActions.load_communication_REQUESTP,
      load_communicationRequestP,
    ),
    takeEvery(
      StatisticsProductActions.load_newly_added_REQUESTP,
      load_newly_addedRequestP,
    ),
    takeEvery(
      StatisticsProductActions.load_activation_REQUESTP,
      load_activationRequestP,
    ),
    takeEvery(
      StatisticsProductActions.load_distribution_REQUESTP,
      load_distributionRequestP,
    ),
    takeEvery(
      StatisticsProductActions.load_realtime_online_REQUESTP,
      load_realtime_onlineRequestP,
    ),
    takeEvery(
      StatisticsProductActions.load_version_division_REQUESTP,
      load_version_divisionRequestP,
    ),
    takeEvery(
      StatisticsProductActions.load_product_usage_REQUESTP,
      load_product_usageRequestP,
    ),
    takeEvery(StatisticsProductActions.load_fault_REQUESTP, load_faultRequestP),
  ]);
}
