const actions = {
  CLOUDHAWK_API_STATUS_ERROR: "CLOUDHAWK_API_STATUS_ERROR",
  CLOUDHAWK_API_STATUS_LOADING: "CLOUDHAWK_API_STATUS_LOADING",
  CLOUDHAWK_API_STATUS_CLEAR: "CLOUDHAWK_API_STATUS_CLEAR",
  CLOUDHAWK_API_STATUS_SUCCESS: "CLOUDHAWK_API_STATUS_SUCCESS",

  cloudHawkApiStatusError: (payload) => ({
    type: actions.CLOUDHAWK_API_STATUS_ERROR,
    payload,
  }),

  cloudHawkApiStatusLoading: () => ({
    type: actions.CLOUDHAWK_API_STATUS_LOADING,
  }),

  cloudHawkApiStatusSuccess: (payload) => ({
    type: actions.CLOUDHAWK_API_STATUS_SUCCESS,
    payload,
  }),

  cloudHawkApiStatusClear: () => ({
    type: actions.CLOUDHAWK_API_STATUS_CLEAR,
  }),
};

export default actions;
