import actions from "./actions";

const INITIAL_DATA = {
  // 0/未操作 1/操作中 2/操作成功 3/操作失败
  copyStatus: "0", //  copy
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除
  detailStatus: "0", // 详情

  apiList: [],

  detail: {},

  list: [],
  listTotal: 0,
  loading: true,
  productCreateSuccess: true,
  error: null,
};
export default function productReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    //  ********* 1 *******  //
    case actions.OPEN_API_PAGE_REQUEST:
      return {
        ...state,
        list: [],
        listTotal: 0,
        loading: true,
        error: null,
      };
    case actions.OPEN_API_PAGE_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        listTotal: action.payload.total,
        loading: !true,
        error: null,
      };
    case actions.OPEN_API_PAGE_FAILURE:
      return {
        ...state,
        list: [],
        listTotal: 0,
        loading: !true,
        error: action.payload,
      };

    //  ********* 2 *******  //
    case actions.OPEN_API_ADD_REQUEST:
      return {
        ...state,
        createStatus: "1", //  新增
        error: null,
      };
    case actions.OPEN_API_ADD_SUCCESS:
      return {
        ...state,
        createStatus: "2", //  新增
        error: null,
      };
    case actions.OPEN_API_ADD_FAILURE:
      return {
        ...state,
        createStatus: "3", //  新增
        error: action.payload,
      };

    //  ********* EDIT *******  //
    case actions.OPEN_API_EDIT_REQUEST:
      return {
        ...state,
        editStatus: "1", //
        error: null,
      };
    case actions.OPEN_API_EDIT_SUCCESS:
      return {
        ...state,
        editStatus: "2", //
        error: null,
      };
    case actions.OPEN_API_EDIT_FAILURE:
      return {
        ...state,
        editStatus: "3", //
        error: action.payload,
      };

    //  ********* 3 *******  //
    case actions.OPEN_API_DETAIL_REQUEST:
      return {
        ...state,
        detail: {},
        detailStatus: "1", // 详情
        error: null,
      };
    case actions.OPEN_API_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        detailStatus: "2", // 详情
        error: null,
      };
    case actions.OPEN_API_DETAIL_FAILURE:
      return {
        ...state,
        detail: {},
        detailStatus: "3", // 详情
        error: action.payload,
      };

    //  ********* 4 *******  //
    case actions.OPEN_API_RESET_KEY_REQUEST:
      return {
        ...state,
        editStatus: "1", //
        error: null,
      };
    case actions.OPEN_API_RESET_KEY_SUCCESS:
      return {
        ...state,
        editStatus: "2", //
        error: null,
      };
    case actions.OPEN_API_RESET_KEY_FAILURE:
      return {
        ...state,
        editStatus: "3", //
        error: action.payload,
      };

    //  ********* 5 *******  //
    case actions.OPEN_API_DELETE_REQUEST:
      return {
        ...state,
        deleteStatus: "1", //  删除
        error: null,
      };
    case actions.OPEN_API_DELETE_SUCCESS:
      return {
        ...state,
        deleteStatus: "2", //  删除
        error: null,
      };
    case actions.OPEN_API_DELETE_FAILURE:
      return {
        ...state,
        deleteStatus: "3", //  删除
        error: action.payload,
      };

    //  ********* 6 *******  //
    case actions.OPEN_API_SAVE_IP_REQUEST:
      return {
        ...state,
        editStatus: "1", //  编辑
        error: null,
      };
    case actions.OPEN_API_SAVE_IP_SUCCESS:
      return {
        ...state,
        editStatus: "2", //  编辑
        error: null,
      };
    case actions.OPEN_API_SAVE_IP_FAILURE:
      return {
        ...state,
        editStatus: "3", //  编辑
        error: action.payload,
      };

    //  ********* 7 *******  //
    case actions.OPEN_API_SAVE_SECCESS_REQUEST:
      return {
        ...state,
        editStatus: "1", //  编辑
        error: null,
      };
    case actions.OPEN_API_SAVE_SECCESS_SUCCESS:
      return {
        ...state,
        editStatus: "2", //  编辑
        error: null,
      };
    case actions.OPEN_API_SAVE_SECCESS_FAILURE:
      return {
        ...state,
        editStatus: "3", //  编辑
        error: action.payload,
      };

    //  ********* 8 *******  //
    case actions.OPEN_API_API_LIST_REQUEST:
      return {
        ...state,
        apiList: [],
        error: null,
      };
    case actions.OPEN_API_API_LIST_SUCCESS:
      return {
        ...state,
        apiList: action.payload,
        error: null,
      };
    case actions.OPEN_API_API_LIST_FAILURE:
      return {
        ...state,
        apiList: [],
        error: action.payload,
      };

    default:
      return state;
  }
}
