import productItemActions from "./actions";

const INITIAL_DATA = {
  addProductBrand: [],
  editProductBrand: [],
  productBrand: [],
  addProductCategory: [],
  editProductCategory: [],
  productCategory: [],
  addProductModel: [],
  editProductModel: [],
  productModel: [],
  addProductActionTypes: [],
  editProductActionTypes: [],
  productActionTypes: [],
  addProductCommunication: [],
  editProductCommunication: [],
  productCommunication: [],
  getCreatedProductItem: [],
  loading: false,
  error: null,
};
export default function productItemReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    //  *********  Add Product Brand *******   //
    case productItemActions.CREATE_PRODUCT_BRAND_REQUEST:
      return {
        ...state,
        addProductBrand: [],
        loading: true,
        error: null,
      };
    case productItemActions.CREATE_PRODUCT_BRAND_SUCCESS:
      return {
        ...state,
        addProductBrand: action.payload,
        loading: false,
        error: null,
      };

    //  ********* get Edit & update Product Brand *******   //
    case productItemActions.GET_PRODUCT_CREATED_ITEM_REQUEST:
      return {
        ...state,
        getCreatedProductItem: [],
        loading: true,
        error: null,
      };
    case productItemActions.GET_PRODUCT_CREATED_ITEM_SUCCESS:
      return {
        ...state,
        getCreatedProductItem: action.payload,
        loading: true,
        error: null,
      };

    //  ********* update Product Brand *******   //
    case productItemActions.EDIT_PRODUCT_BRAND_REQUEST:
      return {
        ...state,
        editProductBrand: [],
        loading: true,
        error: null,
      };

    case productItemActions.EDIT_PRODUCT_BRAND_SUCCESS:
      return {
        ...state,
        editProductBrand: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Delete Product Brand *******   //
    case productItemActions.DELETE_PRODUCT_BRAND_REQUEST:
      return {
        ...state,
        productBrand: [],
        loading: true,
        error: null,
      };
    case productItemActions.DELETE_PRODUCT_BRAND_SUCCESS:
      return {
        ...state,
        productBrand: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Add Product Category *******   //
    case productItemActions.CREATE_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        addProductCategory: [],
        loading: true,
        error: null,
      };
    case productItemActions.CREATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        addProductCategory: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Edit Product Category *******   //
    case productItemActions.EDIT_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        editProductCategory: [],
        loading: true,
        error: null,
      };
    case productItemActions.EDIT_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        editProductCategory: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Delete Product Category *******   //
    case productItemActions.DELETE_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        productCategory: [],
        loading: true,
        error: null,
      };
    case productItemActions.DELETE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategory: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Add Product Model *******   //
    case productItemActions.CREATE_PRODUCT_MODEL_REQUEST:
      return {
        ...state,
        addProductModel: [],
        loading: true,
        error: null,
      };
    case productItemActions.CREATE_PRODUCT_MODEL_SUCCESS:
      return {
        ...state,
        addProductModel: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Edit Product Model *******   //
    case productItemActions.EDIT_PRODUCT_MODEL_REQUEST:
      return {
        ...state,
        editProductModel: [],
        loading: true,
        error: null,
      };
    case productItemActions.EDIT_PRODUCT_MODEL_SUCCESS:
      return {
        ...state,
        editProductModel: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Delete Product Model *******   //
    case productItemActions.DELETE_PRODUCT_MODEL_REQUEST:
      return {
        ...state,
        productModel: [],
        loading: true,
        error: null,
      };
    case productItemActions.DELETE_PRODUCT_MODEL_SUCCESS:
      return {
        ...state,
        productModel: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Add Product Action Type *******   //
    case productItemActions.CREATE_PRODUCT_ACTIONS_TYPES_REQUEST:
      return {
        ...state,
        addProductActionTypes: [],
        loading: true,
        error: null,
      };
    case productItemActions.CREATE_PRODUCT_ACTIONS_TYPES_SUCCESS:
      return {
        ...state,
        addProductActionTypes: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Edit Product Action Type *******   //
    case productItemActions.EDIT_PRODUCT_ACTIONS_TYPES_REQUEST:
      return {
        ...state,
        editProductActionTypes: [],
        loading: true,
        error: null,
      };
    case productItemActions.EDIT_PRODUCT_ACTIONS_TYPES_SUCCESS:
      return {
        ...state,
        editProductActionTypes: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Delete Product Action Type *******   //
    case productItemActions.DELETE_PRODUCT_ACTIONS_TYPES_REQUEST:
      return {
        ...state,
        productActionTypes: [],
        loading: true,
        error: null,
      };
    case productItemActions.DELETE_PRODUCT_ACTIONS_TYPES_SUCCESS:
      return {
        ...state,
        productActionTypes: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Add Product Communication *******   //
    case productItemActions.CREATE_PRODUCT_COMMUNICATION_TYPE_REQUEST:
      return {
        ...state,
        addProductCommunication: [],
        loading: true,
        error: null,
      };
    case productItemActions.CREATE_PRODUCT_COMMUNICATION_TYPE_SUCCESS:
      return {
        ...state,
        addProductCommunication: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Edit Product Communication *******   //
    case productItemActions.EDIT_PRODUCT_COMMUNICATION_TYPE_REQUEST:
      return {
        ...state,
        editProductCommunication: [],
        loading: true,
        error: null,
      };
    case productItemActions.EDIT_PRODUCT_COMMUNICATION_TYPE_SUCCESS:
      return {
        ...state,
        editProductCommunication: action.payload,
        loading: false,
        error: null,
      };

    //  *********  Delete Product Communication *******   //
    case productItemActions.DELETE_PRODUCT_COMMUNICATION_TYPE_REQUEST:
      return {
        ...state,
        productCommunication: [],
        loading: true,
        error: null,
      };
    case productItemActions.DELETE_PRODUCT_COMMUNICATION_TYPE_SUCCESS:
      return {
        ...state,
        productCommunication: action.payload,
        loading: false,
        error: null,
      };

    //  ********* Product Item Error*******   //
    case productItemActions.CREATE_PRODUCT_BRAND_FAILURE:
    case productItemActions.EDIT_PRODUCT_BRAND_FAILURE:
    case productItemActions.DELETE_PRODUCT_BRAND_FAILURE:
    case productItemActions.CREATE_PRODUCT_CATEGORY_FAILURE:
    case productItemActions.EDIT_PRODUCT_CATEGORY_FAILURE:
    case productItemActions.DELETE_PRODUCT_CATEGORY_FAILURE:
    case productItemActions.CREATE_PRODUCT_MODEL_FAILURE:
    case productItemActions.EDIT_PRODUCT_MODEL_FAILURE:
    case productItemActions.DELETE_PRODUCT_MODEL_FAILURE:
    case productItemActions.CREATE_PRODUCT_ACTIONS_TYPES_FAILURE:
    case productItemActions.EDIT_PRODUCT_ACTIONS_TYPES_FAILURE:
    case productItemActions.DELETE_PRODUCT_ACTIONS_TYPES_FAILURE:
    case productItemActions.CREATE_PRODUCT_COMMUNICATION_TYPE_FAILURE:
    case productItemActions.EDIT_PRODUCT_COMMUNICATION_TYPE_FAILURE:
    case productItemActions.DELETE_PRODUCT_COMMUNICATION_TYPE_FAILURE:
    case productItemActions.GET_PRODUCT_CREATED_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
