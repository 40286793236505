import actions from "./firmwareActions";

const INITIAL_DATA = {
  // 0/未操作 1/操作中 2/操作成功 3/操作失败
  passStatus: "0", //
  refuseStatus: "0", //
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除
  detailStatus: "0", // 详情
  disableStatus: "0", //
  enableStatus: "0", //

  dataDetailNew: {},

  historyData: [],

  selectFirmwareDtuList: [],

  selectFirmwareVersionList: [],

  selectOrderList: [],
  selectBatchList: [],

  firmwareLoading: false,
  firmwareList: [],
  firmwareTotal: 0,

  firmwareUpdateNewLoading: false,
  firmwareUpdateNewList: [],
  firmwareUpdateNewTotal: 0,

  firmwareUpdateInNewLoading: false,
  firmwareUpdateInNewList: [],
  firmwareUpdateInNewTotal: 0,

  firmwareUpdateOldLoading: false,
  firmwareUpdateOldList: [],
  firmwareUpdateOldTotal: 0,

  firmware: [],
  loading: false,
  error: null,
};

export default function firmwareReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    // select SELECT
    //
    case actions.SELECT_FIRMWARE_REQUEST:
      return {
        ...state,
        selectFirmwareVersionList: [],
      };
    case actions.SELECT_FIRMWARE_SUCCESS:
      return {
        ...state,
        selectFirmwareVersionList: action.payload,
      };
    case actions.SELECT_FIRMWARE_FAILURE:
      return {
        ...state,
        selectFirmwareVersionList: [],
      };
    // 订单号下拉
    case actions.SELECT_ORDER_REQUEST:
      return {
        ...state,
        selectOrderList: [],
      };
    case actions.SELECT_ORDER_SUCCESS:
      return {
        ...state,
        selectOrderList: action.payload,
      };
    case actions.SELECT_ORDER_FAILURE:
      return {
        ...state,
        selectOrderList: [],
      };
    // 批次号下拉
    case actions.SELECT_BATCH_REQUEST:
      return {
        ...state,
        selectBatchList: [],
      };
    case actions.SELECT_BATCH_SUCCESS:
      return {
        ...state,
        selectBatchList: action.payload,
      };
    case actions.SELECT_BATCH_FAILURE:
      return {
        ...state,
        selectBatchList: [],
      };
    //
    case actions.SELECT_FIRMWARE_UPDATE_DTU_REQUEST:
      return {
        ...state,
        selectFirmwareDtuList: [],
      };
    case actions.SELECT_FIRMWARE_UPDATE_DTU_SUCCESS:
      return {
        ...state,
        selectFirmwareDtuList: action.payload,
      };
    case actions.SELECT_FIRMWARE_UPDATE_DTU_FAILURE:
      return {
        ...state,
        selectFirmwareDtuList: [],
      };

    // create
    //
    case actions.CREATE_FIRMWARE_REQUEST:
      return {
        ...state,
        createStatus: "1",
      };
    case actions.CREATE_FIRMWARE_SUCCESS:
      return {
        ...state,
        createStatus: "2",
      };
    case actions.CREATE_FIRMWARE_FAILURE:
      return {
        ...state,
        createStatus: "3",
      };
    // NEW
    case actions.CREATE_FIRMWARE_UPDATE_NEW_REQUEST:
      return {
        ...state,
        createStatus: "1",
      };
    case actions.CREATE_FIRMWARE_UPDATE_NEW_SUCCESS:
      return {
        ...state,
        createStatus: "2",
      };
    case actions.CREATE_FIRMWARE_UPDATE_NEW_FAILURE:
      return {
        ...state,
        createStatus: "3",
      };
    // OLD
    case actions.CREATE_FIRMWARE_UPDATE_OLD_REQUEST:
      return {
        ...state,
        createStatus: "1",
      };
    case actions.CREATE_FIRMWARE_UPDATE_OLD_SUCCESS:
      return {
        ...state,
        createStatus: "2",
      };
    case actions.CREATE_FIRMWARE_UPDATE_OLD_FAILURE:
      return {
        ...state,
        createStatus: "3",
      };
    // 印度老接口
    case actions.localIsAtionFirmware_REQUEST:
      return {
        ...state,
        // createStatus: '1',
      };
    case actions.localIsAtionFirmware_SUCCESS:
      return {
        ...state,
        // createStatus: '2',
      };
    case actions.localIsAtionFirmware_FAILURE:
      return {
        ...state,
        // createStatus: '3',
      };

    // detail DETAIL
    // NEW
    case actions.DETAIL_FIRMWARE_UPDATE_NEW_REQUEST:
      return {
        ...state,
        detailStatus: "1",
        dataDetailNew: {},
      };
    case actions.DETAIL_FIRMWARE_UPDATE_NEW_SUCCESS:
      return {
        ...state,
        detailStatus: "2",
        dataDetailNew: action.payload,
      };
    case actions.DETAIL_FIRMWARE_UPDATE_NEW_FAILURE:
      return {
        ...state,
        detailStatus: "3",
        dataDetailNew: {},
      };
    // NEW history Data
    case actions.DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_REQUEST:
      return {
        ...state,
        detailStatus: "1",
        historyData: [],
      };
    case actions.DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_SUCCESS:
      return {
        ...state,
        detailStatus: "2",
        historyData: action.payload,
      };
    case actions.DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_FAILURE:
      return {
        ...state,
        detailStatus: "3",
        historyData: [],
      };
    // OLD
    case actions.DETAIL_FIRMWARE_UPDATE_OLD_REQUEST:
      return {
        ...state,
        detailStatus: "1",
      };
    case actions.DETAIL_FIRMWARE_UPDATE_OLD_SUCCESS:
      return {
        ...state,
        detailStatus: "2",
      };
    case actions.DETAIL_FIRMWARE_UPDATE_OLD_FAILURE:
      return {
        ...state,
        detailStatus: "3",
      };

    // edit
    //
    case actions.EDIT_FIRMWARE_REQUEST:
      return {
        ...state,
        editStatus: "1",
      };
    case actions.EDIT_FIRMWARE_SUCCESS:
      return {
        ...state,
        editStatus: "2",
      };
    case actions.EDIT_FIRMWARE_FAILURE:
      return {
        ...state,
        editStatus: "3",
      };
    // NEW
    case actions.EDIT_FIRMWARE_UPDATE_NEW_REQUEST:
      return {
        ...state,
        editStatus: "1",
      };
    case actions.EDIT_FIRMWARE_UPDATE_NEW_SUCCESS:
      return {
        ...state,
        editStatus: "2",
      };
    case actions.EDIT_FIRMWARE_UPDATE_NEW_FAILURE:
      return {
        ...state,
        editStatus: "3",
      };
    // NEW LANGUAGE
    case actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_REQUEST:
      return {
        ...state,
        editStatus: "1",
      };
    case actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_SUCCESS:
      return {
        ...state,
        editStatus: "2",
      };
    case actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_FAILURE:
      return {
        ...state,
        editStatus: "3",
      };
    // OLD
    case actions.EDIT_FIRMWARE_UPDATE_OLD_REQUEST:
      return {
        ...state,
        editStatus: "1",
      };
    case actions.EDIT_FIRMWARE_UPDATE_OLD_SUCCESS:
      return {
        ...state,
        editStatus: "2",
      };
    case actions.EDIT_FIRMWARE_UPDATE_OLD_FAILURE:
      return {
        ...state,
        editStatus: "3",
      };
    // OLD LANGUAGE
    case actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_REQUEST:
      return {
        ...state,
        editStatus: "1",
      };
    case actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_SUCCESS:
      return {
        ...state,
        editStatus: "2",
      };
    case actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_FAILURE:
      return {
        ...state,
        editStatus: "3",
      };

    // delete
    //
    case actions.DELETE_FIRMWARE_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
      };
    case actions.DELETE_FIRMWARE_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
      };
    case actions.DELETE_FIRMWARE_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
      };
    case actions.DELETE_FIRMWARE_UPDATE_NEW_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
      };
    case actions.DELETE_FIRMWARE_UPDATE_NEW_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
      };
    case actions.DELETE_FIRMWARE_UPDATE_NEW_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
      };
    // OLD
    case actions.DELETE_FIRMWARE_UPDATE_OLD_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
      };
    case actions.DELETE_FIRMWARE_UPDATE_OLD_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
      };
    case actions.DELETE_FIRMWARE_UPDATE_OLD_FAILURE:
      return {
        ...state,
        deleteStatus: "3",
      };

    // fetch
    //
    case actions.FETCH_FIRMWARE_REQUEST:
      return {
        ...state,
        firmwareLoading: true,
        firmwareList: [],
        firmwareTotal: 0,
      };
    case actions.FETCH_FIRMWARE_SUCCESS:
      return {
        ...state,
        firmwareLoading: false,
        firmwareList: action.payload.list,
        firmwareTotal: action.payload.total,
      };
    case actions.FETCH_FIRMWARE_FAILURE:
      return {
        ...state,
        firmwareLoading: false,
        firmwareList: [],
        firmwareTotal: 0,
      };
    // NEW
    case actions.FETCH_FIRMWARE_UPDATE_NEW_REQUEST:
      return {
        ...state,
        firmwareUpdateNewLoading: true,
        firmwareUpdateNewList: [],
        firmwareUpdateNewTotal: 0,
      };
    case actions.FETCH_FIRMWARE_UPDATE_NEW_SUCCESS:
      return {
        ...state,
        firmwareUpdateNewLoading: false,
        firmwareUpdateNewList: action.payload.list,
        firmwareUpdateNewTotal: action.payload.total,
      };
    case actions.FETCH_FIRMWARE_UPDATE_NEW_FAILURE:
      return {
        ...state,
        firmwareUpdateNewLoading: false,
        firmwareUpdateNewList: [],
        firmwareUpdateNewTotal: 0,
      };
    // NEW in
    case actions.FETCH_FIRMWARE_UPDATE_IN_NEW_REQUEST:
      return {
        ...state,
        firmwareUpdateInNewLoading: true,
        firmwareUpdateInNewList: [],
        firmwareUpdateInNewTotal: 0,
      };
    case actions.FETCH_FIRMWARE_UPDATE_IN_NEW_SUCCESS:
      return {
        ...state,
        firmwareUpdateInNewLoading: false,
        firmwareUpdateInNewList: action.payload.list,
        firmwareUpdateInNewTotal: action.payload.total,
      };
    case actions.FETCH_FIRMWARE_UPDATE_IN_NEW_FAILURE:
      return {
        ...state,
        firmwareUpdateInNewLoading: false,
        firmwareUpdateInNewList: [],
        firmwareUpdateInNewTotal: 0,
      };
    // OLD
    case actions.FETCH_FIRMWARE_UPDATE_OLD_REQUEST:
      return {
        ...state,
        firmwareUpdateOldLoading: true,
        firmwareUpdateOldList: [],
        firmwareUpdateOldTotal: 0,
      };
    case actions.FETCH_FIRMWARE_UPDATE_OLD_SUCCESS:
      return {
        ...state,
        firmwareUpdateOldLoading: false,
        firmwareUpdateOldList: action.payload.list,
        firmwareUpdateOldTotal: action.payload.total,
      };
    case actions.FETCH_FIRMWARE_UPDATE_OLD_FAILURE:
      return {
        ...state,
        firmwareUpdateOldLoading: false,
        firmwareUpdateOldList: [],
        firmwareUpdateOldTotal: 0,
      };

    // enable
    //
    case actions.ENABLE_FIRMWARE_REQUEST:
      return {
        ...state,
        enableStatus: "1",
      };
    case actions.ENABLE_FIRMWARE_SUCCESS:
      return {
        ...state,
        enableStatus: "2",
      };
    case actions.ENABLE_FIRMWARE_FAILURE:
      return {
        ...state,
        enableStatus: "3",
      };
    // NEW
    case actions.ENABLE_FIRMWARE_UPDATE_NEW_REQUEST:
      return {
        ...state,
        enableStatus: "1",
      };
    case actions.ENABLE_FIRMWARE_UPDATE_NEW_SUCCESS:
      return {
        ...state,
        enableStatus: "2",
      };
    case actions.ENABLE_FIRMWARE_UPDATE_NEW_FAILURE:
      return {
        ...state,
        enableStatus: "3",
      };
    // OLD
    case actions.ENABLE_FIRMWARE_UPDATE_OLD_REQUEST:
      return {
        ...state,
        enableStatus: "1",
      };
    case actions.ENABLE_FIRMWARE_UPDATE_OLD_SUCCESS:
      return {
        ...state,
        enableStatus: "2",
      };
    case actions.ENABLE_FIRMWARE_UPDATE_OLD_FAILURE:
      return {
        ...state,
        enableStatus: "3",
      };

    // disable
    //
    case actions.DISABLE_FIRMWARE_REQUEST:
      return {
        ...state,
        disableStatus: "1",
      };
    case actions.DISABLE_FIRMWARE_SUCCESS:
      return {
        ...state,
        disableStatus: "2",
      };
    case actions.DISABLE_FIRMWARE_FAILURE:
      return {
        ...state,
        disableStatus: "3",
      };
    // NEW
    case actions.DISABLE_FIRMWARE_UPDATE_NEW_REQUEST:
      return {
        ...state,
        disableStatus: "1",
      };
    case actions.DISABLE_FIRMWARE_UPDATE_NEW_SUCCESS:
      return {
        ...state,
        disableStatus: "2",
      };
    case actions.DISABLE_FIRMWARE_UPDATE_NEW_FAILURE:
      return {
        ...state,
        disableStatus: "3",
      };
    // OLD
    case actions.DISABLE_FIRMWARE_UPDATE_OLD_REQUEST:
      return {
        ...state,
        disableStatus: "1",
      };
    case actions.DISABLE_FIRMWARE_UPDATE_OLD_SUCCESS:
      return {
        ...state,
        disableStatus: "2",
      };
    case actions.DISABLE_FIRMWARE_UPDATE_OLD_FAILURE:
      return {
        ...state,
        disableStatus: "3",
      };

    // pass
    case actions.GO_FIRMWARE_PASS_REQUEST:
      return {
        ...state,
        passStatus: "1",
      };
    case actions.GO_FIRMWARE_PASS_SUCCESS:
      return {
        ...state,
        passStatus: "2",
      };
    case actions.GO_FIRMWARE_PASS_FAILURE:
      return {
        ...state,
        passStatus: "3",
      };

    // REFUSE
    case actions.GO_FIRMWARE_REFUSE_REQUEST:
      return {
        ...state,
        refuseStatus: "1",
      };
    case actions.GO_FIRMWARE_REFUSE_SUCCESS:
      return {
        ...state,
        refuseStatus: "2",
      };
    case actions.GO_FIRMWARE_REFUSE_FAILURE:
      return {
        ...state,
        refuseStatus: "3",
      };

    // DOWNLOAD
    //
    case actions.DOWNLOAD_IMPORT_DEVICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.DOWNLOAD_IMPORT_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.DOWNLOAD_IMPORT_DEVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    //
    case actions.DOWNLOAD_VERSION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // NEW
    case actions.DOWNLOAD_VERSION_UPDATE_NEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_UPDATE_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_UPDATE_NEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // OLD
    case actions.DOWNLOAD_VERSION_UPDATE_OLD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_UPDATE_OLD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.DOWNLOAD_VERSION_UPDATE_OLD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
