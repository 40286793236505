import { call, all, takeEvery, put } from "redux-saga/effects";
import notificationsActions from "./notificationsActions";
import CloudHawkApi3 from "@iso/config/Services/CloudHawk3";

function* getNotificationRequest(request) {
  try {
    const response = yield call(CloudHawkApi3.getMessUnReadCount);
    yield put(notificationsActions.getNotificationSuccess(response.data));
  } catch (error) {
    yield put(notificationsActions.getNotificationFailure(error));
  }
}

export default function* notificationsSaga() {
  yield all([
    takeEvery(
      notificationsActions.GET_NOTIFICATION_REQUEST,
      getNotificationRequest,
    ),
  ]);
}
