const firmwareDataActions = {
  //Fetch firmware Data
  GET_FIRMWARE_DATA_REQUEST: "GET_FIRMWARE_DATA_REQUEST",
  GET_FIRMWARE_DATA_SUCCESS: "GET_FIRMWARE_DATA_SUCCESS",
  GET_FIRMWARE_DATA_FAILURE: "GET_FIRMWARE_DATA_FAILURE",
  getFirmwareDataRequest: (request) => {
    return {
      type: firmwareDataActions.GET_FIRMWARE_DATA_REQUEST,
      payload: request,
    };
  },
  getFirmwareDataSuccess: (firmware) => {
    return {
      type: firmwareDataActions.GET_FIRMWARE_DATA_SUCCESS,
      payload: firmware,
    };
  },
  getFirmwareDataFailure: (error) => ({
    type: firmwareDataActions.GET_FIRMWARE_DATA_FAILURE,
    payload: error,
  }),

  //Delete firmware Data
  GET_FIRMWARE_DATA_DELETE_REQUEST: "GET_FIRMWARE_DATA_DELETE_REQUEST",
  GET_FIRMWARE_DATA_DELETE_SUCCESS: "GET_FIRMWARE_DATA_DELETE_SUCCESS",
  GET_FIRMWARE_DATA_DELETE_FAILURE: "GET_FIRMWARE_DATA_DELETE_FAILURE",
  getFirmwareDataDeleteRequest: (request) => {
    return {
      type: firmwareDataActions.GET_FIRMWARE_DATA_DELETE_REQUEST,
      payload: request,
    };
  },
  getFirmwareDataDeleteSuccess: (data) => {
    return {
      type: firmwareDataActions.GET_FIRMWARE_DATA_DELETE_SUCCESS,
      payload: data,
    };
  },
  getFirmwareDataDeleteFailure: (error) => ({
    type: firmwareDataActions.GET_FIRMWARE_DATA_DELETE_FAILURE,
    payload: error,
  }),
};

export default firmwareDataActions;
