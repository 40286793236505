import { call, all, takeEvery, put } from "redux-saga/effects";
import actions from "./actions";
import CloudHawkApi2 from "@iso/config/Services/CloudHawk2";
import Notification from "@iso/components/Notification";

function* open_api_page_request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.open_api_page, payload);
    if (response.code === 100) {
      yield put(actions.open_api_page_success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.open_api_page_failure(response));
    }
  } catch (error) {
    yield put(actions.open_api_page_failure(error));
  }
}

function* open_api_add_request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.open_api_add, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(actions.open_api_add_success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.open_api_add_failure(response));
    }
  } catch (error) {
    yield put(actions.open_api_add_failure(error));
  }
}

function* open_api_edit_request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.open_api_edit, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(actions.open_api_edit_success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.open_api_edit_failure(response));
    }
  } catch (error) {
    yield put(actions.open_api_edit_failure(error));
  }
}

function* open_api_detail_request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.open_api_detail, payload);
    if (response.code === 100) {
      yield put(actions.open_api_detail_success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.open_api_detail_failure(response));
    }
  } catch (error) {
    yield put(actions.open_api_detail_failure(error));
  }
}

function* open_api_reset_key_request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.open_api_reset_key, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(actions.open_api_reset_key_success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.open_api_reset_key_failure(response));
    }
  } catch (error) {
    yield put(actions.open_api_reset_key_failure(error));
  }
}

function* open_api_delete_request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.open_api_delete, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(actions.open_api_delete_success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.open_api_delete_failure(response));
    }
  } catch (error) {
    yield put(actions.open_api_delete_failure(error));
  }
}

function* open_api_save_ip_request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.open_api_save_ip, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(actions.open_api_save_ip_success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.open_api_save_ip_failure(response));
    }
  } catch (error) {
    yield put(actions.open_api_save_ip_failure(error));
  }
}

function* open_api_save_access_request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.open_api_save_access, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(actions.open_api_save_access_success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.open_api_save_access_failure(response));
    }
  } catch (error) {
    yield put(actions.open_api_save_access_failure(error));
  }
}

function* open_api_api_list_request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.open_api_api_list, payload);
    if (response.code === 100) {
      yield put(actions.open_api_api_list_success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.open_api_api_list_failure(response));
    }
  } catch (error) {
    yield put(actions.open_api_api_list_failure(error));
  }
}

export default function* apiOpenSaga() {
  yield all([
    takeEvery(actions.OPEN_API_PAGE_REQUEST, open_api_page_request),
    takeEvery(actions.OPEN_API_ADD_REQUEST, open_api_add_request),
    takeEvery(actions.OPEN_API_EDIT_REQUEST, open_api_edit_request),
    takeEvery(actions.OPEN_API_DETAIL_REQUEST, open_api_detail_request),
    takeEvery(actions.OPEN_API_RESET_KEY_REQUEST, open_api_reset_key_request),
    takeEvery(actions.OPEN_API_DELETE_REQUEST, open_api_delete_request),
    takeEvery(actions.OPEN_API_SAVE_IP_REQUEST, open_api_save_ip_request),
    takeEvery(
      actions.OPEN_API_SAVE_SECCESS_REQUEST,
      open_api_save_access_request,
    ),
    takeEvery(actions.OPEN_API_API_LIST_REQUEST, open_api_api_list_request),
  ]);
}
