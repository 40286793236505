const SysLogActions = {
  GET_WebLog_List_REQUEST: "GET_WebLog_List_REQUEST",
  GET_WebLog_List_SUCCESS: "GET_WebLog_List_SUCCESS",
  GET_WebLog_List_ERROR: "GET_WebLog_List_ERROR",

  GET_AppLog_List_REQUEST: "GET_AppLog_List_REQUEST",
  GET_AppLog_List_SUCCESS: "GET_AppLog_List_SUCCESS",
  GET_AppLog_List_ERROR: "GET_AppLog_List_ERROR",

  fetchGETWebLogList: (request) => ({
    type: SysLogActions.GET_WebLog_List_REQUEST,
    payload: request,
  }),
  fetchGETWebLogListSuccess: (data) => ({
    type: SysLogActions.GET_WebLog_List_SUCCESS,
    payload: data,
  }),
  fetchGETWebLogListError: (error) => ({
    type: SysLogActions.GET_WebLog_List_ERROR,
    payload: error,
  }),

  fetchGETAppLogList: (request) => ({
    type: SysLogActions.GET_AppLog_List_REQUEST,
    payload: request,
  }),
  fetchGETAppLogListSuccess: (data) => ({
    type: SysLogActions.GET_AppLog_List_SUCCESS,
    payload: data,
  }),
  fetchGETAppLogListError: (error) => ({
    type: SysLogActions.GET_AppLog_List_ERROR,
    payload: error,
  }),
};

export default SysLogActions;
