import SetTemplateActions from "./actions";

const INITIAL_DATA = {
  columnList: [],
  dataALL: [],
  languageList: [],
  totalLanguage: 0,
  data: [],
  totalList: 0,
  loading: true,
  error: null,
  reset: false,
};
export default function SetTemplateReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case SetTemplateActions.GET_SetTemplateLanguageDetail_List_REQUEST:
      return {
        ...state,
        languageList: [],
        loading: true,
        error: null,
      };
    case SetTemplateActions.GET_SetTemplateLanguageDetail_List_SUCCESS:
      let data2 = Object.assign([], action.payload);
      let columnList = [];
      data2.forEach((index) => {
        columnList.push(index.language);
      });
      return {
        ...state,
        languageList: action.payload,
        totalLanguage: action.payload.length,
        columnList: columnList,
        loading: false,
        error: null,
      };
    case SetTemplateActions.GET_SetTemplateLanguageDetail_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SetTemplateActions.GET_SetTemplate_List_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case SetTemplateActions.GET_SetTemplate_List_SUCCESS:
      let data = Object.assign([], action.payload);

      data.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        data: action.payload.list,
        totalList: action.payload.total,
        loading: false,
        error: null,
      };
    case SetTemplateActions.GET_SetTemplate_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SetTemplateActions.GET_SetTemplateALL_List_REQUEST:
      return {
        ...state,
        dataALL: [],
        loading: true,
        error: null,
      };
    case SetTemplateActions.GET_SetTemplateALL_List_SUCCESS:
      return {
        ...state,
        dataALL: action.payload,
        loading: false,
        error: null,
      };
    case SetTemplateActions.GET_SetTemplateALL_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SetTemplateActions.ADD_SetTemplate_List_REQUEST:
      return {
        ...state,
        data: [],
        totalList: 0,
        loading: true,
        error: null,
      };
    case SetTemplateActions.ADD_SetTemplate_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SetTemplateActions.ADD_SetTemplate_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SetTemplateActions.EDIT_SetTemplate_List_REQUEST:
      return {
        ...state,
        data: [],
        totalList: 0,
        loading: true,
        error: null,
      };
    case SetTemplateActions.EDIT_SetTemplate_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SetTemplateActions.EDIT_SetTemplate_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SetTemplateActions.DEL_SetTemplate_List_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SetTemplateActions.DEL_SetTemplate_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SetTemplateActions.DEL_SetTemplate_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
