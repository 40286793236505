import { call, all, takeEvery, put } from "redux-saga/effects";
import productItemActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";
import Notification from "@iso/components/Notification";

const alert = (response) => {
  Notification("success", response.message);
};

const Error = (response) => {
  Notification("error", response.message);
};

//  *********  Add Product Brand *******   //
function* createProductBrandRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createBrand, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
    yield put(productItemActions.createProductBrandSuccess(response.data));
  } catch (error) {
    yield put(productItemActions.createProductBrandFailure(error));
  }
}

//  *********  get Product Brand *******   //
function* getProductBrandRequest(request) {
  const { payload, path } = request;
  try {
    const response = yield call(
      CloudHawkApi.getProductBrand,
      payload,
      path.apiName,
    );
    yield put(productItemActions.getProductItemSuccess(response.data));
  } catch (error) {
    yield put(productItemActions.getProductItemFailure(error));
  }
}

//  *********  Edit Product Brand *******   //
function* editProductBrandRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editBrand, payload);
    yield put(productItemActions.editProductBrandSuccess(response.data));
  } catch (error) {
    yield put(productItemActions.editProductBrandFailure(error));
  }
}

//  *********  Delete Product Brand *******   //
function* deleteProductBrandRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteBrand, payload);
    yield put(productItemActions.deleteProductBrandSuccess(response.data));
  } catch (error) {
    yield put(productItemActions.deleteProductBrandFailure(error));
  }
}

//  *********  Add Product Category *******   //
function* createProductCategoryRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createCategory, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
    yield put(productItemActions.createProductCategorySuccess(response.data));
  } catch (error) {
    yield put(productItemActions.createproductCategoryFailure(error));
  }
}

//  *********  Edit Product Category *******   //
function* editProductCategoryRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editCategory, payload);
    yield put(productItemActions.editProductCategorySuccess(response.data));
  } catch (error) {
    yield put(productItemActions.editProductCategoryFailure(error));
  }
}

//  *********  Delete Product Category *******   //
function* deleteProductCategoryRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteCategory, payload);
    yield put(productItemActions.deleteProductCategorySuccess(response.data));
  } catch (error) {
    yield put(productItemActions.deleteProductCategoryFailure(error));
  }
}

//  *********  Add Product Model *******   //
function* createProductModelRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createModel, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
    yield put(productItemActions.createProductModelSuccess(response.data));
  } catch (error) {
    yield put(productItemActions.createProductModelFailure(error));
  }
}

//  *********  Edit Product Model *******   //
function* editProductModelRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editModel, payload);
    yield put(productItemActions.editProductModelSuccess(response.data));
  } catch (error) {
    yield put(productItemActions.editProductModelFailure(error));
  }
}

//  *********  Delete Product Model *******   //
function* deleteProductModelRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteModel, payload);
    yield put(productItemActions.deleteProductModelSuccess(response.data));
  } catch (error) {
    yield put(productItemActions.deleteProductModelFailure(error));
  }
}

//  *********  Add Product Action Type *******   //
function* createProductActionTypesRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createAction, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
    yield put(
      productItemActions.createProductActionTypesSuccess(response.data),
    );
  } catch (error) {
    yield put(productItemActions.createProductActionTypesFailure(error));
  }
}

//  *********  Edit Product Action Type *******   //
function* editProductActionTypesRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editAction, payload);
    yield put(productItemActions.editProductActionTypesSuccess(response.data));
  } catch (error) {
    yield put(productItemActions.editProductActionTypesFailure(error));
  }
}

//  *********  Delete Product Action Type *******   //
function* deleteProductActionTypesRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteAction, payload);
    yield put(
      productItemActions.deleteProductActionTypesSuccess(response.data),
    );
  } catch (error) {
    yield put(productItemActions.deleteProductActionTypesFailure(error));
  }
}

//  *********  Add Product Communication *******   //
function* createProductCommunicationtypeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createCommType, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
    yield put(
      productItemActions.createProductCommunicationtypeSuccess(response.data),
    );
  } catch (error) {
    yield put(productItemActions.createProductCommunicationtypeFailure(error));
  }
}

//  *********  Edit Product Communication *******   //
function* editProductCommunicationtypeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editCommType, payload);
    yield put(
      productItemActions.editProductCommunicationtypeSuccess(response.data),
    );
  } catch (error) {
    yield put(productItemActions.editProductCommunicationtypeFailure(error));
  }
}

//  *********  Delete Product Communication *******   //
function* deleteProductCommunicationtypeRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteCommType, payload);
    yield put(
      productItemActions.deleteProductCommunicationtypeSuccess(response.data),
    );
  } catch (error) {
    yield put(productItemActions.deleteProductCommunicationtypeFailure(error));
  }
}
export default function* productItemSaga() {
  yield all([
    takeEvery(
      productItemActions.CREATE_PRODUCT_BRAND_REQUEST,
      createProductBrandRequest,
    ),
    takeEvery(
      productItemActions.GET_PRODUCT_CREATED_ITEM_REQUEST,
      getProductBrandRequest,
    ),
    takeEvery(
      productItemActions.EDIT_PRODUCT_BRAND_REQUEST,
      editProductBrandRequest,
    ),
    takeEvery(
      productItemActions.DELETE_PRODUCT_BRAND_REQUEST,
      deleteProductBrandRequest,
    ),
    takeEvery(
      productItemActions.CREATE_PRODUCT_CATEGORY_REQUEST,
      createProductCategoryRequest,
    ),
    takeEvery(
      productItemActions.EDIT_PRODUCT_CATEGORY_REQUEST,
      editProductCategoryRequest,
    ),
    takeEvery(
      productItemActions.DELETE_PRODUCT_CATEGORY_REQUEST,
      deleteProductCategoryRequest,
    ),
    takeEvery(
      productItemActions.CREATE_PRODUCT_MODEL_REQUEST,
      createProductModelRequest,
    ),
    takeEvery(
      productItemActions.EDIT_PRODUCT_MODEL_REQUEST,
      editProductModelRequest,
    ),
    takeEvery(
      productItemActions.DELETE_PRODUCT_MODEL_REQUEST,
      deleteProductModelRequest,
    ),
    takeEvery(
      productItemActions.CREATE_PRODUCT_ACTIONS_TYPES_REQUEST,
      createProductActionTypesRequest,
    ),
    takeEvery(
      productItemActions.EDIT_PRODUCT_ACTIONS_TYPES_REQUEST,
      editProductActionTypesRequest,
    ),
    takeEvery(
      productItemActions.DELETE_PRODUCT_ACTIONS_TYPES_REQUEST,
      deleteProductActionTypesRequest,
    ),
    takeEvery(
      productItemActions.CREATE_PRODUCT_COMMUNICATION_TYPE_REQUEST,
      createProductCommunicationtypeRequest,
    ),
    takeEvery(
      productItemActions.EDIT_PRODUCT_COMMUNICATION_TYPE_REQUEST,
      editProductCommunicationtypeRequest,
    ),
    takeEvery(
      productItemActions.DELETE_PRODUCT_COMMUNICATION_TYPE_REQUEST,
      deleteProductCommunicationtypeRequest,
    ),
  ]);
}
