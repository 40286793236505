import firmwareDataActions from "./firmwareDataActions";

const INITIAL_DATA = {
  firmwareData: [],
  deleteData: [],
  loading: false,
  error: null,
};
export default function firmwareDataReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case firmwareDataActions.GET_FIRMWARE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case firmwareDataActions.GET_FIRMWARE_DATA_SUCCESS:
      return {
        ...state,
        firmwareData: action.payload,
        loading: false,
        error: null,
      };
    case firmwareDataActions.GET_FIRMWARE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    //delete reducer
    case firmwareDataActions.GET_FIRMWARE_DATA_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case firmwareDataActions.GET_FIRMWARE_DATA_DELETE_SUCCESS:
      return {
        ...state,
        firmwareData: state.firmwareData.filter(
          (data) => data._id !== action.payload.id,
        ),
        loading: false,
        error: null,
      };
    case firmwareDataActions.GET_FIRMWARE_DATA_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
