const EditProductTemplateActions2 = {
  GET_EditProductTemplate_List_REQUEST2:
    "GET_EditProductTemplate_List_REQUEST2",
  GET_EditProductTemplate_List_SUCCESS2:
    "GET_EditProductTemplate_List_SUCCESS2",
  GET_EditProductTemplate_List_ERROR2: "GET_EditProductTemplate_List_ERROR2",

  GET_Modular_List_REQUEST2: "GET_Modular_List_REQUEST2",
  GET_Modular_List_SUCCESS2: "GET_Modular_List_SUCCESS2",
  GET_Modular_List_ERROR2: "GET_Modular_List_ERROR2",

  GET_Service_List_REQUEST2: "GET_Service_List_REQUEST2",
  GET_Service_List_SUCCESS2: "GET_Service_List_SUCCESS2",
  GET_Service_List_ERROR2: "GET_Service_List_ERROR2",

  GET_Params_List_REQUEST2: "GET_Params_List_REQUEST2",
  GET_Params_List_SUCCESS2: "GET_Params_List_SUCCESS2",
  GET_Params_List_ERROR2: "GET_Params_List_ERROR2",

  GET_SecondarySelect_List_REQUEST2: "GET_SecondarySelect_List_REQUEST2",
  GET_SecondarySelect_List_SUCCESS2: "GET_SecondarySelect_List_SUCCESS2",
  GET_SecondarySelect_List_ERROR2: "GET_SecondarySelect_List_ERROR2",

  // edit
  // all
  POST_SaveAllInfoTemplate_REQUEST2: "POST_SaveAllInfoTemplate_REQUEST2",
  POST_SaveAllInfoTemplate_SUCCESS2: "POST_SaveAllInfoTemplate_SUCCESS2",
  POST_SaveAllInfoTemplate_ERROR2: "POST_SaveAllInfoTemplate_ERROR2",
  // 1
  EDIT_EditProductTemplate_List_REQUEST2:
    "EDIT_EditProductTemplate_List_REQUEST2",
  EDIT_EditProductTemplate_List_SUCCESS2:
    "EDIT_EditProductTemplate_List_SUCCESS2",
  EDIT_EditProductTemplate_List_ERROR2: "EDIT_EditProductTemplate_List_ERROR2",
  // 2
  POST_SaveModuleTemplate_REQUEST2: "POST_SaveModuleTemplate_REQUEST2",
  POST_SaveModuleTemplate_SUCCESS2: "POST_SaveModuleTemplate_SUCCESS2",
  POST_SaveModuleTemplate_ERROR2: "POST_SaveModuleTemplate_ERROR2",
  // 3
  POST_SaveSecondTemplate_REQUEST2: "POST_SaveSecondTemplate_REQUEST2",
  POST_SaveSecondTemplate_SUCCESS2: "POST_SaveSecondTemplate_SUCCESS2",
  POST_SaveSecondTemplate_ERROR2: "POST_SaveSecondTemplate_ERROR2",
  // 4
  POST_SaveSettingsTemplate_REQUEST2: "POST_SaveSettingsTemplate_REQUEST2",
  POST_SaveSettingsTemplate_SUCCESS2: "POST_SaveSettingsTemplate_SUCCESS2",
  POST_SaveSettingsTemplate_ERROR2: "POST_SaveSettingsTemplate_ERROR2",

  // delete
  // 2
  POST_DeleteModuleTemplate_REQUEST2: "POST_DeleteModuleTemplate_REQUEST2",
  POST_DeleteModuleTemplate_SUCCESS2: "POST_DeleteModuleTemplate_SUCCESS2",
  POST_DeleteModuleTemplate_ERROR2: "POST_DeleteModuleTemplate_ERROR2",
  // 3
  POST_DeleteSecondTemplate_REQUEST2: "POST_DeleteSecondTemplate_REQUEST2",
  POST_DeleteSecondTemplate_SUCCESS2: "POST_DeleteSecondTemplate_SUCCESS2",
  POST_DeleteSecondTemplate_ERROR2: "POST_DeleteSecondTemplate_ERROR2",

  //
  fetchGETEditProductTemplateList2: (request) => ({
    type: EditProductTemplateActions2.GET_EditProductTemplate_List_REQUEST2,
    payload: request,
  }),
  fetchGETEditProductTemplateListSuccess2: (data) => ({
    type: EditProductTemplateActions2.GET_EditProductTemplate_List_SUCCESS2,
    payload: data,
  }),
  fetchGETEditProductTemplateListError2: (error) => ({
    type: EditProductTemplateActions2.GET_EditProductTemplate_List_ERROR2,
    payload: error,
  }),

  // edit
  // all
  fetchSaveAllInfoTemplate2: (request) => ({
    type: EditProductTemplateActions2.POST_SaveAllInfoTemplate_REQUEST2,
    payload: request,
  }),
  fetchSaveAllInfoTemplateSuccess2: (request) => ({
    type: EditProductTemplateActions2.POST_SaveAllInfoTemplate_SUCCESS2,
    payload: request,
  }),
  fetchSaveAllInfoTemplateError2: (request) => ({
    type: EditProductTemplateActions2.POST_SaveAllInfoTemplate_ERROR2,
    payload: request,
  }),
  // 1
  fetchEDITEditProductTemplateList2: (request) => ({
    type: EditProductTemplateActions2.EDIT_EditProductTemplate_List_REQUEST2,
    payload: request,
  }),
  fetchEDITEditProductTemplateListSuccess2: (data) => ({
    type: EditProductTemplateActions2.EDIT_EditProductTemplate_List_SUCCESS2,
    payload: data,
  }),
  fetchEDITEditProductTemplateListError2: (error) => ({
    type: EditProductTemplateActions2.EDIT_EditProductTemplate_List_ERROR2,
    payload: error,
  }),
  // 2
  fetchPOSTSaveModuleTemplate2: (request) => ({
    type: EditProductTemplateActions2.POST_SaveModuleTemplate_REQUEST2,
    payload: request,
  }),
  fetchPOSTSaveModuleTemplateSUCCESS2: (data) => ({
    type: EditProductTemplateActions2.POST_SaveModuleTemplate_SUCCESS2,
    payload: data,
  }),
  fetchPOSTSaveModuleTemplateERROR2: (error) => ({
    type: EditProductTemplateActions2.POST_SaveModuleTemplate_ERROR2,
    payload: error,
  }),
  // 3
  fetchPOSTSaveSecondTemplate2: (request) => ({
    type: EditProductTemplateActions2.POST_SaveSecondTemplate_REQUEST2,
    payload: request,
  }),
  fetchPOSTSaveSecondTemplateSUCCESS2: (data) => ({
    type: EditProductTemplateActions2.POST_SaveSecondTemplate_SUCCESS2,
    payload: data,
  }),
  fetchPOSTSaveSecondTemplateERROR2: (error) => ({
    type: EditProductTemplateActions2.POST_SaveSecondTemplate_ERROR2,
    payload: error,
  }),
  // 4
  fetchPOSTSaveSettingsTemplate2: (request) => ({
    type: EditProductTemplateActions2.POST_SaveSettingsTemplate_REQUEST2,
    payload: request,
  }),
  fetchPOSTSaveSettingsTemplateSUCCESS2: (data) => ({
    type: EditProductTemplateActions2.POST_SaveSettingsTemplate_SUCCESS2,
    payload: data,
  }),
  fetchPOSTSaveSettingsTemplateERROR2: (error) => ({
    type: EditProductTemplateActions2.POST_SaveSettingsTemplate_ERROR2,
    payload: error,
  }),

  // delete
  // 2
  fetchPOSTDeleteModuleTemplate2: (request) => ({
    type: EditProductTemplateActions2.POST_DeleteModuleTemplate_REQUEST2,
    payload: request,
  }),
  fetchPOSTDeleteModuleTemplateSUCCESS2: (data) => ({
    type: EditProductTemplateActions2.POST_DeleteModuleTemplate_SUCCESS2,
    payload: data,
  }),
  fetchPOSTDeleteModuleTemplateERROR2: (error) => ({
    type: EditProductTemplateActions2.POST_DeleteModuleTemplate_ERROR2,
    payload: error,
  }),
  // 3
  fetchPOSTDeleteSecondTemplate2: (request) => ({
    type: EditProductTemplateActions2.POST_DeleteSecondTemplate_REQUEST2,
    payload: request,
  }),
  fetchPOSTDeleteSecondTemplateSUCCESS2: (data) => ({
    type: EditProductTemplateActions2.POST_DeleteSecondTemplate_SUCCESS2,
    payload: data,
  }),
  fetchPOSTDeleteSecondTemplateERROR2: (error) => ({
    type: EditProductTemplateActions2.POST_DeleteSecondTemplate_ERROR2,
    payload: error,
  }),

  // select
  //
  fetchGETModularList2: (request) => ({
    type: EditProductTemplateActions2.GET_Modular_List_REQUEST2,
    payload: request,
  }),
  fetchGETModularListSuccess2: (data) => ({
    type: EditProductTemplateActions2.GET_Modular_List_SUCCESS2,
    payload: data,
  }),
  fetchGETModularListError2: (error) => ({
    type: EditProductTemplateActions2.GET_Modular_List_ERROR2,
    payload: error,
  }),
  //
  fetchGETSecondarySelectList2: (request) => ({
    type: EditProductTemplateActions2.GET_SecondarySelect_List_REQUEST2,
    payload: request,
  }),
  fetchGETSecondarySelectListSuccess2: (data) => ({
    type: EditProductTemplateActions2.GET_SecondarySelect_List_SUCCESS2,
    payload: data,
  }),
  fetchGETSecondarySelectListError2: (error) => ({
    type: EditProductTemplateActions2.GET_SecondarySelect_List_ERROR2,
    payload: error,
  }),

  // select
  // Params
  fetchGETParamsList2: (request) => ({
    type: EditProductTemplateActions2.GET_Params_List_REQUEST2,
    payload: request,
  }),
  fetchGETParamsListSuccess2: (data) => ({
    type: EditProductTemplateActions2.GET_Params_List_SUCCESS2,
    payload: data,
  }),
  fetchGETParamsListError2: (error) => ({
    type: EditProductTemplateActions2.GET_Params_List_ERROR2,
    payload: error,
  }),
  // Service
  fetchGETServiceList2: (request) => ({
    type: EditProductTemplateActions2.GET_Service_List_REQUEST2,
    payload: request,
  }),
  fetchGETServiceListSuccess2: (data) => ({
    type: EditProductTemplateActions2.GET_Service_List_SUCCESS2,
    payload: data,
  }),
  fetchGETServiceListError2: (error) => ({
    type: EditProductTemplateActions2.GET_Service_List_ERROR2,
    payload: error,
  }),
};

export default EditProductTemplateActions2;
