const countryActions = {
  //Fetch country Data
  GET_COUNTRY_REQUEST: "GET_COUNTRY_REQUEST",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_FAILURE: "GET_COUNTRY_FAILURE",
  getCountryRequest: (request) => ({
    type: countryActions.GET_COUNTRY_REQUEST,
    payload: request,
  }),
  getCountrySuccess: (country) => ({
    type: countryActions.GET_COUNTRY_SUCCESS,
    payload: country,
  }),
  getCountryFailure: (error) => ({
    type: countryActions.GET_COUNTRY_FAILURE,
    payload: error,
  }),

  //Fetch country Data
  GET_COUNTRY_BY_ID_REQUEST: "GET_COUNTRY_BY_ID_REQUEST",
  GET_COUNTRY_BY_ID_SUCCESS: "GET_COUNTRY_BY_ID_SUCCESS",
  GET_COUNTRY_BY_ID_FAILURE: "GET_COUNTRY_BY_ID_FAILURE",
  getCountryByIdRequest: (request) => ({
    type: countryActions.GET_COUNTRY_BY_ID_REQUEST,
    payload: request,
  }),
  getCountryByIdSuccess: (country) => ({
    type: countryActions.GET_COUNTRY_BY_ID_SUCCESS,
    payload: country,
  }),
  getCountryByIdFailure: (error) => ({
    type: countryActions.GET_COUNTRY_BY_ID_FAILURE,
    payload: error,
  }),

  // Add Langauge
  ADD_COUNTRY_REQUEST: "ADD_COUNTRY_REQUEST",
  ADD_COUNTRY_SUCCESS: "ADD_COUNTRY_SUCCESS",
  ADD_COUNTRY_FAILURE: "ADD_COUNTRY_FAILURE",
  addCountryRequest: (request) => ({
    type: countryActions.ADD_COUNTRY_REQUEST,
    payload: request,
  }),
  addCountrySuccess: (country) => ({
    type: countryActions.ADD_COUNTRY_SUCCESS,
    payload: country,
  }),
  addCountryFailure: (error) => ({
    type: countryActions.ADD_COUNTRY_FAILURE,
    payload: error,
  }),

  // Edit Langauge
  EDIT_COUNTRY_REQUEST: "EDIT_COUNTRY_REQUEST",
  EDIT_COUNTRY_SUCCESS: "EDIT_COUNTRY_SUCCESS",
  EDIT_COUNTRY_FAILURE: "EDIT_COUNTRY_FAILURE",
  editCountryRequest: (request) => ({
    type: countryActions.EDIT_COUNTRY_REQUEST,
    payload: request,
  }),
  editCountrySuccess: (country) => ({
    type: countryActions.EDIT_COUNTRY_SUCCESS,
    payload: country,
  }),
  editCountryFailure: (error) => ({
    type: countryActions.EDIT_COUNTRY_FAILURE,
    payload: error,
  }),

  // Delete Langauge
  DELETE_COUNTRY_REQUEST: "DELETE_COUNTRY_REQUEST",
  DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_FAILURE: "DELETE_COUNTRY_FAILURE",
  deleteCountryRequest: (request) => ({
    type: countryActions.DELETE_COUNTRY_REQUEST,
    payload: request,
  }),
  deleteCountrySuccess: (country) => ({
    type: countryActions.DELETE_COUNTRY_SUCCESS,
    payload: country,
  }),
  deleteCountryFailure: (error) => ({
    type: countryActions.DELETE_COUNTRY_FAILURE,
    payload: error,
  }),
};

export default countryActions;
