import actions from "./actions";

const initState = {
  idToken: null,
  userId: null,
  logindata: null,
  isPushFeedBack: false,
  loginSign: "0",
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loginSign: "1",
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loginSign: "2",
        menu: action.menu,
        idToken: action.token,
        userId: action.userId,
        logindata: action.loginData,
        isPushFeedBack: action.isPushFeedBack,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        loginSign: "3",
        // isPushFeedBack: action.payload.isPushFeedBack,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
