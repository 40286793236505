const SysMenuActions = {
  GET_Menu_List_REQUEST: "GET_Menu_List_REQUEST",
  GET_Menu_List_SUCCESS: "GET_Menu_List_SUCCESS",
  GET_Menu_List_ERROR: "GET_Menu_List_ERROR",

  GET_Sub_Menu_List_REQUEST: "GET_Sub_Menu_List_REQUEST",
  GET_Sub_Menu_List_SUCCESS: "GET_Sub_Menu_List_SUCCESS",
  GET_Sub_Menu_List_ERROR: "_Sub_Menu_List_ERROR",

  ADD_Menu_List_REQUEST: "ADD_Menu_List_REQUEST",
  ADD_Menu_List_SUCCESS: "ADD_Menu_List_SUCCESS",
  ADD_Menu_List_ERROR: "ADD_Menu_List_ERROR",

  EDIT_Menu_List_REQUEST: "EDIT_Menu_List_REQUEST",
  EDIT_Menu_List_SUCCESS: "EDIT_Menu_List_SUCCESS",
  EDIT_Menu_List_ERROR: "EDIT_Menu_List_ERROR",

  DEL_Menu_List_REQUEST: "DEL_Menu_List_REQUEST",
  DEL_Menu_List_SUCCESS: "DEL_Menu_List_SUCCESS",
  DEL_Menu_List_ERROR: "DEL_Menu_List_ERROR",

  GET_ALL_Menu_List_REQUEST: "GET_ALL_Menu_List_REQUEST",
  GET_ALL_Menu_List_SUCCESS: "GET_ALL_Menu_List_SUCCESS",
  GET_ALL_Menu_List_ERROR: "GET_ALL_Menu_List_ERROR",

  fetchGETAllMenuList: () => ({
    type: SysMenuActions.GET_ALL_Menu_List_REQUEST,
    //  payload: request
  }),

  fetchGETAllMenuListSuccess: (data) => ({
    type: SysMenuActions.GET_ALL_Menu_List_SUCCESS,
    payload: data,
  }),
  fetchGETAllMenuListError: (error) => ({
    type: SysMenuActions.GET_ALL_Menu_List_ERROR,
    payload: error,
  }),

  fetchGETMenuList: (request) => ({
    type: SysMenuActions.GET_Menu_List_REQUEST,
    payload: request,
  }),
  fetchGETMenuListSuccess: (data) => ({
    type: SysMenuActions.GET_Menu_List_SUCCESS,
    payload: data,
  }),
  fetchGETMenuListError: (error) => ({
    type: SysMenuActions.GET_Menu_List_ERROR,
    payload: error,
  }),

  fetchGETSubMenuList: (request) => ({
    type: SysMenuActions.GET_Sub_Menu_List_REQUEST,
    payload: request,
  }),
  fetchGETSubMenuListSuccess: (data) => ({
    type: SysMenuActions.GET_Sub_Menu_List_SUCCESS,
    payload: data,
  }),
  fetchGETSubMenuListError: (error) => ({
    type: SysMenuActions.GET_Sub_Menu_List_ERROR,
    payload: error,
  }),

  fetchADDMenuList: (request) => ({
    type: SysMenuActions.ADD_Menu_List_REQUEST,
    payload: request,
  }),
  fetchADDMenuListSuccess: (data) => ({
    type: SysMenuActions.ADD_Menu_List_SUCCESS,
    payload: data,
  }),
  fetchADDMenuListError: (error) => ({
    type: SysMenuActions.ADD_Menu_List_ERROR,
    payload: error,
  }),

  fetchEDITMenuList: (request) => ({
    type: SysMenuActions.EDIT_Menu_List_REQUEST,
    payload: request,
  }),
  fetchEDITMenuListSuccess: (data) => ({
    type: SysMenuActions.EDIT_Menu_List_SUCCESS,
    payload: data,
  }),
  fetchEDITMenuListError: (error) => ({
    type: SysMenuActions.EDIT_Menu_List_ERROR,
    payload: error,
  }),

  fetchDELMenuList: (request) => ({
    type: SysMenuActions.DEL_Menu_List_REQUEST,
    payload: request,
  }),
  fetchDELMenuListSuccess: (request) => ({
    type: SysMenuActions.DEL_Menu_List_SUCCESS,
    payload: request,
  }),
  fetchDELMenuListError: (request) => ({
    type: SysMenuActions.DEL_Menu_List_ERROR,
    payload: request,
  }),
};

export default SysMenuActions;
