const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGOUT: "LOGOUT",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (params = false) => ({
    type: actions.LOGIN_REQUEST,
    payload: { params },
  }),
  loginError: (errParms) => ({
    type: actions.LOGIN_ERROR,
    payload: errParms,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
