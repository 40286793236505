import SysMenuActions from "./actions";

const INITIAL_DATA = {
  createStatus: "0", //  新增
  editStatus: "0", //  编辑
  deleteStatus: "0", //  删除

  noTreeData: [],
  data: [],
  totalList: 0,
  loading: false,
  error: null,
  reset: false,
  childData: [],
  childDatatotalList: 0,
};
export default function SysMenuReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case SysMenuActions.GET_ALL_Menu_List_REQUEST:
      return {
        ...state,
        noTreeData: [],
        // loading: true,
        error: null,
      };
    case SysMenuActions.GET_ALL_Menu_List_SUCCESS:
      let noTreeData = Object.assign([], action.payload);
      noTreeData.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        noTreeData: noTreeData,
        // loading: false,
        error: null,
      };
    case SysMenuActions.GET_ALL_Menu_List_ERROR:
      return {
        ...state,
        // loading: false,
        error: action.payload,
      };
    case SysMenuActions.GET_Menu_List_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case SysMenuActions.GET_Menu_List_SUCCESS:
      let data = Object.assign([], action.payload);

      data.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        data: action.payload.list,
        totalList: action.payload.total,
        loading: false,
        error: null,
      };
    case SysMenuActions.GET_Menu_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SysMenuActions.GET_Sub_Menu_List_REQUEST:
      return {
        ...state,
        childData: [],
        // loading: true,
        error: null,
      };
    case SysMenuActions.GET_Sub_Menu_List_SUCCESS:
      const newlist = action.payload;
      const pidlist = state.data;
      if (newlist.length > 0) {
        const index = pidlist.findIndex((item) => {
          return item.id === newlist[0].pid;
        });
        pidlist[index].children = newlist;
      }
      return {
        ...state,
        childData: action.payload,
        data: pidlist,
        // childDatatotalList:action.payload.total,
        // loading: false,
        error: null,
      };
    case SysMenuActions.GET_Sub_Menu_List_ERROR:
      return {
        ...state,
        // loading: false,
        error: action.payload,
      };

    case SysMenuActions.ADD_Menu_List_REQUEST:
      return {
        ...state,
        createStatus: "1",
        // loading: true,
        error: null,
      };
    case SysMenuActions.ADD_Menu_List_SUCCESS:
      return {
        ...state,
        createStatus: "2",
        // loading: false,
        error: action.payload,
      };
    case SysMenuActions.ADD_Menu_List_ERROR:
      return {
        ...state,
        createStatus: "3",
        // loading: false,
        error: action.payload,
      };

    case SysMenuActions.EDIT_Menu_List_REQUEST:
      return {
        ...state,
        editStatus: "1",
        // loading: true,
        error: null,
      };
    case SysMenuActions.EDIT_Menu_List_SUCCESS:
      return {
        ...state,
        editStatus: "2",
        // loading: false,
        error: action.payload,
      };
    case SysMenuActions.EDIT_Menu_List_ERROR:
      return {
        ...state,
        editStatus: "3",
        // loading: false,
        error: action.payload,
      };

    case SysMenuActions.DEL_Menu_List_REQUEST:
      return {
        ...state,
        deleteStatus: "1",
        // loading: true,
        error: null,
      };
    case SysMenuActions.DEL_Menu_List_SUCCESS:
      return {
        ...state,
        deleteStatus: "2",
        // loading: false,
        error: action.payload,
      };
    case SysMenuActions.DEL_Menu_List_ERROR:
      return {
        ...state,
        deleteStatus: "3",
        // loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
