import React from "react";
import Input from "../uielements/input";
import Button from "../uielements/button";
import IntlMessages from "../utility/intlMessages";

export default function ({
	searchText,
	onInputChange,
	onSearch,
	onReset,
	placeholder,
}) {
	return (
		<div style={{ display: "contents" }}>
			<Input
				allowClear={true}
				id="tableFilterInput"
				placeholder={placeholder || "Search"}
				value={searchText}
				onChange={onInputChange}
				onPressEnter={onSearch}
				// style={{minWidth:120}}
				style={{ width: "320px" }}
			/>
			<Button
				type="primary"
				style={{ marginLeft: "8px" }}
				onClick={onSearch}
			>
				<IntlMessages id="forms.input.searchTitle" />
			</Button>
			<Button
				type=""
				style={{ marginLeft: "8px" }}
				onClick={onReset}
			>
				<IntlMessages id="forms.input.searchReset" />
			</Button>
		</div>
	);
}
