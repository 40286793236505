const messageTemplateActions = {
  //Fetch messageTemplate Data
  FETCH_messageTemplate_DATA_REQUEST: "FETCH_messageTemplate_DATA_REQUEST",
  FETCH_messageTemplate_DATA_SUCCESS: "FETCH_messageTemplate_DATA_SUCCESS",
  FETCH_messageTemplate_DATA_FAILURE: "FETCH_messageTemplate_DATA_FAILURE",
  SET_messageTemplate_DATA: "SET_messageTemplate_DATA",
  fetchmessageTemplateDataRequest: (request) => ({
    type: messageTemplateActions.FETCH_messageTemplate_DATA_REQUEST,
    payload: request,
  }),
  fetchmessageTemplateDataSuccess: (messageTemplate) => ({
    type: messageTemplateActions.FETCH_messageTemplate_DATA_SUCCESS,
    payload: messageTemplate,
  }),
  fetchmessageTemplateDataFailure: (error) => ({
    type: messageTemplateActions.FETCH_messageTemplate_DATA_FAILURE,
    payload: error,
  }),

  // Add messageTemplate
  ADD_messageTemplate_REQUEST: "ADD_messageTemplate_REQUEST",
  ADD_messageTemplate_SUCCESS: "ADD_messageTemplate_SUCCESS",
  ADD_messageTemplate_FAILURE: "ADD_messageTemplate_FAILURE",
  addmessageTemplateDataRequest: (request) => ({
    type: messageTemplateActions.ADD_messageTemplate_REQUEST,
    payload: request,
  }),
  addmessageTemplateDataSuccess: (messageTemplate) => ({
    type: messageTemplateActions.ADD_messageTemplate_SUCCESS,
    payload: messageTemplate,
  }),
  addmessageTemplateDataFailure: (error) => ({
    type: messageTemplateActions.ADD_messageTemplate_FAILURE,
    payload: error,
  }),

  // Edit messageTemplate
  EDIT_messageTemplate_REQUEST: "EDIT_messageTemplate_REQUEST",
  EDIT_messageTemplate_SUCCESS: "EDIT_messageTemplate_SUCCESS",
  EDIT_messageTemplate_FAILURE: "EDIT_messageTemplate_FAILURE",
  editmessageTemplateDataRequest: (request) => ({
    type: messageTemplateActions.EDIT_messageTemplate_REQUEST,
    payload: request,
  }),
  editmessageTemplateDataSuccess: (messageTemplate) => ({
    type: messageTemplateActions.EDIT_messageTemplate_SUCCESS,
    payload: messageTemplate,
  }),
  editmessageTemplateDataFailure: (error) => ({
    type: messageTemplateActions.EDIT_messageTemplate_FAILURE,
    payload: error,
  }),

  // Delete messageTemplate
  DELETE_messageTemplate_REQUEST: "DELETE_messageTemplate_REQUEST",
  DELETE_messageTemplate_SUCCESS: "DELETE_messageTemplate_SUCCESS",
  DELETE_messageTemplate_FAILURE: "DELETE_messageTemplate_FAILURE",
  deletemessageTemplateDataRequest: (request) => ({
    type: messageTemplateActions.DELETE_messageTemplate_REQUEST,
    payload: request,
  }),
  deletemessageTemplateDataSuccess: (messageTemplate) => ({
    type: messageTemplateActions.DELETE_messageTemplate_SUCCESS,
    payload: messageTemplate,
  }),
  deletemessageTemplateDataFailure: (error) => ({
    type: messageTemplateActions.DELETE_messageTemplate_FAILURE,
    payload: error,
  }),

  //Fetch SubTemp Data
  FETCH_SubTemp_DATA_REQUEST: "FETCH_SubTemp_DATA_REQUEST",
  FETCH_SubTemp_DATA_SUCCESS: "FETCH_SubTemp_DATA_SUCCESS",
  FETCH_SubTemp_DATA_FAILURE: "FETCH_SubTemp_DATA_FAILURE",
  fetchSubTempDataRequest: (request) => ({
    type: messageTemplateActions.FETCH_SubTemp_DATA_REQUEST,
    payload: request,
  }),
  fetchSubTempDataSuccess: (SubTemp) => ({
    type: messageTemplateActions.FETCH_SubTemp_DATA_SUCCESS,
    payload: SubTemp,
  }),
  fetchSubTempDataFailure: (error) => ({
    type: messageTemplateActions.FETCH_SubTemp_DATA_FAILURE,
    payload: error,
  }),

  // Add SubTemp
  ADD_SubTemp_REQUEST: "ADD_SubTemp_REQUEST",
  addSubTempDataRequest: (request) => ({
    type: messageTemplateActions.ADD_SubTemp_REQUEST,
    payload: request,
  }),

  //更新子语言信息
  Edit_SubTemp_REQUEST: "Edit_SubTemp_REQUEST",
  editSubTempDataRequest: (request) => ({
    type: messageTemplateActions.Edit_SubTemp_REQUEST,
    payload: request,
  }),
};

export default messageTemplateActions;
