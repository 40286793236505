// saga.js
import { call, all, takeEvery, put } from "redux-saga/effects";
import ruleManageActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

function* fetchruleManageDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getRuleManageList, payload);
    yield put(ruleManageActions.fetchruleManageDataSuccess(response.data));
  } catch (error) {
    yield put(ruleManageActions.fetchruleManageDataFailure(error));
  }
}

function* addruleManageDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addRuleManage, payload.params1);

    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
    // yield put(ruleManageActions.addruleManageDataSuccess(response.data));
  } catch (error) {
    yield put(ruleManageActions.addruleManageDataFailure(error));
  } finally {
    yield put(ruleManageActions.fetchruleManageDataRequest(payload.params2));
  }
}

// EDIT ruleManage
function* editruleManageDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editRuleManage, payload.params1);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(ruleManageActions.editruleManageDataFailure(error));
  } finally {
    yield put(ruleManageActions.fetchruleManageDataRequest(payload.params2));
  }
}

// delete ruleManage
function* deleteRuleManageDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteRuleManage, payload);
    if (response.code === 100) {
      yield put(ruleManageActions.deleteRuleManageDataSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(ruleManageActions.deleteRuleManageDataFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(ruleManageActions.deleteRuleManageDataFailure(error));
  }
}

// EDIT ruleManage--启用 or 禁用
function* isAbleruleManageDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi.isEnableRuleManage,
      payload.params1,
    );
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
  } finally {
    yield put(ruleManageActions.fetchruleManageDataRequest(payload.params2));
  }
}

//获取产品型号 -->事件列表
function* getEventListRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getEventList, payload);
    yield put(ruleManageActions.getEventListSuccess(response.data));
  } catch (error) {
    yield put(ruleManageActions.getEventListFailure(error));
  }
}

//获取产品型号 -->属性列表
function* getPropertyListRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getPropertyList, payload);
    yield put(ruleManageActions.getPropertyListSuccess(response.data));
  } catch (error) {
    yield put(ruleManageActions.getPropertyListFailure(error));
  }
}
//获取消息模板列表
function* getMessageTempListRequest() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getMessageTemplate);
    yield put(ruleManageActions.getMessageTempListSuccess(response.data));
  } catch (error) {
    yield put(ruleManageActions.getMessageTempListFailure(error));
  }
}

//获取系统用户下拉框(web)
function* getSysAdminsListRequest() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getSysAdminSelectList);
    yield put(ruleManageActions.getSysAdminsListSuccess(response.data));
  } catch (error) {
    yield put(ruleManageActions.getSysAdminsListFailure(error));
  }
}

//获取 通知用户下拉框
function* getSysUsersListRequest(request) {
  const { payload } = request;
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getSysUserSelectList, payload);
    yield put(ruleManageActions.getSysUsersListSuccess(response.data));
  } catch (error) {
    yield put(ruleManageActions.getSysUsersListFailure(error));
  }
}

export default function* ruleManageSaga() {
  yield all([
    takeEvery(
      ruleManageActions.FETCH_ruleManage_DATA_REQUEST,
      fetchruleManageDataRequest,
    ),
    takeEvery(
      ruleManageActions.ADD_ruleManage_REQUEST,
      addruleManageDataRequest,
    ),
    takeEvery(
      ruleManageActions.EDIT_ruleManage_REQUEST,
      editruleManageDataRequest,
    ),
    takeEvery(
      ruleManageActions.DELETE_ruleManage_REQUEST,
      deleteRuleManageDataRequest,
    ),
    takeEvery(
      ruleManageActions.ISABLE_ruleManage_REQUEST,
      isAbleruleManageDataRequest,
    ),
    takeEvery(ruleManageActions.GET_Event_REQUEST, getEventListRequest),
    takeEvery(ruleManageActions.GET_Property_REQUEST, getPropertyListRequest),
    takeEvery(
      ruleManageActions.GET_MessageTemp_REQUEST,
      getMessageTempListRequest,
    ),
    takeEvery(
      ruleManageActions.GET_SysAdminsList_REQUEST,
      getSysAdminsListRequest,
    ),
    takeEvery(
      ruleManageActions.GET_SysUsersList_REQUEST,
      getSysUsersListRequest,
    ),
  ]);
}
