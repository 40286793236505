import { call, all, takeEvery, put } from "redux-saga/effects";
import * as XLSX from "xlsx";
import orderActions from "./actions";
import statusAction from "../apiStatus/actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";
import Notification from "@iso/components/Notification";

const alert = (response) => {
  Notification("success", response.message);
};

const Error = (response) => {
  Notification("error", response.message);
};

function* downloadExcelRequest() {
  try {
    const response = yield call(CloudHawkApi.downloadExcel);
    yield put(orderActions.downloadExcelSuccess(response.data));
  } catch (error) {}
}

function* uploadExcelRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.uploadExcel, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
  } catch (error) {}
}

function* getOrdersRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getOrders, payload);
    yield put(orderActions.getOrdersSuccess(response.data));
  } catch (error) {}
}

function* deleteOrdersRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteOrder, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
  } catch (error) {}
}

function* unbindOrderRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.removeDevice, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
  } catch (error) {}
}

function* jsonToExcelRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.downloadFaultRecord, payload);
    if (!response.success) {
      Error(response);
    } else {
      yield put(orderActions.jsonToExcelSuccess(response.data));

      const fileName = "faultRecords.xlsx";
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(response.data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "faultRecords");
      XLSX.writeFile(wb, fileName);

      alert(response);
    }
  } catch (error) {}
}

function* editOrderRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editOrder, payload);

    Notification("success", response.message);
    window.location.reload();
    yield put(orderActions.editOrderDataSuccess(response.data));
  } catch (error) {
    yield put(orderActions.editOrderDataFailure(error));
  }
}

export default function* orderSaga() {
  yield all([
    takeEvery(orderActions.DOWNLOAD_EXCEL_REQUEST, downloadExcelRequest),
    takeEvery(orderActions.UPLOAD_EXCEL_REQUEST, uploadExcelRequest),
    takeEvery(orderActions.GET_ORDERS_REQUEST, getOrdersRequest),
    takeEvery(orderActions.DELETE_ORDER_REQUEST, deleteOrdersRequest),
    takeEvery(orderActions.UNBIND_ORDER_REQUEST, unbindOrderRequest),
    takeEvery(orderActions.EDIT_ORDER_REQUEST, editOrderRequest),
    takeEvery(orderActions.JSON_TO_EXCEL_REQUEST, jsonToExcelRequest),
  ]);
}
