import { call, all, takeEvery, put } from "redux-saga/effects";
import EditProductTemplateActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

//获取当前模板所有信息
function* getEditProductTemplateList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getTemplateInfoList, payload);
    if (!response.success) {
      Error(response);
    }
    yield put(
      EditProductTemplateActions.fetchGETEditProductTemplateListSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      EditProductTemplateActions.fetchGETEditProductTemplateListError(error),
    );
  }
}

//服务下拉框--对应特定属性
function* getServiceList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getServiceList, payload);
    if (response.success) {
      yield put(
        EditProductTemplateActions.fetchGETServiceListSuccess(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(EditProductTemplateActions.fetchGETServiceListError(error));
  }
}

//属性下拉框
function* getParamsList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getParamsList, payload);
    if (response.success) {
      yield put(
        EditProductTemplateActions.fetchGETParamsListSuccess(response.data),
      );
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(EditProductTemplateActions.fetchGETServiceListError(error));
  }
}

//保存模板 设备页详情
function* editEditProductTemplateList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editTemplateDetailList, payload);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    // yield put(EditProductTemplateActions.fetchGETEditProductTemplateListError(error));
  }
}

//

// 获取次级页面下拉列表
function* getSecondary(request) {
  // yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getSecondarySelect, payload);
    if (response.success) {
      yield put(
        EditProductTemplateActions.fetchGETSecondarySelectListSuccess(
          response.data,
        ),
      );
    } else {
      Error(response);
    }
  } catch (error) {}
}

//模板-设置页面-保存
function* saveSettingsTemplate(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.saveTemplateSetList, payload);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    // yield put(EditProductTemplateActions.fetchGETEditProductTemplateListError(error));
  }
}

// 模板-次级页面保存
function* saveTemplateSecPage(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.saveTemplateSecPage, payload);
    if (response.success) {
      alert(response);
      yield put(
        EditProductTemplateActions.saveTemplateSecPageSUCCESS(response.data),
      );
    } else {
      Error(response);
      yield put(EditProductTemplateActions.saveTemplateSecPageERROR(response));
    }
  } catch (error) {
    // yield put(EditProductTemplateActions.fetchGETEditProductTemplateListError(error));
  }
}

//模板-设置页面和设备详情页-保存
function* saveAllInfoTemplate(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.saveAllInfoTemplate, payload);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    // yield put(EditProductTemplateActions.fetchGETEditProductTemplateListError(error));
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(
      EditProductTemplateActions.GET_Service_List_REQUEST,
      getServiceList,
    ),
    takeEvery(
      EditProductTemplateActions.GET_Params_List_REQUEST,
      getParamsList,
    ),
    takeEvery(
      EditProductTemplateActions.GET_EditProductTemplate_List_REQUEST,
      getEditProductTemplateList,
    ),
    takeEvery(
      EditProductTemplateActions.EDIT_EditProductTemplate_List_REQUEST,
      editEditProductTemplateList,
    ),
    takeEvery(
      EditProductTemplateActions.GET_SecondarySelect_List_REQUEST,
      getSecondary,
    ),
    takeEvery(
      EditProductTemplateActions.POST_SaveSettingsTemplate_REQUEST,
      saveSettingsTemplate,
    ),
    takeEvery(
      EditProductTemplateActions.POST_SaveSettingsSecTemplate_REQUEST,
      saveTemplateSecPage,
    ),
    takeEvery(
      EditProductTemplateActions.POST_SaveAllInfoTemplate_REQUEST,
      saveAllInfoTemplate,
    ),
  ]);
}
