import { getCurrentTheme } from "./config";
const actions = {
  CHANGE_THEME: "CHANGE_THEME",
  SWITCH_ACTIVATION: "SWITCH_ACTIVATION",
  SWITCH_ACTIVATION_CLOSE: "SWITCH_ACTIVATION_CLOSE",
  switchActivation: () => ({
    type: actions.SWITCH_ACTIVATION,
  }),
  switchActivation_close: () => ({
    type: actions.SWITCH_ACTIVATION_CLOSE,
  }),
  changeTheme: (attribute, themeName) => {
    const theme = getCurrentTheme(attribute, themeName);
    if (attribute === "layoutTheme") {
      document.getElementsByClassName(
        "isomorphicContent",
      )[0].style.backgroundColor = theme.backgroundColor;
    }
    return {
      type: actions.CHANGE_THEME,
      attribute,
      theme,
    };
  },
};
export default actions;
