import crypto from "crypto";

const KEY = "Ywsmgfichserstwjlwsmgfichserstwj";

function generateIV(length) {
  let result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const encrypt = function (text) {
  const iv = generateIV(16);
  const cipher = crypto.createCipheriv(process.env.REACT_APP_EN_DE_CRYPT_KEY, Buffer.from(KEY), Buffer.from(iv), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return { iv: iv, encryptedData: encrypted.toString('hex') };
};

export const decrypt = function (text) {
  const iv = Buffer.from(text.iv);
  const encryptedText = Buffer.from(text.encryptedData, 'hex');
  const decipher = crypto.createDecipheriv(process.env.REACT_APP_EN_DE_CRYPT_KEY, Buffer.from(KEY), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return extractJson(decrypted.toString());
};

export const extractJson = function (data) {
  var s = data
    .replace(/\\n/g, "\\n")
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, "\\&")
    .replace(/\\r/g, "\\r")
    .replace(/\\t/g, "\\t")
    .replace(/\\b/g, "\\b")
    .replace(/\\f/g, "\\f");
  // eslint-disable-next-line no-control-regex
  s = s.replace(/[\u0000-\u0019]+/g, "");
  var o = JSON.parse(s);
  return o;
};




