const faqActions = {
  //
  // create
  //  *********  create FAQ Faq *******   //
  CREATE_FAQ_REQUEST: "CREATE_FAQ_REQUEST",
  CREATE_FAQ_SUCCESS: "CREATE_FAQ_SUCCESS",
  CREATE_FAQ_FAILURE: "CREATE_FAQ_FAILURE",
  createFaqRequest: (request) => ({
    type: faqActions.CREATE_FAQ_REQUEST,
    payload: request,
  }),
  createFaqSuccess: (res) => ({
    type: faqActions.CREATE_FAQ_SUCCESS,
    payload: res,
  }),
  createFaqFailure: (error) => ({
    type: faqActions.CREATE_FAQ_FAILURE,
    payload: error,
  }),
  //  *********  create FAQ Faq Child child *******   //
  CREATE_FAQ_CHILD_REQUEST: "CREATE_FAQ_CHILD_REQUEST",
  CREATE_FAQ_CHILD_SUCCESS: "CREATE_FAQ_CHILD_SUCCESS",
  CREATE_FAQ_CHILD_FAILURE: "CREATE_FAQ_CHILD_FAILURE",
  createFaqChildRequest: (request) => ({
    type: faqActions.CREATE_FAQ_CHILD_REQUEST,
    payload: request,
  }),
  createFaqChildSuccess: (res) => ({
    type: faqActions.CREATE_FAQ_CHILD_SUCCESS,
    payload: res,
  }),
  createFaqChildFailure: (error) => ({
    type: faqActions.CREATE_FAQ_CHILD_FAILURE,
    payload: error,
  }),
  //  *********  create HELP Help *******   //
  CREATE_HELP_REQUEST: "CREATE_HELP_REQUEST",
  CREATE_HELP_SUCCESS: "CREATE_HELP_SUCCESS",
  CREATE_HELP_FAILURE: "CREATE_HELP_FAILURE",
  createHelpRequest: (request) => ({
    type: faqActions.CREATE_HELP_REQUEST,
    payload: request,
  }),
  createHelpSuccess: (res) => ({
    type: faqActions.CREATE_HELP_SUCCESS,
    payload: res,
  }),
  createHelpFailure: (error) => ({
    type: faqActions.CREATE_HELP_FAILURE,
    payload: error,
  }),
  //  *********  create HELP Help Child child *******   //
  CREATE_HELP_CHILD_REQUEST: "CREATE_HELP_CHILD_REQUEST",
  CREATE_HELP_CHILD_SUCCESS: "CREATE_HELP_CHILD_SUCCESS",
  CREATE_HELP_CHILD_FAILURE: "CREATE_HELP_CHILD_FAILURE",
  createHelpChildRequest: (request) => ({
    type: faqActions.CREATE_HELP_CHILD_REQUEST,
    payload: request,
  }),
  createHelpChildSuccess: (res) => ({
    type: faqActions.CREATE_HELP_CHILD_SUCCESS,
    payload: res,
  }),
  createHelpChildFailure: (error) => ({
    type: faqActions.CREATE_HELP_CHILD_FAILURE,
    payload: error,
  }),

  //
  // edit
  //  *********  edit FAQ Faq *******   //
  EDIT_FAQ_REQUEST: "EDIT_FAQ_REQUEST",
  EDIT_FAQ_SUCCESS: "EDIT_FAQ_SUCCESS",
  EDIT_FAQ_FAILURE: "EDIT_FAQ_FAILURE",
  editFaqRequest: (request) => ({
    type: faqActions.EDIT_FAQ_REQUEST,
    payload: request,
  }),
  editFaqSuccess: (res) => ({
    type: faqActions.EDIT_FAQ_SUCCESS,
    payload: res,
  }),
  editFaqFailure: (error) => ({
    type: faqActions.EDIT_FAQ_FAILURE,
    payload: error,
  }),
  //  *********  edit FAQ Faq Child child *******   //
  EDIT_FAQ_CHILD_REQUEST: "EDIT_FAQ_CHILD_REQUEST",
  EDIT_FAQ_CHILD_SUCCESS: "EDIT_FAQ_CHILD_SUCCESS",
  EDIT_FAQ_CHILD_FAILURE: "EDIT_FAQ_CHILD_FAILURE",
  editFaqChildRequest: (request) => ({
    type: faqActions.EDIT_FAQ_CHILD_REQUEST,
    payload: request,
  }),
  editFaqChildSuccess: (res) => ({
    type: faqActions.EDIT_FAQ_CHILD_SUCCESS,
    payload: res,
  }),
  editFaqChildFailure: (error) => ({
    type: faqActions.EDIT_FAQ_CHILD_FAILURE,
    payload: error,
  }),
  //  *********  edit HELP Help *******   //
  EDIT_HELP_REQUEST: "EDIT_HELP_REQUEST",
  EDIT_HELP_SUCCESS: "EDIT_HELP_SUCCESS",
  EDIT_HELP_FAILURE: "EDIT_HELP_FAILURE",
  editHelpRequest: (request) => ({
    type: faqActions.EDIT_HELP_REQUEST,
    payload: request,
  }),
  editHelpSuccess: (res) => ({
    type: faqActions.EDIT_HELP_SUCCESS,
    payload: res,
  }),
  editHelpFailure: (error) => ({
    type: faqActions.EDIT_HELP_FAILURE,
    payload: error,
  }),
  //  *********  edit HELP Help Child child *******   //
  EDIT_HELP_CHILD_REQUEST: "EDIT_HELP_CHILD_REQUEST",
  EDIT_HELP_CHILD_SUCCESS: "EDIT_HELP_CHILD_SUCCESS",
  EDIT_HELP_CHILD_FAILURE: "EDIT_HELP_CHILD_FAILURE",
  editHelpChildRequest: (request) => ({
    type: faqActions.EDIT_HELP_CHILD_REQUEST,
    payload: request,
  }),
  editHelpChildSuccess: (res) => ({
    type: faqActions.EDIT_HELP_CHILD_SUCCESS,
    payload: res,
  }),
  editHelpChildFailure: (error) => ({
    type: faqActions.EDIT_HELP_CHILD_FAILURE,
    payload: error,
  }),

  //
  // delete
  //  *********  delete FAQ Faq *******   //
  DELETE_FAQ_REQUEST: "DELETE_FAQ_REQUEST",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_FAILURE: "DELETE_FAQ_FAILURE",
  deleteFaqRequest: (request) => ({
    type: faqActions.DELETE_FAQ_REQUEST,
    payload: request,
  }),
  deleteFaqSuccess: (res) => ({
    type: faqActions.DELETE_FAQ_SUCCESS,
    payload: res,
  }),
  deleteFaqFailure: (error) => ({
    type: faqActions.DELETE_FAQ_FAILURE,
    payload: error,
  }),
  //  *********  delete FAQ Faq Child child *******   //
  DELETE_FAQ_CHILD_REQUEST: "DELETE_FAQ_CHILD_REQUEST",
  DELETE_FAQ_CHILD_SUCCESS: "DELETE_FAQ_CHILD_SUCCESS",
  DELETE_FAQ_CHILD_FAILURE: "DELETE_FAQ_CHILD_FAILURE",
  deleteFaqChildRequest: (request) => ({
    type: faqActions.DELETE_FAQ_CHILD_REQUEST,
    payload: request,
  }),
  deleteFaqChildSuccess: (res) => ({
    type: faqActions.DELETE_FAQ_CHILD_SUCCESS,
    payload: res,
  }),
  deleteFaqChildFailure: (error) => ({
    type: faqActions.DELETE_FAQ_CHILD_FAILURE,
    payload: error,
  }),
  //  *********  delete HELP Help *******   //
  DELETE_HELP_REQUEST: "DELETE_HELP_REQUEST",
  DELETE_HELP_SUCCESS: "DELETE_HELP_SUCCESS",
  DELETE_HELP_FAILURE: "DELETE_HELP_FAILURE",
  deleteHelpRequest: (request) => ({
    type: faqActions.DELETE_HELP_REQUEST,
    payload: request,
  }),
  deleteHelpSuccess: (res) => ({
    type: faqActions.DELETE_HELP_SUCCESS,
    payload: res,
  }),
  deleteHelpFailure: (error) => ({
    type: faqActions.DELETE_HELP_FAILURE,
    payload: error,
  }),
  //  *********  delete HELP Help Child child *******   //
  DELETE_HELP_CHILD_REQUEST: "DELETE_HELP_CHILD_REQUEST",
  DELETE_HELP_CHILD_SUCCESS: "DELETE_HELP_CHILD_SUCCESS",
  DELETE_HELP_CHILD_FAILURE: "DELETE_HELP_CHILD_FAILURE",
  deleteHelpChildRequest: (request) => ({
    type: faqActions.DELETE_HELP_CHILD_REQUEST,
    payload: request,
  }),
  deleteHelpChildSuccess: (res) => ({
    type: faqActions.DELETE_HELP_CHILD_SUCCESS,
    payload: res,
  }),
  deleteHelpChildFailure: (error) => ({
    type: faqActions.DELETE_HELP_CHILD_FAILURE,
    payload: error,
  }),

  //
  //  *********  fetch FAQ Faq *******   //
  FETCH_FAQ_REQUEST: "FETCH_FAQ_REQUEST",
  FETCH_FAQ_SUCCESS: "FETCH_FAQ_SUCCESS",
  FETCH_FAQ_FAILURE: "FETCH_FAQ_FAILURE",
  fetchFaqRequest: (request) => ({
    type: faqActions.FETCH_FAQ_REQUEST,
    payload: request,
  }),
  fetchFaqSuccess: (res) => ({
    type: faqActions.FETCH_FAQ_SUCCESS,
    payload: res,
  }),
  fetchFaqFailure: (error) => ({
    type: faqActions.FETCH_FAQ_FAILURE,
    payload: error,
  }),
  //  *********  enable FAQ Faq *******   //
  ENABLE_FAQ_REQUEST: "ENABLE_FAQ_REQUEST",
  ENABLE_FAQ_SUCCESS: "ENABLE_FAQ_SUCCESS",
  ENABLE_FAQ_FAILURE: "ENABLE_FAQ_FAILURE",
  enableFaqRequest: (request) => ({
    type: faqActions.ENABLE_FAQ_REQUEST,
    payload: request,
  }),
  enableFaqSuccess: (res) => ({
    type: faqActions.ENABLE_FAQ_SUCCESS,
    payload: res,
  }),
  enableFaqFailure: (error) => ({
    type: faqActions.ENABLE_FAQ_FAILURE,
    payload: error,
  }),
  //  *********  disable FAQ Faq *******   //
  DISABLE_FAQ_REQUEST: "DISABLE_FAQ_REQUEST",
  DISABLE_FAQ_SUCCESS: "DISABLE_FAQ_SUCCESS",
  DISABLE_FAQ_FAILURE: "DISABLE_FAQ_FAILURE",
  disableFaqRequest: (request) => ({
    type: faqActions.DISABLE_FAQ_REQUEST,
    payload: request,
  }),
  disableFaqSuccess: (res) => ({
    type: faqActions.DISABLE_FAQ_SUCCESS,
    payload: res,
  }),
  disableFaqFailure: (error) => ({
    type: faqActions.DISABLE_FAQ_FAILURE,
    payload: error,
  }),
  //  *********  fetch HELP Help *******   //
  FETCH_HELP_REQUEST: "FETCH_HELP_REQUEST",
  FETCH_HELP_SUCCESS: "FETCH_HELP_SUCCESS",
  FETCH_HELP_FAILURE: "FETCH_HELP_FAILURE",
  fetchHelpRequest: (request) => ({
    type: faqActions.FETCH_HELP_REQUEST,
    payload: request,
  }),
  fetchHelpSuccess: (res) => ({
    type: faqActions.FETCH_HELP_SUCCESS,
    payload: res,
  }),
  fetchHelpFailure: (error) => ({
    type: faqActions.FETCH_HELP_FAILURE,
    payload: error,
  }),
  //  *********  enable HELP Help *******   //
  ENABLE_HELP_REQUEST: "ENABLE_HELP_REQUEST",
  ENABLE_HELP_SUCCESS: "ENABLE_HELP_SUCCESS",
  ENABLE_HELP_FAILURE: "ENABLE_HELP_FAILURE",
  enableHelpRequest: (request) => ({
    type: faqActions.ENABLE_HELP_REQUEST,
    payload: request,
  }),
  enableHelpSuccess: (res) => ({
    type: faqActions.ENABLE_HELP_SUCCESS,
    payload: res,
  }),
  enableHelpFailure: (error) => ({
    type: faqActions.ENABLE_HELP_FAILURE,
    payload: error,
  }),
  //  *********  disable HELP Help *******   //
  DISABLE_HELP_REQUEST: "DISABLE_HELP_REQUEST",
  DISABLE_HELP_SUCCESS: "DISABLE_HELP_SUCCESS",
  DISABLE_HELP_FAILURE: "DISABLE_HELP_FAILURE",
  disableHelpRequest: (request) => ({
    type: faqActions.DISABLE_HELP_REQUEST,
    payload: request,
  }),
  disableHelpSuccess: (res) => ({
    type: faqActions.DISABLE_HELP_SUCCESS,
    payload: res,
  }),
  disableHelpFailure: (error) => ({
    type: faqActions.DISABLE_HELP_FAILURE,
    payload: error,
  }),

  //Fetch FAQ Data By Language
  FETCH_FAQ_DATA_REQUEST: "FETCH_FAQ_DATA_REQUEST",
  FETCH_FAQ_DATA_SUCCESS: "FETCH_FAQ_DATA_SUCCESS",
  FETCH_FAQ_DATA_FAILURE: "FETCH_FAQ_DATA_FAILURE",
  SET_FAQ_DATA: "SET_FAQ_DATA",
  fetchfaqDataRequest: (request) => ({
    type: faqActions.FETCH_FAQ_DATA_REQUEST,
    payload: request,
  }),
  fetchfaqDataSuccess: (faq) => ({
    type: faqActions.FETCH_FAQ_DATA_SUCCESS,
    payload: faq,
  }),
  fetchfaqDataFailure: (error) => ({
    type: faqActions.FETCH_FAQ_DATA_FAILURE,
    payload: error,
  }),

  //Fetch Filter Types Data
  FETCH_FILTERTYPE_DATA_REQUEST: "FETCH_FILTERTYPE_DATA_REQUEST",
  FETCH_FILTERTYPE_DATA_SUCCESS: "FETCH_FILTERTYPE_DATA_SUCCESS",
  FETCH_FILTERTYPE_DATA_FAILURE: "FETCH_FILTERTYPE_DATA_FAILURE",
  SET_FILTERTYPE_DATA: "SET_FAQ_DATA",
  fetchFilterTypeDataRequest: (request) => ({
    type: faqActions.FETCH_FILTERTYPE_DATA_REQUEST,
    payload: request,
  }),
  fetchFilterTypeDataSuccess: (filterType) => ({
    type: faqActions.FETCH_FILTERTYPE_DATA_SUCCESS,
    payload: filterType,
  }),
  fetchFilterTypeDataFailure: (error) => ({
    type: faqActions.FETCH_FILTERTYPE_DATA_FAILURE,
    payload: error,
  }),

  //Fetch FAQ Data By Filter Type
  FETCH_FILTERFAQ_DATA_REQUEST: "FETCH_FILTERFAQ_DATA_REQUEST",
  FETCH_FILTERFAQ_DATA_SUCCESS: "FETCH_FILTERFAQ_DATA_SUCCESS",
  FETCH_FILTERFAQ_DATA_FAILURE: "FETCH_FILTERFAQ_DATA_FAILURE",
  SET_FILTERFAQ_DATA: "SET_FILTERFAQ_DATA",
  fetchFilterFaqDataRequest: (request) => ({
    type: faqActions.FETCH_FILTERFAQ_DATA_REQUEST,
    payload: request,
  }),
  fetchFilterFaqDataSuccess: (filterType) => ({
    type: faqActions.FETCH_FILTERFAQ_DATA_SUCCESS,
    payload: filterType,
  }),
  fetchFilterFaqDataFailure: (error) => ({
    type: faqActions.FETCH_FILTERFAQ_DATA_FAILURE,
    payload: error,
  }),

  // Add FAQ
  ADD_FAQ_REQUEST: "ADD_FAQ_REQUEST",
  ADD_FAQ_SUCCESS: "ADD_FAQ_SUCCESS",
  ADD_FAQ_FAILURE: "ADD_FAQ_FAILURE",
  addfaqDataRequest: (request) => ({
    type: faqActions.ADD_FAQ_REQUEST,
    payload: request,
  }),
  addfaqDataSuccess: (faq) => ({
    type: faqActions.ADD_FAQ_SUCCESS,
    payload: faq,
  }),
  addfaqDataFailure: (error) => ({
    type: faqActions.ADD_FAQ_SUCCESS,
    payload: error,
  }),

  // // Edit FAQ
  // EDIT_FAQ_REQUEST: 'EDIT_FAQ_REQUEST',
  // EDIT_FAQ_SUCCESS: 'EDIT_FAQ_SUCCESS',
  // EDIT_FAQ_FAILURE: 'EDIT_FAQ_FAILURE',
  // editfaqDataRequest: (request) => ({
  // 	type: faqActions.EDIT_FAQ_REQUEST,
  // 	payload: request
  // }),
  // editfaqDataSuccess: faq => ({
  // 	type: faqActions.EDIT_FAQ_SUCCESS,
  // 	payload: faq,
  // }),
  // editfaqDataFailure: error => ({
  // 	type: faqActions.EDIT_FAQ_FAILURE,
  // 	payload: error,
  // }),

  // // Delete FAQ
  // DELETE_FAQ_REQUEST: 'DELETE_FAQ_REQUEST',
  // DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
  // DELETE_FAQ_FAILURE: 'DELETE_FAQ_FAILURE',
  // deletefaqDataRequest: (request) => ({
  // 	type: faqActions.DELETE_FAQ_REQUEST,
  // 	payload: request
  // }),
  // deletefaqDataSuccess: faq => ({
  // 	type: faqActions.DELETE_FAQ_SUCCESS,
  // 	payload: faq,
  // }),
  // deletefaqDataFailure: error => ({
  // 	type: faqActions.DELETE_FAQ_FAILURE,
  // 	payload: error,
  // }),
};

export default faqActions;
