import { call, all, takeEvery, put } from "redux-saga/effects";
import userActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";
import CloudHawkApi3 from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import Notification from "@iso/components/Notification";

const alert = (response) => {
  Notification("success", response.message);
};

const alert2 = (response) => {
  Notification("success", response.msg);
};
const Error = (response) => {
  Notification("error", response.message);
};

function* fetchUserDataEffect(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getAllUsers, payload);
    yield put(userActions.fetchlanguageDataSuccess(response.data));
  } catch (error) {
    yield put(userActions.fetchlanguageDataFailure(error));
  }
}

function* fetchAllUserData() {
  try {
    const response = yield call(CloudHawkApi.getAllUsers);
    yield put(userActions.fetchAllUserDataSuccess(response.data));
  } catch (error) {
    yield put(userActions.fetchAllUserDataFailure(error));
  }
}

//CHANGE PASSWORD
function* changePasswordUserData(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.changePasswordRequest, payload);
    if (!response.success) {
      Error(response);
    } else {
      alert(response);
    }
    yield put(userActions.UserResetPasswordDataSuccess(response));
  } catch (error) {
    yield put(userActions.UserResetPasswordDataFailure(error));
  }
}

//RESET PASSWORD
function* resetPasswordUserData(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.directChangePassword, payload);
    alert(response);
    yield put(userActions.UserResetPasswordDataSuccess(response.data));
    yield put(userActions.resetRedirect(""));
  } catch (error) {
    Notification("error", "Entered old password is wrong!");
    yield put(userActions.UserResetPasswordDataFailure(error));
  }
}

//get verification code
function* getForgetPassVerification(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.getVerificationCode, payload);
    if (response.success) {
      alert2(response);
    } else {
      Notification("error", response.msg);
      sessionStorage.removeItem("Verfication");
    }
  } catch (error) {
    Notification("error", "get verification code error!");
    sessionStorage.removeItem("Verfication");
  }
}

function* fetchResetPasswordRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi3.sendResetPassRequset, payload);
    if (response.success) {
      alert2(response);
      yield put(userActions.SendResetPasswordSuccess(response.success));
    } else {
      Notification("error", response.msg);
    }
  } catch (error) {
    Notification("error", "reset password error!");
    yield put(userActions.SendResetPasswordFailure(error));
  }
}
export default function* userSaga() {
  yield all([
    takeEvery(userActions.FETCH_USER_DATA_REQUEST, fetchUserDataEffect),
    takeEvery(userActions.FETCH_ALL_USER_REQUEST, fetchAllUserData),
    takeEvery(userActions.RESET_PASSWORD_REQUEST, resetPasswordUserData),
    takeEvery(userActions.CHANGE_PASSWORD_REQUEST, changePasswordUserData),
    takeEvery(
      userActions.Get_ForgetPass_Verification_Request,
      getForgetPassVerification,
    ),
    takeEvery(
      userActions.Send_resetPassword_Request,
      fetchResetPasswordRequest,
    ),
  ]);
}
