import SysLogActions from "./actions";

const INITIAL_DATA = {
  WebData: [],
  WebTotalList: 0,
  AppData: [],
  AppTotalList: 0,
  loadingWeb: true,
  loadingApp: true,
  loading: true,
  error: null,
  reset: false,
};
export default function SysLogReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case SysLogActions.GET_WebLog_List_REQUEST:
      return {
        ...state,
        WebData: [],
        WebTotalList: 0,
        loadingWeb: true,
        error: null,
      };
    case SysLogActions.GET_WebLog_List_SUCCESS:
      return {
        ...state,
        WebData: action.payload.list,
        WebTotalList: action.payload.total,
        loadingWeb: false,
        error: null,
      };
    case SysLogActions.GET_WebLog_List_ERROR:
      return {
        ...state,
        loadingWeb: false,
        error: action.payload,
      };
    case SysLogActions.GET_AppLog_List_REQUEST:
      return {
        ...state,
        AppData: [],
        AppTotalList: 0,
        loadingApp: true,
        error: null,
      };
    case SysLogActions.GET_AppLog_List_SUCCESS:
      return {
        ...state,
        AppData: action.payload.list,
        AppTotalList: action.payload.total,
        loadingApp: false,
        error: null,
      };
    case SysLogActions.GET_AppLog_List_ERROR:
      return {
        ...state,
        loadingApp: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
