const SetTemplateActions = {
  GET_SetTemplate_List_REQUEST: "GET_SetTemplate_List_REQUEST",
  GET_SetTemplate_List_SUCCESS: "GET_SetTemplate_List_SUCCESS",
  GET_SetTemplate_List_ERROR: "GET_SetTemplate_List_ERROR",

  GET_SetTemplateLanguageDetail_List_REQUEST:
    "GET_SetTemplateLanguageDetail_List_REQUEST",
  GET_SetTemplateLanguageDetail_List_SUCCESS:
    "GET_SetTemplateLanguageDetail_List_SUCCESS",
  GET_SetTemplateLanguageDetail_List_ERROR:
    "GET_SetTemplateLanguageDetail_List_ERROR",

  ADD_SetTemplate_List_REQUEST: "ADD_SetTemplate_List_REQUEST",
  ADD_SetTemplate_List_SUCCESS: "ADD_SetTemplate_List_SUCCESS",
  ADD_SetTemplate_List_ERROR: "ADD_SetTemplate_List_ERROR",

  Publish_SetTemplate_List_REQUEST: "Publish_SetTemplate_List_REQUEST",
  Publish_SetTemplate_List_SUCCESS: "Publish_SetTemplate_List_SUCCESS",
  Publish_SetTemplate_List_ERROR: "Publish_SetTemplate_List_ERROR",

  EDIT_SetTemplate_List_REQUEST: "EDIT_SetTemplate_List_REQUEST",
  EDIT_SetTemplate_List_SUCCESS: "EDIT_SetTemplate_List_SUCCESS",
  EDIT_SetTemplate_List_ERROR: "EDIT_SetTemplate_List_ERROR",

  DEL_SetTemplate_List_REQUEST: "DEL_SetTemplate_List_REQUEST",
  DEL_SetTemplate_List_SUCCESS: "DEL_SetTemplate_List_SUCCESS",
  DEL_SetTemplate_List_ERROR: "DEL_SetTemplate_List_ERROR",

  GET_SetTemplateALL_List_REQUEST: "GET_SetTemplateALL_List_REQUEST",
  GET_SetTemplateALL_List_SUCCESS: "GET_SetTemplateALL_List_SUCCESS",
  GET_SetTemplateALL_List_ERROR: "GET_SetTemplateALL_List_ERROR",

  Export_Language_List_REQUEST: "Export_Language_List_REQUEST",
  Export_Language_List_SUCCESS: "Export_Language_List_SUCCESS",
  Export_Language_List_ERROR: "Export_Language_List_ERROR",

  IN_Language_List_REQUEST: "IN_Language_List_REQUEST",
  IN_Language_List_SUCCESS: "IN_Language_List_SUCCESS",
  IN_Language_List_ERROR: "IN_Language_List_ERROR",

  fetchExportLanguageList: (request) => ({
    type: SetTemplateActions.Export_Language_List_REQUEST,
    payload: request,
  }),

  fetchExportLanguageSuccess: (data) => ({
    type: SetTemplateActions.Export_Language_List_SUCCESS,
    payload: data,
  }),
  fetchExportLanguageListError: (error) => ({
    type: SetTemplateActions.Export_Language_List_ERROR,
    payload: error,
  }),

  fetchINLanguageList: (request) => ({
    type: SetTemplateActions.IN_Language_List_REQUEST,
    payload: request,
  }),

  fetchINLanguageListSuccess: (data) => ({
    type: SetTemplateActions.IN_Language_List_SUCCESS,
    payload: data,
  }),
  fetchINLanguageListError: (error) => ({
    type: SetTemplateActions.IN_Language_List_ERROR,
    payload: error,
  }),

  fetchGETSetTemplateList: (request) => ({
    type: SetTemplateActions.GET_SetTemplate_List_REQUEST,
    payload: request,
  }),

  fetchGETSetTemplateListSuccess: (data) => ({
    type: SetTemplateActions.GET_SetTemplate_List_SUCCESS,
    payload: data,
  }),
  fetchGETSetTemplateListError: (error) => ({
    type: SetTemplateActions.GET_SetTemplate_List_ERROR,
    payload: error,
  }),

  fetchGETSetTemplateLanguageDetailList: (request) => ({
    type: SetTemplateActions.GET_SetTemplateLanguageDetail_List_REQUEST,
    payload: request,
  }),

  fetchGETSetTemplateLanguageDetailSuccess: (data) => ({
    type: SetTemplateActions.GET_SetTemplateLanguageDetail_List_SUCCESS,
    payload: data,
  }),
  fetchGETSetTemplateLanguageDetailError: (error) => ({
    type: SetTemplateActions.GET_SetTemplateLanguageDetail_List_ERROR,
    payload: error,
  }),
  fetchGETSetTemplateALLList: () => ({
    type: SetTemplateActions.GET_SetTemplateALL_List_REQUEST,
    // payload: request
  }),
  fetchGETSetTemplateALLListSuccess: (data) => ({
    type: SetTemplateActions.GET_SetTemplateALL_List_SUCCESS,
    payload: data,
  }),
  fetchGETSetTemplateALLListError: (error) => ({
    type: SetTemplateActions.GET_SetTemplateALL_List_ERROR,
    payload: error,
  }),

  fetchADDSetTemplateList: (request) => ({
    type: SetTemplateActions.ADD_SetTemplate_List_REQUEST,
    payload: request,
  }),
  fetchADDSetTemplateListSuccess: (data) => ({
    type: SetTemplateActions.ADD_SetTemplate_List_SUCCESS,
    payload: data,
  }),
  fetchADDSetTemplateListError: (error) => ({
    type: SetTemplateActions.ADD_SetTemplate_List_ERROR,
    payload: error,
  }),

  fetchPublishSetTemplateList: (request) => ({
    type: SetTemplateActions.Publish_SetTemplate_List_REQUEST,
    payload: request,
  }),
  fetchPublishSetTemplateListSuccess: (data) => ({
    type: SetTemplateActions.Publish_SetTemplate_List_SUCCESS,
    payload: data,
  }),
  fetchPublishSetTemplateListError: (error) => ({
    type: SetTemplateActions.Publish_SetTemplate_List_ERROR,
    payload: error,
  }),

  fetchEDITSetTemplateList: (request) => ({
    type: SetTemplateActions.EDIT_SetTemplate_List_REQUEST,
    payload: request,
  }),
  fetchEDITSetTemplateListSuccess: (data) => ({
    type: SetTemplateActions.EDIT_SetTemplate_List_SUCCESS,
    payload: data,
  }),
  fetchEDITSetTemplateListError: (error) => ({
    type: SetTemplateActions.EDIT_SetTemplate_List_ERROR,
    payload: error,
  }),

  fetchDELSetTemplateList: (request) => ({
    type: SetTemplateActions.DEL_SetTemplate_List_REQUEST,
    payload: request,
  }),
};

export default SetTemplateActions;
