import SysCustomerActions from "./actions";

const INITIAL_DATA = {
  dataALL: [],
  data: [],
  totalList: 0,
  loading: true,
  error: null,
  reset: false,
};
export default function SysCustomerReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case SysCustomerActions.GET_Customer_List_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case SysCustomerActions.GET_Customer_List_SUCCESS:
      let data = Object.assign([], action.payload);

      data.forEach((element, index) => {
        element.key = index;
      });
      return {
        ...state,
        data: action.payload.list,
        totalList: action.payload.total,
        loading: false,
        error: null,
      };
    case SysCustomerActions.GET_Customer_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysCustomerActions.GET_CustomerALL_List_REQUEST:
      return {
        ...state,
        dataALL: [],
        loading: true,
        error: null,
      };
    case SysCustomerActions.GET_CustomerALL_List_SUCCESS:
      return {
        ...state,
        dataALL: action.payload,
        loading: false,
        error: null,
      };
    case SysCustomerActions.GET_CustomerALL_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysCustomerActions.ADD_Customer_List_REQUEST:
      return {
        ...state,
        data: [],
        totalList: 0,
        loading: true,
        error: null,
      };
    case SysCustomerActions.ADD_Customer_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysCustomerActions.ADD_Customer_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SysCustomerActions.EDIT_Customer_List_REQUEST:
      return {
        ...state,
        data: [],
        totalList: 0,
        loading: true,
        error: null,
      };
    case SysCustomerActions.EDIT_Customer_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysCustomerActions.EDIT_Customer_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SysCustomerActions.DEL_Customer_List_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SysCustomerActions.DEL_Customer_List_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SysCustomerActions.DEL_Customer_List_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
