const productActions = {
  //  *********  根据订单号、批次号入库 *******   //
  UP_DEVICE_ORDER_BATCH_REQUEST: "UP_DEVICE_ORDER_BATCH_REQUEST",
  UP_DEVICE_ORDER_BATCH_SUCCESS: "UP_DEVICE_ORDER_BATCH_SUCCESS",
  UP_DEVICE_ORDER_BATCH_FAILURE: "UP_DEVICE_ORDER_BATCH_FAILURE",
  upDeviceTestOrderBatchRequest: (request) => ({
    type: productActions.UP_DEVICE_ORDER_BATCH_REQUEST,
    payload: request,
  }),
  upDeviceTestOrderBatchSuccess: (product) => ({
    type: productActions.UP_DEVICE_ORDER_BATCH_SUCCESS,
    payload: product,
  }),
  upDeviceTestOrderBatchFailure: (error) => ({
    type: productActions.UP_DEVICE_ORDER_BATCH_FAILURE,
    payload: error,
  }),

  //  *********  产测订单号下拉 *******   //
  FETCH_DEVICE_ORDER_REQUEST: "FETCH_DEVICE_ORDER_REQUEST",
  FETCH_DEVICE_ORDER_SUCCESS: "FETCH_DEVICE_ORDER_SUCCESS",
  FETCH_DEVICE_ORDER_FAILURE: "FETCH_DEVICE_ORDER_FAILURE",
  fetchDeviceTestOrderRequest: (request) => ({
    type: productActions.FETCH_DEVICE_ORDER_REQUEST,
    payload: request,
  }),
  fetchDeviceTestOrderSuccess: (product) => ({
    type: productActions.FETCH_DEVICE_ORDER_SUCCESS,
    payload: product,
  }),
  fetchDeviceTestOrderFailure: (error) => ({
    type: productActions.FETCH_DEVICE_ORDER_FAILURE,
    payload: error,
  }),

  //  *********  产测订单号下拉 *******   //
  FETCH_DEVICE_BATCH_REQUEST: "FETCH_DEVICE_BATCH_REQUEST",
  FETCH_DEVICE_BATCH_SUCCESS: "FETCH_DEVICE_BATCH_SUCCESS",
  FETCH_DEVICE_BATCH_FAILURE: "FETCH_DEVICE_BATCH_FAILURE",
  fetchDeviceTestBatchRequest: (request) => ({
    type: productActions.FETCH_DEVICE_BATCH_REQUEST,
    payload: request,
  }),
  fetchDeviceTestBatchSuccess: (product) => ({
    type: productActions.FETCH_DEVICE_BATCH_SUCCESS,
    payload: product,
  }),
  fetchDeviceTestBatchFailure: (error) => ({
    type: productActions.FETCH_DEVICE_BATCH_FAILURE,
    payload: error,
  }),

  // 产品管理-产品型号（老）共 (4) 个
  //  *********  分页查询列表 *******   //
  FETCH_PRODUCT_V1_P_REQUEST: "FETCH_PRODUCT_V1_P_REQUEST",
  FETCH_PRODUCT_V1_P_SUCCESS: "FETCH_PRODUCT_V1_P_SUCCESS",
  FETCH_PRODUCT_V1_P_FAILURE: "FETCH_PRODUCT_V1_P_FAILURE",
  fetchProductV1pRequest: (request) => ({
    type: productActions.FETCH_PRODUCT_V1_P_REQUEST,
    payload: request,
  }),
  fetchProductV1pSuccess: (product) => ({
    type: productActions.FETCH_PRODUCT_V1_P_SUCCESS,
    payload: product,
  }),
  fetchProductV1pFailure: (error) => ({
    type: productActions.FETCH_PRODUCT_V1_P_FAILURE,
    payload: error,
  }),
  //  *********  添加 *******   //
  CREATE_PRODUCT_V1_P_REQUEST: "CREATE_PRODUCT_V1_P_REQUEST",
  CREATE_PRODUCT_V1_P_SUCCESS: "CREATE_PRODUCT_V1_P_SUCCESS",
  CREATE_PRODUCT_V1_P_FAILURE: "CREATE_PRODUCT_V1_P_FAILURE",
  createProductV1pRequest: (request) => ({
    type: productActions.CREATE_PRODUCT_V1_P_REQUEST,
    payload: request,
  }),
  createProductV1pSuccess: (product) => ({
    type: productActions.CREATE_PRODUCT_V1_P_SUCCESS,
    payload: product,
  }),
  createProductV1pFailure: (error) => ({
    type: productActions.CREATE_PRODUCT_V1_P_FAILURE,
    payload: error,
  }),
  //  *********  更新 *******   //
  EDIT_PRODUCT_V1_P_REQUEST: "EDIT_PRODUCT_V1_P_REQUEST",
  EDIT_PRODUCT_V1_P_SUCCESS: "EDIT_PRODUCT_V1_P_SUCCESS",
  EDIT_PRODUCT_V1_P_FAILURE: "EDIT_PRODUCT_V1_P_FAILURE",
  editProductV1pRequest: (request) => ({
    type: productActions.EDIT_PRODUCT_V1_P_REQUEST,
    payload: request,
  }),
  editProductV1pSuccess: (product) => ({
    type: productActions.EDIT_PRODUCT_V1_P_SUCCESS,
    payload: product,
  }),
  editProductV1pFailure: (error) => ({
    type: productActions.EDIT_PRODUCT_V1_P_FAILURE,
    payload: error,
  }),
  //  *********  删除 *******   //
  DELETE_PRODUCT_V1_P_REQUEST: "DELETE_PRODUCT_V1_P_REQUEST",
  DELETE_PRODUCT_V1_P_SUCCESS: "DELETE_PRODUCT_V1_P_SUCCESS",
  DELETE_PRODUCT_V1_P_FAILURE: "DELETE_PRODUCT_V1_P_FAILURE",
  deleteProductV1pRequest: (request) => ({
    type: productActions.DELETE_PRODUCT_V1_P_REQUEST,
    payload: request,
  }),
  deleteProductV1pSuccess: (product) => ({
    type: productActions.DELETE_PRODUCT_V1_P_SUCCESS,
    payload: product,
  }),
  deleteProductV1pFailure: (error) => ({
    type: productActions.DELETE_PRODUCT_V1_P_FAILURE,
    payload: error,
  }),

  //  *********  *******   //
  CHECK_FACTORYCODE_REQUEST2: "CHECK_FACTORYCODE_REQUEST2",
  CHECK_FACTORYCODE_SUCCESS2: "CHECK_FACTORYCODE_SUCCESS2",
  CHECK_FACTORYCODE_FAILURE2: "CHECK_FACTORYCODE_FAILURE2",
  checkFactoryCodeRequest2: (request) => ({
    type: productActions.CHECK_FACTORYCODE_REQUEST2,
    payload: request,
  }),
  checkFactoryCodeSuccess2: (product) => ({
    type: productActions.CHECK_FACTORYCODE_SUCCESS2,
    payload: product,
  }),
  checkFactoryCodeFailure2: (error) => ({
    type: productActions.CHECK_FACTORYCODE_FAILURE2,
    payload: error,
  }),

  // 新增 产品 文件
  ADD_PRODUCT_DATA_FILE_REQUEST2: "ADD_PRODUCT_DATA_FILE_REQUEST2",
  ADD_PRODUCT_DATA_FILE_SUCCESS2: "ADD_PRODUCT_DATA_FILE_SUCCESS2",
  ADD_PRODUCT_DATA_FILE_FAILURE2: "ADD_PRODUCT_DATA_FILE_FAILURE2",
  addproductDataFileRequest2: (request) => ({
    type: productActions.ADD_PRODUCT_DATA_FILE_REQUEST2,
    payload: request,
  }),
  addproductDataFileSuccess2: (product) => ({
    type: productActions.ADD_PRODUCT_DATA_FILE_SUCCESS2,
    payload: product,
  }),
  addproductDataFileFailure2: (error) => ({
    type: productActions.ADD_PRODUCT_DATA_FILE_FAILURE2,
    payload: error,
  }),

  //
  // 获取 Select
  //  *********  获取 Series Select 获取产品型号对应的组件信息 *******   //
  FETCH_SERIES_SELECT_REQUEST: "FETCH_SERIES_SELECT_REQUEST",
  FETCH_SERIES_SELECT_SUCCESS: "FETCH_SERIES_SELECT_SUCCESS",
  FETCH_SERIES_SELECT_FAILURE: "FETCH_SERIES_SELECT_FAILURE",
  fetchSeriesSelectRequest: (request) => ({
    type: productActions.FETCH_SERIES_SELECT_REQUEST,
    payload: request,
  }),
  fetchSeriesSelectSuccess: (product) => ({
    type: productActions.FETCH_SERIES_SELECT_SUCCESS,
    payload: product,
  }),
  fetchSeriesSelectFailure: (error) => ({
    type: productActions.FETCH_SERIES_SELECT_FAILURE,
    payload: error,
  }),
  //  *********  获取 Series Select 获取产品型号对应的组件信息 search *******   //
  FETCH_SERIES_SELECT_SEARCH_REQUEST: "FETCH_SERIES_SELECT_SEARCH_REQUEST",
  FETCH_SERIES_SELECT_SEARCH_SUCCESS: "FETCH_SERIES_SELECT_SEARCH_SUCCESS",
  FETCH_SERIES_SELECT_SEARCH_FAILURE: "FETCH_SERIES_SELECT_SEARCH_FAILURE",
  fetchSeriesSelectSearchRequest: (request) => ({
    type: productActions.FETCH_SERIES_SELECT_SEARCH_REQUEST,
    payload: request,
  }),
  fetchSeriesSelectSearchSuccess: (product) => ({
    type: productActions.FETCH_SERIES_SELECT_SEARCH_SUCCESS,
    payload: product,
  }),
  fetchSeriesSelectSearchFailure: (error) => ({
    type: productActions.FETCH_SERIES_SELECT_SEARCH_FAILURE,
    payload: error,
  }),
  //  *********  获取 history Select *******   //
  FETCH_HISTORY_SELECT_REQUEST: "FETCH_HISTORY_SELECT_REQUEST",
  FETCH_HISTORY_SELECT_SUCCESS: "FETCH_HISTORY_SELECT_SUCCESS",
  FETCH_HISTORY_SELECT_FAILURE: "FETCH_HISTORY_SELECT_FAILURE",
  fetchHistorySelectRequest: (request) => ({
    type: productActions.FETCH_HISTORY_SELECT_REQUEST,
    payload: request,
  }),
  fetchHistorySelectSuccess: (product) => ({
    type: productActions.FETCH_HISTORY_SELECT_SUCCESS,
    payload: product,
  }),
  fetchHistorySelectFailure: (error) => ({
    type: productActions.FETCH_HISTORY_SELECT_FAILURE,
    payload: error,
  }),
  //  *********  获取 Property 属性列表 Select *******   //
  FETCH_PROPERTY_SELECT_REQUEST: "FETCH_PROPERTY_SELECT_REQUEST",
  FETCH_PROPERTY_SELECT_SUCCESS: "FETCH_PROPERTY_SELECT_SUCCESS",
  FETCH_PROPERTY_SELECT_FAILURE: "FETCH_PROPERTY_SELECT_FAILURE",
  fetchPropertySelectRequest: (request) => ({
    type: productActions.FETCH_PROPERTY_SELECT_REQUEST,
    payload: request,
  }),
  fetchPropertySelectSuccess: (product) => ({
    type: productActions.FETCH_PROPERTY_SELECT_SUCCESS,
    payload: product,
  }),
  fetchPropertySelectFailure: (error) => ({
    type: productActions.FETCH_PROPERTY_SELECT_FAILURE,
    payload: error,
  }),
  //  *********  获取 Brands Select *******   //
  FETCH_BRANDS_SELECT_REQUEST2: "FETCH_BRANDS_SELECT_REQUEST2",
  FETCH_BRANDS_SELECT_SUCCESS2: "FETCH_BRANDS_SELECT_SUCCESS2",
  FETCH_BRANDS_SELECT_FAILURE2: "FETCH_BRANDS_SELECT_FAILURE2",
  fetchBrandsSelectRequest2: (request) => ({
    type: productActions.FETCH_BRANDS_SELECT_REQUEST2,
    payload: request,
  }),
  fetchBrandsSelectSuccess2: (product) => ({
    type: productActions.FETCH_BRANDS_SELECT_SUCCESS2,
    payload: product,
  }),
  fetchBrandsSelectFailure2: (error) => ({
    type: productActions.FETCH_BRANDS_SELECT_FAILURE2,
    payload: error,
  }),
  //  *********  获取 Categories Select *******   //
  FETCH_CATEGORY_SELECT_REQUEST2: "FETCH_CATEGORY_SELECT_REQUEST2",
  FETCH_CATEGORY_SELECT_SUCCESS2: "FETCH_CATEGORY_SELECT_SUCCESS2",
  FETCH_CATEGORY_SELECT_FAILURE2: "FETCH_CATEGORY_SELECT_FAILURE2",
  fetchCategoriesSelectRequest2: (request) => ({
    type: productActions.FETCH_CATEGORY_SELECT_REQUEST2,
    payload: request,
  }),
  fetchCategoriesSelectSuccess2: (product) => ({
    type: productActions.FETCH_CATEGORY_SELECT_SUCCESS2,
    payload: product,
  }),
  fetchCategoriesSelectFailure2: (error) => ({
    type: productActions.FETCH_CATEGORY_SELECT_FAILURE2,
    payload: error,
  }),
  //  *********  获取 Models Select *******   //
  FETCH_MODEL_SELECT_REQUEST2: "FETCH_MODEL_SELECT_REQUEST2",
  FETCH_MODEL_SELECT_SUCCESS2: "FETCH_MODEL_SELECT_SUCCESS2",
  FETCH_MODEL_SELECT_FAILURE2: "FETCH_MODEL_SELECT_FAILURE2",
  fetchModelsSelectRequest2: (request) => ({
    type: productActions.FETCH_MODEL_SELECT_REQUEST2,
    payload: request,
  }),
  fetchModelsSelectSuccess2: (product) => ({
    type: productActions.FETCH_MODEL_SELECT_SUCCESS2,
    payload: product,
  }),
  fetchModelsSelectFailure2: (error) => ({
    type: productActions.FETCH_MODEL_SELECT_FAILURE2,
    payload: error,
  }),
  //  *********  获取 Series Select *******   //
  FETCH_SERIES_SELECT_REQUEST2: "FETCH_SERIES_SELECT_REQUEST2",
  FETCH_SERIES_SELECT_SUCCESS2: "FETCH_SERIES_SELECT_SUCCESS2",
  FETCH_SERIES_SELECT_FAILURE2: "FETCH_SERIES_SELECT_FAILURE2",
  fetchSeriesSelectRequest2: (request) => ({
    type: productActions.FETCH_SERIES_SELECT_REQUEST2,
    payload: request,
  }),
  fetchSeriesSelectSuccess2: (product) => ({
    type: productActions.FETCH_SERIES_SELECT_SUCCESS2,
    payload: product,
  }),
  fetchSeriesSelectFailure2: (error) => ({
    type: productActions.FETCH_SERIES_SELECT_FAILURE2,
    payload: error,
  }),
  //  *********  获取 client Client Select *******   //
  FETCH_CLIENT_SELECT_REQUEST2: "FETCH_CLIENT_SELECT_REQUEST2",
  FETCH_CLIENT_SELECT_SUCCESS2: "FETCH_CLIENT_SELECT_SUCCESS2",
  FETCH_CLIENT_SELECT_FAILURE2: "FETCH_CLIENT_SELECT_FAILURE2",
  fetchClientSelectRequest2: (request) => ({
    type: productActions.FETCH_CLIENT_SELECT_REQUEST2,
    payload: request,
  }),
  fetchClientSelectSuccess2: (product) => ({
    type: productActions.FETCH_CLIENT_SELECT_SUCCESS2,
    payload: product,
  }),
  fetchClientSelectFailure2: (error) => ({
    type: productActions.FETCH_CLIENT_SELECT_FAILURE2,
    payload: error,
  }),

  //
  // 新增
  //  *********  新增 Product Brand v2 *******   //
  CREATE_PRODUCT_BRAND_REQUEST2: "CREATE_PRODUCT_BRAND_REQUEST2",
  CREATE_PRODUCT_BRAND_SUCCESS2: "CREATE_PRODUCT_BRAND_SUCCESS2",
  CREATE_PRODUCT_BRAND_FAILURE2: "CREATE_PRODUCT_BRAND_FAILURE2",
  createProductBrandRequest2: (request) => ({
    type: productActions.CREATE_PRODUCT_BRAND_REQUEST2,
    payload: request,
  }),
  createProductBrandSuccess2: (res) => ({
    type: productActions.CREATE_PRODUCT_BRAND_SUCCESS2,
    payload: res,
  }),
  createProductBrandFailure2: (error) => ({
    type: productActions.CREATE_PRODUCT_BRAND_FAILURE2,
    payload: error,
  }),
  //  *********  新增 Product Category v2 *******   //
  CREATE_PRODUCT_CATEGORY_REQUEST2: "CREATE_PRODUCT_CATEGORY_REQUEST2",
  CREATE_PRODUCT_CATEGORY_SUCCESS2: "CREATE_PRODUCT_CATEGORY_SUCCESS2",
  CREATE_PRODUCT_CATEGORY_FAILURE2: "CREATE_PRODUCT_CATEGORY_FAILURE2",
  createProductCategoryRequest2: (request) => ({
    type: productActions.CREATE_PRODUCT_CATEGORY_REQUEST2,
    payload: request,
  }),
  createProductCategorySuccess2: (res) => ({
    type: productActions.CREATE_PRODUCT_CATEGORY_SUCCESS2,
    payload: res,
  }),
  createProductCategoryFailure2: (error) => ({
    type: productActions.CREATE_PRODUCT_CATEGORY_FAILURE2,
    payload: error,
  }),
  //  *********  新增 Product Model v2 *******   //
  CREATE_PRODUCT_MODEL_REQUEST2: "CREATE_PRODUCT_MODEL_REQUEST2",
  CREATE_PRODUCT_MODEL_SUCCESS2: "CREATE_PRODUCT_MODEL_SUCCESS2",
  CREATE_PRODUCT_MODEL_FAILURE2: "CREATE_PRODUCT_MODEL_FAILURE2",
  createProductModelRequest2: (request) => ({
    type: productActions.CREATE_PRODUCT_MODEL_REQUEST2,
    payload: request,
  }),
  createProductModelSuccess2: (res) => ({
    type: productActions.CREATE_PRODUCT_MODEL_SUCCESS2,
    payload: res,
  }),
  createProductModelFailure2: (error) => ({
    type: productActions.CREATE_PRODUCT_MODEL_FAILURE2,
    payload: error,
  }),
  //  *********  新增 Product Series v2 *******   //
  CREATE_PRODUCT_SERIES_REQUEST2: "CREATE_PRODUCT_SERIES_REQUEST2",
  CREATE_PRODUCT_SERIES_SUCCESS2: "CREATE_PRODUCT_SERIES_SUCCESS2",
  CREATE_PRODUCT_SERIES_FAILURE2: "CREATE_PRODUCT_SERIES_FAILURE2",
  createProductSeriesRequest2: (request) => ({
    type: productActions.CREATE_PRODUCT_SERIES_REQUEST2,
    payload: request,
  }),
  createProductSeriesSuccess2: (res) => ({
    type: productActions.CREATE_PRODUCT_SERIES_SUCCESS2,
    payload: res,
  }),
  createProductSeriesFailure2: (error) => ({
    type: productActions.CREATE_PRODUCT_SERIES_FAILURE2,
    payload: error,
  }),
  //  *********  新增 Product client Client v2 *******   //
  CREATE_PRODUCT_CLIENT_REQUEST2: "CREATE_PRODUCT_CLIENT_REQUEST2",
  CREATE_PRODUCT_CLIENT_SUCCESS2: "CREATE_PRODUCT_CLIENT_SUCCESS2",
  CREATE_PRODUCT_CLIENT_FAILURE2: "CREATE_PRODUCT_CLIENT_FAILURE2",
  createProductClientRequest2: (request) => ({
    type: productActions.CREATE_PRODUCT_CLIENT_REQUEST2,
    payload: request,
  }),
  createProductClientSuccess2: (res) => ({
    type: productActions.CREATE_PRODUCT_CLIENT_SUCCESS2,
    payload: res,
  }),
  createProductClientFailure2: (error) => ({
    type: productActions.CREATE_PRODUCT_CLIENT_FAILURE2,
    payload: error,
  }),
  //  *********  新增 Tsl 属性/1 *******   //
  CREATE_TSL_1_REQUEST: "CREATE_TSL_1_REQUEST",
  CREATE_TSL_1_SUCCESS: "CREATE_TSL_1_SUCCESS",
  CREATE_TSL_1_FAILURE: "CREATE_TSL_1_FAILURE",
  createTsl1Request: (request) => ({
    type: productActions.CREATE_TSL_1_REQUEST,
    payload: request,
  }),
  createTsl1Success: (res) => ({
    type: productActions.CREATE_TSL_1_SUCCESS,
    payload: res,
  }),
  createTsl1Failure: (error) => ({
    type: productActions.CREATE_TSL_1_FAILURE,
    payload: error,
  }),
  //  *********  新增 Tsl 服务/2 *******   //
  CREATE_TSL_2_REQUEST: "CREATE_TSL_2_REQUEST",
  CREATE_TSL_2_SUCCESS: "CREATE_TSL_2_SUCCESS",
  CREATE_TSL_2_FAILURE: "CREATE_TSL_2_FAILURE",
  createTsl2Request: (request) => ({
    type: productActions.CREATE_TSL_2_REQUEST,
    payload: request,
  }),
  createTsl2Success: (res) => ({
    type: productActions.CREATE_TSL_2_SUCCESS,
    payload: res,
  }),
  createTsl2Failure: (error) => ({
    type: productActions.CREATE_TSL_2_FAILURE,
    payload: error,
  }),
  //  *********  新增 Tsl 事件/3 *******   //
  CREATE_TSL_3_REQUEST: "CREATE_TSL_3_REQUEST",
  CREATE_TSL_3_SUCCESS: "CREATE_TSL_3_SUCCESS",
  CREATE_TSL_3_FAILURE: "CREATE_TSL_3_FAILURE",
  createTsl3Request: (request) => ({
    type: productActions.CREATE_TSL_3_REQUEST,
    payload: request,
  }),
  createTsl3Success: (res) => ({
    type: productActions.CREATE_TSL_3_SUCCESS,
    payload: res,
  }),
  createTsl3Failure: (error) => ({
    type: productActions.CREATE_TSL_3_FAILURE,
    payload: error,
  }),
  //  *********  获取 发布草稿 *******   //
  CREATE_TSL_CRAFT_REQUEST: "CREATE_TSL_CRAFT_REQUEST",
  CREATE_TSL_CRAFT_SUCCESS: "CREATE_TSL_CRAFT_SUCCESS",
  CREATE_TSL_CRAFT_FAILURE: "CREATE_TSL_CRAFT_FAILURE",
  createTslCraftRequest: (request) => ({
    type: productActions.CREATE_TSL_CRAFT_REQUEST,
    payload: request,
  }),
  createTslCraftSuccess: (product) => ({
    type: productActions.CREATE_TSL_CRAFT_SUCCESS,
    payload: product,
  }),
  createTslCraftFailure: (error) => ({
    type: productActions.CREATE_TSL_CRAFT_FAILURE,
    payload: error,
  }),
  //  *********  获取 发布草稿 *******   //
  RECOVERY_TSL_REQUEST: "RECOVERY_TSL_REQUEST",
  RECOVERY_TSL_SUCCESS: "RECOVERY_TSL_SUCCESS",
  RECOVERY_TSL_FAILURE: "RECOVERY_TSL_FAILURE",
  recoveryTslRequest: (request) => ({
    type: productActions.RECOVERY_TSL_REQUEST,
    payload: request,
  }),
  recoveryTslSuccess: (product) => ({
    type: productActions.RECOVERY_TSL_SUCCESS,
    payload: product,
  }),
  recoveryTslFailure: (error) => ({
    type: productActions.RECOVERY_TSL_FAILURE,
    payload: error,
  }),

  //
  // 编辑
  //  ********* 编辑 Product Brand v2 *******   //
  EDIT_PRODUCT_BRAND_REQUEST2: "EDIT_PRODUCT_BRAND_REQUEST2",
  EDIT_PRODUCT_BRAND_SUCCESS2: "EDIT_PRODUCT_BRAND_SUCCESS2",
  EDIT_PRODUCT_BRAND_FAILURE2: "EDIT_PRODUCT_BRAND_FAILURE2",
  editProductBrandRequest2: (request) => ({
    type: productActions.EDIT_PRODUCT_BRAND_REQUEST2,
    payload: request,
  }),
  editProductBrandSuccess2: (res) => ({
    type: productActions.EDIT_PRODUCT_BRAND_SUCCESS2,
    payload: res,
  }),
  editProductBrandFailure2: (error) => ({
    type: productActions.EDIT_PRODUCT_BRAND_FAILURE2,
    payload: error,
  }),
  //  *********  编辑 Product Category v2 *******   //
  EDIT_PRODUCT_CATEGORY_REQUEST2: "EDIT_PRODUCT_CATEGORY_REQUEST2",
  EDIT_PRODUCT_CATEGORY_SUCCESS2: "EDIT_PRODUCT_CATEGORY_SUCCESS2",
  EDIT_PRODUCT_CATEGORY_FAILURE2: "EDIT_PRODUCT_CATEGORY_FAILURE2",
  editProductCategoryRequest2: (request) => ({
    type: productActions.EDIT_PRODUCT_CATEGORY_REQUEST2,
    payload: request,
  }),
  editProductCategorySuccess2: (res) => ({
    type: productActions.EDIT_PRODUCT_CATEGORY_SUCCESS2,
    payload: res,
  }),
  editProductCategoryFailure2: (error) => ({
    type: productActions.EDIT_PRODUCT_CATEGORY_FAILURE2,
    payload: error,
  }),
  //  *********  编辑 Product Model v2 *******   //
  EDIT_PRODUCT_MODEL_REQUEST2: "EDIT_PRODUCT_MODEL_REQUEST2",
  EDIT_PRODUCT_MODEL_SUCCESS2: "EDIT_PRODUCT_MODEL_SUCCESS2",
  EDIT_PRODUCT_MODEL_FAILURE2: "EDIT_PRODUCT_MODEL_FAILURE2",
  editProductModelRequest2: (request) => ({
    type: productActions.EDIT_PRODUCT_MODEL_REQUEST2,
    payload: request,
  }),
  editProductModelSuccess2: (res) => ({
    type: productActions.EDIT_PRODUCT_MODEL_SUCCESS2,
    payload: res,
  }),
  editProductModelFailure2: (error) => ({
    type: productActions.EDIT_PRODUCT_MODEL_FAILURE2,
    payload: error,
  }),
  //  *********  编辑 Product Series v2 *******   //
  EDIT_PRODUCT_SERIES_REQUEST2: "EDIT_PRODUCT_SERIES_REQUEST2",
  EDIT_PRODUCT_SERIES_SUCCESS2: "EDIT_PRODUCT_SERIES_SUCCESS2",
  EDIT_PRODUCT_SERIES_FAILURE2: "EDIT_PRODUCT_SERIES_FAILURE2",
  editProductSeriesRequest2: (request) => ({
    type: productActions.EDIT_PRODUCT_SERIES_REQUEST2,
    payload: request,
  }),
  editProductSeriesSuccess2: (res) => ({
    type: productActions.EDIT_PRODUCT_SERIES_SUCCESS2,
    payload: res,
  }),
  editProductSeriesFailure2: (error) => ({
    type: productActions.EDIT_PRODUCT_SERIES_FAILURE2,
    payload: error,
  }),
  //  *********  编辑 Product client v2 *******   //
  EDIT_PRODUCT_CLIENT_REQUEST2: "EDIT_PRODUCT_CLIENT_REQUEST2",
  EDIT_PRODUCT_CLIENT_SUCCESS2: "EDIT_PRODUCT_CLIENT_SUCCESS2",
  EDIT_PRODUCT_CLIENT_FAILURE2: "EDIT_PRODUCT_CLIENT_FAILURE2",
  editProductClientRequest2: (request) => ({
    type: productActions.EDIT_PRODUCT_CLIENT_REQUEST2,
    payload: request,
  }),
  editProductClientSuccess2: (res) => ({
    type: productActions.EDIT_PRODUCT_CLIENT_SUCCESS2,
    payload: res,
  }),
  editProductClientFailure2: (error) => ({
    type: productActions.EDIT_PRODUCT_CLIENT_FAILURE2,
    payload: error,
  }),
  //  *********  编辑 Tsl 属性/1 *******   //
  EDIT_TSL_1_REQUEST: "EDIT_TSL_1_REQUEST",
  EDIT_TSL_1_SUCCESS: "EDIT_TSL_1_SUCCESS",
  EDIT_TSL_1_FAILURE: "EDIT_TSL_1_FAILURE",
  editTsl1Request: (request) => ({
    type: productActions.EDIT_TSL_1_REQUEST,
    payload: request,
  }),
  editTsl1Success: (res) => ({
    type: productActions.EDIT_TSL_1_SUCCESS,
    payload: res,
  }),
  editTsl1Failure: (error) => ({
    type: productActions.EDIT_TSL_1_FAILURE,
    payload: error,
  }),
  //  *********  编辑 Tsl 服务/2 *******   //
  EDIT_TSL_2_REQUEST: "EDIT_TSL_2_REQUEST",
  EDIT_TSL_2_SUCCESS: "EDIT_TSL_2_SUCCESS",
  EDIT_TSL_2_FAILURE: "EDIT_TSL_2_FAILURE",
  editTsl2Request: (request) => ({
    type: productActions.EDIT_TSL_2_REQUEST,
    payload: request,
  }),
  editTsl2Success: (res) => ({
    type: productActions.EDIT_TSL_2_SUCCESS,
    payload: res,
  }),
  editTsl2Failure: (error) => ({
    type: productActions.EDIT_TSL_2_FAILURE,
    payload: error,
  }),
  //  *********  编辑 Tsl 事件/3 *******   //
  EDIT_TSL_3_REQUEST: "EDIT_TSL_3_REQUEST",
  EDIT_TSL_3_SUCCESS: "EDIT_TSL_3_SUCCESS",
  EDIT_TSL_3_FAILURE: "EDIT_TSL_3_FAILURE",
  editTsl3Request: (request) => ({
    type: productActions.EDIT_TSL_3_REQUEST,
    payload: request,
  }),
  editTsl3Success: (res) => ({
    type: productActions.EDIT_TSL_3_SUCCESS,
    payload: res,
  }),
  editTsl3Failure: (error) => ({
    type: productActions.EDIT_TSL_3_FAILURE,
    payload: error,
  }),

  //
  // 删除
  //  *********  删除 Product Brand v2 *******   //
  DELETE_PRODUCT_BRAND_REQUEST2: "DELETE_PRODUCT_BRAND_REQUEST2",
  DELETE_PRODUCT_BRAND_SUCCESS2: "DELETE_PRODUCT_BRAND_SUCCESS2",
  DELETE_PRODUCT_BRAND_FAILURE2: "DELETE_PRODUCT_BRAND_FAILURE2",
  deleteProductBrandRequest2: (request) => ({
    type: productActions.DELETE_PRODUCT_BRAND_REQUEST2,
    payload: request,
  }),
  deleteProductBrandSuccess2: (res) => ({
    type: productActions.DELETE_PRODUCT_BRAND_SUCCESS2,
    payload: res,
  }),
  deleteProductBrandFailure2: (error) => ({
    type: productActions.DELETE_PRODUCT_BRAND_FAILURE2,
    payload: error,
  }),
  //  *********  删除 Product Category v2 *******   //
  DELETE_PRODUCT_CATEGORY_REQUEST2: "DELETE_PRODUCT_CATEGORY_REQUEST2",
  DELETE_PRODUCT_CATEGORY_SUCCESS2: "DELETE_PRODUCT_CATEGORY_SUCCESS2",
  DELETE_PRODUCT_CATEGORY_FAILURE2: "DELETE_PRODUCT_CATEGORY_FAILURE2",
  deleteProductCategoryRequest2: (request) => ({
    type: productActions.DELETE_PRODUCT_CATEGORY_REQUEST2,
    payload: request,
  }),
  deleteProductCategorySuccess2: (res) => ({
    type: productActions.DELETE_PRODUCT_CATEGORY_SUCCESS2,
    payload: res,
  }),
  deleteProductCategoryFailure2: (error) => ({
    type: productActions.DELETE_PRODUCT_CATEGORY_FAILURE2,
    payload: error,
  }),
  //  *********  删除 Product Model v2 *******   //
  DELETE_PRODUCT_MODEL_REQUEST2: "DELETE_PRODUCT_MODEL_REQUEST2",
  DELETE_PRODUCT_MODEL_SUCCESS2: "DELETE_PRODUCT_MODEL_SUCCESS2",
  DELETE_PRODUCT_MODEL_FAILURE2: "DELETE_PRODUCT_MODEL_FAILURE2",
  deleteProductModelRequest2: (request) => ({
    type: productActions.DELETE_PRODUCT_MODEL_REQUEST2,
    payload: request,
  }),
  deleteProductModelSuccess2: (res) => ({
    type: productActions.DELETE_PRODUCT_MODEL_SUCCESS2,
    payload: res,
  }),
  deleteProductModelFailure2: (error) => ({
    type: productActions.DELETE_PRODUCT_MODEL_FAILURE2,
    payload: error,
  }),
  //  *********  删除 Product Series v2 *******   //
  DELETE_PRODUCT_SERIES_REQUEST2: "DELETE_PRODUCT_SERIES_REQUEST2",
  DELETE_PRODUCT_SERIES_SUCCESS2: "DELETE_PRODUCT_SERIES_SUCCESS2",
  DELETE_PRODUCT_SERIES_FAILURE2: "DELETE_PRODUCT_SERIES_FAILURE2",
  deleteProductSeriesRequest2: (request) => ({
    type: productActions.DELETE_PRODUCT_SERIES_REQUEST2,
    payload: request,
  }),
  deleteProductSeriesSuccess2: (res) => ({
    type: productActions.DELETE_PRODUCT_SERIES_SUCCESS2,
    payload: res,
  }),
  deleteProductSeriesFailure2: (error) => ({
    type: productActions.DELETE_PRODUCT_SERIES_FAILURE2,
    payload: error,
  }),
  //  *********  删除 Product client v2 *******   //
  DELETE_PRODUCT_CLIENT_REQUEST2: "DELETE_PRODUCT_CLIENT_REQUEST2",
  DELETE_PRODUCT_CLIENT_SUCCESS2: "DELETE_PRODUCT_CLIENT_SUCCESS2",
  DELETE_PRODUCT_CLIENT_FAILURE2: "DELETE_PRODUCT_CLIENT_FAILURE2",
  deleteProductClientRequest2: (request) => ({
    type: productActions.DELETE_PRODUCT_CLIENT_REQUEST2,
    payload: request,
  }),
  deleteProductClientSuccess2: (res) => ({
    type: productActions.DELETE_PRODUCT_CLIENT_SUCCESS2,
    payload: res,
  }),
  deleteProductClientFailure2: (error) => ({
    type: productActions.DELETE_PRODUCT_CLIENT_FAILURE2,
    payload: error,
  }),
  //  *********  删除 Tsl 属性/1 *******   //
  DELETE_TSL_1_REQUEST: "DELETE_TSL_1_REQUEST",
  DELETE_TSL_1_SUCCESS: "DELETE_TSL_1_SUCCESS",
  DELETE_TSL_1_FAILURE: "DELETE_TSL_1_FAILURE",
  deleteTsl1Request: (request) => ({
    type: productActions.DELETE_TSL_1_REQUEST,
    payload: request,
  }),
  deleteTsl1Success: (res) => ({
    type: productActions.DELETE_TSL_1_SUCCESS,
    payload: res,
  }),
  deleteTsl1Failure: (error) => ({
    type: productActions.DELETE_TSL_1_FAILURE,
    payload: error,
  }),
  //  *********  删除 Tsl 服务/2 *******   //
  DELETE_TSL_2_REQUEST: "DELETE_TSL_2_REQUEST",
  DELETE_TSL_2_SUCCESS: "DELETE_TSL_2_SUCCESS",
  DELETE_TSL_2_FAILURE: "DELETE_TSL_2_FAILURE",
  deleteTsl2Request: (request) => ({
    type: productActions.DELETE_TSL_2_REQUEST,
    payload: request,
  }),
  deleteTsl2Success: (res) => ({
    type: productActions.DELETE_TSL_2_SUCCESS,
    payload: res,
  }),
  deleteTsl2Failure: (error) => ({
    type: productActions.DELETE_TSL_2_FAILURE,
    payload: error,
  }),
  //  *********  删除 Tsl 事件/3 *******   //
  DELETE_TSL_3_REQUEST: "DELETE_TSL_3_REQUEST",
  DELETE_TSL_3_SUCCESS: "DELETE_TSL_3_SUCCESS",
  DELETE_TSL_3_FAILURE: "DELETE_TSL_3_FAILURE",
  deleteTsl3Request: (request) => ({
    type: productActions.DELETE_TSL_3_REQUEST,
    payload: request,
  }),
  deleteTsl3Success: (res) => ({
    type: productActions.DELETE_TSL_3_SUCCESS,
    payload: res,
  }),
  deleteTsl3Failure: (error) => ({
    type: productActions.DELETE_TSL_3_FAILURE,
    payload: error,
  }),

  //
  // 获取 List
  //  *********  获取 Brands List *******   //
  FETCH_BRANDS_REQUEST2: "FETCH_BRANDS_REQUEST2",
  FETCH_BRANDS_SUCCESS2: "FETCH_BRANDS_SUCCESS2",
  FETCH_BRANDS_FAILURE2: "FETCH_BRANDS_FAILURE2",
  fetchBrandsRequest2: (request) => ({
    type: productActions.FETCH_BRANDS_REQUEST2,
    payload: request,
  }),
  fetchBrandsSuccess2: (product) => ({
    type: productActions.FETCH_BRANDS_SUCCESS2,
    payload: product,
  }),
  fetchBrandsFailure2: (error) => ({
    type: productActions.FETCH_BRANDS_FAILURE2,
    payload: error,
  }),
  //  *********  获取 Categories List *******   //
  FETCH_CATEGORY_REQUEST2: "FETCH_CATEGORY_REQUEST2",
  FETCH_CATEGORY_SUCCESS2: "FETCH_CATEGORY_SUCCESS2",
  FETCH_CATEGORY_FAILURE2: "FETCH_CATEGORY_FAILURE2",
  fetchCategoriesRequest2: (request) => ({
    type: productActions.FETCH_CATEGORY_REQUEST2,
    payload: request,
  }),
  fetchCategoriesSuccess2: (product) => ({
    type: productActions.FETCH_CATEGORY_SUCCESS2,
    payload: product,
  }),
  fetchCategoriesFailure2: (error) => ({
    type: productActions.FETCH_CATEGORY_FAILURE2,
    payload: error,
  }),
  //  *********  获取 Models List *******   //
  FETCH_MODEL_REQUEST2: "FETCH_MODEL_REQUEST2",
  FETCH_MODEL_SUCCESS2: "FETCH_MODEL_SUCCESS2",
  FETCH_MODEL_FAILURE2: "FETCH_MODEL_FAILURE2",
  fetchModelsRequest2: (request) => ({
    type: productActions.FETCH_MODEL_REQUEST2,
    payload: request,
  }),
  fetchModelsSuccess2: (product) => ({
    type: productActions.FETCH_MODEL_SUCCESS2,
    payload: product,
  }),
  fetchModelsFailure2: (error) => ({
    type: productActions.FETCH_MODEL_FAILURE2,
    payload: error,
  }),
  //  *********  获取 Series List *******   //
  FETCH_SERIES_REQUEST2: "FETCH_SERIES_REQUEST2",
  FETCH_SERIES_SUCCESS2: "FETCH_SERIES_SUCCESS2",
  FETCH_SERIES_FAILURE2: "FETCH_SERIES_FAILURE2",
  fetchSeriesRequest2: (request) => ({
    type: productActions.FETCH_SERIES_REQUEST2,
    payload: request,
  }),
  fetchSeriesSuccess2: (product) => ({
    type: productActions.FETCH_SERIES_SUCCESS2,
    payload: product,
  }),
  fetchSeriesFailure2: (error) => ({
    type: productActions.FETCH_SERIES_FAILURE2,
    payload: error,
  }),
  //  *********  获取 Client List *******   //
  FETCH_CLIENT_REQUEST2: "FETCH_CLIENT_REQUEST2",
  FETCH_CLIENT_SUCCESS2: "FETCH_CLIENT_SUCCESS2",
  FETCH_CLIENT_FAILURE2: "FETCH_CLIENT_FAILURE2",
  fetchClientRequest2: (request) => ({
    type: productActions.FETCH_CLIENT_REQUEST2,
    payload: request,
  }),
  fetchClientSuccess2: (product) => ({
    type: productActions.FETCH_CLIENT_SUCCESS2,
    payload: product,
  }),
  fetchClientFailure2: (error) => ({
    type: productActions.FETCH_CLIENT_FAILURE2,
    payload: error,
  }),

  //
  // 详情
  //  *********  获取 Product Series 详情 v2 *******   //
  DETAIL_PRODUCT_SERIES_REQUEST2: "DETAIL_PRODUCT_SERIES_REQUEST2",
  DETAIL_PRODUCT_SERIES_SUCCESS2: "DETAIL_PRODUCT_SERIES_SUCCESS2",
  DETAIL_PRODUCT_SERIES_FAILURE2: "DETAIL_PRODUCT_SERIES_FAILURE2",
  fetchSeriesDetailRequest2: (request) => ({
    type: productActions.DETAIL_PRODUCT_SERIES_REQUEST2,
    payload: request,
  }),
  fetchSeriesDetailSuccess2: (product) => ({
    type: productActions.DETAIL_PRODUCT_SERIES_SUCCESS2,
    payload: product,
  }),
  fetchSeriesDetailFailure2: (error) => ({
    type: productActions.DETAIL_PRODUCT_SERIES_FAILURE2,
    payload: error,
  }),
  //  *********  获取 Tsl 属性/1 详情 *******   //
  DETAIL_TSL_1_REQUEST: "DETAIL_TSL_1_REQUEST",
  DETAIL_TSL_1_SUCCESS: "DETAIL_TSL_1_SUCCESS",
  DETAIL_TSL_1_FAILURE: "DETAIL_TSL_1_FAILURE",
  detailTsl1Request: (request) => ({
    type: productActions.DETAIL_TSL_1_REQUEST,
    payload: request,
  }),
  detailTsl1Success: (res) => ({
    type: productActions.DETAIL_TSL_1_SUCCESS,
    payload: res,
  }),
  detailTsl1Failure: (error) => ({
    type: productActions.DETAIL_TSL_1_FAILURE,
    payload: error,
  }),
  //  *********  获取 Tsl 服务/2 详情 *******   //
  DETAIL_TSL_2_REQUEST: "DETAIL_TSL_2_REQUEST",
  DETAIL_TSL_2_SUCCESS: "DETAIL_TSL_2_SUCCESS",
  DETAIL_TSL_2_FAILURE: "DETAIL_TSL_2_FAILURE",
  detailTsl2Request: (request) => ({
    type: productActions.DETAIL_TSL_2_REQUEST,
    payload: request,
  }),
  detailTsl2Success: (res) => ({
    type: productActions.DETAIL_TSL_2_SUCCESS,
    payload: res,
  }),
  detailTsl2Failure: (error) => ({
    type: productActions.DETAIL_TSL_2_FAILURE,
    payload: error,
  }),
  //  *********  获取 Tsl 事件/3 详情 *******   //
  DETAIL_TSL_3_REQUEST: "DETAIL_TSL_3_REQUEST",
  DETAIL_TSL_3_SUCCESS: "DETAIL_TSL_3_SUCCESS",
  DETAIL_TSL_3_FAILURE: "DETAIL_TSL_3_FAILURE",
  detailTsl3Request: (request) => ({
    type: productActions.DETAIL_TSL_3_REQUEST,
    payload: request,
  }),
  detailTsl3Success: (res) => ({
    type: productActions.DETAIL_TSL_3_SUCCESS,
    payload: res,
  }),
  detailTsl3Failure: (error) => ({
    type: productActions.DETAIL_TSL_3_FAILURE,
    payload: error,
  }),

  //
  // 产品型号
  //  *********  copy 产品型号 *******   //
  COPY_TYPE_REQUEST2: "COPY_TYPE_REQUEST2",
  COPY_TYPE_SUCCESS2: "COPY_TYPE_SUCCESS2",
  COPY_TYPE_FAILURE2: "COPY_TYPE_FAILURE2",
  copyTypesRequest2: (request) => ({
    type: productActions.COPY_TYPE_REQUEST2,
    payload: request,
  }),
  copyTypesSuccess2: (product) => ({
    type: productActions.COPY_TYPE_SUCCESS2,
    payload: product,
  }),
  copyTypesFailure2: (error) => ({
    type: productActions.COPY_TYPE_FAILURE2,
    payload: error,
  }),
  //  *********  新增 产品型号 *******   //
  CREATE_TYPE_REQUEST2: "CREATE_TYPE_REQUEST2",
  CREATE_TYPE_SUCCESS2: "CREATE_TYPE_SUCCESS2",
  CREATE_TYPE_FAILURE2: "CREATE_TYPE_FAILURE2",
  createTypesRequest2: (request) => ({
    type: productActions.CREATE_TYPE_REQUEST2,
    payload: request,
  }),
  createTypesSuccess2: (product) => ({
    type: productActions.CREATE_TYPE_SUCCESS2,
    payload: product,
  }),
  createTypesFailure2: (error) => ({
    type: productActions.CREATE_TYPE_FAILURE2,
    payload: error,
  }),
  //  *********  编辑 产品型号 *******   //
  EDIT_TYPE_REQUEST2: "EDIT_TYPE_REQUEST2",
  EDIT_TYPE_SUCCESS2: "EDIT_TYPE_SUCCESS2",
  EDIT_TYPE_FAILURE2: "EDIT_TYPE_FAILURE2",
  editTypesRequest2: (request) => ({
    type: productActions.EDIT_TYPE_REQUEST2,
    payload: request,
  }),
  editTypesSuccess2: (product) => ({
    type: productActions.EDIT_TYPE_SUCCESS2,
    payload: product,
  }),
  editTypesFailure2: (error) => ({
    type: productActions.EDIT_TYPE_FAILURE2,
    payload: error,
  }),
  //  *********  删除 产品型号 *******   //
  DELETE_TYPE_REQUEST2: "DELETE_TYPE_REQUEST2",
  DELETE_TYPE_SUCCESS2: "DELETE_TYPE_SUCCESS2",
  DELETE_TYPE_FAILURE2: "DELETE_TYPE_FAILURE2",
  deleteTypesRequest2: (request) => ({
    type: productActions.DELETE_TYPE_REQUEST2,
    payload: request,
  }),
  deleteTypesSuccess2: (product) => ({
    type: productActions.DELETE_TYPE_SUCCESS2,
    payload: product,
  }),
  deleteTypesFailure2: (error) => ({
    type: productActions.DELETE_TYPE_FAILURE2,
    payload: error,
  }),

  //  *********  获取 产品型号 下拉框 *******   //
  FETCH_TYPE_SELECT: "FETCH_TYPE_SELECT",
  FETCH_TYPE_SELECT_SUSSESS: "FETCH_TYPE_SELECT_SUSSESS",
  FETCH_TYPE_SELECT_FAILURE: "FETCH_TYPE_SELECT_FAILURE",
  fetchTypeSelect: () => ({
    type: productActions.FETCH_TYPE_SELECT,
  }),
  fetchTypesSelectSuccess: (product) => ({
    type: productActions.FETCH_TYPE_SELECT_SUSSESS,
    payload: product,
  }),
  fetchTypesSelectFailure: (error) => ({
    type: productActions.FETCH_TYPE_SELECT_FAILURE,
    payload: error,
  }),

  //  *********  获取 产品型号 List *******   //
  FETCH_TYPE_REQUEST2: "FETCH_TYPE_REQUEST2",
  FETCH_TYPE_SUCCESS2: "FETCH_TYPE_SUCCESS2",
  FETCH_TYPE_FAILURE2: "FETCH_TYPE_FAILURE2",
  fetchTypesRequest2: (request) => ({
    type: productActions.FETCH_TYPE_REQUEST2,
    payload: request,
  }),
  fetchTypesSuccess2: (product) => ({
    type: productActions.FETCH_TYPE_SUCCESS2,
    payload: product,
  }),
  fetchTypesFailure2: (error) => ({
    type: productActions.FETCH_TYPE_FAILURE2,
    payload: error,
  }),
  //  *********  获取 产品型号 detail *******   //
  FETCH_TYPE_DETAIL_REQUEST2: "FETCH_TYPE_DETAIL_REQUEST2",
  FETCH_TYPE_DETAIL_SUCCESS2: "FETCH_TYPE_DETAIL_SUCCESS2",
  FETCH_TYPE_DETAIL_FAILURE2: "FETCH_TYPE_DETAIL_FAILURE2",
  fetchTypesDetailRequest2: (request) => ({
    type: productActions.FETCH_TYPE_DETAIL_REQUEST2,
    payload: request,
  }),
  fetchTypesDetailSuccess2: (product) => ({
    type: productActions.FETCH_TYPE_DETAIL_SUCCESS2,
    payload: product,
  }),
  fetchTypesDetailFailure2: (error) => ({
    type: productActions.FETCH_TYPE_DETAIL_FAILURE2,
    payload: error,
  }),
  //  *********  获取 产品型号 detail *******   //
  FETCH_TYPE_DETAIL_REQUEST2_V1: "FETCH_TYPE_DETAIL_REQUEST2_V1",
  FETCH_TYPE_DETAIL_SUCCESS2_V1: "FETCH_TYPE_DETAIL_SUCCESS2_V1",
  FETCH_TYPE_DETAIL_FAILURE2_V1: "FETCH_TYPE_DETAIL_FAILURE2_V1",
  fetchTypesDetailRequest2_V1: (request) => ({
    type: productActions.FETCH_TYPE_DETAIL_REQUEST2_V1,
    payload: request,
  }),
  fetchTypesDetailSuccess2_V1: (product) => ({
    type: productActions.FETCH_TYPE_DETAIL_SUCCESS2_V1,
    payload: product,
  }),
  fetchTypesDetailFailure2_V1: (error) => ({
    type: productActions.FETCH_TYPE_DETAIL_FAILURE2_V1,
    payload: error,
  }),

  // 物模型
  //  *********  获取 功能列表 list *******   //
  FETCH_TSL_FUNCTION_REQUEST: "FETCH_TSL_FUNCTION_REQUEST",
  FETCH_TSL_FUNCTION_SUCCESS: "FETCH_TSL_FUNCTION_SUCCESS",
  FETCH_TSL_FUNCTION_FAILURE: "FETCH_TSL_FUNCTION_FAILURE",
  fetchTslFunctionRequest: (request) => ({
    type: productActions.FETCH_TSL_FUNCTION_REQUEST,
    payload: request,
  }),
  fetchTslFunctionSuccess: (product) => ({
    type: productActions.FETCH_TSL_FUNCTION_SUCCESS,
    payload: product,
  }),
  fetchTslFunctionFailure: (error) => ({
    type: productActions.FETCH_TSL_FUNCTION_FAILURE,
    payload: error,
  }),
};

export default productActions;
