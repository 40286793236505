import { call, all, takeEvery, put } from "redux-saga/effects";
import actions from "./firmwareActions";
import CloudHawkApi2 from "@iso/config/Services/CloudHawk2";
import Notification from "@iso/components/Notification";
import qs from "qs";

//
// select
//  ********* select FIRMWARE Firmware APP App *******   //
function* selectFirmwareAppRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectFirmwareApp, payload);
    if (response.code === 100) {
      yield put(actions.selectFirmwareAppSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectFirmwareAppFailure(response));
    }
  } catch (error) {
    yield put(actions.selectFirmwareAppFailure(error));
  }
}

//
// create
//  *********  create FIRMWARE Firmware APP App *******   //
function* createFirmwareAppRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createFirmwareApp, payload);
    if (response.code === 100) {
      yield put(actions.createFirmwareAppSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createFirmwareAppFailure(response));
    }
  } catch (error) {
    yield put(actions.createFirmwareAppFailure(error));
  }
}
//  *********  create FIRMWARE Firmware APP App UPDATE Update *******   //
function* createFirmwareAppUpdateRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createFirmwareAppUpdate, payload);
    if (response.code === 100) {
      yield put(actions.createFirmwareAppUpdateSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createFirmwareAppUpdateFailure(response));
    }
  } catch (error) {
    yield put(actions.createFirmwareAppUpdateFailure(error));
  }
}

//
//  ********* fetch FIRMWARE Firmware APP App *******   //
function* fetchFirmwareAppRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchFirmwareApp, payload);
    if (response.code === 100) {
      yield put(actions.fetchFirmwareAppSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchFirmwareAppFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchFirmwareAppFailure(error));
  }
}
//  ********* fetch FIRMWARE Firmware APP App UPDATE Update  *******   //
function* fetchFirmwareAppUpdateRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchFirmwareAppUpdate, payload);
    if (response.code === 100) {
      yield put(actions.fetchFirmwareAppUpdateSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchFirmwareAppUpdateFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchFirmwareAppUpdateFailure(error));
  }
}

// enable
//  *********  enable FIRMWARE Firmware  APP App UPDATE Update *******   //
function* enableFirmwareAppUpdateRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.enableFirmwareAppUpdate, payload);
    if (response.code === 100) {
      yield put(actions.enableFirmwareAppUpdateSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.enableFirmwareAppUpdateFailure(response));
    }
  } catch (error) {
    yield put(actions.enableFirmwareAppUpdateFailure(error));
  }
}

// disable
//  *********  disable FIRMWARE Firmware APP App UPDATE Update  *******   //
function* disableFirmwareAppUpdateRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.disableFirmwareAppUpdate,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.disableFirmwareAppUpdateSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.disableFirmwareAppUpdateFailure(response));
    }
  } catch (error) {
    yield put(actions.disableFirmwareAppUpdateFailure(error));
  }
}

//
// delete
//  *********  delete FIRMWARE Firmware APP App *******   //
function* deleteFirmwareAppRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteFirmwareApp, payload);
    if (response.code === 100) {
      yield put(actions.deleteFirmwareAppSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteFirmwareAppFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteFirmwareAppFailure(error));
  }
}
//  *********  delete FIRMWARE Firmware APP App UPDATE Update  *******   //
function* deleteFirmwareAppUpdateRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteFirmwareAppUpdate, payload);
    if (response.code === 100) {
      yield put(actions.deleteFirmwareAppUpdateSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteFirmwareAppUpdateFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteFirmwareAppUpdateFailure(error));
  }
}

//
// edit
//  *********  edit FIRMWARE Firmware APP App *******   //
function* editFirmwareAppRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editFirmwareApp, payload);
    if (response.code === 100) {
      yield put(actions.editFirmwareAppSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFirmwareAppFailure(response));
    }
  } catch (error) {
    yield put(actions.editFirmwareAppFailure(error));
  }
}
//  *********  edit FIRMWARE Firmware APP App UPDATE Update  *******   //
function* editFirmwareAppUpdateRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editFirmwareAppUpdate, payload);
    if (response.code === 100) {
      yield put(actions.editFirmwareAppUpdateSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFirmwareAppUpdateFailure(response));
    }
  } catch (error) {
    yield put(actions.editFirmwareAppUpdateFailure(error));
  }
}
//  *********  edit FIRMWARE Firmware APP App UPDATE Update Language *******   //
function* editFirmwareAppUpdateLanguageRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.editFirmwareAppUpdateLanguage,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.editFirmwareAppUpdateLanguageSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFirmwareAppUpdateLanguageFailure(response));
    }
  } catch (error) {
    yield put(actions.editFirmwareAppUpdateLanguageFailure(error));
  }
}

// Download
//  APP App
function* downloadVersionAppRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.downloadVersionApp, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.downloadVersionAppSuccess(response.data));
  } catch (error) {
    yield put(actions.downloadVersionAppFailure(error));
  }
}
//  APP App UPDATE Update
function* downloadVersionAppUpdateRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.downloadVersionAppUpdate,
      payload,
    );

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.downloadVersionAppUpdateSuccess(response.data));
  } catch (error) {
    yield put(actions.downloadVersionAppUpdateFailure(error));
  }
}

export default function* firmwareSaga() {
  yield all([
    // select
    takeEvery(actions.SELECT_FIRMWARE_APP_REQUEST, selectFirmwareAppRequest),
    // create
    takeEvery(actions.CREATE_FIRMWARE_APP_REQUEST, createFirmwareAppRequest),
    takeEvery(
      actions.CREATE_FIRMWARE_APP_UPDATE_REQUEST,
      createFirmwareAppUpdateRequest,
    ),
    // edit
    takeEvery(actions.EDIT_FIRMWARE_APP_REQUEST, editFirmwareAppRequest),
    takeEvery(
      actions.EDIT_FIRMWARE_APP_UPDATE_REQUEST,
      editFirmwareAppUpdateRequest,
    ),
    takeEvery(
      actions.EDIT_FIRMWARE_APP_UPDATE_LANGUAGE_REQUEST,
      editFirmwareAppUpdateLanguageRequest,
    ),
    // delete
    takeEvery(actions.DELETE_FIRMWARE_APP_REQUEST, deleteFirmwareAppRequest),
    takeEvery(
      actions.DELETE_FIRMWARE_APP_UPDATE_REQUEST,
      deleteFirmwareAppUpdateRequest,
    ),
    // fetch
    takeEvery(actions.FETCH_FIRMWARE_APP_REQUEST, fetchFirmwareAppRequest),
    takeEvery(
      actions.FETCH_FIRMWARE_APP_UPDATE_REQUEST,
      fetchFirmwareAppUpdateRequest,
    ),
    // enable
    takeEvery(
      actions.ENABLE_FIRMWARE_APP_UPDATE_REQUEST,
      enableFirmwareAppUpdateRequest,
    ),
    // disable
    takeEvery(
      actions.DISABLE_FIRMWARE_APP_UPDATE_REQUEST,
      disableFirmwareAppUpdateRequest,
    ),

    // DOWNLOAD
    takeEvery(actions.DOWNLOAD_VERSION_APP_REQUEST, downloadVersionAppRequest),
    takeEvery(
      actions.DOWNLOAD_VERSION_APP_UPDATE_REQUEST,
      downloadVersionAppUpdateRequest,
    ),
    // takeEvery(actions.GET_FIRMWARE_REQUEST, getFirmwareRequest),
    // takeEvery(actions.GET_FIRMWARE_LOCALISATION_REQUEST, getFirmwareLocaliseRequest),
  ]);
}
