const actions = {
  // select
  // ********* select FIRMWARE Firmware ******* //
  SELECT_FIRMWARE_REQUEST: "SELECT_FIRMWARE_REQUEST",
  SELECT_FIRMWARE_SUCCESS: "SELECT_FIRMWARE_SUCCESS",
  SELECT_FIRMWARE_FAILURE: "SELECT_FIRMWARE_FAILURE",
  selectFirmwareRequest: (request) => ({
    type: actions.SELECT_FIRMWARE_REQUEST,
    payload: request,
  }),
  selectFirmwareSuccess: (res) => ({
    type: actions.SELECT_FIRMWARE_SUCCESS,
    payload: res,
  }),
  selectFirmwareFailure: (error) => ({
    type: actions.SELECT_FIRMWARE_FAILURE,
    payload: error,
  }),
  // ********* select FIRMWARE Firmware 订单号下拉 ******* //
  SELECT_ORDER_REQUEST: "SELECT_ORDER_REQUEST",
  SELECT_ORDER_SUCCESS: "SELECT_ORDER_SUCCESS",
  SELECT_ORDER_FAILURE: "SELECT_ORDER_FAILURE",
  selectOrderRequest: (request) => ({
    type: actions.SELECT_ORDER_REQUEST,
    payload: request,
  }),
  selectOrderSuccess: (res) => ({
    type: actions.SELECT_ORDER_SUCCESS,
    payload: res,
  }),
  selectOrderFailure: (error) => ({
    type: actions.SELECT_ORDER_FAILURE,
    payload: error,
  }),
  // ********* select FIRMWARE Firmware 批次号下拉 ******* //
  SELECT_BATCH_REQUEST: "SELECT_BATCH_REQUEST",
  SELECT_BATCH_SUCCESS: "SELECT_BATCH_SUCCESS",
  SELECT_BATCH_FAILURE: "SELECT_BATCH_FAILURE",
  selectBatchRequest: (request) => ({
    type: actions.SELECT_BATCH_REQUEST,
    payload: request,
  }),
  selectBatchSuccess: (res) => ({
    type: actions.SELECT_BATCH_SUCCESS,
    payload: res,
  }),
  selectBatchFailure: (error) => ({
    type: actions.SELECT_BATCH_FAILURE,
    payload: error,
  }),
  // ********* select FIRMWARE Firmware ******* //
  SELECT_FIRMWARE_UPDATE_DTU_REQUEST: "SELECT_FIRMWARE_UPDATE_DTU_REQUEST",
  SELECT_FIRMWARE_UPDATE_DTU_SUCCESS: "SELECT_FIRMWARE_UPDATE_DTU_SUCCESS",
  SELECT_FIRMWARE_UPDATE_DTU_FAILURE: "SELECT_FIRMWARE_UPDATE_DTU_FAILURE",
  selectFirmwareUpdateDtuRequest: (request) => ({
    type: actions.SELECT_FIRMWARE_UPDATE_DTU_REQUEST,
    payload: request,
  }),
  selectFirmwareUpdateDtuSuccess: (res) => ({
    type: actions.SELECT_FIRMWARE_UPDATE_DTU_SUCCESS,
    payload: res,
  }),
  selectFirmwareUpdateDtuFailure: (error) => ({
    type: actions.SELECT_FIRMWARE_UPDATE_DTU_FAILURE,
    payload: error,
  }),

  //
  // create
  // ********* create firmware Firmware ******* //
  CREATE_FIRMWARE_REQUEST: "CREATE_FIRMWARE_REQUEST",
  CREATE_FIRMWARE_SUCCESS: "CREATE_FIRMWARE_SUCCESS",
  CREATE_FIRMWARE_FAILURE: "CREATE_FIRMWARE_FAILURE",
  createFirmwareRequest: (request) => ({
    type: actions.CREATE_FIRMWARE_REQUEST,
    payload: request,
  }),
  createFirmwareSuccess: (res) => ({
    type: actions.CREATE_FIRMWARE_SUCCESS,
    payload: res,
  }),
  createFirmwareFailure: (error) => ({
    type: actions.CREATE_FIRMWARE_FAILURE,
    payload: error,
  }),
  // ********* create firmware Firmware UPDATE Update NEW New ******* //
  CREATE_FIRMWARE_UPDATE_NEW_REQUEST: "CREATE_FIRMWARE_UPDATE_NEW_REQUEST",
  CREATE_FIRMWARE_UPDATE_NEW_SUCCESS: "CREATE_FIRMWARE_UPDATE_NEW_SUCCESS",
  CREATE_FIRMWARE_UPDATE_NEW_FAILURE: "CREATE_FIRMWARE_UPDATE_NEW_FAILURE",
  createFirmwareUpdateNewRequest: (request) => ({
    type: actions.CREATE_FIRMWARE_UPDATE_NEW_REQUEST,
    payload: request,
  }),
  createFirmwareUpdateNewSuccess: (res) => ({
    type: actions.CREATE_FIRMWARE_UPDATE_NEW_SUCCESS,
    payload: res,
  }),
  createFirmwareUpdateNewFailure: (error) => ({
    type: actions.CREATE_FIRMWARE_UPDATE_NEW_FAILURE,
    payload: error,
  }),
  // ********* create firmware Firmware UPDATE Update OLD Old ******* //
  CREATE_FIRMWARE_UPDATE_OLD_REQUEST: "CREATE_FIRMWARE_UPDATE_OLD_REQUEST",
  CREATE_FIRMWARE_UPDATE_OLD_SUCCESS: "CREATE_FIRMWARE_UPDATE_OLD_SUCCESS",
  CREATE_FIRMWARE_UPDATE_OLD_FAILURE: "CREATE_FIRMWARE_UPDATE_OLD_FAILURE",
  createFirmwareUpdateOldRequest: (request) => ({
    type: actions.CREATE_FIRMWARE_UPDATE_OLD_REQUEST,
    payload: request,
  }),
  createFirmwareUpdateOldSuccess: (res) => ({
    type: actions.CREATE_FIRMWARE_UPDATE_OLD_SUCCESS,
    payload: res,
  }),
  createFirmwareUpdateOldFailure: (error) => ({
    type: actions.CREATE_FIRMWARE_UPDATE_OLD_FAILURE,
    payload: error,
  }),
  // ********* 印度老接口 ******* //
  localIsAtionFirmware_REQUEST: "localIsAtionFirmware_REQUEST",
  localIsAtionFirmware_SUCCESS: "localIsAtionFirmware_SUCCESS",
  localIsAtionFirmware_FAILURE: "localIsAtionFirmware_FAILURE",
  localIsAtionFirmwareRequest: (request) => ({
    type: actions.localIsAtionFirmware_REQUEST,
    payload: request,
  }),
  localIsAtionFirmwareSuccess: (res) => ({
    type: actions.localIsAtionFirmware_SUCCESS,
    payload: res,
  }),
  localIsAtionFirmwareFailure: (error) => ({
    type: actions.localIsAtionFirmware_FAILURE,
    payload: error,
  }),

  //
  // detail DETAIL
  // ********* detail firmware Firmware UPDATE Update NEW New ******* //
  DETAIL_FIRMWARE_UPDATE_NEW_REQUEST: "DETAIL_FIRMWARE_UPDATE_NEW_REQUEST",
  DETAIL_FIRMWARE_UPDATE_NEW_SUCCESS: "DETAIL_FIRMWARE_UPDATE_NEW_SUCCESS",
  DETAIL_FIRMWARE_UPDATE_NEW_FAILURE: "DETAIL_FIRMWARE_UPDATE_NEW_FAILURE",
  detailFirmwareUpdateNewRequest: (request) => ({
    type: actions.DETAIL_FIRMWARE_UPDATE_NEW_REQUEST,
    payload: request,
  }),
  detailFirmwareUpdateNewSuccess: (res) => ({
    type: actions.DETAIL_FIRMWARE_UPDATE_NEW_SUCCESS,
    payload: res,
  }),
  detailFirmwareUpdateNewFailure: (error) => ({
    type: actions.DETAIL_FIRMWARE_UPDATE_NEW_FAILURE,
    payload: error,
  }),
  // ********* HISTORY History firmware Firmware UPDATE Update NEW New ******* //
  DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_REQUEST:
    "DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_REQUEST",
  DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_SUCCESS:
    "DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_SUCCESS",
  DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_FAILURE:
    "DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_FAILURE",
  detailFirmwareUpdateHistoryNewRequest: (request) => ({
    type: actions.DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_REQUEST,
    payload: request,
  }),
  detailFirmwareUpdateHistoryNewSuccess: (res) => ({
    type: actions.DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_SUCCESS,
    payload: res,
  }),
  detailFirmwareUpdateHistoryNewFailure: (error) => ({
    type: actions.DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_FAILURE,
    payload: error,
  }),
  // ********* detail firmware Firmware UPDATE Update OLD Old ******* //
  DETAIL_FIRMWARE_UPDATE_OLD_REQUEST: "DETAIL_FIRMWARE_UPDATE_OLD_REQUEST",
  DETAIL_FIRMWARE_UPDATE_OLD_SUCCESS: "DETAIL_FIRMWARE_UPDATE_OLD_SUCCESS",
  DETAIL_FIRMWARE_UPDATE_OLD_FAILURE: "DETAIL_FIRMWARE_UPDATE_OLD_FAILURE",
  detailFirmwareUpdateOldRequest: (request) => ({
    type: actions.DETAIL_FIRMWARE_UPDATE_OLD_REQUEST,
    payload: request,
  }),
  detailFirmwareUpdateOldSuccess: (res) => ({
    type: actions.DETAIL_FIRMWARE_UPDATE_OLD_SUCCESS,
    payload: res,
  }),
  detailFirmwareUpdateOldFailure: (error) => ({
    type: actions.DETAIL_FIRMWARE_UPDATE_OLD_FAILURE,
    payload: error,
  }),

  //
  // edit
  // ********* edit FIRMWARE Firmware ******* //
  EDIT_FIRMWARE_REQUEST: "EDIT_FIRMWARE_REQUEST",
  EDIT_FIRMWARE_SUCCESS: "EDIT_FIRMWARE_SUCCESS",
  EDIT_FIRMWARE_FAILURE: "EDIT_FIRMWARE_FAILURE",
  editFirmwareRequest: (request) => ({
    type: actions.EDIT_FIRMWARE_REQUEST,
    payload: request,
  }),
  editFirmwareSuccess: (res) => ({
    type: actions.EDIT_FIRMWARE_SUCCESS,
    payload: res,
  }),
  editFirmwareFailure: (error) => ({
    type: actions.EDIT_FIRMWARE_FAILURE,
    payload: error,
  }),
  // ********* edit FIRMWARE Firmware UPDATE Update NEW New ******* //
  EDIT_FIRMWARE_UPDATE_NEW_REQUEST: "EDIT_FIRMWARE_UPDATE_NEW_REQUEST",
  EDIT_FIRMWARE_UPDATE_NEW_SUCCESS: "EDIT_FIRMWARE_UPDATE_NEW_SUCCESS",
  EDIT_FIRMWARE_UPDATE_NEW_FAILURE: "EDIT_FIRMWARE_UPDATE_NEW_FAILURE",
  editFirmwareUpdateNewRequest: (request) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_NEW_REQUEST,
    payload: request,
  }),
  editFirmwareUpdateNewSuccess: (res) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_NEW_SUCCESS,
    payload: res,
  }),
  editFirmwareUpdateNewFailure: (error) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_NEW_FAILURE,
    payload: error,
  }),
  // ********* edit FIRMWARE Firmware UPDATE Update OLD Old ******* //
  EDIT_FIRMWARE_UPDATE_OLD_REQUEST: "EDIT_FIRMWARE_UPDATE_OLD_REQUEST",
  EDIT_FIRMWARE_UPDATE_OLD_SUCCESS: "EDIT_FIRMWARE_UPDATE_OLD_SUCCESS",
  EDIT_FIRMWARE_UPDATE_OLD_FAILURE: "EDIT_FIRMWARE_UPDATE_OLD_FAILURE",
  editFirmwareUpdateOldRequest: (request) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_OLD_REQUEST,
    payload: request,
  }),
  editFirmwareUpdateOldSuccess: (res) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_OLD_SUCCESS,
    payload: res,
  }),
  editFirmwareUpdateOldFailure: (error) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_OLD_FAILURE,
    payload: error,
  }),
  // ********* edit FIRMWARE Firmware UPDATE Update NEW New LANGUAGE Language ******* //
  EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_REQUEST:
    "EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_REQUEST",
  EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_SUCCESS:
    "EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_SUCCESS",
  EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_FAILURE:
    "EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_FAILURE",
  editFirmwareUpdateLanguageNewRequest: (request) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_REQUEST,
    payload: request,
  }),
  editFirmwareUpdateLanguageNewSuccess: (res) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_SUCCESS,
    payload: res,
  }),
  editFirmwareUpdateLanguageNewFailure: (error) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_FAILURE,
    payload: error,
  }),
  // ********* edit FIRMWARE Firmware UPDATE Update OLD Old LANGUAGE Language ******* //
  EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_REQUEST:
    "EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_REQUEST",
  EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_SUCCESS:
    "EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_SUCCESS",
  EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_FAILURE:
    "EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_FAILURE",
  editFirmwareUpdateLanguageOldRequest: (request) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_REQUEST,
    payload: request,
  }),
  editFirmwareUpdateLanguageOldSuccess: (res) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_SUCCESS,
    payload: res,
  }),
  editFirmwareUpdateLanguageOldFailure: (error) => ({
    type: actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_FAILURE,
    payload: error,
  }),

  //
  // delete
  // ********* delete FIRMWARE Firmware ******* //
  DELETE_FIRMWARE_REQUEST: "DELETE_FIRMWARE_REQUEST",
  DELETE_FIRMWARE_SUCCESS: "DELETE_FIRMWARE_SUCCESS",
  DELETE_FIRMWARE_FAILURE: "DELETE_FIRMWARE_FAILURE",
  deleteFirmwareRequest: (request) => ({
    type: actions.DELETE_FIRMWARE_REQUEST,
    payload: request,
  }),
  deleteFirmwareSuccess: (res) => ({
    type: actions.DELETE_FIRMWARE_SUCCESS,
    payload: res,
  }),
  deleteFirmwareFailure: (error) => ({
    type: actions.DELETE_FIRMWARE_FAILURE,
    payload: error,
  }),
  // ********* delete FIRMWARE Firmware UPDATE Update NEW New  ******* //
  DELETE_FIRMWARE_UPDATE_NEW_REQUEST: "DELETE_FIRMWARE_UPDATE_NEW_REQUEST",
  DELETE_FIRMWARE_UPDATE_NEW_SUCCESS: "DELETE_FIRMWARE_UPDATE_NEW_SUCCESS",
  DELETE_FIRMWARE_UPDATE_NEW_FAILURE: "DELETE_FIRMWARE_UPDATE_NEW_FAILURE",
  deleteFirmwareUpdateNewRequest: (request) => ({
    type: actions.DELETE_FIRMWARE_UPDATE_NEW_REQUEST,
    payload: request,
  }),
  deleteFirmwareUpdateNewSuccess: (res) => ({
    type: actions.DELETE_FIRMWARE_UPDATE_NEW_SUCCESS,
    payload: res,
  }),
  deleteFirmwareUpdateNewFailure: (error) => ({
    type: actions.DELETE_FIRMWARE_UPDATE_NEW_FAILURE,
    payload: error,
  }),
  // ********* delete FIRMWARE Firmware UPDATE Update OLD Old  ******* //
  DELETE_FIRMWARE_UPDATE_OLD_REQUEST: "DELETE_FIRMWARE_UPDATE_OLD_REQUEST",
  DELETE_FIRMWARE_UPDATE_OLD_SUCCESS: "DELETE_FIRMWARE_UPDATE_OLD_SUCCESS",
  DELETE_FIRMWARE_UPDATE_OLD_FAILURE: "DELETE_FIRMWARE_UPDATE_OLD_FAILURE",
  deleteFirmwareUpdateOldRequest: (request) => ({
    type: actions.DELETE_FIRMWARE_UPDATE_OLD_REQUEST,
    payload: request,
  }),
  deleteFirmwareUpdateOldSuccess: (res) => ({
    type: actions.DELETE_FIRMWARE_UPDATE_OLD_SUCCESS,
    payload: res,
  }),
  deleteFirmwareUpdateOldFailure: (error) => ({
    type: actions.DELETE_FIRMWARE_UPDATE_OLD_FAILURE,
    payload: error,
  }),

  // fetch
  // ********* fetch FIRMWARE Firmware ******* //
  FETCH_FIRMWARE_REQUEST: "FETCH_FIRMWARE_REQUEST",
  FETCH_FIRMWARE_SUCCESS: "FETCH_FIRMWARE_SUCCESS",
  FETCH_FIRMWARE_FAILURE: "FETCH_FIRMWARE_FAILURE",
  fetchFirmwareRequest: (request) => ({
    type: actions.FETCH_FIRMWARE_REQUEST,
    payload: request,
  }),
  fetchFirmwareSuccess: (res) => ({
    type: actions.FETCH_FIRMWARE_SUCCESS,
    payload: res,
  }),
  fetchFirmwareFailure: (error) => ({
    type: actions.FETCH_FIRMWARE_FAILURE,
    payload: error,
  }),
  // ********* fetch FIRMWARE Firmware UPDATE Update NEW New  ******* //
  FETCH_FIRMWARE_UPDATE_NEW_REQUEST: "FETCH_FIRMWARE_UPDATE_NEW_REQUEST",
  FETCH_FIRMWARE_UPDATE_NEW_SUCCESS: "FETCH_FIRMWARE_UPDATE_NEW_SUCCESS",
  FETCH_FIRMWARE_UPDATE_NEW_FAILURE: "FETCH_FIRMWARE_UPDATE_NEW_FAILURE",
  fetchFirmwareUpdateNewRequest: (request) => ({
    type: actions.FETCH_FIRMWARE_UPDATE_NEW_REQUEST,
    payload: request,
  }),
  fetchFirmwareUpdateNewSuccess: (res) => ({
    type: actions.FETCH_FIRMWARE_UPDATE_NEW_SUCCESS,
    payload: res,
  }),
  fetchFirmwareUpdateNewFailure: (error) => ({
    type: actions.FETCH_FIRMWARE_UPDATE_NEW_FAILURE,
    payload: error,
  }),
  // ********* fetch FIRMWARE Firmware UPDATE Update In NEW New  ******* //
  FETCH_FIRMWARE_UPDATE_IN_NEW_REQUEST: "FETCH_FIRMWARE_UPDATE_IN_NEW_REQUEST",
  FETCH_FIRMWARE_UPDATE_IN_NEW_SUCCESS: "FETCH_FIRMWARE_UPDATE_IN_NEW_SUCCESS",
  FETCH_FIRMWARE_UPDATE_IN_NEW_FAILURE: "FETCH_FIRMWARE_UPDATE_IN_NEW_FAILURE",
  fetchFirmwareUpdateInNewRequest: (request) => ({
    type: actions.FETCH_FIRMWARE_UPDATE_IN_NEW_REQUEST,
    payload: request,
  }),
  fetchFirmwareUpdateInNewSuccess: (res) => ({
    type: actions.FETCH_FIRMWARE_UPDATE_IN_NEW_SUCCESS,
    payload: res,
  }),
  fetchFirmwareUpdateInNewFailure: (error) => ({
    type: actions.FETCH_FIRMWARE_UPDATE_IN_NEW_FAILURE,
    payload: error,
  }),
  // ********* fetch FIRMWARE Firmware UPDATE Update OLD Old  ******* //
  FETCH_FIRMWARE_UPDATE_OLD_REQUEST: "FETCH_FIRMWARE_UPDATE_OLD_REQUEST",
  FETCH_FIRMWARE_UPDATE_OLD_SUCCESS: "FETCH_FIRMWARE_UPDATE_OLD_SUCCESS",
  FETCH_FIRMWARE_UPDATE_OLD_FAILURE: "FETCH_FIRMWARE_UPDATE_OLD_FAILURE",
  fetchFirmwareUpdateOldRequest: (request) => ({
    type: actions.FETCH_FIRMWARE_UPDATE_OLD_REQUEST,
    payload: request,
  }),
  fetchFirmwareUpdateOldSuccess: (res) => ({
    type: actions.FETCH_FIRMWARE_UPDATE_OLD_SUCCESS,
    payload: res,
  }),
  fetchFirmwareUpdateOldFailure: (error) => ({
    type: actions.FETCH_FIRMWARE_UPDATE_OLD_FAILURE,
    payload: error,
  }),

  // enable
  // ********* enable FIRMWARE Firmware ******* //
  ENABLE_FIRMWARE_REQUEST: "ENABLE_FIRMWARE_REQUEST",
  ENABLE_FIRMWARE_SUCCESS: "ENABLE_FIRMWARE_SUCCESS",
  ENABLE_FIRMWARE_FAILURE: "ENABLE_FIRMWARE_FAILURE",
  enableFirmwareRequest: (request) => ({
    type: actions.ENABLE_FIRMWARE_REQUEST,
    payload: request,
  }),
  enableFirmwareSuccess: (res) => ({
    type: actions.ENABLE_FIRMWARE_SUCCESS,
    payload: res,
  }),
  enableFirmwareFailure: (error) => ({
    type: actions.ENABLE_FIRMWARE_FAILURE,
    payload: error,
  }),
  // ********* enable FIRMWARE Firmware UPDATE Update NEW New ******* //
  ENABLE_FIRMWARE_UPDATE_NEW_REQUEST: "ENABLE_FIRMWARE_UPDATE_NEW_REQUEST",
  ENABLE_FIRMWARE_UPDATE_NEW_SUCCESS: "ENABLE_FIRMWARE_UPDATE_NEW_SUCCESS",
  ENABLE_FIRMWARE_UPDATE_NEW_FAILURE: "ENABLE_FIRMWARE_UPDATE_NEW_FAILURE",
  enableFirmwareUpdateNewRequest: (request) => ({
    type: actions.ENABLE_FIRMWARE_UPDATE_NEW_REQUEST,
    payload: request,
  }),
  enableFirmwareUpdateNewSuccess: (res) => ({
    type: actions.ENABLE_FIRMWARE_UPDATE_NEW_SUCCESS,
    payload: res,
  }),
  enableFirmwareUpdateNewFailure: (error) => ({
    type: actions.ENABLE_FIRMWARE_UPDATE_NEW_FAILURE,
    payload: error,
  }),
  // ********* enable FIRMWARE Firmware UPDATE Update OLD Old ******* //
  ENABLE_FIRMWARE_UPDATE_OLD_REQUEST: "ENABLE_FIRMWARE_UPDATE_OLD_REQUEST",
  ENABLE_FIRMWARE_UPDATE_OLD_SUCCESS: "ENABLE_FIRMWARE_UPDATE_OLD_SUCCESS",
  ENABLE_FIRMWARE_UPDATE_OLD_FAILURE: "ENABLE_FIRMWARE_UPDATE_OLD_FAILURE",
  enableFirmwareUpdateOldRequest: (request) => ({
    type: actions.ENABLE_FIRMWARE_UPDATE_OLD_REQUEST,
    payload: request,
  }),
  enableFirmwareUpdateOldSuccess: (res) => ({
    type: actions.ENABLE_FIRMWARE_UPDATE_OLD_SUCCESS,
    payload: res,
  }),
  enableFirmwareUpdateOldFailure: (error) => ({
    type: actions.ENABLE_FIRMWARE_UPDATE_OLD_FAILURE,
    payload: error,
  }),

  // disable
  // ********* disable FIRMWARE Firmware ******* //
  DISABLE_FIRMWARE_REQUEST: "DISABLE_FIRMWARE_REQUEST",
  DISABLE_FIRMWARE_SUCCESS: "DISABLE_FIRMWARE_SUCCESS",
  DISABLE_FIRMWARE_FAILURE: "DISABLE_FIRMWARE_FAILURE",
  disableFirmwareRequest: (request) => ({
    type: actions.DISABLE_FIRMWARE_REQUEST,
    payload: request,
  }),
  disableFirmwareSuccess: (res) => ({
    type: actions.DISABLE_FIRMWARE_SUCCESS,
    payload: res,
  }),
  disableFirmwareFailure: (error) => ({
    type: actions.DISABLE_FIRMWARE_FAILURE,
    payload: error,
  }),
  // ********* disable FIRMWARE Firmware UPDATE Update NEW New ******* //
  DISABLE_FIRMWARE_UPDATE_NEW_REQUEST: "DISABLE_FIRMWARE_UPDATE_NEW_REQUEST",
  DISABLE_FIRMWARE_UPDATE_NEW_SUCCESS: "DISABLE_FIRMWARE_UPDATE_NEW_SUCCESS",
  DISABLE_FIRMWARE_UPDATE_NEW_FAILURE: "DISABLE_FIRMWARE_UPDATE_NEW_FAILURE",
  disableFirmwareUpdateNewRequest: (request) => ({
    type: actions.DISABLE_FIRMWARE_UPDATE_NEW_REQUEST,
    payload: request,
  }),
  disableFirmwareUpdateNewSuccess: (res) => ({
    type: actions.DISABLE_FIRMWARE_UPDATE_NEW_SUCCESS,
    payload: res,
  }),
  disableFirmwareUpdateNewFailure: (error) => ({
    type: actions.DISABLE_FIRMWARE_UPDATE_NEW_FAILURE,
    payload: error,
  }),
  // ********* disable FIRMWARE Firmware UPDATE Update OLD Old ******* //
  DISABLE_FIRMWARE_UPDATE_OLD_REQUEST: "DISABLE_FIRMWARE_UPDATE_OLD_REQUEST",
  DISABLE_FIRMWARE_UPDATE_OLD_SUCCESS: "DISABLE_FIRMWARE_UPDATE_OLD_SUCCESS",
  DISABLE_FIRMWARE_UPDATE_OLD_FAILURE: "DISABLE_FIRMWARE_UPDATE_OLD_FAILURE",
  disableFirmwareUpdateOldRequest: (request) => ({
    type: actions.DISABLE_FIRMWARE_UPDATE_OLD_REQUEST,
    payload: request,
  }),
  disableFirmwareUpdateOldSuccess: (res) => ({
    type: actions.DISABLE_FIRMWARE_UPDATE_OLD_SUCCESS,
    payload: res,
  }),
  disableFirmwareUpdateOldFailure: (error) => ({
    type: actions.DISABLE_FIRMWARE_UPDATE_OLD_FAILURE,
    payload: error,
  }),

  // Pass
  // ********* Pass ******* //
  GO_FIRMWARE_PASS_REQUEST: "GO_FIRMWARE_PASS_REQUEST",
  GO_FIRMWARE_PASS_SUCCESS: "GO_FIRMWARE_PASS_SUCCESS",
  GO_FIRMWARE_PASS_FAILURE: "GO_FIRMWARE_PASS_FAILURE",
  goFirmwarePassRequest: (request) => ({
    type: actions.GO_FIRMWARE_PASS_REQUEST,
    payload: request,
  }),
  goFirmwarePassSuccess: (res) => ({
    type: actions.GO_FIRMWARE_PASS_SUCCESS,
    payload: res,
  }),
  goFirmwarePassFailure: (error) => ({
    type: actions.GO_FIRMWARE_PASS_FAILURE,
    payload: error,
  }),

  // Refuse
  // ********* Refuse ******* //
  GO_FIRMWARE_REFUSE_REQUEST: "GO_FIRMWARE_REFUSE_REQUEST",
  GO_FIRMWARE_REFUSE_SUCCESS: "GO_FIRMWARE_REFUSE_SUCCESS",
  GO_FIRMWARE_REFUSE_FAILURE: "GO_FIRMWARE_REFUSE_FAILURE",
  goFirmwareRefuseRequest: (request) => ({
    type: actions.GO_FIRMWARE_REFUSE_REQUEST,
    payload: request,
  }),
  goFirmwareRefuseSuccess: (res) => ({
    type: actions.GO_FIRMWARE_REFUSE_SUCCESS,
    payload: res,
  }),
  goFirmwareRefuseFailure: (error) => ({
    type: actions.GO_FIRMWARE_REFUSE_FAILURE,
    payload: error,
  }),

  //
  // DOWNLOAD
  // goImportDownloadRequest
  DOWNLOAD_IMPORT_DEVICE_REQUEST: "DOWNLOAD_IMPORT_DEVICE_REQUEST",
  DOWNLOAD_IMPORT_DEVICE_SUCCESS: "DOWNLOAD_IMPORT_DEVICE_SUCCESS",
  DOWNLOAD_IMPORT_DEVICE_FAILURE: "DOWNLOAD_IMPORT_DEVICE_FAILURE",
  downloadImportDeviceRequest: (request) => {
    return {
      type: actions.DOWNLOAD_IMPORT_DEVICE_REQUEST,
      payload: request,
    };
  },
  downloadImportDeviceSuccess: (data) => {
    return {
      type: actions.DOWNLOAD_IMPORT_DEVICE_SUCCESS,
      payload: data,
    };
  },
  downloadImportDeviceFailure: (error) => ({
    type: actions.DOWNLOAD_IMPORT_DEVICE_FAILURE,
    payload: error,
  }),
  DOWNLOAD_VERSION_REQUEST: "DOWNLOAD_VERSION_REQUEST",
  DOWNLOAD_VERSION_SUCCESS: "DOWNLOAD_VERSION_SUCCESS",
  DOWNLOAD_VERSION_FAILURE: "DOWNLOAD_VERSION_FAILURE",
  downloadVersionRequest: (request) => {
    return {
      type: actions.DOWNLOAD_VERSION_REQUEST,
      payload: request,
    };
  },
  downloadVersionSuccess: (data) => {
    return {
      type: actions.DOWNLOAD_VERSION_SUCCESS,
      payload: data,
    };
  },
  downloadVersionFailure: (error) => ({
    type: actions.DOWNLOAD_VERSION_FAILURE,
    payload: error,
  }),
  // DOWNLOAD UPDATE Update NEW New
  DOWNLOAD_VERSION_UPDATE_NEW_REQUEST: "DOWNLOAD_VERSION_UPDATE_NEW_REQUEST",
  DOWNLOAD_VERSION_UPDATE_NEW_SUCCESS: "DOWNLOAD_VERSION_UPDATE_NEW_SUCCESS",
  DOWNLOAD_VERSION_UPDATE_NEW_FAILURE: "DOWNLOAD_VERSION_UPDATE_NEW_FAILURE",
  downloadVersionUpdateNewRequest: (request) => {
    return {
      type: actions.DOWNLOAD_VERSION_UPDATE_NEW_REQUEST,
      payload: request,
    };
  },
  downloadVersionUpdateNewSuccess: (data) => {
    return {
      type: actions.DOWNLOAD_VERSION_UPDATE_NEW_SUCCESS,
      payload: data,
    };
  },
  downloadVersionUpdateNewFailure: (error) => ({
    type: actions.DOWNLOAD_VERSION_UPDATE_NEW_FAILURE,
    payload: error,
  }),
  // DOWNLOAD UPDATE Update OLD Old
  DOWNLOAD_VERSION_UPDATE_OLD_REQUEST: "DOWNLOAD_VERSION_UPDATE_OLD_REQUEST",
  DOWNLOAD_VERSION_UPDATE_OLD_SUCCESS: "DOWNLOAD_VERSION_UPDATE_OLD_SUCCESS",
  DOWNLOAD_VERSION_UPDATE_OLD_FAILURE: "DOWNLOAD_VERSION_UPDATE_OLD_FAILURE",
  downloadVersionUpdateOldRequest: (request) => {
    return {
      type: actions.DOWNLOAD_VERSION_UPDATE_OLD_REQUEST,
      payload: request,
    };
  },
  downloadVersionUpdateOldSuccess: (data) => {
    return {
      type: actions.DOWNLOAD_VERSION_UPDATE_OLD_SUCCESS,
      payload: data,
    };
  },
  downloadVersionUpdateOldFailure: (error) => ({
    type: actions.DOWNLOAD_VERSION_UPDATE_OLD_FAILURE,
    payload: error,
  }),
};

export default actions;
