// saga.js
import { call, all, takeEvery, put, select } from "redux-saga/effects";
import adsActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  //
  notification.error({
    // message:"Msg:"+response.msg,
    message: "" + response.msg,
  });
};
function* fetchAdsDataRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getAds, payload);
    //
    yield put(adsActions.fetchAdsDataSuccess(response.data));
  } catch (error) {
    yield put(adsActions.fetchAdsDataFailure(error));
  }
}

function* addAdsDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.createAd, payload.params1);

    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
    // yield put(adsActions.addAdsDataSuccess(response.data));
  } catch (error) {
    yield put(adsActions.addAdsDataFailure(error));
  } finally {
    yield put(adsActions.fetchAdsDataRequest(payload.params2));
  }
}

// EDIT ADS
function* editAdsDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editAd, payload.params1);
    if (response.success) {
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {
    yield put(adsActions.editAdsDataFailure(error));
  } finally {
    yield put(adsActions.fetchAdsDataRequest(payload.params2));
  }
}

function* deleteAdsDataRequest(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.deleteAd, payload);
    if (response.success) {
      const total = yield select((state) => state.ads.adsdataTotal);
      if (
        payload["params2"]["pageNum"] >
        Math.ceil((total - 1) / payload["params2"]["pageSize"])
      ) {
        //last page
        payload["params2"]["pageNum"] = payload["params2"]["pageNum"] - 1;
        yield put(adsActions.fetchAdsDataRequest(payload["params2"]));
      } else {
        //this page
        yield put(adsActions.fetchAdsDataRequest(payload["params2"]));
      }
      alert(response);
    } else {
      yield put(adsActions.fetchAdsDataRequest(payload["params2"]));
      Error(response);
    }
  } catch (error) {
    yield put(adsActions.deleteAdsDataFailure(error));
  }
}

export default function* adsSaga() {
  yield all([
    takeEvery(adsActions.FETCH_ADS_DATA_REQUEST, fetchAdsDataRequest),
    takeEvery(adsActions.ADD_ADS_REQUEST, addAdsDataRequest),
    takeEvery(adsActions.EDIT_ADS_REQUEST, editAdsDataRequest),
    takeEvery(adsActions.DELETE_ADS_REQUEST, deleteAdsDataRequest),
  ]);
}
