import { call, all, takeEvery, put } from "redux-saga/effects";
import actions from "./firmwareActions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";
import CloudHawkApi2 from "@iso/config/Services/CloudHawk2";
import Notification from "@iso/components/Notification";
import qs from "qs";

//
//  ********* fetch FIRMWARE Firmware *******   //
function* selectFirmwareRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectFirmware, payload);
    if (response.code === 100) {
      yield put(actions.selectFirmwareSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectFirmwareFailure(response));
    }
  } catch (error) {
    yield put(actions.selectFirmwareFailure(error));
  }
}
//  ********* fetch FIRMWARE Firmware 订单号下拉 *******   //
function* selectOrderRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectOrder, payload);
    if (response.code === 100) {
      yield put(actions.selectOrderSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectOrderFailure(response));
    }
  } catch (error) {
    yield put(actions.selectOrderFailure(error));
  }
}
//  ********* fetch FIRMWARE Firmware 批次号下拉 *******   //
function* selectBatchRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectBatch, payload);
    if (response.code === 100) {
      yield put(actions.selectBatchSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectBatchFailure(response));
    }
  } catch (error) {
    yield put(actions.selectBatchFailure(error));
  }
}
//  ********* fetch FIRMWARE Firmware DTU *******   //
function* selectFirmwareUpdateDtuRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.selectFirmwareDtu, payload);
    if (response.code === 100) {
      yield put(actions.selectFirmwareUpdateDtuSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.selectFirmwareUpdateDtuFailure(response));
    }
  } catch (error) {
    yield put(actions.selectFirmwareUpdateDtuFailure(error));
  }
}

//
// create
//  *********  create FIRMWARE Firmware *******   //
function* createFirmwareRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createFirmware, payload);
    if (response.code === 100) {
      yield put(actions.createFirmwareSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createFirmwareFailure(response));
    }
  } catch (error) {
    yield put(actions.createFirmwareFailure(error));
  }
}
// ********* create firmware Firmware UPDATE Update NEW New ******* //
function* createFirmwareUpdateNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createFirmwareUpdateNew, payload);
    if (response.code === 100) {
      yield put(actions.createFirmwareUpdateNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createFirmwareUpdateNewFailure(response));
    }
  } catch (error) {
    yield put(actions.createFirmwareUpdateNewFailure(error));
  }
}
// ********* create firmware Firmware UPDATE Update OLD Old ******* //
function* createFirmwareUpdateOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createFirmwareUpdateOld, payload);
    if (response.code === 100) {
      yield put(actions.createFirmwareUpdateOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.createFirmwareUpdateOldFailure(response));
    }
  } catch (error) {
    yield put(actions.createFirmwareUpdateOldFailure(error));
  }
}
// === 印度老接口 ===
function* localIsAtionFirmwareRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.localisationFirmware, payload);
    if (response.success) {
      yield put(actions.localIsAtionFirmwareSuccess(response.data));
    } else {
      // Notification("error", response.msg || response.message);
      yield put(actions.localIsAtionFirmwareFailure(response));
    }
  } catch (error) {
    yield put(actions.localIsAtionFirmwareFailure(error));
  }
}

// detail
// ********* detail firmware Firmware UPDATE Update NEW New ******* //
function* detailFirmwareUpdateNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailFirmwareUpdateNew, payload);
    if (response.code === 100) {
      yield put(actions.detailFirmwareUpdateNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.detailFirmwareUpdateNewFailure(response));
    }
  } catch (error) {
    yield put(actions.detailFirmwareUpdateNewFailure(error));
  }
}
// ********* detail firmware Firmware UPDATE Update NEW New ******* //
function* detailFirmwareUpdateHistoryNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.detailFirmwareUpdateHistoryNew,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.detailFirmwareUpdateHistoryNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.detailFirmwareUpdateHistoryNewFailure(response));
    }
  } catch (error) {
    yield put(actions.detailFirmwareUpdateHistoryNewFailure(error));
  }
}
// ********* detail firmware Firmware UPDATE Update OLD Old ******* //
function* detailFirmwareUpdateOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailFirmwareUpdateOld, payload);
    if (response.code === 100) {
      yield put(actions.detailFirmwareUpdateOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.detailFirmwareUpdateOldFailure(response));
    }
  } catch (error) {
    yield put(actions.detailFirmwareUpdateOldFailure(error));
  }
}

//
// edit
//  *********  edit FIRMWARE Firmware *******   //
function* editFirmwareRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editFirmware, payload);
    if (response.code === 100) {
      yield put(actions.editFirmwareSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFirmwareFailure(response));
    }
  } catch (error) {
    yield put(actions.editFirmwareFailure(error));
  }
}
// ********* edit FIRMWARE Firmware UPDATE Update NEW New ******* //
function* editFirmwareUpdateNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editFirmwareUpdateNew, payload);
    if (response.code === 100) {
      yield put(actions.editFirmwareUpdateNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFirmwareUpdateNewFailure(response));
    }
  } catch (error) {
    yield put(actions.editFirmwareUpdateNewFailure(error));
  }
}
// ********* edit FIRMWARE Firmware UPDATE Update OLD Old ******* //
function* editFirmwareUpdateOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editFirmwareUpdateOld, payload);
    if (response.code === 100) {
      yield put(actions.editFirmwareUpdateOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFirmwareUpdateOldFailure(response));
    }
  } catch (error) {
    yield put(actions.editFirmwareUpdateOldFailure(error));
  }
}
// ********* edit FIRMWARE Firmware UPDATE Update NEW New LANGUAGE Language ******* //
function* editFirmwareUpdateLanguageNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.editFirmwareUpdateNewLanguage,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.editFirmwareUpdateLanguageNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFirmwareUpdateLanguageNewFailure(response));
    }
  } catch (error) {
    yield put(actions.editFirmwareUpdateLanguageNewFailure(error));
  }
}
// ********* edit FIRMWARE Firmware UPDATE Update OLD Old LANGUAGE Language ******* //
function* editFirmwareUpdateLanguageOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.editFirmwareUpdateOldLanguage,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.editFirmwareUpdateLanguageOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.editFirmwareUpdateLanguageOldFailure(response));
    }
  } catch (error) {
    yield put(actions.editFirmwareUpdateLanguageOldFailure(error));
  }
}

//
// delete
//  *********  delete FIRMWARE Firmware *******   //
function* deleteFirmwareRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteFirmware, payload);
    if (response.code === 100) {
      yield put(actions.deleteFirmwareSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteFirmwareFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteFirmwareFailure(error));
  }
}
// ********* delete FIRMWARE Firmware UPDATE Update NEW New  ******* //
function* deleteFirmwareUpdateNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteFirmwareUpdateNew, payload);
    if (response.code === 100) {
      yield put(actions.deleteFirmwareUpdateNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteFirmwareUpdateNewFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteFirmwareUpdateNewFailure(error));
  }
}
// ********* delete FIRMWARE Firmware UPDATE Update OLD Old  ******* //
function* deleteFirmwareUpdateOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteFirmwareUpdateOld, payload);
    if (response.code === 100) {
      yield put(actions.deleteFirmwareUpdateOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.deleteFirmwareUpdateOldFailure(response));
    }
  } catch (error) {
    yield put(actions.deleteFirmwareUpdateOldFailure(error));
  }
}

//
//  ********* fetch FIRMWARE Firmware *******   //
function* fetchFirmwareRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchFirmware, payload);
    if (response.code === 100) {
      yield put(actions.fetchFirmwareSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchFirmwareFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchFirmwareFailure(error));
  }
}
// ********* fetch FIRMWARE Firmware UPDATE Update NEW New  ******* //
function* fetchFirmwareUpdateNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchFirmwareUpdateNew, payload);
    if (response.code === 100) {
      yield put(actions.fetchFirmwareUpdateNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchFirmwareUpdateNewFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchFirmwareUpdateNewFailure(error));
  }
}
// ********* fetch FIRMWARE Firmware UPDATE Update NEW New  ******* //
function* fetchFirmwareUpdateInNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.fetchFirmwareUpdateInNew,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.fetchFirmwareUpdateInNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchFirmwareUpdateInNewFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchFirmwareUpdateInNewFailure(error));
  }
}
// ********* fetch FIRMWARE Firmware UPDATE Update OLD Old  ******* //
function* fetchFirmwareUpdateOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.fetchFirmwareUpdateOld, payload);
    if (response.code === 100) {
      yield put(actions.fetchFirmwareUpdateOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.fetchFirmwareUpdateOldFailure(response));
    }
  } catch (error) {
    yield put(actions.fetchFirmwareUpdateOldFailure(error));
  }
}

// enable
//  *********  enable FIRMWARE Firmware *******   //
function* enableFirmwareRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.enableFirmware, payload);
    if (response.code === 100) {
      yield put(actions.enableFirmwareSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.enableFirmwareFailure(response));
    }
  } catch (error) {
    yield put(actions.enableFirmwareFailure(error));
  }
}
// ********* enable FIRMWARE Firmware UPDATE Update NEW New ******* //
function* enableFirmwareUpdateNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.enableFirmwareUpdateNew, payload);
    if (response.code === 100) {
      yield put(actions.enableFirmwareUpdateNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.enableFirmwareUpdateNewFailure(response));
    }
  } catch (error) {
    yield put(actions.enableFirmwareUpdateNewFailure(error));
  }
}
// ********* enable FIRMWARE Firmware UPDATE Update OLD Old ******* //
function* enableFirmwareUpdateOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.enableFirmwareUpdateOld, payload);
    if (response.code === 100) {
      yield put(actions.enableFirmwareUpdateOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.enableFirmwareUpdateOldFailure(response));
    }
  } catch (error) {
    yield put(actions.enableFirmwareUpdateOldFailure(error));
  }
}

// disable
//  *********  disable FIRMWARE Firmware *******   //
function* disableFirmwareRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.disableFirmware, payload);
    if (response.code === 100) {
      yield put(actions.disableFirmwareSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.disableFirmwareFailure(response));
    }
  } catch (error) {
    yield put(actions.disableFirmwareFailure(error));
  }
}
// ********* disable FIRMWARE Firmware UPDATE Update NEW New ******* //
function* disableFirmwareUpdateNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.disableFirmwareUpdateNew,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.disableFirmwareUpdateNewSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.disableFirmwareUpdateNewFailure(response));
    }
  } catch (error) {
    yield put(actions.disableFirmwareUpdateNewFailure(error));
  }
}
// ********* disable FIRMWARE Firmware UPDATE Update OLD Old ******* //
function* disableFirmwareUpdateOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.disableFirmwareUpdateOld,
      payload,
    );
    if (response.code === 100) {
      yield put(actions.disableFirmwareUpdateOldSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.disableFirmwareUpdateOldFailure(response));
    }
  } catch (error) {
    yield put(actions.disableFirmwareUpdateOldFailure(error));
  }
}

// pass
// ********* disable FIRMWARE Firmware PASS Pass NEW New ******* //
function* goFirmwarePassRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.goFirmwarePass, payload);
    if (response.code === 100) {
      yield put(actions.goFirmwarePassSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.goFirmwarePassFailure(response));
    }
  } catch (error) {
    yield put(actions.goFirmwarePassFailure(error));
  }
}

// refuse
// ********* disable FIRMWARE Firmware REFUSE Refuse NEW New ******* //
function* goFirmwareRefuseRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.goFirmwareRefuse, payload);
    if (response.code === 100) {
      yield put(actions.goFirmwareRefuseSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(actions.goFirmwareRefuseFailure(response));
    }
  } catch (error) {
    yield put(actions.goFirmwareRefuseFailure(error));
  }
}

// Download
//
function* downloadImportDeviceRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.downloadImportDevice, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.downloadImportDeviceSuccess(response.data));
  } catch (error) {
    yield put(actions.downloadImportDeviceFailure(error));
  }
}
//
function* downloadVersionRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.downloadVersion, payload);

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.downloadVersionSuccess(response.data));
  } catch (error) {
    yield put(actions.downloadVersionFailure(error));
  }
}
// DOWNLOAD UPDATE Update NEW New
function* downloadVersionUpdateNewRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.downloadVersionUpdateNew,
      payload,
    );

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.downloadVersionUpdateNewSuccess(response.data));
  } catch (error) {
    yield put(actions.downloadVersionUpdateNewFailure(error));
  }
}
// DOWNLOAD UPDATE Update OLD Old
function* downloadVersionUpdateOldRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(
      CloudHawkApi2.downloadVersionUpdateOld,
      payload,
    );

    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    // createDownloadElement(downLoadUrl, obj.filename)
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);

    yield put(actions.downloadVersionUpdateOldSuccess(response.data));
  } catch (error) {
    yield put(actions.downloadVersionUpdateOldFailure(error));
  }
}

export default function* firmwareSaga() {
  yield all([
    // select
    takeEvery(actions.SELECT_FIRMWARE_REQUEST, selectFirmwareRequest),
    takeEvery(actions.SELECT_ORDER_REQUEST, selectOrderRequest),
    takeEvery(actions.SELECT_BATCH_REQUEST, selectBatchRequest),
    takeEvery(
      actions.SELECT_FIRMWARE_UPDATE_DTU_REQUEST,
      selectFirmwareUpdateDtuRequest,
    ),
    // create
    takeEvery(actions.CREATE_FIRMWARE_REQUEST, createFirmwareRequest),
    takeEvery(
      actions.CREATE_FIRMWARE_UPDATE_NEW_REQUEST,
      createFirmwareUpdateNewRequest,
    ),
    takeEvery(
      actions.CREATE_FIRMWARE_UPDATE_OLD_REQUEST,
      createFirmwareUpdateOldRequest,
    ),
    takeEvery(
      actions.localIsAtionFirmware_REQUEST,
      localIsAtionFirmwareRequest,
    ),
    // detail DETAIL
    // takeEvery(actions.DETAIL_FIRMWARE_REQUEST, detailFirmwareRequest),
    takeEvery(
      actions.DETAIL_FIRMWARE_UPDATE_NEW_REQUEST,
      detailFirmwareUpdateNewRequest,
    ),
    takeEvery(
      actions.DETAIL_FIRMWARE_UPDATE_HISTORY_NEW_REQUEST,
      detailFirmwareUpdateHistoryNewRequest,
    ),
    takeEvery(
      actions.DETAIL_FIRMWARE_UPDATE_OLD_REQUEST,
      detailFirmwareUpdateOldRequest,
    ),
    // edit
    takeEvery(actions.EDIT_FIRMWARE_REQUEST, editFirmwareRequest),
    takeEvery(
      actions.EDIT_FIRMWARE_UPDATE_NEW_REQUEST,
      editFirmwareUpdateNewRequest,
    ),
    takeEvery(
      actions.EDIT_FIRMWARE_UPDATE_OLD_REQUEST,
      editFirmwareUpdateOldRequest,
    ),
    takeEvery(
      actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_NEW_REQUEST,
      editFirmwareUpdateLanguageNewRequest,
    ),
    takeEvery(
      actions.EDIT_FIRMWARE_UPDATE_LANGUAGE_OLD_REQUEST,
      editFirmwareUpdateLanguageOldRequest,
    ),
    // delete
    takeEvery(actions.DELETE_FIRMWARE_REQUEST, deleteFirmwareRequest),
    takeEvery(
      actions.DELETE_FIRMWARE_UPDATE_NEW_REQUEST,
      deleteFirmwareUpdateNewRequest,
    ),
    takeEvery(
      actions.DELETE_FIRMWARE_UPDATE_OLD_REQUEST,
      deleteFirmwareUpdateOldRequest,
    ),
    // fetch
    takeEvery(actions.FETCH_FIRMWARE_REQUEST, fetchFirmwareRequest),
    takeEvery(
      actions.FETCH_FIRMWARE_UPDATE_NEW_REQUEST,
      fetchFirmwareUpdateNewRequest,
    ),
    takeEvery(
      actions.FETCH_FIRMWARE_UPDATE_IN_NEW_REQUEST,
      fetchFirmwareUpdateInNewRequest,
    ),
    takeEvery(
      actions.FETCH_FIRMWARE_UPDATE_OLD_REQUEST,
      fetchFirmwareUpdateOldRequest,
    ),
    // enable
    takeEvery(actions.ENABLE_FIRMWARE_REQUEST, enableFirmwareRequest),
    takeEvery(
      actions.ENABLE_FIRMWARE_UPDATE_NEW_REQUEST,
      enableFirmwareUpdateNewRequest,
    ),
    takeEvery(
      actions.ENABLE_FIRMWARE_UPDATE_OLD_REQUEST,
      enableFirmwareUpdateOldRequest,
    ),
    // disable
    takeEvery(actions.DISABLE_FIRMWARE_REQUEST, disableFirmwareRequest),
    takeEvery(
      actions.DISABLE_FIRMWARE_UPDATE_NEW_REQUEST,
      disableFirmwareUpdateNewRequest,
    ),
    takeEvery(
      actions.DISABLE_FIRMWARE_UPDATE_OLD_REQUEST,
      disableFirmwareUpdateOldRequest,
    ),
    //
    takeEvery(actions.GO_FIRMWARE_PASS_REQUEST, goFirmwarePassRequest),
    takeEvery(actions.GO_FIRMWARE_REFUSE_REQUEST, goFirmwareRefuseRequest),

    // DOWNLOAD
    takeEvery(
      actions.DOWNLOAD_IMPORT_DEVICE_REQUEST,
      downloadImportDeviceRequest,
    ),
    takeEvery(actions.DOWNLOAD_VERSION_REQUEST, downloadVersionRequest),
    takeEvery(
      actions.DOWNLOAD_VERSION_UPDATE_NEW_REQUEST,
      downloadVersionUpdateNewRequest,
    ),
    takeEvery(
      actions.DOWNLOAD_VERSION_UPDATE_OLD_REQUEST,
      downloadVersionUpdateOldRequest,
    ),
  ]);
}
