import { call, all, takeEvery, put } from "redux-saga/effects";
import productActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk";
import CloudHawkApi2 from "@iso/config/Services/CloudHawk2";
import Notification from "@iso/components/Notification";

//  ********* 根据订单号、批次号入库 *******   //
function* upDeviceTestOrderBatchRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.upDeviceTestOrderBatch, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(productActions.upDeviceTestOrderBatchSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.upDeviceTestOrderBatchFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.upDeviceTestOrderBatchFailure(error));
  }
}
//  *********  获取 产测订单号下拉 *******   //
function* fetchDeviceTestOrderRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getDeviceTestOrder, payload);
    if (response.code === 100) {
      yield put(productActions.fetchDeviceTestOrderSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchDeviceTestOrderFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchDeviceTestOrderFailure(error));
  }
}
//  *********  获取 产测批次号下拉 *******   //
function* fetchDeviceTestBatchRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getDeviceTestBatch, payload);
    if (response.code === 100) {
      yield put(productActions.fetchDeviceTestBatchSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchDeviceTestBatchFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchDeviceTestBatchFailure(error));
  }
}

//
function* fetchProductV1pRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.productsGetV1pClient, payload);
    if (response.code === 100) {
      yield put(productActions.fetchProductV1pSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchProductV1pFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchProductV1pFailure(error));
  }
}
//
function* createProductV1pRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.productsCreateV1pClient, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(productActions.createProductV1pSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createProductV1pFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createProductV1pFailure(error));
  }
}
//
function* editProductV1pRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.productsEditV1pClient, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(productActions.editProductV1pSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.editProductV1pFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.editProductV1pFailure(error));
  }
}
//
function* deleteProductV1pRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.productsDeleteV1pClient, payload);
    if (response.code === 100) {
      Notification("success", response.msg);
      yield put(productActions.deleteProductV1pSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteProductV1pFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.deleteProductV1pFailure(error));
  }
}

//
function* checkFactoryCodeRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.checkFactoryCode, payload);
    // yield put(productActions.addproductDataFileSuccess2(response.data));
    if (response.code === 100) {
      yield put(productActions.checkFactoryCodeSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.checkFactoryCodeSuccess2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.checkFactoryCodeFailure2(error));
  }
}

// 新增 产品 文件
function* addproductDataFileRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.productDataUpload, payload);
    // yield put(productActions.addproductDataFileSuccess2(response.data));
    if (response.code === 100) {
      yield put(productActions.addproductDataFileSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.addproductDataFileFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.addproductDataFileFailure2(error));
  }
}

//
// 获取 Select
//  *********  获取 history Select *******   //
function* fetchHistorySelectRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getHistorySelect, payload);
    if (response.code === 100) {
      yield put(productActions.fetchHistorySelectSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchHistorySelectFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchHistorySelectFailure(error));
  }
}
//  *********  获取 series Select 获取产品型号对应的组件信息 *******   //
function* fetchSeriesSelectRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getSeriesSelectAim, payload);
    if (response.code === 100) {
      yield put(productActions.fetchSeriesSelectSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchSeriesSelectFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchSeriesSelectFailure(error));
  }
}
//  *********  获取 series Select 获取产品型号对应的组件信息 *******   //
function* fetchSeriesSelectSearchRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getSeriesSelectAim, payload);
    if (response.code === 100) {
      yield put(productActions.fetchSeriesSelectSearchSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchSeriesSelectSearchFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchSeriesSelectSearchFailure(error));
  }
}
//  *********  获取 Property Select *******   //
function* fetchPropertySelectRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getPropertySelect, payload);
    if (response.code === 100) {
      yield put(productActions.fetchPropertySelectSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchPropertySelectFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchPropertySelectFailure(error));
  }
}
//  *********  获取 Brands Select *******   //
function* fetchBrandsSelectRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getBrandsSelect, payload);
    if (response.code === 100) {
      yield put(productActions.fetchBrandsSelectSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchBrandsSelectFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchBrandsSelectFailure2(error));
  }
}
//  *********  获取 Categories Select *******   //
function* fetchCategoriesSelectRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getCategoriesSelect, payload);
    if (response.code === 100) {
      yield put(productActions.fetchCategoriesSelectSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchCategoriesSelectFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchCategoriesSelectFailure2(error));
  }
}
//  *********  获取 Models Select *******   //
function* fetchModelsSelectRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getModelsSelect, payload);
    if (response.code === 100) {
      yield put(productActions.fetchModelsSelectSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchModelsSelectFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchModelsSelectFailure2(error));
  }
}
//  *********  获取 Series Select *******   //
function* fetchSeriesSelectRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getSeriesSelect, payload);
    if (response.code === 100) {
      yield put(productActions.fetchSeriesSelectSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchSeriesSelectFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchSeriesSelectFailure2(error));
  }
}
//  *********  获取 client Client Select *******   //
function* fetchClientSelectRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getClientSelect, payload);
    if (response.code === 100) {
      yield put(productActions.fetchClientSelectSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchClientSelectFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.fetchClientSelectFailure2(error));
  }
}

//
// 新增
//  *********  新增 Product Brand v2 *******   //
function* createProductBrandRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createBrand, payload);
    if (response.code === 100) {
      yield put(productActions.createProductBrandSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createProductBrandFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createProductBrandFailure2(error));
  }
}
//  *********  新增 Product Category v2 *******   //
function* createProductCategoryRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createCategory, payload);
    if (response.code === 100) {
      yield put(productActions.createProductCategorySuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createProductCategoryFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createProductCategoryFailure2(error));
  }
}
//  *********  新增 Product Model v2 *******   //
function* createProductModelRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createModel, payload);
    if (response.code === 100) {
      yield put(productActions.createProductModelSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createProductModelFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createProductModelFailure2(error));
  }
}
//  *********  新增 Product Series v2 *******   //
function* createProductSeriesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createSeries, payload);
    if (response.code === 100) {
      yield put(productActions.createProductSeriesSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createProductSeriesFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createProductSeriesFailure2(error));
  }
}
//  *********  新增 Product client Client v2 *******   //
function* createProductClientRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createClient, payload);
    if (response.code === 100) {
      yield put(productActions.createProductClientSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createProductClientFailure2(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createProductClientFailure2(error));
  }
}
//  *********  新增 Tsl 服务/1 *******   //
function* createTsl1Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createTsl1, payload);
    if (response.code === 100) {
      yield put(productActions.createTsl1Success(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createTsl1Failure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createTsl1Failure(error));
  }
}
//  *********  新增 Tsl 服务/2 *******   //
function* createTsl2Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createTsl2, payload);
    if (response.code === 100) {
      yield put(productActions.createTsl2Success(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createTsl2Failure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createTsl2Failure(error));
  }
}
//  *********  新增 Tsl 服务/3 *******   //
function* createTsl3Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createTsl3, payload);
    if (response.code === 100) {
      yield put(productActions.createTsl3Success(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createTsl3Failure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createTsl3Failure(error));
  }
}
//  *********  新增 Tsl 发布草稿 *******   //
function* createTslCraftRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createTslCraft, payload);
    if (response.code === 100) {
      yield put(productActions.createTslCraftSuccess(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createTslCraftFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.createTslCraftFailure(error));
  }
}

//  ********* 恢复到可编辑草稿 *******   //
function* recoveryTslRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.recoveryTsl, payload);
    if (response.code === 100) {
      yield put(productActions.recoveryTslSuccess(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.recoveryTslFailure(response));
    }
  } catch (error) {
    // Notification("error", "Request error");
    yield put(productActions.recoveryTslFailure(error));
  }
}

//
// 编辑
//  ********* 编辑 Product Brand v2 *******   //
function* editProductBrandRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editBrand, payload);
    if (response.code === 100) {
      yield put(productActions.editProductBrandSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.editProductBrandFailure2(response));
    }
  } catch (error) {
    yield put(productActions.editProductBrandFailure2(error));
  }
}
//  *********  编辑 Product Category v2 *******   //
function* editProductCategoryRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editCategory, payload);
    if (response.code === 100) {
      yield put(productActions.editProductCategorySuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.editProductCategoryFailure2(response));
    }
  } catch (error) {
    yield put(productActions.editProductCategoryFailure2(error));
  }
}
//  *********  编辑 Product Model v2 *******   //
function* editProductModelRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editModel, payload);
    if (response.code === 100) {
      yield put(productActions.editProductModelSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.editProductModelFailure2(response));
    }
  } catch (error) {
    yield put(productActions.editProductModelFailure2(error));
  }
}
//  *********  编辑 Product Series v2 *******   //
function* editProductSeriesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editSeries, payload);
    if (response.code === 100) {
      yield put(productActions.editProductSeriesSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.editProductSeriesFailure2(response));
    }
  } catch (error) {
    yield put(productActions.editProductSeriesFailure2(error));
  }
}
//  *********  编辑 Product client Client v2 *******   //
function* editProductClientRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editClient, payload);
    if (response.code === 100) {
      yield put(productActions.editProductClientSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.editProductClientFailure2(response));
    }
  } catch (error) {
    yield put(productActions.editProductClientFailure2(error));
  }
}
//  *********  编辑 Tsl 服务/1 *******   //
function* editTsl1Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editTsl1, payload);
    if (response.code === 100) {
      yield put(productActions.editTsl1Success(response));
    } else {
      Notification("error", response.msg || "error");
      yield put(productActions.editTsl1Failure(response));
    }
  } catch (error) {
    yield put(productActions.editTsl1Failure(error));
  }
}
//  *********  编辑 Tsl 服务/2 *******   //
function* editTsl2Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editTsl2, payload);
    if (response.code === 100) {
      yield put(productActions.editTsl2Success(response));
    } else {
      Notification("error", response.msg || "error");
      yield put(productActions.editTsl2Failure(response));
    }
  } catch (error) {
    yield put(productActions.editTsl2Failure(error));
  }
}
//  *********  编辑 Tsl 服务/3 *******   //
function* editTsl3Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editTsl3, payload);
    if (response.code === 100) {
      yield put(productActions.editTsl3Success(response));
    } else {
      Notification("error", response.msg || "error");
      yield put(productActions.editTsl3Failure(response));
    }
  } catch (error) {
    yield put(productActions.editTsl3Failure(error));
  }
}

//
// 删除
//  *********  删除 Product Brand v2 *******   //
function* deleteProductBrandRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteBrand, payload);
    if (response.code === 100) {
      yield put(productActions.deleteProductBrandSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteProductBrandFailure2(response));
    }
  } catch (error) {
    yield put(productActions.deleteProductBrandFailure2(error));
  }
}
//  *********  删除 Product Category v2 *******   //
function* deleteProductCategoryRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteCategory, payload);
    if (response.code === 100) {
      yield put(productActions.deleteProductCategorySuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteProductCategoryFailure2(response));
    }
  } catch (error) {
    yield put(productActions.deleteProductCategoryFailure2(error));
  }
}
//  *********  删除 Product Model v2 *******   //
function* deleteProductModelRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteModel, payload);
    if (response.code === 100) {
      yield put(productActions.deleteProductModelSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteProductModelFailure2(response));
    }
  } catch (error) {
    yield put(productActions.deleteProductModelFailure2(error));
  }
}
//  *********  删除 Product Series v2 *******   //
function* deleteProductSeriesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteSeries, payload);
    if (response.code === 100) {
      yield put(productActions.deleteProductSeriesSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteProductSeriesFailure2(response));
    }
  } catch (error) {
    yield put(productActions.deleteProductSeriesFailure2(error));
  }
}
//  *********  删除 Product client Client v2 *******   //
function* deleteProductClientRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteClient, payload);
    if (response.code === 100) {
      yield put(productActions.deleteProductClientSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteProductClientFailure2(response));
    }
  } catch (error) {
    yield put(productActions.deleteProductClientFailure2(error));
  }
}
//  *********  删除 Tsl 服务/1 *******   //
function* deleteTsl1Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteTsl1, payload);
    if (response.code === 100) {
      yield put(productActions.deleteTsl1Success(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteTsl1Failure(response));
    }
  } catch (error) {
    yield put(productActions.deleteTsl1Failure(error));
  }
}
//  *********  删除 Tsl 服务/2 *******   //
function* deleteTsl2Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteTsl2, payload);
    if (response.code === 100) {
      yield put(productActions.deleteTsl2Success(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteTsl2Failure(response));
    }
  } catch (error) {
    yield put(productActions.deleteTsl2Failure(error));
  }
}
//  *********  删除 Tsl 服务/3 *******   //
function* deleteTsl3Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteTsl3, payload);
    if (response.code === 100) {
      yield put(productActions.deleteTsl3Success(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteTsl3Failure(response));
    }
  } catch (error) {
    yield put(productActions.deleteTsl3Failure(error));
  }
}

//获取产品型号下拉款
function* fetchTypeSelectRequest() {
  try {
    const response = yield call(CloudHawkApi2.selectEqNewProductType);
    if (response.code === 100) {
      yield put(productActions.fetchTypesSelectSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchTypesSelectFailure(response));
    }
  } catch (error) {
    yield put(productActions.fetchTypesSelectFailure(error));
  }
}

//
// 获取 List
//  *********  获取 Brands List *******   //
function* fetchBrandsRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getBrands, payload);
    if (response.code === 100) {
      yield put(productActions.fetchBrandsSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchBrandsFailure2(response));
    }
  } catch (error) {
    yield put(productActions.fetchBrandsFailure2(error));
  }
}
//  *********  获取 Categories List *******   //
function* fetchCategoriesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getCategories, payload);
    if (response.code === 100) {
      yield put(productActions.fetchCategoriesSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchCategoriesFailure2(response));
    }
  } catch (error) {
    yield put(productActions.fetchCategoriesFailure2(error));
  }
}
//  *********  获取 Models List *******   //
function* fetchModelsRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getModels, payload);
    if (response.code === 100) {
      yield put(productActions.fetchModelsSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchModelsFailure2(response));
    }
  } catch (error) {
    yield put(productActions.fetchModelsFailure2(error));
  }
}
//  *********  获取 Series List *******   //
function* fetchSeriesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getSeries, payload);
    if (response.code === 100) {
      yield put(productActions.fetchSeriesSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchSeriesFailure2(response));
    }
  } catch (error) {
    yield put(productActions.fetchSeriesFailure2(error));
  }
}
//  *********  获取 client Client List *******   //
function* fetchClientRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getClient, payload);
    if (response.code === 100) {
      yield put(productActions.fetchClientSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchClientFailure2(response));
    }
  } catch (error) {
    yield put(productActions.fetchClientFailure2(error));
  }
}

//
// 详情
//  *********  获取 Product Series 详情 v2 *******   //
function* fetchSeriesDetailRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailSeries, payload);
    if (response.code === 100) {
      yield put(productActions.fetchSeriesDetailSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchSeriesDetailFailure2(response));
    }
  } catch (error) {
    yield put(productActions.fetchSeriesDetailFailure2(error));
  }
}
//  *********  获取 Tsl 服务/1 详情 *******   //
function* detailTsl1Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailTsl1, payload);
    if (response.code === 100) {
      yield put(productActions.detailTsl1Success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.detailTsl1Failure(response));
    }
  } catch (error) {
    yield put(productActions.detailTsl1Failure(error));
  }
}
//  *********  获取 Tsl 服务/2 详情 *******   //
function* detailTsl2Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailTsl2, payload);
    if (response.code === 100) {
      yield put(productActions.detailTsl2Success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.detailTsl2Failure(response));
    }
  } catch (error) {
    yield put(productActions.detailTsl2Failure(error));
  }
}
//  *********  获取 Tsl 服务/3 详情 *******   //
function* detailTsl3Request(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailTsl3, payload);
    if (response.code === 100) {
      yield put(productActions.detailTsl3Success(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.detailTsl3Failure(response));
    }
  } catch (error) {
    yield put(productActions.detailTsl3Failure(error));
  }
}

//
// 产品型号
//  *********  copy 产品型号 *******   //
function* copyTypesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.copyTypes, payload);
    if (response.code === 100) {
      yield put(productActions.copyTypesSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.copyTypesFailure2(response));
    }
  } catch (error) {
    yield put(productActions.copyTypesFailure2(error));
  }
}
//  *********  新增 产品型号 *******   //
function* createTypesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.createTypes, payload);
    if (response.code === 100) {
      yield put(productActions.createTypesSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.createTypesFailure2(response));
    }
  } catch (error) {
    yield put(productActions.createTypesFailure2(error));
  }
}
//  *********  编辑 产品型号 *******   //
function* editTypesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.editTypes, payload);
    if (response.code === 100) {
      yield put(productActions.editTypesSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.editTypesFailure2(response));
    }
  } catch (error) {
    yield put(productActions.editTypesFailure2(error));
  }
}
//  *********  删除 产品型号 *******   //
function* deleteTypesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.deleteTypes, payload);
    if (response.code === 100) {
      yield put(productActions.deleteTypesSuccess2(response));
    } else {
      Notification("error", response.msg);
      yield put(productActions.deleteTypesFailure2(response));
    }
  } catch (error) {
    yield put(productActions.deleteTypesFailure2(error));
  }
}
//  *********  获取 产品型号 List *******   //
function* fetchTypesRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.getTypes, payload);
    if (response.code === 100) {
      yield put(productActions.fetchTypesSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchTypesFailure2(response));
    }
  } catch (error) {
    yield put(productActions.fetchTypesFailure2(error));
  }
}
//  *********  获取 产品型号 detail *******   //
function* fetchTypesDetailRequest2(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailTypes, payload);
    if (response.code === 100) {
      yield put(productActions.fetchTypesDetailSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchTypesDetailFailure2(response));
    }
  } catch (error) {
    yield put(productActions.fetchTypesDetailFailure2(error));
  }
}
//  *********  获取 产品型号 detail *******   //
function* fetchTypesDetailRequest2V1(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.detailTypesV1, payload);
    if (response.code === 100) {
      yield put(productActions.fetchTypesDetailSuccess2(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchTypesDetailFailure2(response));
    }
  } catch (error) {
    yield put(productActions.fetchTypesDetailFailure2(error));
  }
}

//
// 物模型
//  *********  获取 功能列表 list *******   //
function* fetchTslFunctionRequest(request) {
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi2.tslFunction, payload);
    if (response.code === 100) {
      yield put(productActions.fetchTslFunctionSuccess(response.data));
    } else {
      Notification("error", response.msg);
      yield put(productActions.fetchTslFunctionFailure(response));
    }
  } catch (error) {
    yield put(productActions.fetchTslFunctionFailure(error));
  }
}

export default function* productSagaV2() {
  yield all([
    takeEvery(
      productActions.UP_DEVICE_ORDER_BATCH_REQUEST,
      upDeviceTestOrderBatchRequest,
    ),
    takeEvery(
      productActions.FETCH_DEVICE_ORDER_REQUEST,
      fetchDeviceTestOrderRequest,
    ),
    takeEvery(
      productActions.FETCH_DEVICE_BATCH_REQUEST,
      fetchDeviceTestBatchRequest,
    ),
    takeEvery(
      productActions.FETCH_PRODUCT_V1_P_REQUEST,
      fetchProductV1pRequest,
    ),
    takeEvery(
      productActions.CREATE_PRODUCT_V1_P_REQUEST,
      createProductV1pRequest,
    ),
    takeEvery(productActions.EDIT_PRODUCT_V1_P_REQUEST, editProductV1pRequest),
    takeEvery(
      productActions.DELETE_PRODUCT_V1_P_REQUEST,
      deleteProductV1pRequest,
    ),

    takeEvery(
      productActions.CHECK_FACTORYCODE_REQUEST2,
      checkFactoryCodeRequest2,
    ),

    // 新增 产品 文件
    takeEvery(
      productActions.ADD_PRODUCT_DATA_FILE_REQUEST2,
      addproductDataFileRequest2,
    ),

    // 获取 Select
    takeEvery(
      productActions.FETCH_SERIES_SELECT_REQUEST,
      fetchSeriesSelectRequest,
    ),
    takeEvery(
      productActions.FETCH_SERIES_SELECT_SEARCH_REQUEST,
      fetchSeriesSelectSearchRequest,
    ),
    takeEvery(
      productActions.FETCH_HISTORY_SELECT_REQUEST,
      fetchHistorySelectRequest,
    ),
    takeEvery(
      productActions.FETCH_PROPERTY_SELECT_REQUEST,
      fetchPropertySelectRequest,
    ),
    takeEvery(
      productActions.FETCH_BRANDS_SELECT_REQUEST2,
      fetchBrandsSelectRequest2,
    ),
    takeEvery(
      productActions.FETCH_CATEGORY_SELECT_REQUEST2,
      fetchCategoriesSelectRequest2,
    ),
    takeEvery(
      productActions.FETCH_MODEL_SELECT_REQUEST2,
      fetchModelsSelectRequest2,
    ),
    takeEvery(
      productActions.FETCH_SERIES_SELECT_REQUEST2,
      fetchSeriesSelectRequest2,
    ),
    takeEvery(
      productActions.FETCH_CLIENT_SELECT_REQUEST2,
      fetchClientSelectRequest2,
    ),

    // 新增
    takeEvery(
      productActions.CREATE_PRODUCT_BRAND_REQUEST2,
      createProductBrandRequest2,
    ),
    takeEvery(
      productActions.CREATE_PRODUCT_CATEGORY_REQUEST2,
      createProductCategoryRequest2,
    ),
    takeEvery(
      productActions.CREATE_PRODUCT_MODEL_REQUEST2,
      createProductModelRequest2,
    ),
    takeEvery(
      productActions.CREATE_PRODUCT_SERIES_REQUEST2,
      createProductSeriesRequest2,
    ),
    takeEvery(
      productActions.CREATE_PRODUCT_CLIENT_REQUEST2,
      createProductClientRequest2,
    ),
    takeEvery(productActions.CREATE_TSL_1_REQUEST, createTsl1Request),
    takeEvery(productActions.CREATE_TSL_2_REQUEST, createTsl2Request),
    takeEvery(productActions.CREATE_TSL_3_REQUEST, createTsl3Request),
    takeEvery(productActions.CREATE_TSL_CRAFT_REQUEST, createTslCraftRequest),

    takeEvery(productActions.RECOVERY_TSL_REQUEST, recoveryTslRequest),

    // 编辑
    takeEvery(
      productActions.EDIT_PRODUCT_BRAND_REQUEST2,
      editProductBrandRequest2,
    ),
    takeEvery(
      productActions.EDIT_PRODUCT_CATEGORY_REQUEST2,
      editProductCategoryRequest2,
    ),
    takeEvery(
      productActions.EDIT_PRODUCT_MODEL_REQUEST2,
      editProductModelRequest2,
    ),
    takeEvery(
      productActions.EDIT_PRODUCT_SERIES_REQUEST2,
      editProductSeriesRequest2,
    ),
    takeEvery(
      productActions.EDIT_PRODUCT_CLIENT_REQUEST2,
      editProductClientRequest2,
    ),
    takeEvery(productActions.EDIT_TSL_1_REQUEST, editTsl1Request),
    takeEvery(productActions.EDIT_TSL_2_REQUEST, editTsl2Request),
    takeEvery(productActions.EDIT_TSL_3_REQUEST, editTsl3Request),

    // 删除
    takeEvery(
      productActions.DELETE_PRODUCT_BRAND_REQUEST2,
      deleteProductBrandRequest2,
    ),
    takeEvery(
      productActions.DELETE_PRODUCT_CATEGORY_REQUEST2,
      deleteProductCategoryRequest2,
    ),
    takeEvery(
      productActions.DELETE_PRODUCT_MODEL_REQUEST2,
      deleteProductModelRequest2,
    ),
    takeEvery(
      productActions.DELETE_PRODUCT_SERIES_REQUEST2,
      deleteProductSeriesRequest2,
    ),
    takeEvery(
      productActions.DELETE_PRODUCT_CLIENT_REQUEST2,
      deleteProductClientRequest2,
    ),
    takeEvery(productActions.DELETE_TSL_1_REQUEST, deleteTsl1Request),
    takeEvery(productActions.DELETE_TSL_2_REQUEST, deleteTsl2Request),
    takeEvery(productActions.DELETE_TSL_3_REQUEST, deleteTsl3Request),

    //获取产品型号下拉框
    takeEvery(productActions.FETCH_TYPE_SELECT, fetchTypeSelectRequest),
    // takeEvery(productActions.FETCH_TYPE_SELECT_SUCCESS, fetchTypeSelectRequest2),
    // takeEvery(productActions.FETCH_MODEL_REQUEST2, fetchTypeSelect),
    // 获取 List
    takeEvery(productActions.FETCH_BRANDS_REQUEST2, fetchBrandsRequest2),
    takeEvery(productActions.FETCH_CATEGORY_REQUEST2, fetchCategoriesRequest2),
    takeEvery(productActions.FETCH_MODEL_REQUEST2, fetchModelsRequest2),
    takeEvery(productActions.FETCH_SERIES_REQUEST2, fetchSeriesRequest2),
    takeEvery(productActions.FETCH_CLIENT_REQUEST2, fetchClientRequest2),

    // 详情
    takeEvery(
      productActions.DETAIL_PRODUCT_SERIES_REQUEST2,
      fetchSeriesDetailRequest2,
    ),
    takeEvery(productActions.DETAIL_TSL_1_REQUEST, detailTsl1Request),
    takeEvery(productActions.DETAIL_TSL_2_REQUEST, detailTsl2Request),
    takeEvery(productActions.DETAIL_TSL_3_REQUEST, detailTsl3Request),

    // 产品型号
    takeEvery(productActions.COPY_TYPE_REQUEST2, copyTypesRequest2),
    takeEvery(productActions.CREATE_TYPE_REQUEST2, createTypesRequest2),
    takeEvery(productActions.EDIT_TYPE_REQUEST2, editTypesRequest2),
    takeEvery(productActions.DELETE_TYPE_REQUEST2, deleteTypesRequest2),
    takeEvery(productActions.FETCH_TYPE_REQUEST2, fetchTypesRequest2),
    takeEvery(
      productActions.FETCH_TYPE_DETAIL_REQUEST2,
      fetchTypesDetailRequest2,
    ),
    takeEvery(
      productActions.FETCH_TYPE_DETAIL_REQUEST2_V1,
      fetchTypesDetailRequest2V1,
    ),
    
    // 物模型
    takeEvery(
      productActions.FETCH_TSL_FUNCTION_REQUEST,
      fetchTslFunctionRequest,
    ),
  ]);
}
