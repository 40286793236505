const SysCustomerActions = {
  GET_Customer_List_REQUEST: "GET_Customer_List_REQUEST",
  GET_Customer_List_SUCCESS: "GET_Customer_List_SUCCESS",
  GET_Customer_List_ERROR: "GET_Customer_List_ERROR",

  ADD_Customer_List_REQUEST: "ADD_Customer_List_REQUEST",
  ADD_Customer_List_SUCCESS: "ADD_Customer_List_SUCCESS",
  ADD_Customer_List_ERROR: "ADD_Customer_List_ERROR",

  EDIT_Customer_List_REQUEST: "EDIT_Customer_List_REQUEST",
  EDIT_Customer_List_SUCCESS: "EDIT_Customer_List_SUCCESS",
  EDIT_Customer_List_ERROR: "EDIT_Customer_List_ERROR",

  DEL_Customer_List_REQUEST: "DEL_Customer_List_REQUEST",
  DEL_Customer_List_SUCCESS: "DEL_Customer_List_SUCCESS",
  DEL_Customer_List_ERROR: "DEL_Customer_List_ERROR",

  GET_CustomerALL_List_REQUEST: "GET_CustomerALL_List_REQUEST",
  GET_CustomerALL_List_SUCCESS: "GET_CustomerALL_List_SUCCESS",
  GET_CustomerALL_List_ERROR: "GET_CustomerALL_List_ERROR",

  fetchGETCustomerList: (request) => ({
    type: SysCustomerActions.GET_Customer_List_REQUEST,
    payload: request,
  }),

  fetchGETCustomerListSuccess: (data) => ({
    type: SysCustomerActions.GET_Customer_List_SUCCESS,
    payload: data,
  }),
  fetchGETCustomerListError: (error) => ({
    type: SysCustomerActions.GET_Customer_List_ERROR,
    payload: error,
  }),
  fetchGETCustomerALLList: () => ({
    type: SysCustomerActions.GET_CustomerALL_List_REQUEST,
    // payload: request
  }),
  fetchGETCustomerALLListSuccess: (data) => ({
    type: SysCustomerActions.GET_CustomerALL_List_SUCCESS,
    payload: data,
  }),
  fetchGETCustomerALLListError: (error) => ({
    type: SysCustomerActions.GET_CustomerALL_List_ERROR,
    payload: error,
  }),

  fetchADDCustomerList: (request) => ({
    type: SysCustomerActions.ADD_Customer_List_REQUEST,
    payload: request,
  }),
  fetchADDCustomerListSuccess: (data) => ({
    type: SysCustomerActions.ADD_Customer_List_SUCCESS,
    payload: data,
  }),
  fetchADDCustomerListError: (error) => ({
    type: SysCustomerActions.ADD_Customer_List_ERROR,
    payload: error,
  }),

  fetchEDITCustomerList: (request) => ({
    type: SysCustomerActions.EDIT_Customer_List_REQUEST,
    payload: request,
  }),
  fetchEDITCustomerListSuccess: (data) => ({
    type: SysCustomerActions.EDIT_Customer_List_SUCCESS,
    payload: data,
  }),
  fetchEDITCustomerListError: (error) => ({
    type: SysCustomerActions.EDIT_Customer_List_ERROR,
    payload: error,
  }),

  fetchDELCustomerList: (request) => ({
    type: SysCustomerActions.DEL_Customer_List_REQUEST,
    payload: request,
  }),
};

export default SysCustomerActions;
