import { call, all, takeEvery, put, select } from "redux-saga/effects";
import SetTemplateActions from "./actions";
import CloudHawkApi from "@iso/config/Services/CloudHawk3";
import statusAction from "../apiStatus/actions";
import { notification } from "antd";
import qs from "qs";

const alert = (response) => {
  notification.success({
    message: response.msg,
  });
};

const Error = (response) => {
  notification.error({
    message: "" + response.msg,
    // message: "Msg:" + response.msg,
  });
};

function* exportLanguageList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.exportLanguage, payload);
    let downLoadUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv,charset=UTF-8" }),
    );
    let disposition = response.headers["content-disposition"].split(";")[1];
    let obj = qs.parse(disposition);
    let a = document.createElement("a");
    a.href = downLoadUrl;
    a.download = obj.filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
    URL.revokeObjectURL(downLoadUrl);
  } catch (error) {}
}
//获取模板语言列表
function* getSetTemplateList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getlanguageList, payload);
    if (!response.success) {
      Error(response);
    }
    yield put(SetTemplateActions.fetchGETSetTemplateListSuccess(response.data));
  } catch (error) {
    yield put(SetTemplateActions.fetchGETSetTemplateListError(error));
  }
}
//获取语言详情列表
function* getSetTemplateLanguageDetailList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.getlanguageDetailList, payload);
    if (!response.success) {
      Error(response);
    }
    yield put(
      SetTemplateActions.fetchGETSetTemplateLanguageDetailSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(SetTemplateActions.fetchGETSetTemplateLanguageDetailError(error));
  }
}
//基础信息中-语言下拉框列表
function* getSetTemplateALLList() {
  yield put(statusAction.cloudHawkApiStatusClear());
  try {
    const response = yield call(CloudHawkApi.getlanguageALLList);
    yield put(
      SetTemplateActions.fetchGETSetTemplateALLListSuccess(response.data),
    );
  } catch (error) {
    yield put(SetTemplateActions.fetchGETSetTemplateALLListError(error));
  }
}

function* addSetTemplateList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.addSetTemplateList, payload);
    if (response.success) {
      yield put(SetTemplateActions.fetchGETSetTemplateList({}));
      alert(response);
    } else {
      Error(response);
    }
    //yield put(SetTemplateActions.fetchGETSetTemplateListSuccess(response.data));
  } catch (error) {
    // yield put(SetTemplateActions.fetchGETSetTemplateListError(error));
  }
}

function* pubilshSetTemplateList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.isPublishTemp, payload.params1);
    if (response.success) {
      yield put(SetTemplateActions.fetchGETSetTemplateList(payload.params2));
      if (payload.params1.state === "N") {
        const res = {
          msg: "撤销成功!",
        };
        alert(res);
      } else {
        const res = {
          msg: "发布成功!",
        };
        alert(res);
      }
    } else {
      Error(response);
    }
    //yield put(SetTemplateActions.fetchGETSetTemplateListSuccess(response.data));
  } catch (error) {
    // yield put(SetTemplateActions.fetchGETSetTemplateListError(error));
  }
}
//编辑语言详情
function* editSetTemplateList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.editlanguageList, payload);
    if (response.success) {
      yield put(
        SetTemplateActions.fetchGETSetTemplateLanguageDetailList({
          productId: payload.productId,
        }),
      );
      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {}
}

function* delSetTemplateList(request) {
  yield put(statusAction.cloudHawkApiStatusClear());
  const { payload } = request;
  try {
    const response = yield call(CloudHawkApi.dellanguageList, payload);
    if (response.success) {
      const total = yield select((state) => state.setTemplate.totalList);
      if (payload.params2.pageNum > Math.ceil((total - 1) / 10)) {
        //last page
        payload.params2.pageNum = payload.params2.pageNum - 1;
        yield put(SetTemplateActions.fetchGETSetTemplateList(payload.params2));
      } else {
        //this page
        yield put(SetTemplateActions.fetchGETSetTemplateList(payload.params2));
      }

      alert(response);
    } else {
      Error(response);
    }
  } catch (error) {}
}
export default function* userSaga() {
  yield all([
    takeEvery(
      SetTemplateActions.GET_SetTemplate_List_REQUEST,
      getSetTemplateList,
    ),
    takeEvery(
      SetTemplateActions.GET_SetTemplateLanguageDetail_List_REQUEST,
      getSetTemplateLanguageDetailList,
    ),
    takeEvery(
      SetTemplateActions.GET_SetTemplateALL_List_REQUEST,
      getSetTemplateALLList,
    ),
    takeEvery(
      SetTemplateActions.ADD_SetTemplate_List_REQUEST,
      addSetTemplateList,
    ),
    takeEvery(
      SetTemplateActions.Publish_SetTemplate_List_REQUEST,
      pubilshSetTemplateList,
    ),
    takeEvery(
      SetTemplateActions.EDIT_SetTemplate_List_REQUEST,
      editSetTemplateList,
    ),
    takeEvery(
      SetTemplateActions.DEL_SetTemplate_List_REQUEST,
      delSetTemplateList,
    ),
    takeEvery(
      SetTemplateActions.Export_Language_List_REQUEST,
      exportLanguageList,
    ),
  ]);
}
